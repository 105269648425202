import {createBaseOptions, fetchJson,  METHOD_POST} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";

export const fetchNewUserCustomizations = (settings,callback) => {
    
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_POST;
    options.headers["Content-Type"] = "application/json";
    options["body"] = settings;

    let r = fetchJson(`${config.url.API_URL}/usercustomization/custom/item`, options) // /async
    r.then(json => {
       callback(json);
    }).catch(error => {
        console.log(error);
        callback(error);
    });
};