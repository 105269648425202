import RequestResult from "../storage/RequestResult";
import RequestState from "../storage/RequestState";

export const isEmptyObject = (obj) => {
    if(obj === undefined) return true;
    if(obj === null) return true;
    for(let prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }
    return true;
};

/**
 * Return true if object is null or undefined.
 *
 * @param {Object} obj
 * @returns {boolean} true object is null or undefined
 */
export const isUndefined = (obj) => {
    return obj === undefined || obj === null;

};

export const isEmptyValue = (value) => {
    if(value === null || value === undefined) return true;
    if(typeof value === 'undefined') {
        return true;
    } else if(typeof value === 'boolean') {
        return false;
    } else if(typeof value === 'string') {
        if(value === '' || value.length === 0) return true;
    } else if(typeof value === 'number') {
        if(isNaN(value))return true;
    } else if(typeof value === 'object') {
        if(Array.isArray(value)) {
            if(value.length === 0) return true;
        } else {
            if (isEmptyObject(value)) return true;
        }
    } else if(typeof value === 'function') {
        return false;
    } else if(typeof value === 'symbol') {
        return false;
    }

    return false;
};

/**
 * Compare if objects have some values.
 * For example compare two arrays.
 *
 * @param {*} obj1
 * @param {*} obj2
 * @returns {boolean}
 */
export const areEqualObjects = (obj1, obj2) => {
    return (obj1 === obj2 || (Array.isArray(obj1) && Array.isArray(obj2) && obj1.toString() === obj2.toString()));
};

/**
 * Check if a date is valid.
 *
 * @param {Date} date
 * @returns {boolean}
 */
export const isDateValid = (date) => {
    // An invalid date object returns NaN for getTime() and NaN is the only
    // object not strictly equal to itself.
    return date !== null && date !== undefined && date.getTime() === date.getTime();
};


/**
 * Check if value is an array
 *
 * @param {*} obj variable to check
 * @returns {boolean} if is an Array object
 */
export const isArray = (obj) => { return obj !== undefined && obj !== null && obj.constructor === [].constructor; };
/**
 * Check if value is a boolean
 *
 * @param {*} obj variable to check
 * @returns {boolean} if is a Boolean object
 */
export const isBoolean = (obj) => { return obj !== undefined && obj !== null && obj.constructor === new Boolean().constructor };
/**
 * Check if value is a function
 *
 * @param {*} obj variable to check
 * @returns {boolean} if is a Function
 */
export const isFunction = (obj) => { return obj !== undefined && obj !== null && obj.constructor === Function };
/**
 * Check if value is a number
 *
 * @param {*} obj variable to check
 * @returns {boolean} if is a Number object
 */
export const isNumber = (obj) => { return obj !== undefined && obj !== null && obj.constructor === new Number().constructor };
/**
 * Check if value is a string
 *
 * @param {*} obj variable to check
 * @returns {boolean} if is a String object
 */
export const isString = (obj) => { return obj !== undefined && obj !== null && obj.constructor === ''.constructor };
/**
 * Check if value is a json object
 *
 * @param {*} obj variable to check
 * @returns {boolean} if is a json object
 */
export const isObject = (obj) => { return obj !== undefined && obj !== null && obj.constructor === {}.constructor };
/**
 * Check if value is a date object
 *
 * @param {*} obj variable to check
 * @returns {boolean} if is a date object
 */
export const isDate = (obj) => { return obj !== undefined && obj !== null && obj.constructor === new Date().constructor };
/**
 * Check if value is a date object
 *
 * @param {*} obj variable to check
 * @returns {boolean} if is a date object
 */
export const isRegexp = (obj) => { return obj !== undefined && obj !== null && obj.constructor === new RegExp().constructor };

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}



export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};
export const EMPTY_FUNCTION = () => {};
export const EMPTY_REQUEST_RESULT_INIT_STATE = new RequestResult(RequestState.getInstanceInit(), null);