import React from 'react'
import { findDOMNode } from 'react-dom'
import {DropTarget} from 'react-dnd'
import {Types} from "./DraggableConst";
import PropTypes from "prop-types";
import {isUndefined} from "../../utils/JsObjectHelper";

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const chessSquareTarget = {
    canDrop(props, monitor) {
        // console.log("canDrop");
        // You can disallow drop based on props or item
        const item = monitor.getItem()
        return true; //canMakeChessMove(item.fromPosition, props.position)
    },

    hover(props, monitor, component) {
        // console.log("drop hover");
        // This is fired very often and lets you perform side effects
        // in response to the hover. You can't handle enter and leave
        // here—if you need them, put monitor.isOver() into collect() so you
        // can just use componentDidUpdate() to handle enter/leave.

        // You can access the coordinates if you need them
        const clientOffset = monitor.getClientOffset()
        const componentRect = findDOMNode(component).getBoundingClientRect()

        // You can check whether we're over a nested drop target
        const isJustOverThisOne = monitor.isOver({ shallow: true })

        // You will receive hover() even for items for which canDrop() is false
        const canDrop = monitor.canDrop()
    },

    drop(props, monitor, component) {
        // console.log("drop!!!!");
        // console.log(props);
       let data = {item: props.object};
        // console.log("drop");
        if (monitor.didDrop()) {
            // If you want, you can check whether some nested
            // target already handled drop
            return data;
        }

        // Obtain the dragged item
        const item = monitor.getItem()

        // You can do something with it
       // ChessActions.movePiece(item.fromPosition, props.position)

        // You can also do nothing and return a drop result,
        // which will be available as monitor.getDropResult()
        // in the drag source's endDrag() method
        data.moved = true;
        return data;
    },
};

/**
 * Specifies which props to inject into your component.
 */
const collect = (connect, monitor) => {
    // console.log("drop collect");
    // console.log(connect);
    // console.log(monitor.isOver());
    // console.log( monitor.isOver({ shallow: true }));
    // console.log( monitor.getItemType());
    return {
        // Call this function inside render()
        // to let React DnD handle the drag events:
        connectDropTarget: connect.dropTarget(),
        // You can ask the monitor about the current drag state:
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
    }
};

class DroppableDiv extends React.Component{
    constructor(props) {
        super(props);
        this.onSectionSelect = this.onSectionSelect.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.isOver && this.props.isOver) {
            // You can use this as enter handler
            // console.log("change is over");
        }

        if (prevProps.isOver && !this.props.isOver) {
            // You can use this as leave handler
            // console.log("change is over");
        }

        if (prevProps.isOverCurrent && !this.props.isOverCurrent) {
            // You can be more specific and track enter/leav
            // shallowly, not including nested targets
            // console.log("change is over current");
        }
    }

    onSectionSelect() {
        if (this.props.onSelect) {
            this.props.onSelect(this.props.object);
        }
    }

    render() {
        // Your component receives its own props as usual
        // const { position } = this.props;
        // console.log(position);

        // These props are injected by React DnD,
        // as defined by your `collect` function above:
        const { isOver, canDrop, connectDropTarget } = this.props;
        // console.log('isOver: ' + isOver);
        // console.log('canDrop: ' + canDrop);


        // console.log(this.props);

        let color = 'white';
        if(this.props.baseColor !== "none") color = this.props.baseColor;
        if (isOver && canDrop)
            if(this.props.overAndDroppableColor !== "none") color = this.props.overAndDroppableColor;
        if (!isOver && canDrop)
            if(this.props.notOverAndDroppableColor !== "none") color = this.props.notOverAndDroppableColor;
        if (isOver && !canDrop)
            if(this.props.overAndNotDroppableColor !== "none") color = this.props.overAndNotDroppableColor;
        
        return connectDropTarget(
            
            <div className="Cell" style={{backgroundColor: color, width: this.props.width, height: this.props.height, overflow: 'auto'}} onClick={this.onSectionSelect}>
                <div style={{textAlign:'center', fontWeight:'bold', color:'black'}}>
                    {this.props.object.name}
                </div>
                <div className="droppableDivTagsArray">
                    {this.props.children}
                </div>
            </div>,
        )
    }
}

const WrappedDroppableDiv = DropTarget(Types.TAG, chessSquareTarget, collect)(DroppableDiv);
export default WrappedDroppableDiv;

WrappedDroppableDiv.propTypes = {
    object: PropTypes.object.isRequired,
    baseColor: PropTypes.string.isRequired,
    overAndDroppableColor: PropTypes.string.isRequired,
    notOverAndDroppableColor: PropTypes.string.isRequired,
    overAndNotDroppableColor: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

WrappedDroppableDiv.defaultProps = {
    baseColor: "white",//"grey",
    overAndDroppableColor: "#91d5ff",
    notOverAndDroppableColor: "#e6f7ff",//"yellow",
    overAndNotDroppableColor: "red",
    width: '100%',
    height: '100%'
};

