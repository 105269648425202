import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { isEmptyValue } from '../../utils/JsObjectHelper';

const getValueMoment = (value) => {
    let dateValue = null;
    if (!isEmptyValue(value)) {
        dateValue = moment(value);
        if (!dateValue.isValid()) {
            dateValue = null;
        }
    }

    return dateValue;
};

class FormDatePicker extends React.Component {


    constructor(props) {
        super(props);
        const value = getValueMoment(props.value)//props.fileUrlArrayData.length || null;
        this.state = {
            // Same name as valuePropName in getFieldDecorator ('value' is default):
            // https://ant.design/components/form/?locale=en-US#getFieldDecorator(id,-options)-parameters
            value
        };
    }
    
    handleChange = (value) => {
        this.setState({ value : value });
        const { onChange } = this.props;
        if (onChange) {
            // This will provide the form with changes
            onChange(value);
        }
    };

    render() {
        return <DatePicker {...this.props} value={this.state.value} onChange={this.handleChange} ></DatePicker>;
    }
}

export default FormDatePicker;