import { createBaseOptions, fetchJson, METHOD_GET } from "../../utils/SuperAgentFetch";
import { config } from "../../config/Config";

export const fetchAllBrandingForAdmin = (callback) => {


    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_GET;
    options.headers["Content-Type"] = "application/json";

    let r = fetchJson(`${config.url.API_URL}/branding/custom/items/all`, options);
    r.then(json => {
        let res = [];
        if (json._embedded && json._embedded.brandings && json._embedded.brandings.length > 0) {
            res = json._embedded.brandings;
            callback(res);
        }

    }).catch(error => {
        console.log(error);
        // callback(error);
    });
};

export const fetchAllBrandingForAdminByType = (type,callback) => {


    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_GET;
    options.headers["Content-Type"] = "application/json";

    let r = fetchJson(`${config.url.API_URL}/branding/custom/items/all/${type}`, options);
    r.then(json => {
        let res = {};
        console.log(json,'^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
        if (json._embedded && json._embedded.brandings && json._embedded.brandings.length > 0) {
            res = json._embedded.brandings;
            callback(res);
        }

    }).catch(error => {
        console.log(error);
        // callback(error);
    });
};
