import React from 'react'
import {Tag} from "antd";
import { DragSource } from 'react-dnd';
import {Types} from "./DraggableConst";
import PropTypes from "prop-types";

/**
 * Specifies the drag source contract.
 * Only `beginDrag` function is required.
 */
const cardSource = {
    beginDrag: (props, monitor, component) => {
        return props.object;
    },

    endDrag: (props, monitor, component) => {
        if (!monitor.didDrop()) {
            return
        }

        // When dropped on a compatible target, do something
        //CardActions.moveCardToList(item.id, dropResult.listId)
        const item = monitor.getItem()
        const dropResult = monitor.getDropResult()

        if (dropResult) {
            props.onTagDropped(item, dropResult);
        }
    },
};

/**
 * Specifies which props to inject into your component.
 */
const collect = (connect, monitor) => {
    return {
        // Call this function inside render()
        // to let React DnD handle the drag events:
        connectDragSource: connect.dragSource(),
        // You can ask the monitor about the current drag state:
        isDragging: monitor.isDragging(),
    }
};


class DraggableTag extends React.Component {
    render() {
        // These two props are injected by React DnD,
        // as defined by your `collect` function above:
        const { /*isDragging,*/ connectDragSource } = this.props;
        return connectDragSource(<span className="NonDraggableArea"><Tag color="blue">{this.props.children}</Tag></span>);
    }
}


const WrappedDraggableTag = DragSource(Types.TAG, cardSource, collect)(DraggableTag);
export default WrappedDraggableTag;

WrappedDraggableTag.propTypes = {
    object: PropTypes.object.isRequired,
    onTagDropped: PropTypes.func.isRequired
};