import LineageDetailPresenter from '../../components/setup/LineageDetail';
import { connect } from 'react-redux';
import {
    fetchDetail,
    fetchEmptyNewSettings,
    fetchSettingsList,
    saveEditedSettings,
    saveNewSettings,
    unmountDetail
} from "../../actions/settings";
import {fetchObjectList} from "../../actions/objectList"
import {withRouter} from "react-router-dom";
import {isUndefined} from "../../utils/JsObjectHelper";
import { EMPTY_SETTINGS_LINEAGE } from '../../utils/EmptySettings';
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');

/**
 * 
 * @param {SettingsDetailRequestResult} settingDetail 
 * @returns 
 */
const prepareSettingDetail = (settingDetail) => {
    let newSettingDetail = null;

    if (!isUndefined(settingDetail) && settingDetail.getState().isDone()) {
        newSettingDetail = settingDetail.getData();

        //init new lineage setting record
        if (newSettingDetail.id === null && newSettingDetail.name === null) {
            newSettingDetail.id = "lineage";
            newSettingDetail.name = uuidv4();
            newSettingDetail.value = cloneDeep(EMPTY_SETTINGS_LINEAGE);
        }
    }

    return newSettingDetail;
};


const mapStateToProps = (state, ownProps) => {
    return {
        settingsDetailRequestResult: state.settingsDetail,
        settingsDetailData: prepareSettingDetail(state.settingsDetail),
        objectListRequestResult: state.objectList,
        isEdit: (!isUndefined(ownProps.match.params.setupDetailId)),
        isCreateNew: (isUndefined(ownProps.match.params.setupDetailId)),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: (settingsId = null, shouldUpdateOnly = false) => {
            if(isUndefined(settingsId)) {
                dispatch(fetchEmptyNewSettings());
            } else {
                dispatch(fetchDetail("lineage", settingsId, shouldUpdateOnly));
            }
            dispatch(fetchObjectList());
        },
        onUnmount: () => {
            dispatch(unmountDetail());
        },
        /**
         * @param {BaseSettinss} item
         */
        onSaveSettings: (item) => {
            if(isUndefined(ownProps.match.params.setupDetailId)) {
                // create new
                dispatch(saveNewSettings(item));
            } else {
                // update existing
                dispatch(saveEditedSettings("lineage", ownProps.match.params.setupDetailId, item));
            }
            //dispatch(fetchSettingsList(true));
        },
    }
};

const LineageDetail = connect(
    mapStateToProps,
    mapDispatchToProps
)(LineageDetailPresenter);

export default withRouter(LineageDetail);