import {createBaseOptions, fetchJson, METHOD_GET, METHOD_POST} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";



export const fetchGroupRights = async (id,callback) => {
    
        
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_GET;
    options.headers["Content-Type"] = "application/json";
    try {
        const json = await  fetchJson(`${config.url.API_URL}/visibilityRightsWithDeleted/${id}`, options);
        let res = [];
        console.log(json);
        if (json) {
            res = json;
            return res;
        }
    } catch (error) {
        console.log(error);
    }
};
export const fetchGroupRightsBreadCrumbs = async (ids,callback) => {
    
        
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_POST;
    options.headers["Content-Type"] = "application/json";
    options["body"] = ids;

    try {
        const json = await fetchJson(`${config.url.API_URL}/visibilityRights/breadcrumbs`, options);
        let res = [];
        console.log(json);
        if (json) {
            res = json;
            return res;
        }
    } catch (error) {
        console.log(error);
    }
};