import React from 'react';
import { Button, Table } from 'antd';
import { isEmptyValue } from '../../../utils/JsObjectHelper';
import { withTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import LoadFormNameFolderModal from './LoadFormNameFolderModal';
//import FormNameValueManipulationModal from './FormNameValueManipulationModal';

const cloneDeep = require('lodash.clonedeep');

class LoadFormNameFolderItem extends React.Component {

    constructor(props) {
        super(props);
        const value = props.value //props.fileUrlArrayData.length || null;
        this.state = {
            // Same name as valuePropName in getFieldDecorator ('value' is default):
            // https://ant.design/components/form/?locale=en-US#getFieldDecorator(id,-options)-parameters
            value,
            isModalEdit: false,
            isModalOpen: false,
            modalItemData: null
        };
    }
    
    handleChange = (value) => {
console.log(value);
        this.setState({ value : value });
        const { onChange } = this.props;
        if (onChange) {
            // This will provide the form with changes
            onChange(value);
        }
    };

    itemAdded = (itemName, itemValue) => {
        let newItemObj = { name: itemName, folderId: itemValue };

        let thisItemArray = cloneDeep(this.state.value);
        if(isUndefined(thisItemArray)) {
            thisItemArray = [];
            thisItemArray.push(newItemObj);
        } else {
            thisItemArray.push(newItemObj);
        }
        this.setState({ isModalEdit: false, isModalOpen: false, modalItemData: null});
        this.handleChange(thisItemArray);
    };

    itemUpdated = (itemName, itemValue, prevItem) => {
        let thisItemArray = cloneDeep(this.state.value);
        let newItemObj = { name: itemName, folderId: itemValue };
        thisItemArray.push(newItemObj);
        thisItemArray = thisItemArray.filter(f => !(
            f.name === prevItem.name && f.folderId === prevItem.value 
        ));

        this.setState({ isModalEdit: false, isModalOpen: false, modalItemData: null});
        this.handleChange(thisItemArray);
    };

    onItemEdit = (record) => {
        this.setState({ isModalEdit: true, isModalOpen: true, modalItemData: record});
    };

    onItemDeleted = (record) => {
        let thisItemArray = cloneDeep(this.state.value);
        if(!isUndefined(thisItemArray)) {
            thisItemArray = thisItemArray.filter(f => !(
                f.name === record.name && f.folderId === record.folderId 
            ));
            this.handleChange(thisItemArray);
        }
    };

    onModalHide = () => {
        this.setState({ isModalEdit: false, isModalOpen: false, modalItemData: null});
    }

    render() {
        const {t} = this.props;

        let columns = [
            {
                title: t('setup.loader.nameFolderItem.tabName'),
                dataIndex: 'name',
                key: 'name',
                //width: 150,
                //fixed: 'left',
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortOrder: 'ascend'
            },
            {
                title: t('setup.loader.nameFolderItem.tabFolder'),
                dataIndex: 'folderId',
                key: 'folderId',
                render: (text, record) => {
                    return (
                        <span>
                            {(isEmptyValue(record.folderId) ? "" : record.folderId)}
                        </span>
                    );
                }
            },
            {
                title: '',
                dataIndex: 'action',
                width: '76px',
                render : (text, record) => {
                    return [
                        <Button 
                            key="btnEdit"
                            onClick={() => this.onItemEdit(record)}
                            icon={<EditOutlined />} size="small" type="primary" ghost></Button>,
                        <Button 
                            key="btnDelete"
                            onClick={() => this.onItemDeleted(record)}
                            icon={<DeleteOutlined />} size="small" type="danger" ghost></Button>
                    ];
                }
            }
        ];

        return <Table 
                key="nameFolderTable"
                columns={columns} 
                dataSource={this.state.value} 
                size="small"
                pagination={false}
                title={() => <LoadFormNameFolderModal 
                                onItemAdd={this.itemAdded} 
                                onItemUpdated={this.itemUpdated}
                                onModalHide={this.onModalHide}
                                isEdit={this.state.isModalEdit}
                                isOpen={this.state.isModalOpen}
                                itemValues={this.state.modalItemData}
                                >
                            </LoadFormNameFolderModal>}
                scroll={{ x: true }}
                rowKey={record => {
                    //console.log(`${record.name}|${record.value}`);
                    return `${record.name}|${record.folderId}`;
                }}
            />;
    }
}

export default withTranslation() (LoadFormNameFolderItem);