import React from "react";
import { withTranslation} from 'react-i18next'
import AdvancedSearchSubQuery from './AdvancedSearchSubQuery';
import AdvancedSearchOperand from './AdvancedSearchOperand';
import PropTypes, { object } from 'prop-types';
import { Button, Tooltip } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import { isMobile } from "../../utils/AdaptiveLayout";

class AdvancedSearchGroup extends React.Component {

    handleSubQueryChange = (data) => {
        this.props.onDataChanged(this.props.dataIndex, data)
    }

    handleOperandChange = (op) => {
        this.props.onOperandChange(this.props.dataIndex, op)
    }

    handleRemove = () => {
        this.props.onRemoveGroup(this.props.dataIndex);
    };

    render() {
        const {t} = this.props;
        //let conditionStatus = "error";
        return <div>
                <AdvancedSearchOperand 
                    operand={this.props.operand}
                    onOperandChange={this.handleOperandChange}
                    >
                </AdvancedSearchOperand>
                {/* <div>
                <Badge status={conditionStatus}></Badge> */}
                <div className="advancedSearchRow">
                    <section>
                        <main>
                        <Tooltip placement={isMobile()?"top":"right"} title={t('app.advancedSearch.selectSearchSubQuery')} visible={this.props.groupData < 1 ? true:false}>
                            <AdvancedSearchSubQuery
                                searchData={this.props.groupData}
                                entryTypeAttributes={this.props.entryTypeAttributes}
                                onDataChanged={this.handleSubQueryChange}
                            >
                            </AdvancedSearchSubQuery>
                            </Tooltip>
                        </main>
                    </section>
                    <Button title={t('app.advancedSearch.btnRemoveCondition')} 
                        onClick={this.handleRemove}
                        //disabled={isEmptyValue(this.props.operand)} 
                        icon={<DeleteOutlined />} type="danger" size="small" style={{float: 'right', right: '10px', bottom: '30px', }}></Button>
                </div>

                {/* </div> */}
            </div>;
    }
}

AdvancedSearchGroup.propTypes = {
    groupData: PropTypes.arrayOf(object),
    dataIndex: PropTypes.number,
    entryTypeAttributes: PropTypes.arrayOf(object),
    onDataChanged: PropTypes.func.isRequired,
    onOperandChange: PropTypes.func.isRequired,
    onRemoveGroup: PropTypes.func.isRequired
};

export default withTranslation() (AdvancedSearchGroup);