import {
    OBJECT_DEFINITION_DETAIL_ERROR,
    OBJECT_DEFINITION_DETAIL_RECEIVED,
    OBJECT_DEFINITION_DETAIL_REQUESTED, OBJECT_DEFINITION_DETAIL_UNMOUNT, OBJECT_DEFINITION_DETAIL_UPDATE_REQUESTED, OBJECT_DEFINITION_LOAD_NEW
} from '../actions/objectDefinitionDetail';
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";
import {EMPTY_OBJECT_DEFINITION} from "../utils/EmptyObjectDefinitions";

/**
 *
 * @param {RequestResult} state
 * @param {BaseAction|ItemIdAction|ReceiveObjectDefinitionAction|ReceiveEntryErrorAction} action
 * @return {RequestResult}
 */
export const objectDefinitionDetail = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

    switch (action.type) {
        case OBJECT_DEFINITION_DETAIL_REQUESTED:
            return new RequestResult(RequestState.getInstanceLoading(), null);
        case OBJECT_DEFINITION_LOAD_NEW:
            return new RequestResult(RequestState.getInstanceDone(), EMPTY_OBJECT_DEFINITION);
        case OBJECT_DEFINITION_DETAIL_UNMOUNT:
            return new RequestResult(RequestState.getInstanceInit(), null);
        case OBJECT_DEFINITION_DETAIL_UPDATE_REQUESTED:
            return new RequestResult(RequestState.getInstanceUpdating(), state.data);
        case OBJECT_DEFINITION_DETAIL_RECEIVED:
            return new RequestResult(RequestState.getInstanceDone(), action.item, action.receivedAt);
        case OBJECT_DEFINITION_DETAIL_ERROR:
            return new RequestResult(
                RequestState.getInstanceError(
                    action.errorResponseStatusCode,
                    action.errorResponseMessage ? action.errorResponseMessage : null
                ),
                state.getData()
            );
        default:
            return state
    }
};
