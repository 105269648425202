import React from "react";
import PropTypes from "prop-types";
import { Select, Form, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next'

import { isUndefined } from "../../../utils/JsObjectHelper";
import { SETTINGS_WORKFLOW_USETYPE_ONSAVE, SETTINGS_WORKFLOW_USETYPE_ONRUNBYUSER } from "../../../utils/EmptySettings";

const Option = Select.Option;

class FolderWorkflowEvents extends React.Component {

    componentDidMount() {
        this.props.onMount();
    }
    validateSelectionLimit = (_, value) => {
        if (value && value.length > 1) {
          return Promise.reject(new Error('You '));
        }
        return Promise.resolve();
      };
    render() {
        const { t } = this.props;

        /*let workflowOptions = (!this.props.workflowsRequestResult.getState().isDone() ? 
                null : 
                this.props.workflowsRequestResult.getData().map((w) => {
                    return <Option value={w.key} key={w.key}>{isEmptyValue(w.name) ? w.key : w.name}</Option>;
                })
            );*/
        let workflowOptions = (isUndefined(this.props.workflowSettingsList) ? null :
            this.props.workflowSettingsList
                .filter((w) =>
                    w.value.workflowUseType === SETTINGS_WORKFLOW_USETYPE_ONSAVE && w.value.isEnabled
                )
                .map((w) => {
                    return <Option value={w.name} key={w.name} title={w.value.workflowName}>{w.value.workflowName}</Option>;
                })
        );
        let workflowForEntry = (isUndefined(this.props.workflowSettingsList) ? null :
            this.props.workflowSettingsList
                .filter((w) =>
                    w.value.workflowUseType === SETTINGS_WORKFLOW_USETYPE_ONRUNBYUSER && w.value.isEnabled && w.value.objectType === "entry"
                )
                .map((w) => {
                    return <Option value={w.name} key={w.name} title={w.value.workflowName}>{w.value.workflowName}</Option>;
                })
        );

        return [
            <Form.Item label={<span>{t('app.entry.edit.lblOnSaveEvent')}<Tooltip placement="top" title={t('app.entry.edit.tooltipTextOnSaveEvent')}><InfoCircleOutlined style={{color:'#bfbfbf'}} /></Tooltip></span> } key="onSave"
                name={["properties", "workflowForEntry", "onSave"]}
                rules={[
                    {
                      validator: this.validateSelectionLimit,
                      message: t('app.entry.edit.validatorOneWorkflow'),
                    },
                  ]}
            >
                <Select
                    mode='multiple'
                    placeholder={t('app.entry.edit.lblOnSaveEventHint')}
                    onChange={this.selectWorkflow}
                >
                    <Option value="" key="" > --- </Option>
                    {workflowOptions}
                </Select>
            </Form.Item>,
            <Form.Item label={<span>{t('app.entry.edit.lblOnRunByUser')}<Tooltip placement="top" title={t('app.entry.edit.tooltipTextOnRunByUser')}><InfoCircleOutlined style={{color:'#bfbfbf'}} /></Tooltip></span>} key="onRunByUser"
                name={["properties", "workflowForEntry", "onRunByUser"]}
            >
                <Select
                    mode="multiple"
                    placeholder={t('app.entry.edit.lblOnSaveEventHint')}
                >
                    <Option value="" key="" > --- </Option>
                    {workflowForEntry}
                </Select>
            </Form.Item>,
        ];
    }
};

/*const onFieldsChange = (props, fields) => {
    props.onPropertyChange(fields);
};
const WrappedFolderWorkflowEvents = Form.create({ name: 'folder_workflow_events', key: 'folder_workflow_events',
    mapPropsToFields(props) {
        return {
            onSave: Form.createFormField({
                ...props.propertyData,
                value: props.propertyData.onSave,
            }),
        };
    },
    onValuesChange: onFieldsChange,
})(FolderWorkflowEvents);*/

export default withTranslation()(FolderWorkflowEvents);

FolderWorkflowEvents.propTypes = {
    //propertyData: PropTypes.object.isRequired,//folderPropShape.isRequired,
    //onPropertyChange: PropTypes.func.isRequired,
};