import React, { useRef, useState, useEffect } from 'react'
import { useSpring, a } from '@react-spring/web'
import useMeasure from 'react-use-measure'
import {  Frame, Content } from './styles'
import { Spin } from "antd";
import { PlusOutlined, MinusOutlined,LoadingOutlined } from '@ant-design/icons'


const TOO_MUCH_RECORDS_IN_SUBTREE = "@#CANNOTBEAR#@";
function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => void (ref.current = value), [value])
  return ref.current
}

const Tree = React.memo<
  React.HTMLAttributes<HTMLDivElement> & {
    defaultOpen?: boolean
    name: string | JSX.Element
    selectedItemId: string
    selectedItemKey: string
    onLoadData?: (treeNodeData: object) => void
    title: JSX.Element
    loading: boolean
  }
>(({ children, defaultOpen = false,  selectedItemId, selectedItemKey, onLoadData ,title,loading}) => {
  const [isOpen, setOpen] = useState(defaultOpen);
  const [loaded, setLoaded] = useState(false);
  const previous = usePrevious(isOpen)
  const [ref, { height: viewHeight }] = useMeasure()
  const { height, opacity, y } = useSpring({
    from: { height: 0, opacity: 0, y: 0 },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      y: isOpen ? 0 : 20,
    },
  })
  const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
  const updatedLoading = !loaded && loading;
  const icon = isOpen ? ( updatedLoading  ? <Spin indicator={antIcon} />:(
    <>{!loaded?setLoaded(true):
    <MinusOutlined style={{maxHeight:'12px', border: '1px solid black', fontSize: '10px', marginRight: '5px',marginTop:'5px' }} onClick={() => setOpen(!isOpen)} />}</>)
  ) : (
    <PlusOutlined style={{ maxHeight:'12px', border: '1px solid black', fontSize: '10px', marginRight: '5px',marginTop:'5px' }} onClick={() => { onLoadData && onLoadData({ id: selectedItemId, key: selectedItemKey }); setOpen(!isOpen) }} /> //
  )
    

  return (
    <Frame>
      <div style={{display:'flex',width:'100%',  whiteSpace: 'nowrap',overflow: "hidden",textOverflow: "ellipsis", }}>
      {icon}
      {title}
      </div>
      <Content
        style={{
          opacity,
          height: isOpen && previous === isOpen ? 'auto' : height,
        }}>
        <a.div ref={ref} style={{ y }} children={children} />
      </Content>
    </Frame>
  )
})

export default Tree
