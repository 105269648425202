import React from "react";
import {isEmptyObject} from "../utils/JsObjectHelper";
import {Result} from "antd";
import {Link, Redirect} from "react-router-dom";
import {stringResultShape} from "../shapes/RequestResult";
import PropTypes from "prop-types";
import { withTranslation} from 'react-i18next';

class Homepage extends React.Component {
    componentDidMount() {
        this.props.onMount();
    }

    render() {
        const {t} = this.props;

        /** @type {RequestResult} */
        const rr = this.props.homepageResult;
        if(isEmptyObject(rr) || rr.getState().isLoading())
            return <div>{t('app.entry.header.msgLoading')}</div>;
        else if(rr.getState().isError() && rr.getState().getErrorCode() === 403) {
            return <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={<Link to={`/`} type="primary">{t('app.entry.header.msgBackToLandingPage')}</Link>}
            />
        }
        else if(isEmptyObject(rr.getData()))
            return <div>{t('app.entry.header.msgHomepageNonFound')}</div>;
        else {
            /** @type {String} */
            const entryId = rr.getData();

            return <Redirect to={`/entry/${entryId}`} />;
        }
    }
}

export default withTranslation() (Homepage);

Homepage.propTypes = {
    homepageResult: stringResultShape,
    onMount: PropTypes.func.isRequired
};