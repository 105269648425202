import {fetchJson} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";

export const ENTRY_OUTGOING_RELATIONS_REQUESTED = 'ENTRY_OUTGOING_RELATIONS_REQUESTED';
/**
 * Action for request of entry.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const requestEntryDetailOutgoingRelations = (entryId) => {
    return {
        type: ENTRY_OUTGOING_RELATIONS_REQUESTED,
        entryId: entryId
    };
};

export const ENTRY_OUTGOING_RELATIONS_UPDATE_REQUESTED = "ENTRY_OUTGOING_RELATIONS_UPDATE_REQUESTED";
/**
 * Action for request of entry.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const requestEntryDetailOutgoingRelationsUpdate = (entryId) => {
    return {
        type: ENTRY_OUTGOING_RELATIONS_UPDATE_REQUESTED,
        entryId: entryId
    }
};

export const ENTRY_OUTGOING_RELATIONS_RECEIVED = "ENTRY_OUTGOING_RELATIONS_RECEIVED";
/**
 *
 * @param {String} entryId
 * @param {Array<OutgoingRelation>} relations
 * @return {ReceiveRelationsAction}
 */
export const receiveEntryDetailOutgoingRelations = (entryId, relations) => {
    return {
        type: ENTRY_OUTGOING_RELATIONS_RECEIVED,
        relatedEntryId: entryId,
        relations: relations,
        receivedAt: Date.now()
    }
};

export const ENTRY_OUTGOING_RELATIONS_ERROR = 'ENTRY_OUTGOING_RELATIONS_ERROR';
/**
 *
 * @param {String} entryId
 * @param {Object} error
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveEntryErrorAction}
 */
export const receiveError = (
    entryId,
    error,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: ENTRY_OUTGOING_RELATIONS_ERROR,
        entryId: entryId,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};

/**
 * Fetch entry detail
 *
 * @param {String} entryId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchDetailOutgoingRelations = (entryId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            dispatch(requestEntryDetailOutgoingRelationsUpdate(entryId));
        } else {
            dispatch(requestEntryDetailOutgoingRelations(entryId));
        }
            return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/relations/outgoing/projection/relationWithTarget`, null, dispatch)
                .then(json => {
                        let res = [];
                        if (json._embedded && json._embedded.bmcEntryRelations && json._embedded.bmcEntryRelations.length > 0) {
                            res = json._embedded.bmcEntryRelations;
                        }
                        dispatch(receiveEntryDetailOutgoingRelations(entryId, res));
                    }
                ).catch(error => {
                    if(error instanceof ResponseStatusException) {
                        const description = error.response.message ? error.response.message : null;
                        dispatch(receiveError(entryId, error, error.response.status, description));
                    } else {
                        dispatch(receiveError(entryId, error));
                    }
                });
    }
};
