import {createBaseOptions, fetchJson, METHOD_POST} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";

export const fetchCreateEntryToIdentity = (obj,callback) => {
    console.log(obj);
       
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_POST;
    options.headers["Content-Type"] = "application/json";
    options["body"] = obj

    let r = fetchJson(`${config.url.API_URL}/identityToEntries/custom/items/create`, options);
    r.then(json => {
        console.log(json);
        callback()
       
    }).catch(error => {
        console.log(error);
        callback()
    });
};

