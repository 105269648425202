/**
 * Object for storing information about fetching data from server.
 */
export default class RequestResult {
    /**
     * @param {RequestState} state
     * @param {Object} data
     * @param {Date} receivedAt
     */
    constructor(state, data, receivedAt=null) {
        this.state = state;
        this.data = data;
        this.receivedAt = receivedAt;
    }

    /**
     *
     * @return {RequestState}
     */
    getState() {
        return this.state;
    }

    /**
     * Object with fetched data from server.
     *
     * @returns {Object}
     */
    getData() {
        return this.data;
    }

    /**
     * Date when data successfully fetched from server.
     *
     * @returns {Date}
     */
    getReceivedAt() {
        return this.receivedAt;
    }
}
