import {
    ENTRY_FILES_REQUESTED,
    ENTRY_FILES_UPDATE_REQUESTED,
    ENTRY_FILES_RECEIVED,
    ENTRY_FILES_ERROR,
    ENTRY_FILES_UNMOUNT
} from '../actions/entryFiles';
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";

/**
 *
 * @param {RequestResult} state
 * @param {BaseAction|EntryIdAction|ReceiveRelatedEntriesAction|ReceiveEntryErrorAction} action
 * @return {RequestResult}
 */
export const entryDetailFiles = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

    switch (action.type) {
        case ENTRY_FILES_REQUESTED:
            return new RequestResult(RequestState.getInstanceLoading(), null);
        case ENTRY_FILES_UNMOUNT:
            return new RequestResult(RequestState.getInstanceInit(), null);
        case ENTRY_FILES_UPDATE_REQUESTED:
            return new RequestResult(RequestState.getInstanceUpdating(), state.data);
        case ENTRY_FILES_RECEIVED:
            return new RequestResult(RequestState.getInstanceDone(), action.files, action.receivedAt);
        case ENTRY_FILES_ERROR:
            return new RequestResult(
                RequestState.getInstanceError(
                    action.errorResponseStatusCode,
                    action.errorResponseMessage ? action.errorResponseMessage : null
                ),
                state.getData()
            );
        default:
            return state
    }
};
