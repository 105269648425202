import React from "react";
import { Card, Row, Col, Divider, Skeleton } from 'antd';
import NextSectionButton from '../controls/NextSectionButton'
import { withTranslation} from 'react-i18next'

class DomainSettings extends React.Component {
    render () {
        const {t} = this.props;
        
        return (
            <div>
                <Divider>{t('setup.userManagement.domainSection')}</Divider>
                <Card title={t('setup.userManagement.domainTitle')}>
                    <Skeleton></Skeleton>
                </Card>
                <Divider dashed ></Divider>
                <Row >
                    <NextSectionButton align="left" title={t('setup.userManagement.groupSection')} toUrl="/setup/groups"></NextSectionButton>
                    <Col span={12}></Col>
                </Row>
            </div>
        );
    }
}

export default withTranslation() (DomainSettings);