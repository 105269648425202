import React from 'react';
import { isArray, isEmptyObject, mergeDeep } from "../../../utils/JsObjectHelper";
import PropTypes, { element } from "prop-types";
import update from 'immutability-helper';
import { withTranslation } from 'react-i18next'
import { withRouter } from "react-router-dom";
import { fetchEntries } from '../../../apicalls/fetchEntries';
import { Row, Col, PageHeader, Button, Modal, Card, Input, Select, Alert, Checkbox, Form } from 'antd';
import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { isUndefined, isEmptyValue } from '../../../utils/JsObjectHelper';
import { EMPTY_SETTINGS_ID_MAPPING, EMPTY_SETTINGS } from '../../../utils/EmptySettings';
const { Option } = Select;

const cloneDeep = require('lodash.clonedeep');

class UsersMappingDetail extends React.Component {

    constructor(props) {
        super(props);
        this.onMount = this.onMount.bind(this);
        this.onHandleSubmit = this.onHandleSubmit.bind(this);
        let initFormaData = cloneDeep(EMPTY_SETTINGS);
        initFormaData.value = cloneDeep(EMPTY_SETTINGS_ID_MAPPING);
        this.state = {
            formData: initFormaData,
            searchableInputData: [],
            searchableInputValue: [],
            foldersDetail: []
        };
        this.fetchFolders = this.fetchFolders.bind(this);
        this.fetchFolders = debounce(this.fetchFolders, 800);
    }
    formRef = React.createRef();
    componentDidMount() {
        this.onMount();
    };
    onMount() {
        this.props.onMount(this.props.match.params.setupDetailId);
    };

    componentWillUnmount() {
        this.props.onUnmount();
    }
    componentDidUpdate(prevProps, prevState) {
        if (!isEmptyObject(this.props.settingsDetailData) && isEmptyObject(prevProps.settingsDetailData)) {
            let v = cloneDeep(this.props.settingsDetailData);
            v.value = mergeDeep(cloneDeep(EMPTY_SETTINGS_ID_MAPPING), v.value)
            this.getFolderDetail(this.props.settingsDetailData.value)
            this.setState({ formData: v });
        }
    }

    onHandleFormChange = (formInputs) => {
        formInputs.forEach((field) => {
            let fieldName = (isArray(field.name) ? field.name.at(-1) : field.name);
            let fieldValue = field.value;
            this.setState((prevState, props) => {
                return update(prevState, {
                    formData: {
                        value: {
                            [fieldName]: { $set: fieldValue }
                        }
                    }
                });
            }
            );
        });
    };
    onHandleSubmit = () => {
        this.props.onSaveSettings(this.state.formData);
    };
    fetchFolders(value) {
        fetchEntries(value, "fetchFolders", searchData => this.setState({ searchableInputData: searchData }), null, ["folder"]);
    }

    getFolderDetail = (settingsList) => {
        if (!isUndefined(settingsList)) {
            let searchQuery = "id:" + settingsList.folderId + " ";
            fetchEntries(searchQuery, "getFolderDetail", search => this.setState({ foldersDetail: search }));
        }
    }

    render() {
        const { t } = this.props;
        let info = () => {
            Modal.info({
                title: t('setup.usersMapping.infoTitle'),
                content: (
                    <div>
                        {t('setup.usersMapping.infoText')}
                    </div>
                ),
                onOk() {
                },
            });
        };
        let entryTypeOptions = [];
        let entryFolderOprions = this.state.foldersDetail.map(d => <Option value={d.id} key={d.id}>{d.name}</Option>);
        entryFolderOprions = entryFolderOprions.concat(this.state.searchableInputData.filter(e => !this.state.searchableInputValue.find(v => v.id === e.id)).map(d => <Option value={d.id} key={d.id}>{d.name}</Option>));

        if (
            this.props.objectListRequestResult.getState().isDone() &&
            !isEmptyObject(this.props.objectListRequestResult.getData())
        ) {
            entryTypeOptions = this.props.objectListRequestResult
                .getData()
                .map((et) => (
                    <Option value={et.type} key={et.type}>
                        {et.name}
                    </Option>
                ));
        }


        let mainform = null
        if (this.props.settingsDetailData) {


            mainform =
                <Form
                    layout='vertical'
                    ref={this.formRef}
                    initialValues={this.props.settingsDetailData.value}
                    onFinish={this.onHandleSubmit}
                    onFieldsChange={this.onHandleFormChange}
                >
                    <Form.Item
                        label={t('setup.usersMapping.detailFormName')}
                        name='idMapName'>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item
                        label={t('setup.usersMapping.detailFormTypes')}
                        name='entryType'>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }>
                            {entryTypeOptions}
                        </Select>
                    </Form.Item>
                    <Alert message={t('setup.usersMapping.warningForFormSelectFolder')} type="warning" showIcon style={{marginBottom: '5px'}}/>
                    <Form.Item
                        label={t('setup.usersMapping.detailFormNameFolder')}
                        name={"folderId"}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            onSearch={this.fetchFolders}
                            filterOption={false}
                        >
                            {entryFolderOprions}
                        </Select>
                    </Form.Item>
                    <Row className="steps-action" justify="end" type="flex">
                        <Col>
                            <Button icon={<SaveOutlined />} type="primary" htmlType="submit" >
                                {this.props.isCreateNew ? t('setup.usersMapping.detailBtnCreate') : t('setup.usersMapping.detailBtnSave')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
        }

        return <div>
            <PageHeader
                title={t('setup.usersMapping.headerDetail')}
                subTitle={t('setup.usersMapping.headerDetailLabel')}
                extra={[
                    <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                    </Button>,
                ]}
            >
            </PageHeader>
            <div className="steps-content">
                <Row align="top" gutter={[30, 20]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Card>
                            {mainform}
                        </Card>
                    </Col>
                </Row>
            </div>

        </div>;

    }
}

const UsersMappingDetailWithRouter = withRouter(UsersMappingDetail);
export default withTranslation()(UsersMappingDetailWithRouter);

