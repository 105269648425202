import React from 'react';
import { withTranslation} from 'react-i18next'
import { Input, Space, Tag, Tooltip } from 'antd';
import ColorPickerModal from './ColorPickerModal';
import { getTextColorFromBackgroundHex } from '../../utils/ColorHelper';

const { Search } = Input;

class FormColorPicker extends React.Component {


    constructor(props) {
        super(props);
        const value = props.value
        this.state = {
            // Same name as valuePropName in getFieldDecorator ('value' is default):
            // https://ant.design/components/form/?locale=en-US#getFieldDecorator(id,-options)-parameters
            value,
            visible: false
        };
    }
    
    handleChange = (value) => {
        this.setState({ value : value });
        const { onChange } = this.props;
        if (onChange) {
            // This will provide the form with changes
            onChange(value);
        }
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleSelect = e => {
        this.handleChange(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const {t} = this.props;

        let tagTextColor = getTextColorFromBackgroundHex(this.state.value);

        return <Space >
                <Tag color={this.state.value} style={{color:tagTextColor}}>{t('app.colorPicker.lblColorPreview')}</Tag>
                <Tooltip
                    trigger={['focus']}
                    title={t('app.colorPicker.boxColorHint')}
                    placement="topLeft"
                >
                    <Search 
                        style={{ width: 'auto' }} size="small" 
                        enterButton={t('app.colorPicker.btnPickColor')}
                        onSearch={value => this.showModal()}
                        value={this.state.value}
                        ></Search>
                </Tooltip>
                <ColorPickerModal 
                    colorValue={this.state.value}
                    isVisible={this.state.visible}
                    onSelect={this.handleSelect}
                    onCancel={this.handleCancel}
                >
                </ColorPickerModal>
        </Space>;
    }
}

export default  withTranslation() (FormColorPicker);