import { EMPTY_GENERAL_SETTINGS_DEFINITION } from "./EmptyGeneralSettingsDefinition";
import { isUndefined, mergeDeep } from "./JsObjectHelper";

export const getGeneralSettingsObject = (settingsList) => {
    if (!isUndefined(settingsList) && settingsList.getState().isDone()) {
        let generalSettingsFromDB = (isUndefined(settingsList.getData().find(eT => eT.id === "general")) ? {} : settingsList.getData().find(eT => eT.id === "general").value);

        let resultGeneral = mergeDeep(EMPTY_GENERAL_SETTINGS_DEFINITION, generalSettingsFromDB);

        //console.log(resultGeneral);
        return resultGeneral;
    } else {
        return null;
    }
};