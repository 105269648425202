import InfoForBpmnPresenter  from "../../components/bpmn/InfoForBpmn";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";



const mapStateToProps = (state, ownProps) => {
	return {
		token: state.loggedUser.token,
	}
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: () => {
		
		}
	};
};

const InfoFormBpmn = connect(
	mapStateToProps,
	mapDispatchToProps
)(InfoForBpmnPresenter);

export default withRouter(InfoFormBpmn);
