import FolderWorkflowEventsPresenter from '../../components/detail/edit/FolderWorkflowEvents';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
//import {fetchWorkflowList} from "../../actions/workflowList";
import { isUndefined } from '../../utils/JsObjectHelper';
import { fetchObjectList } from '../../actions/objectList';
import { fetchSettingsList } from '../../actions/settings';

const getWorkflowSettingsList = (settingsList, objectList) => {
    if (!isUndefined(settingsList) && settingsList.getState().isDone() && !isUndefined(objectList) && objectList.getState().isDone()) {
        let setList = settingsList.getData().filter(eT => eT.id === "workflow");
        let objList = objectList.getData();
        setList.forEach(element => {
            let objNameList = element.value.entryTypes.map(eT => { 
                return (isUndefined(objList.find(obj=>obj.type === eT)) ? eT : objList.find(obj=>obj.type === eT).name);
            });
    
            element.value.entryTypesNameList = objNameList;
        });

        return setList;
    } else {
        return null;
    }
};


const mapStateToProps = (state, ownProps) => {
    return {
        //workflowsRequestResult: state.workflowList,
        workflowSettingsList: getWorkflowSettingsList(state.settingsList, state.objectList)
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            //dispatch(fetchWorkflowList());
            dispatch(fetchObjectList());
            dispatch(fetchSettingsList(true));
        },
    }
};

const FolderWorkflowEvents = connect(
    mapStateToProps,
    mapDispatchToProps
)(FolderWorkflowEventsPresenter);

export default withRouter(FolderWorkflowEvents);