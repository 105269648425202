import React from "react";
import { Descriptions, Avatar, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {isUndefined} from '../../utils/JsObjectHelper'
import { withTranslation} from 'react-i18next'

class UserDetail extends React.Component {
    render () {
        const {t} = this.props;

        let displData = (isUndefined(this.props.data) ? {key: null, userName: null, email: null} : this.props.data);

        return (
            <Spin spinning={this.props.loading} size="large">
                <div>
                    <Descriptions bordered title={t('setup.userManagement.usrDetailTitle')} size="small" layout="vertical" column={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
                        <Descriptions.Item label={t('setup.userManagement.usrDetailId')}>{displData.id}</Descriptions.Item>
                        <Descriptions.Item label={t('setup.userManagement.usrDetailAvatar')}> <div style={{textAlign: 'center'}}><Avatar size={128} icon={<UserOutlined />}/> </div></Descriptions.Item>
                        <Descriptions.Item label={t('setup.userManagement.usrDetailUserName')} span="2">{displData.name}</Descriptions.Item>
                        <Descriptions.Item label={t('setup.userManagement.usrDetailEmail')} span="2">{displData.email}</Descriptions.Item>
                        <Descriptions.Item label={t('setup.userManagement.usrDetailFirstName')}>{displData.firstName}</Descriptions.Item>
                        <Descriptions.Item label={t('setup.userManagement.usrDetailLastName')}>{displData.lastName}</Descriptions.Item>
                        <Descriptions.Item label={t('setup.userManagement.usrDetailDescription')}>{displData.info}</Descriptions.Item>
                    </Descriptions>
                </div>
            </Spin>
        );
    }
}

export default withTranslation() (UserDetail);