import ObjectDeletePresenter from '../../components/object/ObjectDelete';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { fetchSettingsList } from '../../actions/settings';
import { isUndefined } from '../../utils/JsObjectHelper';
import {fetchObjectList} from "../../actions/objectList";

const getISettingsList = (settingsList,type) => {
    if (!isUndefined(settingsList) && settingsList.getState().isDone() ) {
        let setList = settingsList.getData().filter(eT => eT.id === type);

        return setList;
    } else {
        return null;
    }
};


const mapStateToProps = (state, ownProps) => {
    return {
        idMapping: getISettingsList(state.settingsList,"id_mapping"),
        lineage: getISettingsList(state.settingsList,"lineage"),
        workflow: getISettingsList(state.settingsList,"workflow"),
        EntryTypeRequestResult: state.objectList,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateObjectList(){
            dispatch(fetchObjectList(true));
        },
        onMount() {
            dispatch(fetchSettingsList(true));
        },
        onShowChanged() {
            dispatch(fetchSettingsList(true));
            dispatch(fetchObjectList(true));
        }
    }
};

const ObjectDelete = connect(
    mapStateToProps,
    mapDispatchToProps
)(ObjectDeletePresenter);

export default withRouter(ObjectDelete);