import PropTypes from 'prop-types';
import {idNameWithTypeShape} from "./IdNameShapes";
import {idNameShapeWithBmcEntrySource} from "./IdNameShapes";
import {idNameShapeWithBmcEntryTarget} from "./IdNameShapes";


export const bmcEntryDocShape = PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    parent: PropTypes.string,
    inserted: PropTypes.number,
    updated: PropTypes.number,
    deleted: PropTypes.number
});

export const bmcEntryShape = PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    properties: PropTypes.object,
    parent: idNameWithTypeShape,
    inserted: PropTypes.number,
    updated: PropTypes.number,
    deleted: PropTypes.number,
    children: PropTypes.arrayOf(idNameWithTypeShape),
    incomingRelations: PropTypes.arrayOf(idNameShapeWithBmcEntrySource),
    outgoingRelations: PropTypes.arrayOf(idNameShapeWithBmcEntryTarget)
});

export const baseEntryShape = PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    properties: PropTypes.object,
    inserted: PropTypes.number,
    updated: PropTypes.number,
    deleted: PropTypes.number,
    createdByUserId: PropTypes.string,
    createdByUserName: PropTypes.string,
    modifiedByUserId: PropTypes.string,
    modifiedByUserName: PropTypes.string
});

export const baseEntryWithParentShape = PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    properties: PropTypes.object,
    inserted: PropTypes.number,
    updated: PropTypes.number,
    deleted: PropTypes.number,
    parent: idNameWithTypeShape,
    draftChildId: PropTypes.string,
    draftParentId: PropTypes.string,
    createdByUserId: PropTypes.string,
    createdByUserName: PropTypes.string,
    modifiedByUserId: PropTypes.string,
    modifiedByUserName: PropTypes.string
});

export const friendsEntryShape = PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    level: PropTypes.number,
    relNum: PropTypes.number,
});

export const folderPropShape = PropTypes.shape({
    //canCreateSubFolders: PropTypes.bool,
    childObjectEntryTypes: PropTypes.arrayOf(PropTypes.string),
    childFolderEntryTypes: PropTypes.arrayOf(PropTypes.string),
    workflowEvents: PropTypes.object,
    folderIcon: PropTypes.string,
    folderIconColor: PropTypes.string,
    subFolderDisplayType: PropTypes.string,
    childrenDisplayType: PropTypes.string,
    childrenDisplayAttributes: PropTypes.arrayOf(PropTypes.string),
    childrenDisplayTechAttr: PropTypes.arrayOf(PropTypes.string),
    childrenDisplayHierarchyDefId: PropTypes.string,
    displayTechAttributesFirst: PropTypes.bool,
    childrenPosition: PropTypes.arrayOf(PropTypes.string),
});