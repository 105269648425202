import EntryManipulationPresenter from '../../components/detail/view/EntryManipulation';
import { moveEntry, copyEntry } from '../../actions/entryManipulation'
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";



const mapStateToProps = (state, ownProps) => {
    return {
        entryRequestResult: state.entryDetail
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            
        },
        onMove(entryId, manipulationDefinition) {
            dispatch(moveEntry(entryId, manipulationDefinition));
        },
        onCopy(entryId, manipulationDefinition, afterCopyCallback) {
            dispatch(copyEntry(entryId, manipulationDefinition, afterCopyCallback));
        },
    }
};

const EntryManipulation = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryManipulationPresenter);

export default withRouter(EntryManipulation);
