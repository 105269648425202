import React from "react";
import {EMPTY_ARRAY, isEmptyObject, isEmptyValue} from "../utils/JsObjectHelper";
import {Divider, Result, Tag} from "antd";
import EntryDetailBreadCrumb from "./EntryDetailBreadCrumb";
import EntryView from "../containers/detail/EntryView";
import {Link} from "react-router-dom";
import { Button,  Row, Col } from 'antd';
import { EditOutlined, DeleteOutlined, DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import EntryEdit from "../containers/detail/EntryEdit";
import {
    entriesRequestResult,
    entryResultDetailShape,
    idNameTypeWithFoldersRequestResult,
    incomingRelationsRequestResult,
    outgoingRelationsRequestResult
} from "../shapes/RequestResult";
import { withTranslation} from 'react-i18next'
import PropTypes from "prop-types";
import EntryNew from "../containers/detail/EntryNew";
import { isMobile } from "../utils/AdaptiveLayout";

import EntryDeleteDialog from "../containers/detail/dialogs/EntryDeleteDialog"
import { Helmet } from "react-helmet";


class EntryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.onMount = this.onMount.bind(this);
        this.onShowDeleteDialog = this.onShowDeleteDialog.bind(this);
        this.onDeleteOk = this.onDeleteOk.bind(this);
        this.onDeleteCancel = this.onDeleteCancel.bind(this);

        this.state = {
            deleteDialogVisible : false,
        }
    }

    componentDidMount() {
        this.onMount(this.props.match.params.entryID);
    }

    /**
     * UNSAFE and deprecated lifecycle method!
     */
    /*UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.match.params.entryID !== this.props.match.params.entryID) {
            this.onMount(nextProps.match.params.entryID, true);
        }
    }*/

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.entryID !== prevProps.match.params.entryID) {
            this.onMount(this.props.match.params.entryID, true);
        }
    }

    onMount(entryId) {
        this.props.onMount(entryId);
    }

    onShowDeleteDialog(e) {
        this.setState({deleteDialogVisible : true});
    }

    onDeleteOk() {
        this.setState({deleteDialogVisible : false});
        this.props.onDelete(this.props.match.params.entryID);
    }

    onDeleteCancel() {
        this.setState({deleteDialogVisible : false});
    }

    render() {
        const {t} = this.props;

        /** @type {RequestResult} */
        const rr = this.props.entryRequestResult;
        /** @type {RequestResult} */
        const rrBc = this.props.breadCrumbRequestResult;
        if(isEmptyObject(rr) || rr.getState().isLoading())
            return <div>{t('app.entry.header.msgLoading')}</div>;
        else if(rr.getState().isError() && rr.getState().getErrorCode() === 403) {
            return <Result
                status="403"
                title="403"
                subTitle={t('app.entry.header.msgUnauthorized')}
                extra={<Link to={`/`} type="primary">{t('app.entry.header.msgBackToLandingPage')}</Link>}
            />
        }
        else if(isEmptyObject(rr.getData()))
            return <Result
                        status="404"
                        title={t('app.entry.header.msgEntryNonFound')}
                        subTitle={t('app.entry.header.msgEntryNonFoundHint')}
                        extra={<Link to={`/`} type="primary">{t('app.entry.header.msgBackToLandingPage')}</Link>}
                    />;
        else if(
            (this.props.isEdit && rr.getData().userEntryAccess !== "EDIT")
            || (this.props.isCreateNew && !(rr.getData().userEntryAccess === "EDIT" || rr.getData().userEntryAccess === "VIEW_PARENT_EDIT_CHILD"))
        ) {
            return <Result
                status="403"
                title="403"
                subTitle={t('app.entry.header.msgUnauthorized')}
                extra={<Link to={`/entry/${rr.getData().id}`} type="primary">{t('app.entry.header.msgBackTo')} {rr.getData().name}</Link>}
            />
        }
        else if(!isEmptyValue(rr.getData().deleted)) {
            return <Result
                        status="warning"
                        title={t('app.entry.header.msgEntryDeleted')}
                        subTitle={rr.getData().name + " - " + t('app.entry.header.msgEntryDeleteDate') + ": " + t('datetime', {date : new Date(rr.getData().deleted)})}
                        extra={<Link to={`/`} type="primary">{t('app.entry.header.msgBackToLandingPage')}</Link>}
                    />;
        }
        else {
            /** @type {BaseEntryWithParent} */
            const entry = rr.getData();

            let editOrDraftBtn = null;
            let breadCrumb = null;

            if (isEmptyValue(entry.draftChildId)) {
                editOrDraftBtn = <Link to={`/entry/${entry.id}/edit`} style={{float: 'right', marginLeft: '10px'}}><Button type="primary" icon={<EditOutlined />}>{t('app.entry.header.btnEditText')}</Button></Link>;
            } else {
                editOrDraftBtn = <Link to={`/entry/${entry.draftChildId}`} style={{float: 'right', marginLeft: '10px'}}><Button type="dashed" icon={<DoubleRightOutlined />} title={t('app.entry.header.btnDraftHint')}>{t('app.entry.header.btnDraftText')}</Button></Link>;
            }
            
            if (isEmptyValue(entry.draftParentId)) {
                /** @type {Array<IdNameTypeWithFolders>} */
                const breadCrumbWithFolders = !isEmptyObject(rrBc) && !isEmptyValue(rrBc.getData()) ? rrBc.getData() : EMPTY_ARRAY;
                breadCrumb = (!isEmptyObject(breadCrumbWithFolders) && breadCrumbWithFolders.length > 0) ? <EntryDetailBreadCrumb entries={breadCrumbWithFolders}/> : <p>&nbsp;</p>;
            } else {
                breadCrumb = [
                    <Link to={`/entry/${entry.draftParentId}`}><Button type="dashed" icon={<DoubleLeftOutlined />} title={t('app.entry.header.lblDraft')}>{t('app.entry.header.btnGoToActiveVersion')}</Button></Link>,
                    <Tag color="red" style={{marginLeft: '10px'}}>{t('app.entry.header.lblDraft')}</Tag>
                ];

                if (!isEmptyValue(entry.processProperties) && !isEmptyValue(entry.processProperties.status)) {
                    breadCrumb.push(<Tag color="geekblue"  title={t('app.entry.header.lblDraftStatus') + entry.processProperties.status}>{t('app.entry.header.lblDraftStatus')} {entry.processProperties.status}</Tag>);
                }
            }

            let entryView = this.props.isCreateNew ?
                <EntryNew /> :
                (this.props.isEdit ?
                <EntryEdit />
                :
                <EntryView />);
            let btns = this.props.isEdit || this.props.isCreateNew || entry.userEntryAccess !== "EDIT" ?
                ""
                :
                <span>
                    {editOrDraftBtn}
                    <Button type="danger" icon={<DeleteOutlined />} onClick={this.onShowDeleteDialog} style={{float: 'right'}}>{t('app.entry.header.btnDeleteText')}</Button>
                </span>
                ;
            return <div>
                <Helmet>
                    <title>Aphinit - {entry.name}</title>
                </Helmet>
                <Row type="flex" align="bottom">
                 { isMobile()?'':  <Col span={14}>{breadCrumb}</Col>}
                  <Col span={isMobile()?24:10} justify="end">{btns}</Col>
                
                </Row>
                {/*{breadCrumb} {editBtn}*/}
                <EntryDeleteDialog 
                    entryData={entry}
                    childs={this.props.childrenRequestResult}
                    inRelations={this.props.incomingRelationsRequestResult}
                    outRelations={this.props.outgoingRelationsRequestResult}
                    visible={this.state.deleteDialogVisible} 
                    onOk={this.onDeleteOk} 
                    onCancel={this.onDeleteCancel}>
                </EntryDeleteDialog>
                <Divider />
                {entryView}
            </div>;
        }
    }
}

export default withTranslation() (EntryDetail);

EntryDetail.propTypes = {
    entryRequestResult: entryResultDetailShape,
    childrenRequestResult: entriesRequestResult,
    incomingRelationsRequestResult: incomingRelationsRequestResult,
    outgoingRelationsRequestResult: outgoingRelationsRequestResult,
    breadCrumbRequestResult: idNameTypeWithFoldersRequestResult,
    onMount: PropTypes.func.isRequired,
    onUnmount: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    isEdit: PropTypes.bool.isRequired,
    isCreateNew: PropTypes.bool.isRequired,
};