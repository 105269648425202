import React from "react";
import { Descriptions } from 'antd';
//import { Translation } from 'react-i18next';
import { withTranslation} from 'react-i18next'
import {bmcEntryShape} from "../../../shapes/BmcEntryShape";
import { isEmptyValue } from "../../../utils/JsObjectHelper";
import { UserOutlined} from '@ant-design/icons';

class EntryTechnicalInfo extends React.Component {
    render() {
        //let aa = this.props.i18n.t('datetime', {date : new Date(this.props.entry.inserted)});

        const {t} = this.props;

        return ( <Descriptions bordered title={t('app.entry.tools.technical.title')} size="small" column={1}>
                    <Descriptions.Item label={t('app.entry.tools.technical.tblID')}>
                        {this.props.entry.id}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('app.entry.tools.technical.tblCreated')}>
                        { isEmptyValue(this.props.entry.inserted) ? " - " : t('datetime', {date : new Date(this.props.entry.inserted)})}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('app.entry.tools.technical.tblCreatedBy')}>
                        { isEmptyValue(this.props.entry.createdByUserName) ? " - " : <a href={"/id2entry/" + this.props.entry.createdByUserId} target="_blank"><UserOutlined /> {this.props.entry.createdByUserName}</a> }
                    </Descriptions.Item>
                    <Descriptions.Item label={t('app.entry.tools.technical.tblLastUpdated')}>
                        { isEmptyValue(this.props.entry.updated) ? " - " : t('datetime', {date : new Date(this.props.entry.updated)})}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('app.entry.tools.technical.tblLastUpdatedBy')}>
                        { isEmptyValue(this.props.entry.modifiedByUserName) ? " - " : <a href={"/id2entry/" + this.props.entry.modifiedByUserId} target="_blank"><UserOutlined /> {this.props.entry.modifiedByUserName}</a> }
                    </Descriptions.Item>
                    <Descriptions.Item label={t('app.entry.tools.technical.tblDeleted')}>
                        { isEmptyValue(this.props.entry.deleted) ? " - " : t('datetime', {date : new Date(this.props.entry.deleted)})}
                    </Descriptions.Item>
                </Descriptions>
                );
    }
};

export default withTranslation() (EntryTechnicalInfo);

EntryTechnicalInfo.propTypes = {
    entry: bmcEntryShape.isRequired
};