import { fetchJson } from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import { config } from "../config/Config";
import { notificationWarning, showError } from "../utils/NotificationsHelper";

export const USER_PAGE_REQUESTED = "USER_PAGE_REQUESTED";
/**
 * Action for request of entry.
 *
 * @return {UserPageAction}
 */
export const requestUserPage = () => {
	return {
		type: USER_PAGE_REQUESTED,
	};
};


export const USER_PAGE_RECEIVED = "USER_PAGE_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} userpage
 * @return {ReceiveUserPageAction}
 */
export const receiveUserPage = (userpage) => {
	return {
		type: USER_PAGE_RECEIVED,
		userpage: userpage,
		receivedAt: Date.now(),
	};
};

export const USER_PAGE_ERROR = "USER_PAGE_ERROR";
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveUserPageErrorAction}
 */
export const receiveError = (
	error,
	type = USER_PAGE_ERROR,
	errorResponseStatusCode = null,
	errorResponseMessage = null
) => {
	return {
		type: type,
		error: error,
		errorResponseStatusCode: errorResponseStatusCode,
		errorResponseMessage: errorResponseMessage,
	};
};


/**
 * 
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchUserPage = (identityId,t, update = false) => {
	return (dispatch, getState) => {
		if (update) {
			dispatch(requestUserPage());
		} else {
			dispatch(requestUserPage());
		}

		/** @type RequestOptions options **/
		let options = {
			mode: "cors",
			method: 'GET',
			headers: {
			  "Content-Type": "application/json",
			  Authorization: 'Bearer ' + sessionStorage.getItem("kcToken")
			}
		};

		return fetch(`${config.url.API_URL}/identityToEntries/custom/item/${identityId}`, options, dispatch)
		.then(response => {
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			return response.json();
		})
			.then(json => {
				let res = json;
				dispatch(receiveUserPage(res));
			}
			)
			.catch(error => {
				if (error instanceof ResponseStatusException) {
					const description = error.response.message ? error.response.message : null;
					if (description.indexOf('Id2Entry for identity') > -1) {
						dispatch(receiveError(error, USER_PAGE_ERROR, error.response.status, description));
						notificationWarning(t('app.userPage.notificationWarrning'))
					}
					else{	
						dispatch(receiveError(error, USER_PAGE_ERROR, error.response.status, description));
						notificationWarning(t('app.userPage.notificationWarrning'))
						console.log(error);
					}
				} else {
					dispatch(receiveError(error, USER_PAGE_ERROR));
					notificationWarning(t('app.userPage.notificationWarrning'))
					console.log(error);
				}
			});
	}
};


