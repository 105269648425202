import EntryNewModalPresenter from '../../components/detail/EntryNewModal';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {saveNewValues} from "../../actions/entryDetail";
//import {fetchObjectList} from "../../actions/objectList";

const mapStateToProps = (state, ownProps) => {
    return {
        //parentEntryRequestResult: state.entryDetail,
        entryTypesRequestResult: state.objectList
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            //dispatch(fetchObjectList());
        },
        onSaveNew(parentEntryId, values) {
            if (ownProps.onNewEntryCreated) {
                dispatch(saveNewValues(parentEntryId, values, false, ownProps.onNewEntryCreated));
            } else {
                dispatch(saveNewValues(parentEntryId, values));
            }
        },
    }
};

const EntryNewModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryNewModalPresenter);

export default withRouter(EntryNewModal);