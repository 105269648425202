import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import languageEN from './locate/en/translate.json'
import languageCS from './locate/cs/translate.json'
import languageNL from './locate/nl/translate.json'
import moment from 'moment'
import 'moment/locale/cs';
import 'moment/locale/nl';

i18n
.use(XHR)
.use(LanguageDetector)
.use(initReactI18next)
.on('languageChanged', function(lng) {
    moment.locale(lng);
})
.init({
    resources: {
        en: languageEN,
        cs: languageCS,
        nl: languageNL
    },
    //detection: options,
    /* default language when load the website in browser */
    //lng: "en",
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: "en",
    /* debugger For Development environment */
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ",",
        format: function(value, format, lng) {
            if (format === 'uppercase') return value.toUpperCase();
            if (format === 'lowercase') return value.toLowerCase();
            if(value instanceof Date)  {
                if (format === 'FROM_NOW') {
                    return moment(value).fromNow();
                }
                if (format !== null) {
                    return moment(value).format(format);
                } else {
                    return moment(value);
                }
            }
            return value;
        }
    },
    react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
        useSuspense: false
    }
})

// i18n.on('languageChanged', function(lng) {
// console.log('CHANGING MOMENT LOCALE');
//   moment.locale(lng);
// })

export default i18n;