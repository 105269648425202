import React from "react";
import { PageHeader, Button, Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import Users from '../../containers/setup/Users';
import Groups from '../../containers/setup/Groups';
import DomainSettings from '../../containers/setup/DomainSettings';
import { withTranslation} from 'react-i18next'


class UserManagement extends React.Component {
    render () {
        const {t} = this.props;

        let info = () => {
            switch (this.props.displaySectionName) {
                case "groups":
                    Modal.info({
                        title: t('setup.userManagement.infoBoxTitleGroup'),
                        content: (
                            <div>
                                {t('setup.userManagement.infoBoxContentGroup')}
                            </div>
                        ),
                        onOk() {
                        },
                    });
                    break;
                default:
                    Modal.info({
                        title: t('setup.userManagement.infoBoxTitle'),
                        content: (
                            <div>
                                {t('setup.userManagement.infoBoxContent')}
                            </div>
                        ),
                        onOk() {
                        },
                    });
                    break;
            };
    
        };

        let displaySection = '';

        switch (this.props.displaySectionName) {
            case "domain":
                displaySection = <DomainSettings></DomainSettings>
                break;
            case "groups":
                displaySection = <Groups></Groups>
                break;
            default:
                displaySection = <Users></Users>
                break;
        };

        return (
            <div>
                <PageHeader
                    title={t('setup.userManagement.header')}
                    subTitle={t('setup.userManagement.headerLabel')}
                    extra={[
                        <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                        </Button>,
                    ]}
                >
                </PageHeader>
                {displaySection}
            </div>
        );
    }
}

export default withTranslation() (UserManagement);