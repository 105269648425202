import UserMappingListPresenter from '../../components/setup/userMapping/UserMappingList';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { fetchSettingsList } from "../../actions/settings";
import { fetchObjectList } from "../../actions/objectList";
import { fetchEntryDetailMinimal } from '../../apicalls/fetchEntryDetail';
import { isUndefined } from "../../utils/JsObjectHelper";
import { fetchDeleteSettings } from '../../apicalls/fetchDeleteSettings';




const getIdMappingSettingsList = (settingsList, objectList) => {
    if (!isUndefined(settingsList) && settingsList.getState().isDone() && !isUndefined(objectList) && objectList.getState().isDone()) {
        let setList = settingsList.getData().filter(eT => eT.id === "id_mapping");
        let objList = objectList.getData();

        return setList;
    } else {
        return null;
    }
};


const mapStateToProps = (state, ownProps) => {
    return {
        entryTypesRequestResult: state.objectList,
        idMappingSettingsList: getIdMappingSettingsList(state.settingsList, state.objectList),
        List: state.settingsList
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList());
            dispatch(fetchSettingsList(true));          
        },
        deleteMapping: (id,name) => {
            let reload = ()=>{ dispatch(fetchSettingsList(true))}
            fetchDeleteSettings(id,name,reload)
        }
    }
};

const UserMappingList = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserMappingListPresenter);

export default withRouter(UserMappingList);
