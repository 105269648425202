import SubFoldersEditChildPositionPresenter from '../../components/detail/view/SubFoldersEditChildPosition'
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {saveEditedValues} from "../../actions/entryDetail";




const mapStateToProps = (state, ownProps) => {
    return {
        entryId: ownProps.match.params.entryID
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSaveEdit(entryId, values) {
            dispatch(saveEditedValues(entryId, values));
        },
    }
};

const SubFoldersEditChildPosition = connect(
    mapStateToProps,
    mapDispatchToProps
)(SubFoldersEditChildPositionPresenter);

export default withRouter(SubFoldersEditChildPosition);