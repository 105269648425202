import AppHeaderPresenter from "../components/AppHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchTaskCount } from "../actions/bpmnTaskCount";
import { withTranslation } from 'react-i18next'


const mapStateToProps = (state, ownProps) => {
	return {
		onLogout: ownProps.onLogout,
		loggedUser: ownProps.loggedUser,
		isAdmin: ownProps.isAdmin,
		hasSetupRight: ownProps.hasSetupRight,
		taskCount: state.taskCount,
		allBranding: state.allBrandingActive
	}
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: () => {
			dispatch(fetchTaskCount());
		},
		onInterval: () => {
			dispatch(fetchTaskCount(true));
		}
	};
};

const AppHeader = connect(
	mapStateToProps,
	mapDispatchToProps
)(AppHeaderPresenter);

export default withTranslation()(withRouter(AppHeader));
