import PropTypes from 'prop-types';

export const requestStateShape = PropTypes.shape({
    isLoading: PropTypes.func.isRequired,
    isDone: PropTypes.func.isRequired,
    isError: PropTypes.func.isRequired,
    isInit: PropTypes.func.isRequired,
    isInvalidated: PropTypes.func.isRequired,
    isUpdating: PropTypes.func.isRequired,
    getErrorCode: PropTypes.func.isRequired
});