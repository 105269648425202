import React from "react";
import {bmcEntryShape} from "../../shapes/BmcEntryShape";
import { Collapse } from 'antd';
//import request from "superagent";
//import {config} from "../../config/Config";
import {isEmptyObject, isEmptyValue} from "../../utils/JsObjectHelper";
import EntryFriendsResults from "./EntryFriendsResult";
import { withTranslation} from 'react-i18next'
import { fetchEntryContextEngine } from "../../apicalls/fetchEntryContextEngine";

const { Panel } = Collapse;

class EntryFriends extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingFriends: {},
            friends: {},
            visible: false
        };

        this._collapseRef = null;

        this.getTypeFriends = this.getTypeFriends.bind(this);
        this.loadFriends = this.loadFriends.bind(this);
        this.setCollapseRef = this.setCollapseRef.bind(this);
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };
    
    componentDidMount() {
        this.props.onMount();
    }

    getTypeFriends = (type) => {
        const {t} = this.props;

        if(this.state.friends[type] === undefined) {
            return <p>{t('app.entry.tools.context.msgLoading')}</p>;
        } else {
            if(isEmptyObject(this.state.friends[type])) {
                return <p>{t('app.entry.tools.context.msgNoContextFound')}</p>;
            } else {
                return <EntryFriendsResults data={this.state.friends[type]} />;
            }
        }
    };

    loadFriends = (panelKeyArray) => {
        let contextEngineDepth = 6;
        if (!isEmptyValue(this.props.generalSettingsObject) && 
            !isEmptyValue(this.props.generalSettingsObject.contextEngine) &&
            !isEmptyValue(this.props.generalSettingsObject.contextEngine.depth)) {
            contextEngineDepth = this.props.generalSettingsObject.contextEngine.depth;
        }

        for (const panelKey of panelKeyArray) {
            if(this.state.loadingFriends[panelKey] === undefined) {
                this.setState(prevState => ({
                    loadingFriends: {
                        ...prevState.loadingFriends,
                        [panelKey]: panelKey
                    },
                }));
                fetchEntryContextEngine(this.props.entry.id, panelKey, contextEngineDepth, friends  => {
                    this.setState(prevState => ({
                        friends: {
                            ...prevState.friends,
                            [panelKey]: friends
                        },
                    }));
                });
            }
        }
    };

    setCollapseRef = element => {
        this._collapseRef = element;
    };

    render() {
        if (!this.props.entryTypesRequestResult.getState().isDone()) {
            return <div />;
        }
        const panels = this.props.entryTypesRequestResult.getData().map((item, key) => { return <Panel key={item.type} header={item.name}>{this.getTypeFriends(item.type)}</Panel>});

        return <span>
                <Collapse ref={this.setCollapseRef} onChange={this.loadFriends}>
                    {panels}
                </Collapse>
        </span>;
    }
}

EntryFriends.propTypes = {
    entry: bmcEntryShape.isRequired
};

export default withTranslation() (EntryFriends);

