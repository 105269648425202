import { Button, Modal } from "antd";
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import React from "react";
import { withTranslation} from 'react-i18next'
import cytoscape from 'cytoscape';
import klay from 'cytoscape-klay';
import { getTextColorFromBackgroundHex } from "../../utils/ColorHelper";
import { isEmptyValue, isUndefined } from "../../utils/JsObjectHelper";

class RelationVisualization extends React.Component {

    constructor(props) {
        super(props);
        this.createGraph = this.createGraph.bind(this);
        this.createLayout = this.createLayout.bind(this);
        this.runLayout = this.runLayout.bind(this);
        this.drawChart = this.drawChart.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
    }

    state = {
        visible: false,
        data: [],
        divPlaceholder: null
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.entryTypeList.getState().isDone() &&
            this.state.data !== this.props.entryTypeList.getData()) {
            this.setState({ data: this.props.entryTypeList.getData() });
        }
    }

    showModal = () => {
        this.setState({ visible: true }, this.drawChart);
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    onDownloadClick() {
        let expPng = this.cy.png( { output: 'base64', full: true } );
        var a = document.createElement("a"); //Create <a>
        a.href = "data:image/png;base64," + expPng; //Image Base64 Goes here
        a.download = "relations.png"; //File name Here
        a.click(); //Downloaded file
    };

    getColorsForTypes() {
        let typeColors = [];
        if(!isEmptyValue(this.state.data)) {
            let entryTypeNameList = this.state.data.filter(et => { return (et.systemType === "object"); });

            entryTypeNameList.forEach(type => {
                let textColor = "#000000";
                
                if (type.properties.typeColor && type.properties.typeColor.indexOf('#') === 0) {
                    textColor = getTextColorFromBackgroundHex(type.properties.typeColor);
                }

                typeColors.push({
                    selector: `node.${type.type}`,
                    style: {
                        'background-color': type.properties.typeColor,
                        'color': textColor
                    }
                });
            });
        }

        return typeColors;
    };

    createGraph() {
        let graphPlaceholder = document.getElementById('relationsCytoscapePlaceholder');
        if (!isUndefined(graphPlaceholder)) {
            cytoscape.use(klay);
    
            let cytoscapeOptions = this.props.getCytoscapeOptions(graphPlaceholder);
            let typeColorOptions = this.getColorsForTypes();
            typeColorOptions.forEach(type => {
                cytoscapeOptions.style.push(type);
            });
    
            this.cy = cytoscape(cytoscapeOptions);
            /*this.cy.on("tap", (e) => { 
                if(e.target === this.cy){
                    this.setState({ selectedNodeId: null }); 
                    this.clearHighlightedGraphElements();
                }
            })*/
            this.cy.maxZoom(1.5);
    
            this.runLayout();
    
            this.cy.center();
        }
    };

    createLayout() {
        this.layout = this.cy.layout(this.props.getLayoutOptions());
    };

    runLayout() {
        this.createLayout();

        this.layout.run();
    };

    drawChart() {
        var foundNodes = [];
        var foundEdges = [];

        this.state.data.forEach(d => {
            foundNodes.push({ id: d.type, name: d.name, type: d.type, parent: null });
            
            if (!isUndefined(d.properties) && !isUndefined(d.properties.attributes)) {
                d.properties.attributes.forEach(att=>{
                    if (att.type === "relation" && !att.deleted) {
                        if (Array.isArray(att.relationEntryType)) {
                            att.relationEntryType.forEach(rel=>{
                                foundEdges.push({ 
                                    s: d.type, st: d.type, 
                                    t: rel, tt: rel, 
                                    name: att.name, type: att.techName,
                                    className: (d.type === rel ? "loop" : null)
                                });
                            });
                        } else {
                            foundEdges.push({ 
                                s: d.type, st: d.type, 
                                t: att.relationEntryType, tt: att.relationEntryType, 
                                name: att.name, type: att.techName,
                                className: (d.type === att.relationEntryType ? "loop" : null)
                            });
                        }
                    }
                });
            }
        });


        if (!this.cy) {
            this.createGraph();
        }

        //pridani novych nodu
        foundNodes.forEach(n => {
            if (this.cy) {
                var matchNodes = this.cy.$id(n.id);
                if (matchNodes.length === 0) {
                    this.cy.add(this.props.createNodeElement(n, null));
                }
            }
        });

        //pridani lajn
        foundEdges.forEach(edg => {
            if (this.cy.$id(`${edg.s}#${edg.t}`).length === 0)
            {
                if (!isUndefined(foundNodes.find(n => n.type === edg.t))) {
                    let newEdge = this.cy.add(this.props.createEdgeElement(edg));
                    if (!isEmptyValue(edg.className)) {
                        newEdge.addClass(edg.className);
                    }
                }
            } else {
                if (this.cy.$id(`${edg.s}#${edg.t}`).data("label").indexOf(`(${edg.name})`) === -1 && 
                    this.cy.$id(`${edg.s}#${edg.t}`).data("label") !== edg.name) {
                    if (this.cy.$id(`${edg.s}#${edg.t}`).data("label").indexOf('), (') > -1)
                        this.cy.$id(`${edg.s}#${edg.t}`).data("label", `${this.cy.$id(`${edg.s}#${edg.t}`).data("label")}, (${edg.name})`);
                    else
                        this.cy.$id(`${edg.s}#${edg.t}`).data("label", `(${this.cy.$id(`${edg.s}#${edg.t}`).data("label")}), (${edg.name})`);
                }
            }
        });

        if (this.cy) {
            this.runLayout();
        }
    };

    render () {
        const {t} = this.props;

        return (
            <div>
                <Button onClick={this.showModal}  icon={<EyeOutlined />}>
                    {t('setup.entryTypes.relationVisualization.btnOpen')}
                </Button>
                <Modal
                    visible={this.state.visible}
                    title={t('setup.entryTypes.relationVisualization.title')}
                    //onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="download" onClick={this.onDownloadClick} icon={<DownloadOutlined />}>
                            {t('setup.entryTypes.relationVisualization.btnDownload')}
                        </Button>,
                        <Button key="back" onClick={this.handleCancel}>
                            {t('setup.entryTypes.relationVisualization.btnReturn')}
                        </Button>,
                    ]}
                    width="95%"
                    style={{width: '95%', height: '90%'}}
                    forceRender={true}
                    >
                    <div id="relationsCytoscapePlaceholder" className="lineagePlaceholder">
                        {/* PLACEHOLDER FOR LINEAGE GRAPH */}
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withTranslation() (RelationVisualization);