import EntryDeleteDialogPresenter from '../../../components/detail/dialogs/EntryDeleteDialog';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
//import { fetchObjectList } from '../../../actions/objectList';

const mapStateToProps = (state, ownProps) => {
    return {
        entryTypesRequestResult: state.objectList,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            //dispatch(fetchObjectList());
        },
    }
};

const EntryDeleteDialog = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryDeleteDialogPresenter);

export default withRouter(EntryDeleteDialog);
