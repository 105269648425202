import {createBaseOptions, fetchJson, METHOD_DELETE, METHOD_GET, } from "../../utils/SuperAgentFetch";
import {config} from "../../config/Config";


export const fetchDeleteProcess = (processId,callback) => {
   console.log(processId);
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_DELETE;
    options.headers["Content-Type"] = "application/json";

   
    let r = fetchJson(`${config.url.API_URL}/bpmn/processInstance/${processId}`, options) // /async
    r.then(json => {
        console.log('ok');
        callback()   
    })
    .catch(error => {
        callback() 
       
    });
    

};