import {fetchJson} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";

export const ENTRY_BREADCRUMB_REQUESTED = 'ENTRY_BREADCRUMB_REQUESTED';
/**
 * Action for request of entry.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const requestEntryDetailBreadCrumb = (entryId) => {
    return {
        type: ENTRY_BREADCRUMB_REQUESTED,
        entryId: entryId
    };
};

export const ENTRY_BREADCRUMB_UPDATE_REQUESTED = "ENTRY_BREADCRUMB_UPDATE_REQUESTED";
/**
 * Action for request of entry.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const requestEntryDetailBreadCrumbUpdate = (entryId) => {
    return {
        type: ENTRY_BREADCRUMB_UPDATE_REQUESTED,
        entryId: entryId
    }
};

export const ENTRY_BREADCRUMB_RECEIVED = "ENTRY_BREADCRUMB_RECEIVED";
/**
 *
 * @param {String} entryId
 * @param {Array<IdNameTypeWithFolders>} entries
 * @return {ReceiveBreadCrumbAction}
 */
export const receiveEntryDetailBreadCrumb = (entryId, entries) => {
    return {
        type: ENTRY_BREADCRUMB_RECEIVED,
        relatedEntryId: entryId,
        entries: entries,
        receivedAt: Date.now()
    }
};

export const ENTRY_BREADCRUMB_ERROR = 'ENTRY_BREADCRUMB_ERROR';
/**
 *
 * @param {String} entryId
 * @param {Object} error
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveEntryErrorAction}
 */
export const receiveError = (
    entryId,
    error,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: ENTRY_BREADCRUMB_ERROR,
        entryId: entryId,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};

/**
 * Fetch entry detail
 *
 * @param {String} entryId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchDetailBreadCrumb = (entryId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            dispatch(requestEntryDetailBreadCrumbUpdate(entryId));
        } else {
            dispatch(requestEntryDetailBreadCrumb(entryId));
        }
        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/breadCrumb`, null, dispatch)
            .then(json => {
                    let res = [];
                    if (json._embedded && json._embedded.breadCrumbEntries && json._embedded.breadCrumbEntries.length > 0) {
                        res = json._embedded.breadCrumbEntries;
                    }
                    dispatch(receiveEntryDetailBreadCrumb(entryId, res));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(entryId, error, error.response.status, description));
                } else {
                    dispatch(receiveError(entryId, error));
                }
            });
    }
};
