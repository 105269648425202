import React from 'react';
import PropTypes from "prop-types";
import { Input, Button, Modal, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { isUndefined } from '../../../utils/JsObjectHelper';

//const [form] = Form.useForm();

class FileUrlAddForm extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      addModalVisible: false
    };
  }

  componentDidMount() {
    // To disable submit button at the beginning.
    //this.props.form.validateFields();
  }

  handleReset = () => {
    this.formRef.current.resetFields();
    //this.formRef.current.validateFields();
    this.setState({ addModalVisible: false });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.formRef.current.validateFields()
      .then(values => {
        this.props.onFileAdd(values.filename, values.fileurl);
        this.handleReset();
      })
      .catch(errorInfo => {
        console.log(errorInfo);
        return;
      });

    /*this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onFileAdd(values.filename, values.fileurl);

        this.handleReset();
      }
    });*/
  };

  hideModal = () => {
    this.setState({ addModalVisible: false });
  };

  render() {
    const { t } = this.props;
    
    return [
      <Button key="addButton" size="small" icon={<PlusOutlined />} onClick={() => this.setState({ addModalVisible: true })}>{t('app.entry.attributes.btnAddFile')}</Button>,
      <Modal
        title={t('app.entry.attributes.titleAddFileUrlModal')}
        key="addFileModal"
        onCancel={this.hideModal}
        okButtonProps={{ hidden: true }}
        visible={this.state.addModalVisible}>

        <Form layout="vertical"
          name="horizontal_addUrl"
          ref={this.formRef}
          onFinish={this.handleSubmit}
          >
          <Form.Item label={t('app.entry.attributes.lblAddFileName')} 
            name="filename"
            rules={[{ required: true, message: t('app.entry.attributes.msgAddFileName') }]}>
            <Input
              placeholder={t('app.entry.attributes.lblAddFileName')}
            />
          </Form.Item>
          <Form.Item label={t('app.entry.attributes.lblAddFileUrl')} 
            name="fileurl"
            rules={[{ required: true, message: t('app.entry.attributes.msgAddFileUrl') }]}
            >
            <Input
              placeholder={t('app.entry.attributes.lblAddFileUrl')}
            />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button type="primary" icon={<PlusOutlined />} onClick={this.handleSubmit}
                disabled={
                  isUndefined(this.formRef.current) ||
                  (!isUndefined(this.formRef.current) && 
                  (!this.formRef.current.isFieldsTouched(true) ||
                  this.formRef.current.getFieldsError().filter(({ errors }) => errors.length).length > 0)
                  )
                }
              >
                {t('app.entry.attributes.btnAddFile')}
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    ];
  }
}

export default withTranslation()(FileUrlAddForm);

FileUrlAddForm.propTypes = {
  onFileAdd: PropTypes.func.isRequired,
};