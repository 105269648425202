import EntryAccessPresenter from '../../components/detail/view/EntryAccess';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchGroupList} from "../../actions/groupList";
import {fetchEntryAccess, updateEntryAccess} from "../../actions/entryAccess";
import {isUndefined, isArray} from "../../utils/JsObjectHelper";

const getGroupAccessList = (rrG, rrA) => {
    if(!isUndefined(rrG) && rrG.getState().isDone() && !isUndefined(rrA) && rrA.getState().isDone()) {
        let groups = rrG.getData();
        let accessRights = rrA.getData();
        if (!isUndefined(groups) && isArray(groups) && groups.length > 0 &&
            !isUndefined(accessRights) && isArray(accessRights) /*&& accessRights.length > 0*/
        ) {
            let result = [];
            for (const group of groups) {
                let actualAccessLevel = 'NONE';
                let foundAccess = accessRights.find(aR => aR.groupId === group.id);

                if (!isUndefined(foundAccess)) {
                    actualAccessLevel = foundAccess.entryAccess;
                }

                result.push({ groupId: group.id, groupName: group.name, accessRightOrigin: actualAccessLevel, accessRightNew: actualAccessLevel});
            }
            return result;
        }
    }
    return [];
};

const mapStateToProps = (state, ownProps) => {
    return {
        groupListRequestResult: state.groupList,
        entryAccessRequestResult: state.entryAccess,
        groupAccessList: getGroupAccessList(state.groupList, state.entryAccess),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: (entryId) => {
            dispatch(fetchGroupList());
            dispatch(fetchEntryAccess(entryId));
        },

        onSaveAccessChanges: (accessList, entryId, applyOnSubFolders) => {
            let changedList = accessList.filter(aL => aL.accessRightOrigin !== aL.accessRightNew);

            if (!isUndefined(changedList) && changedList.length > 0) {
                changedList.forEach((chL) => {
                    dispatch(updateEntryAccess(entryId, chL.groupId, chL.accessRightNew, applyOnSubFolders));
                });
            }
            //dispatch(fetchGroupList());
            //dispatch(fetchEntryAccess(entryId));
        },
    }
};

const EntryAccess = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryAccessPresenter);

export default withRouter(EntryAccess);