import React from "react";
import "antd/dist/antd.css";
import { Button, Table, Popconfirm, Row, Col, PageHeader, Typography, Tag, Skeleton, Modal, Select, Drawer } from "antd";
import { DeleteOutlined, InfoCircleOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { getColumnCustomAttributeProps } from "../../utils/TableHelper";
import { isArray, isUndefined, } from "../../utils/JsObjectHelper";
import { isMobile, isTablet } from "../../utils/AdaptiveLayout";
const { Option } = Select;
const { Text } = Typography;
class BpmnProcessActive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalSetAssignee: false,
            assignee: null,
            taskId: null,
            processId: null,
            modalDetailMobile: false,
            showSkeleton: true
        };
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.processId !== this.props.processId ||
            (prevProps.processRequestResult.getState() !==
                this.props.processRequestResult.getState() &&
                this.props.processId !== "")
        ) {
            let data = this.props.processRequestResult.getState().isLoading() ? [] : this.props.processRequestResult.getData()
            if (data.length > 0 && data.find((p) => p.id === this.props.processId)) {
                let record = data.find((p) => p.id === this.props.processId);
                this.props.taskHistory(record.id); //record.id
            }
        }
    }
    componentDidMount() {
        if (this.props.processId !== "" && this.props.processRequestResult) {
            let data = this.props.processRequestResult.getState().isLoading() ? undefined : this.props.processRequestResult.getData()
            if (!isUndefined(data)) {
                let record = data.find((p) => p.id === this.props.processId);
                this.props.taskHistory(record.id); //record.id
            }
        }
        this.props.onMount();
    }
    showDetailTasks = (record) => {
        this.props.history.push(`/setup/bpmnprocess/` + record.id)
        this.setState({
            showSkeleton: false
        })
        if (isMobile() || isTablet()) {
            this.setState({
                modalDetailMobile: true
            })
        }
    }
    handleDelete = (record) => {
        this.props.deleteProcess(record.id)
    }
    setAssignee = (id) => {
        let identity = { userId: id }
        this.setState({
            assignee: identity
        })
    }
    render() {
        const { t } = this.props;
        let userList = this.props.userListRequestResult.getState().isLoading() ? [] : this.props.userListRequestResult.getData();
        let data = this.props.processRequestResult.getState().isLoading() ? [] : this.props.processRequestResult.getData()
        let dataDetail = this.props.taskHistoryRequestResult.getState().isLoading() ? undefined : this.props.taskHistoryRequestResult.getData()
        let info = () => {
            Modal.info({
                title: t('setup.bpmn.bpmnInfoTitleProcesses'),
                content: (
                    <div>
                        {t('setup.bpmn.bpmnInfoTextProcesses')}
                    </div>
                ),
                onOk() {
                },
            });
        };
        const columnsDetail = [
            {
                title: t("app.bpmn.titleName"),
                dataIndex: 'name',
                editable: true,
            },
            {
                ...getColumnCustomAttributeProps("startTime", t('setup.bpmn.bpmnProcessStartTimeTask'), "date", t),
                width: '20%'
            },
            {
                ...getColumnCustomAttributeProps("endTime", t('setup.bpmn.bpmnProcessEndTimeTask'), "date", t),
                width: '20%'
            },
            {
                title: t('setup.bpmn.bpmnProcessAssignee'),
                dataIndex: 'assignee',
                render: (usersDef, record) => {
                    let user = null
                    if (userList.length > 0 && !isUndefined(usersDef)) {
                        user = userList.find((el) => el.id === usersDef)
                        if (!isUndefined(user)&&!isUndefined(user.firstName)) {
                            return <><a href={"/id2entry/" + user.id} style={{ marginLeft: "17px" }} target="_blank" ><UserOutlined /> {user.firstName} {user.lastName}</a></>
                        }

                    }

                    if (isUndefined(usersDef)) {
                        return <Button
                            type="link"
                            style={{ textAlign: "left" }}
                            onClick={() => {
                                this.setState({
                                    processId: record.processInstanceId,
                                    taskId: record.id,
                                    modalSetAssignee: true
                                })
                            }}
                        >
                            {t("app.bpmn.setAssignee")}<UserAddOutlined />
                        </Button>
                    }

                }
            },
            {
                title: t('setup.bpmn.bpmnProcessStatus'),
                dataIndex: 'endTime',
                width: '2%',
                render: (endTime, record) => {
                    if (!isUndefined(endTime)) {
                        return <Tag color="success">{t('setup.bpmn.bpmnTagCompleted')}</Tag>
                    }
                    if (isUndefined(endTime)) {
                        return <Tag color="processing">{t('setup.bpmn.bpmnTagActive')}</Tag>
                    }

                }
            },

        ]
        const columns = [
            {
                title: t('setup.bpmn.bpmnProcessName'),
                dataIndex: 'processDefinitionName',
                width: '30%',

            },
            {
                ...getColumnCustomAttributeProps("startTime", t("app.bpmn.titleCreated"), "date", t),
                width: '20%'
            },
            {
                width: ' 1%',
                dataIndex: 'operation',
                render: (_, record) =>

                    <Popconfirm title={t("setup.bpmn.bpmnDeleteProcess")} okText={t("setup.bpmn.bpmnDeleteProcessOk")} cancelText={t("setup.bpmn.bpmnDeleteProcessCancel")} onConfirm={() => this.handleDelete(record)}>
                        <DeleteOutlined style={{ color: "#40a9ff" }} />
                    </Popconfirm>
            },
        ];

        let detail = null
        if (!isUndefined(dataDetail)) {
            detail = <Skeleton loading={isUndefined(dataDetail)} ><Table
                columns={columnsDetail}
                dataSource={dataDetail}
                loading={this.props.taskHistoryRequestResult
                    .getState()
                    .isLoading()}
                size="small"
                rowKey="id"
            />
            </Skeleton>
        }

        let optionsUser = []
        if (isArray(userList)) {
            userList.forEach((el) => {
                optionsUser.push(<Option key={el.id} value={el.id}>
                    {el.firstName} {el.lastName}
                </Option>)
            })
        }
        let modalSetAssignee =
            <Modal title={t("app.bpmn.setAssignee")} visible={this.state.modalSetAssignee}
                onOk={() => {
                    this.props.setAssignee(this.state.processId, this.state.taskId, this.state.assignee)
                    this.setState({
                        modalSetAssignee: false
                    })
                }}
                onCancel={() =>
                    this.setState({
                        modalSetAssignee: false
                    })
                }>
                <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t("setup.bpmn.selectAssignee")}
                    onChange={this.setAssignee}>
                    {optionsUser}
                </Select>
            </Modal>
        if (isMobile() || isTablet()) {
            detail =
                <Drawer width={"100%"} placement="right" onClose={() => this.setState({ modalDetailMobile: false })} visible={this.state.modalDetailMobile}>
                    <Table
                        columns={columnsDetail}
                        dataSource={dataDetail}
                        loading={this.props.taskHistoryRequestResult
                            .getState()
                            .isLoading()}
                        size="small"
                        rowKey="id"
                        scroll={{
                            x: true
                        }}
                    />

                </Drawer>
        }

        let main =
            <>
                <PageHeader
                    title={t('setup.bpmn.bpmnRunningProcessInstances')}
                    subTitle={t('setup.bpmn.bpmnProcessSetupDescription')}
                    extra={[
                        <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info} >
                        </Button>,
                    ]}
                >
                </PageHeader>
                <Row gutter={[{ xs: 6, sm: 12, md: 24, lg: 32 }, 20]}>
                    <Col span={isMobile() || isTablet() ? 24 : 8}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            size="small"
                            rowKey="id"
                            scroll={{
                                x: true
                            }}
                            loading={this.props.processRequestResult
                                .getState()
                                .isLoading()}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => {
                                        this.showDetailTasks(record);
                                    },
                                };
                            }}
                        />
                    </Col>
                    <Col span={16}>
                        <Skeleton style={{ marginLeft: '20px', width: '95%' }} loading={this.state.showSkeleton} >
                            {detail}
                            {modalSetAssignee}	
                        </Skeleton>


                    </Col>
                </Row>
            </>;
        return (
            <>
                {main}

            </>
        )
    }
}

const BpmnProcessActiveWithRouter = withRouter(BpmnProcessActive);

export default withTranslation()(BpmnProcessActiveWithRouter);
