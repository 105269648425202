import { fetchJson } from "../../utils/SuperAgentFetch";
import ResponseStatusException from "../../exceptions/ResponseStatusException";
import { config } from "../../config/Config";




/**
 * 
 * @param {String} taskId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchClaimTask = (taskId, update = false, callback, errorClaim) => {

    /** @type RequestOptions options **/
    let options = {
        method: "POST"
    };



    return fetchJson(`${config.url.API_URL}/bpmn/task/${taskId}/claim/currentUser`, options)
        .then(json => {
            let res = [];
            res = json
            if (callback) {
                callback()
            }
        }
        )
        .catch(error => {
            if (error === "Ignoring request") {
            } else if (error instanceof ResponseStatusException) {
                const description = error.response.message ? error.response.message : null;
                if (description.indexOf('TaskAlreadyClaimedException') > -1 && errorClaim) {
                    errorClaim()
                }
            }
        });

}


