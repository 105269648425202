import BpmnProcessPresenter from "../../components/bpmn/BpmnProcessActive";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchUserList } from "../../actions/userList";
import { isUndefined } from "../../utils/JsObjectHelper";
import { fetchProcess } from "../../actions/bpmnProcess";
import { fetchTaskHistory } from "../../actions/bpmnTaskHistory";
import { fetchDeleteProcess } from "../../apicalls/bpmn/fetchDeleteProcess";
import { fetchSetAssignee } from "../../apicalls/bpmn/fetchSetAssignee";
import { fetchBpmnDeleteAssignee } from "../../apicalls/bpmn/fetchBpmnDeleteAssignee";
import { fetchTaskCount } from "../../actions/bpmnTaskCount";

const mapStateToProps = (state, ownProps) => {
	return {
		processRequestResult: state.process,
		userListRequestResult: state.userList,
		user: state.loggedUser.user,
		taskHistoryRequestResult: state.taskHistory,
		processId: !isUndefined(ownProps.match.params.setupDetailId) ? ownProps.match.params.setupDetailId : ""
	}
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: () => {
			dispatch(fetchProcess());
			dispatch(fetchUserList())
		},
		taskHistory: (processId) => {
			dispatch(fetchTaskHistory(processId))
		},
		deleteProcess: (processId) => {
			let reload = () => {
				ownProps.history.push(`/setup/bpmnprocess`)
				dispatch(fetchProcess());
				dispatch(fetchTaskHistory())
				dispatch(fetchTaskCount(true));
			}
			fetchDeleteProcess(processId, reload)
		},
		setAssignee: (processId, taskId, assignee) => {
			let reload = () => {
				dispatch(fetchTaskHistory(processId));
			}
			fetchSetAssignee(taskId, assignee, reload)
		},
		deleteAssignee: (assigneeId, taskId, processId) => {
			let reloadTask = () => {
				dispatch(fetchTaskHistory(processId))
			}
			fetchBpmnDeleteAssignee(taskId, assigneeId, reloadTask)
		},

	};
};

const BpmnProcess = connect(
	mapStateToProps,
	mapDispatchToProps
)(BpmnProcessPresenter);

export default withRouter(BpmnProcess);
