import React from "react";
import { isEmptyValue } from "./JsObjectHelper";

/**
 * Returns text formatted as html with searched string highlighted as yellow span.
 * 
 * @param {string} text origin text
 * @param {string} searchText string to find and highlight in origin text
 * @returns 
 */
export const highlightSearchedText = (text, searchText) => {
    let finalText = [];
    let matchkey = 1;

    if (!isEmptyValue(searchText)) {
        while (text.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            let position = text.toLowerCase().indexOf(searchText.toLowerCase());

            finalText.push(text.substring(0, position));

            finalText.push(<span key={matchkey} style={{backgroundColor:'yellow'}}>{text.substring(position, position + searchText.length)}</span>);

            text = text.substring(position + searchText.length);

            matchkey++;
        }    
    }
    finalText.push(text);

    return <span>{finalText}</span>;
};