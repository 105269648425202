import React from "react";
import { Table, Select } from "antd";
import { isEmptyValue } from "../../../utils/JsObjectHelper";
import { withTranslation} from 'react-i18next'
import { Helmet } from "react-helmet";

const { Option } = Select;

class EntryArchiveCompare extends React.Component {

    constructor(props) {
        super(props);
        this.onMount = this.onMount.bind(this);
    }

    componentDidMount() {
        this.onMount(this.props.match.params.entryID);
    }

    onMount(entryId) {
        this.props.onMount(entryId);
    }

    handleVersionChange = (value) => {
        this.props.history.push('/entryarchive/'+ this.props.entryRequestResult.getData().id + '/' + value.join());
    }

    decideRowClassName = (record, index) => {
        if (record.isDiff) {
            return "compareTableDiffRow";
        }

        return "";
    };

    

    render () {
        const {t} = this.props;

        let tableLoading = !this.props.entryRequestResult.getState().isDone() || !this.props.entryVersionListRequestResult.getState().isDone();

        let titleName = (this.props.entryRequestResult.getState().isDone() ? this.props.entryRequestResult.getData().name : "");

        let versionIds = [];

        if (!isEmptyValue(this.props.match.params.versionIdList)) {
            versionIds = this.props.match.params.versionIdList.split(',');
        }

        let versionOptions = [];
        if (this.props.entryVersionListRequestResult.getState().isDone()) {
            versionOptions = this.props.entryVersionListRequestResult.getData().map(v=>
                <Option key={v.version}>{v.name + " (V:" + v.version + ")"}</Option>
            );
        }

        let diffCount = this.props.compareTableData.tableData.filter(d=>d.isDiff===true).length;

        let footerContent = <span>{t('app.entry.archive.lblAllAttributes')} <b>{this.props.compareTableData.tableData.length}</b><br/>{t('app.entry.archive.lblDiffAttributes')} <b>{diffCount}</b></span>;

        let tableHeader = <span>
            <span style={{paddingRight:"10px"}}><b>{t('app.entry.archive.lblVersionSelector')}</b></span>
            <Select
                size="large"
                mode="multiple"
                style={{ width: 'auto', minWidth: '250px' }}
                onChange={this.handleVersionChange}
                defaultValue={versionIds}
            >
                {versionOptions}
            </Select>
        </span> 
            

        return <div>
            <Helmet>
                <title>Aphinit Compare - {titleName}</title>
            </Helmet>
            <Table
                columns={this.props.compareTableData.columnConfig}
                dataSource={this.props.compareTableData.tableData}
                bordered
                title={()=>tableHeader}
                footer={()=>footerContent}
                loading={tableLoading}
                pagination={false}
                rowClassName={this.decideRowClassName}
                scroll={{
                    x: true,
                  }}
            ></Table>
        </div>;
    }
}


export default withTranslation() (EntryArchiveCompare);