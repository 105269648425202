import {createBaseOptions, fetchJson, METHOD_PATCH, METHOD_POST, METHOD_PUT} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError, showSuccess} from "../utils/NotificationsHelper";
import { push } from 'connected-react-router'

export const SETTINGS_LIST_REQUESTED = 'SETTINGS_LIST_REQUESTED';
/**
 * Action for request of settings list.
 *
 * @return {SettingsListAction}
 */
export const requestSettingsList = () => {
    return {
        type: SETTINGS_LIST_REQUESTED
    };
};

export const SETTINGS_LIST_RECEIVED = "SETTINGS_LIST_RECEIVED";
/**
 *
 * @param {Array<Object>} settingsList
 * @return {ReceiveSettingsListAction}
 */
export const receiveSettingsList = (settingsList) => {
    return {
        type: SETTINGS_LIST_RECEIVED,
        settingsList: settingsList,
        receivedAt: Date.now()
    }
};

export const SETTINGS_LIST_ERROR = 'SETTINGS_LIST_ERROR';
export const SETTINGS_DETAIL_ERROR = 'SETTINGS_DETAIL_ERROR';
export const SETTINGS_SAVE_ERROR = 'SETTINGS_SAVE_ERROR';
export const SETTINGS_DELETE_ERROR = 'SETTINGS_DELETE_ERROR';

/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {String} settingId
 * @param {String} settingName
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveSettingsErrorAction}
 */
export const receiveError = (
    error,
    settingId = null,
    settingName = null,
    type = SETTINGS_LIST_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        settingId: settingId,
        settingName: settingName,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};


/**
 * Fetch settings list
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchSettingsList = (update = false) => {
    return (dispatch, getState) => {
        if (update) {
            //dispatch(requestEntryDetailUpdate());
            dispatch(requestSettingsList());
console.log("updating settings");
        } else {
            dispatch(requestSettingsList());
        }

        /** @type RequestOptions options **/
        let options = {
            method: "GET"
        };
        options.ignore = {
            key: "fetchSettingsList",
            maxLifeTime: 60000
        };
        options.cache = {
            key: "fetchSettingsList",
            maxLifeTime: 60000,
            forceRefresh: update
        };

        //TODO: doresit otazku kolem strankovani
        return fetchJson(`${config.url.API_URL}/settings/custom/items/all`, options, dispatch)
            .then(json => {
                    let res = [];
                    if (json._embedded && json._embedded.settings && json._embedded.settings.length > 0) {
                        res = json._embedded.settings;
                    }
                    dispatch(receiveSettingsList(res));
                }
            ).catch(error => {
                if(error === "Ignoring request") {
                    // TODO create some better solution with ignoring same requests
                    // do nothing, is expected state
                } else if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, null, null, SETTINGS_LIST_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(error, null, null, SETTINGS_LIST_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};


export const SETTINGS_LOAD_NEW = 'SETTINGS_LOAD_NEW';
/**
 * Action for request new setting item.
 *
 * @return {BaseAction}
 */
export const requestEmptyNewSettings = () => {
    return {
        type: SETTINGS_LOAD_NEW,
    };
};

export const SETTINGS_DETAIL_REQUESTED = 'SETTINGS_DETAIL_REQUESTED';
/**
 * Action for request of setting item.
 *
 * @param {String} settingId
 * @param {String} settingName
 * @return {ItemIdAction}
 */
export const requestSettingsDetail = (settingId, settingName) => {
    return {
        type: SETTINGS_DETAIL_REQUESTED,
        settingId: settingId,
        settingName: settingName
    };
};

export const SETTINGS_DETAIL_UPDATE_REQUESTED = "SETTINGS_DETAIL_UPDATE_REQUESTED";
/**
 * Action for request of setting item.
 *
 * @param {String} settingId
 * @param {String} settingName
 * @return {ItemIdAction}
 */
export const requestSettingsDetailUpdate = (settingId, settingName) => {
    return {
        type: SETTINGS_DETAIL_UPDATE_REQUESTED,
        settingId: settingId,
        settingName: settingName
    }
};


export const SETTINGS_SUCCESSFULLY_SAVED = "SETTINGS_SUCCESSFULLY_SAVED";
/**
 * Action for successfully saved of an setting item.
 *
 * @param {String} settingId
 * @param {String} settingName
 * @return {ItemIdAction}
 */
export const receiveSettingsSuccessfullySaved = (settingId, settingName) => {
    return {
        type: SETTINGS_SUCCESSFULLY_SAVED,
        settingId: settingId,
        settingName: settingName
    }
};

export const SETTINGS_DETAIL_RECEIVED = "SETTINGS_DETAIL_RECEIVED";
/**
 *
 * @param {String} settingId
 * @param {String} settingName
 * @param {BaseObjectDefinition} item
 * @return {ReceiveSettingAction}
 */
export const receiveSettingsDetail = (settingId, settingName, item) => {
    return {
        type: SETTINGS_DETAIL_RECEIVED,
        item: item,
        settingId: settingId,
        settingName: settingName,
        receivedAt: Date.now()
    }
};

export const SETTINGS_DETAIL_UNMOUNT = "SETTINGS_DETAIL_UNMOUNT";
/**
 * action unmount detail
 * @return {BaseAction}
 */
export const unmountDetail = () => {
    return {
        type: SETTINGS_DETAIL_UNMOUNT
    }
};


export const SETTINGS_DETAIL_EDIT_REQUESTED = "SETTINGS_DETAIL_EDIT_REQUESTED";
/**
 * Action for update item.
 *
 * @param {String} settingId
 * @param {String} settingName
 * @param {BaseObjectDefinition} item
 * @return {SettingsEditAction}
 */
export const requestSettingsDetailEdit = (settingId, settingName, item) => {
    return {
        type: SETTINGS_DETAIL_EDIT_REQUESTED,
        settingId: settingId,
        settingName: settingName,
        item: item,
    }
};

export const SETTINGS_DETAIL_NEW_REQUESTED = "SETTINGS_DETAIL_NEW_REQUESTED";
/**
 * Action for create new item.
 *
 * @param {Object} item
 * @return {SettingsEditAction}
 */
export const requestSettingsDetailNew = (item) => {
    return {
        type: SETTINGS_DETAIL_NEW_REQUESTED,
        item: item,
    }
};

/**
 * Get new empty setting item
 *
 * @return {function(*=, *): Promise<Object | never>}
 */
 export const fetchEmptyNewSettings = () => {
    return (dispatch, getState) => {
        dispatch(requestEmptyNewSettings());
    };
};

export const SETTINGS_DELETE_REQUESTED = 'SETTINGS_DELETE_REQUESTED';
/**
 * Action for delete of entry.
 *
 * @param {String} settingId
 * @param {String} settingName
 * @return {ItemIdAction}
 */
export const requestSettingsDelete = (settingId, settingName) => {
    return {
        type: SETTINGS_DELETE_REQUESTED,
        settingId: settingId,
        settingName: settingName,
    };
};

export const SETTINGS_SUCCESSFULLY_DELETED = "SETTINGS_SUCCESSFULLY_DELETED";
/**
 * Action successfully deleted entry.
 *
 * @param {String} settingId
 * @param {String} settingName
 * @return {ItemIdAction}
 */
export const receiveSettingsSuccessfullyDeleted = (settingId, settingName) => {
    return {
        type: SETTINGS_SUCCESSFULLY_DELETED,
        settingId: settingId,
        settingName: settingName,
    }
};


/**
 * Fetch settings detail
 *
 * @param {String} settingId
 * @param {String} settingName
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
 export const fetchDetail = (settingId, settingName, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            dispatch(requestSettingsDetailUpdate(settingId, settingName));
        } else {
            dispatch(requestSettingsDetail(settingId, settingName));
        }

        return fetchJson(`${config.url.API_URL}/settings/custom/item/${settingId}/${settingName}`, null, dispatch)
            .then(json => {
                    //json['productID'] = productID;
                    dispatch(receiveSettingsDetail(settingId, settingName, json));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, settingId, settingName, SETTINGS_DETAIL_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(error, settingId, settingName, SETTINGS_DETAIL_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};


/**
 * Save item detail
 *
 * @param {String} itemId
 * @param {BaseObjectDefinition} item
 * @return {function(*=, *): Promise<Object | never>}
 */
export const saveEditedSettings = (settingId, settingName, item, callback) => {
    return (dispatch, getState) => {
        dispatch(requestSettingsDetailEdit(settingId, settingName, item));
        /** @type RequestOptions */
        let options = createBaseOptions();
        //TODO PATCH or PUT ???
        options.method = METHOD_PATCH;
        options.headers["Content-Type"] = "application/json";
        //item.updated = new Date().toISOString();
        options["body"] = item;

        return fetchJson(`${config.url.API_URL}/settings/custom/item/`, options, dispatch)
            .then(json => {
                    dispatch(receiveSettingsSuccessfullySaved(settingId, settingName));
                    dispatch(receiveSettingsDetail(settingId, settingName, json));
                    if (callback) {
                        callback();
                    }
                    //Go back to list
                    dispatch(push(`/setup/${settingId}`));
                    showSuccess(dispatch, "Success", "Setting has been successfully updated!");
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, settingId, settingName, SETTINGS_SAVE_ERROR, error.response.status, description));
                } else {
                    dispatch(receiveError(error, settingId, settingName, SETTINGS_SAVE_ERROR));
                }
                showError(dispatch, "Error", "Setting couldn't be updated!<br />Contact aphinit administrator.");
            });
    }
};

/**
 * Save new item
 *
 * @param {BaseObjectDefinition} item
 * @return {function(*=, *): Promise<Object | never>}
 */
export const saveNewSettings = (item, settingId = "lineage", callback) => {
    return (dispatch, getState) => {
        dispatch(requestSettingsDetailNew(item));
        /** @type RequestOptions */
        let options = createBaseOptions();
        options.method = METHOD_POST;
        options.headers["Content-Type"] = "application/json";
        //item.inserted = new Date().toISOString();
        options["body"] = item;

        return fetchJson(`${config.url.API_URL}/settings/custom/item/`, options, dispatch)
            .then(json => {
                    dispatch(receiveSettingsSuccessfullySaved(json.id, json.name));
                    dispatch(receiveSettingsDetail(json.id, json.name, json));
                    if (callback) {
                        callback();
                    }
                    //Go back to list
                    dispatch(push(`/setup/${settingId}`));
                    showSuccess(dispatch, "Success", "Setting has been successfully inserted!");
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, null, null, SETTINGS_SAVE_ERROR, error.response.status, description));
                } else {
                    dispatch(receiveError(error, null, null, SETTINGS_SAVE_ERROR));
                }
                showError(dispatch, "Error", "Setting couldn't be inserted!<br />Contact aphinit administrator.");
            });
    }
};

/**
 * Delete entry
 *
 * @param {String} itemId
 * @return {function(*=, *): Promise<Object | never>}
 */
// export const deleteEntry = (itemId) => {
//     return (dispatch, getState) => {
//
//         dispatch(requestEntryDelete(itemId));
//
//         let options = createBaseOptions();
//         options.method = METHOD_DELETE;
//
//         return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${itemId}`, options, dispatch)
//             .then(json => {
//                     //json['productID'] = productID;
//                     dispatch(receiveEntrySuccessfullyDeleted(itemId));
//                     dispatch(receiveObjectDefinitionDetail(json.id, json));
//                     dispatch(push(`/entry/${json.parent.id}`));
//                     showSuccess(dispatch, "Success", "Entry has been successfully deleted!");
//                 }
//             ).catch(error => {
//                 if(error instanceof ResponseStatusException) {
//                     const description = error.response.message ? error.response.message : null;
//                     dispatch(receiveError(itemId, error, SETTINGS_DELETE_ERROR, error.response.status, description));
//                     showError(dispatch, error.message, description);
//                 } else {
//                     dispatch(receiveError(itemId, error, SETTINGS_DELETE_ERROR));
//                     showError(dispatch, error.message);
//                 }
//             });
//     }
// };