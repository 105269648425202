import GroupsPresenter from '../../components/setup/Groups';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchGroupList} from "../../actions/groupList";
import {fetchGroupDetail} from "../../actions/groupDetail";
import {fetchUserList} from "../../actions/userList";
import {fetchGroupUsers, addGroupUser, deleteGroupUser} from "../../actions/groupUsers";
import {isUndefined, isEmptyValue} from "../../utils/JsObjectHelper";
import {isAdminUser} from "../../utils/AuthHelper";

const getGroupUserIdArray = (rr) => {
    if (!isUndefined(rr) && rr.getState().isDone()) {
        return rr.getData().map(u => u.id);
    }
    return [];
}

const mapStateToProps = (state, ownProps) => {
    return {
        groupListRequestResult: state.groupList,
        showDetail: (ownProps.match.params.setupDetailId !== undefined ? true : false),
        groupDetailId : (ownProps.match.params.setupDetailId !== undefined ? ownProps.match.params.setupDetailId : null),
        groupDetailDataRequestResult : state.groupDetail,
        allUsersDataRequestResult: state.userList,
        groupUsersData : getGroupUserIdArray(state.groupUsers),
        groupUsersRequestResult: state.groupUsers,
        isAdmin: isAdminUser(state),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: (groupId) => {
            dispatch(fetchGroupList());
            dispatch(fetchUserList());
            if (!isEmptyValue(groupId)) {
                dispatch(fetchGroupDetail(groupId));
                dispatch(fetchGroupUsers(groupId));
            }
        },

        onGroupSave(groupId, values) {
            console.log(groupId);
            console.log(values);
        },

        getGroupUsers(groupId) {
            if (!isEmptyValue(groupId)) {
                dispatch(fetchGroupUsers(groupId));
            }
        },

        getGroupDetail(groupId) {
            if (!isEmptyValue(groupId)) {
                dispatch(fetchGroupDetail(groupId));
            }
        },

        /**
         * 
         * @param {String} groupId 
         * @param {Array} userIdArr 
         */
        addUsers2Group(groupId, userIdArr) {
            userIdArr.forEach( userId => {
                dispatch(addGroupUser(groupId, userId));
            });
        },

        /**
         * 
         * @param {String} groupId 
         * @param {Array} userIdArr 
         */
        removeUsersFromGroup(groupId, userIdArr) {
            userIdArr.forEach( userId => {
                dispatch(deleteGroupUser(groupId, userId));
            });
        },
    }
};

const Groups = connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupsPresenter);

export default withRouter(Groups);