import MySavedRequestsPresenter from '../../../components/pluginPage/widgets/MySavedRequests';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { isUndefined } from '../../../utils/JsObjectHelper';
import { fetchUserCustomizations } from '../../../actions/userCustomizations';
import { fetchDeleteUserCustomization } from '../../../apicalls/fetchDeleteUserCustomization';


const getSavedRequestList = (customizationsList) => {
    if (!isUndefined(customizationsList) && customizationsList.getState().isDone()) {
        let setList = customizationsList.getData().filter(el => el.type === "search");
		if(isUndefined(setList)){
			setList = {value:[]}
		}
        return setList;
    } else {
        return null;
    }
};


const mapStateToProps = (state, ownProps) => {
    return {
        requestList: getSavedRequestList(state.userCustomizations),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: () => {
			dispatch(fetchUserCustomizations());
		},
        deleteUserCustomization: (id) => {
            let reload =()=>{dispatch(fetchUserCustomizations(true));}
          fetchDeleteUserCustomization(id, reload)
		},
    }
};

const MySavedRequests = connect(
    mapStateToProps,
    mapDispatchToProps
)(MySavedRequestsPresenter);

export default withRouter(MySavedRequests);