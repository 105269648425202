import WorkflowDetailPresenter from '../../components/setup/workflow/WorkflowDetail';
import { connect } from 'react-redux';
import {
    fetchDetail,
    fetchEmptyNewSettings,
    fetchSettingsList,
    saveEditedSettings,
    saveNewSettings,
    unmountDetail
} from "../../actions/settings";
import {fetchGroupList} from "../../actions/groupList";
import {fetchObjectList} from "../../actions/objectList"
import {fetchWorkflowList} from "../../actions/workflowList";
import {withRouter} from "react-router-dom";
import {isUndefined} from "../../utils/JsObjectHelper";
import { EMPTY_SETTINGS_WORKFLOW } from '../../utils/EmptySettings';
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');

/**
 * 
 * @param {SettingsDetailRequestResult} settingDetail 
 * @returns 
 */
const prepareSettingDetail = (settingDetail) => {
    let newSettingDetail = null;

    if (!isUndefined(settingDetail) && settingDetail.getState().isDone()) {
        newSettingDetail = settingDetail.getData();

        //init new workflow setting record
        if (newSettingDetail.id === null && newSettingDetail.name === null) {
            newSettingDetail.id = "workflow";
            newSettingDetail.name = uuidv4();
            newSettingDetail.value = cloneDeep(EMPTY_SETTINGS_WORKFLOW);
        }
    }
    
    return newSettingDetail;
};

const getWorkflowSettingsList = (settingsList, objectList) => {
    if (!isUndefined(settingsList) && settingsList.getState().isDone() && !isUndefined(objectList) && objectList.getState().isDone()) {
        let setList = settingsList.getData().filter(eT => eT.id === "workflow");
        let objList = objectList.getData();
        setList.forEach(element => {
            let objNameList = element.value.entryTypes.map(eT => { 
                return (isUndefined(objList.find(obj=>obj.type === eT)) ? eT : objList.find(obj=>obj.type === eT).name);
            });
    
            element.value.entryTypesNameList = objNameList;
        });

        return setList;
    } else {
        return null;
    }
};


const mapStateToProps = (state, ownProps) => {
    return {
        settingsDetailRequestResult: state.settingsDetail,
        settingsDetailData: prepareSettingDetail(state.settingsDetail),
        objectListRequestResult: state.objectList,
        groupListRequestResult: state.groupList,
        workflowsRequestResult: state.workflowList,
        workflowSettingsList: getWorkflowSettingsList(state.settingsList, state.objectList),
        isEdit: (!isUndefined(ownProps.match.params.setupDetailId)),
        isCreateNew: (isUndefined(ownProps.match.params.setupDetailId)),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: (settingsId = null, shouldUpdateOnly = false) => {
            dispatch(fetchGroupList());
            dispatch(fetchWorkflowList());
            dispatch(fetchSettingsList(true));
            if(isUndefined(settingsId)) {
                dispatch(fetchEmptyNewSettings());
            } else {
                dispatch(fetchDetail("workflow", settingsId, shouldUpdateOnly));
            }
            dispatch(fetchObjectList());
        },
        onUnmount: () => {
            dispatch(unmountDetail());
        },
        /**
         * @param {BaseSettinss} item
         */
        onSaveSettings: (item) => {
            if(isUndefined(ownProps.match.params.setupDetailId)) {
                // create new
                dispatch(saveNewSettings(item, "workflow"));
            } else {
                // update existing
                dispatch(saveEditedSettings("workflow", ownProps.match.params.setupDetailId, item));
            }
            //dispatch(fetchSettingsList(true));
        },
    }
};

const WorkflowDetail = connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkflowDetailPresenter);

export default withRouter(WorkflowDetail);