import React from "react";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import logoAph from '../../aphnit_simple_wide.svg';
import { Radio, Button, Upload, Modal, Tabs, Card, Row, Col, Space,Alert } from 'antd';
import { withTranslation } from 'react-i18next'
import { fetchAllBrandingForAdminByType } from "../../apicalls/branding/brandingForSetup";
import { fetchCreateBranding } from "../../apicalls/branding/createBranding";
import { fetchUpdateBranding } from "../../apicalls/branding/updateBranding";
import { notificationSuccess } from "../../utils/NotificationsHelper";
const { TabPane } = Tabs;

class Branding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brandingActive: false,
            previewOpen: false,
            previewImage: '',
            singleFileList: [],
            brandingExisting: false
        }
    }
    componentDidMount = () => {
        fetchAllBrandingForAdminByType('webTheme', (json) => { let logo = json.find((el) => el.id === 'mainLogo'); this.setSettings(logo) });
    };
    setSettings = (json, message) => {
        this.setState({
            singleFileList: json.value ? [json.value] : [],
            brandingActive: json.value ? json.active : false,
            brandingExisting: true
        });
        if (message) {
            notificationSuccess(this.props.t('setup.branding.notificationSuccess'))
            this.props.refresh();
        }
    }
    getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    handleCancel = () => {
        this.setState({ previewOpen: false });
    };

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
        });
    };

    handleDelete = async (file) => {
        try {
            this.setState(prevState => {
                const newFileList = prevState.fileList.filter(item => item.uid !== file.uid);
                return { fileList: newFileList };
            });
        } catch (error) {
            console.error('Error deleting file:', file, error);
        }
    };

    handleSingleChange = async ({ file, fileList }) => {
        if (file.status === 'done' || file.status === 'error' || file.status === 'uploading') {
            let base64 = file.thumbUrl || '';
            if (!base64 && file.originFileObj) {
                base64 = await this.getBase64(file.originFileObj);
            }
            const newFile = {
                uid: file.uid,
                name: file.name,
                status: file.status === 'error' ? "done" : file.status,
                url: base64
            };

            this.setState({ singleFileList: [newFile] });
        } else {
            this.setState({ singleFileList: [] });
        }
    };

    handleFileSubmit = async () => {
        const { singleFileList, brandingExisting, brandingActive } = this.state;
        const file = singleFileList[0];
        let branding = {
            id: 'mainLogo',
            type: 'webTheme',
            value: file,
            active: brandingActive
        }
        branding = JSON.stringify(branding);
        if (brandingExisting) {
            fetchUpdateBranding(branding, (json) => this.setSettings(json, 'refresh'));
        }
        else {
            fetchCreateBranding(branding, (json) => this.setSettings(json, 'save'));
        }

    };
    changeRadio = (radio) => {
        this.setState({ brandingActive: radio.target.value })
    }
    remove = () => {
        this.setState({ singleFileList: [] })
    }

    render() {
        const { t } = this.props;
        const { previewOpen, previewImage, singleFileList, brandingActive } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        const singleUploadProps = {
            listType: "picture-card",
            fileList: singleFileList,
            showUploadList: { showRemoveIcon: false },
            onChange: this.handleSingleChange,
            onPreview: this.handlePreview,
        };
        let mainTabs = <Tabs tabPosition="left">
            <TabPane tab={t('setup.branding.tabChangeLogo')} key="cl">
                <Card>
                    <Alert
                        message={t('setup.branding.infoAlertTitle')}
                        description={t('setup.branding.infoAlertDesc')}
                        type="info"
                        showIcon
                    />
                    <div style={{ display: 'flex', flexDirection: 'row',marginTop:'20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <h5 style={{ textAlign: 'center' }}>{t('setup.branding.customLogo')}</h5>
                            <Upload {...singleUploadProps}>
                                {singleFileList.length >= 1 ? null : uploadButton}
                            </Upload>
                            <Button onClick={() => this.remove()} type="link" danger >{t('setup.branding.btnDelete')}<DeleteOutlined /></Button>
                        </div>
                        <div style={{ marginLeft: '20px' }}>
                            <h5 style={{ textAlign: 'center' }}>{t('setup.branding.defaultLogo')}</h5>
                            <div style={{ width: '104px', height: '104px', objectFit: 'contain', backgroundColor: "#fafafa", border: "1px dashed #d9d9d9", borderRadius: "2px" }}>
                                <img src={logoAph} width='100%' height="100%" />
                            </div>
                        </div>
                    </div>
                    <h5>{t('setup.branding.radioLabel')}</h5>
                    <Radio.Group onChange={this.changeRadio} value={brandingActive}>
                        <Space direction="vertical">
                            <Radio value={true}>{t('setup.branding.radioCustomLogo')}</Radio>
                            <Radio value={false}>{t('setup.branding.radioDefaultLogo')}</Radio>
                        </Space>
                    </Radio.Group>
                    <Row className="steps-action" justify="end" type="flex">
                        <Col>
                            <Button onClick={this.handleFileSubmit} type="primary">{t('setup.branding.btnSave')}</Button>
                        </Col>
                    </Row>

                </Card>
            </TabPane>

        </Tabs>
        return (
            <>
                {mainTabs}
                <Modal visible={previewOpen} footer={null} onCancel={this.handleCancel}>
                    <img
                        alt="example"
                        style={{ width: '100%' }}
                        src={previewImage}
                    />
                </Modal>
            </>
        );
    }
}

export default withTranslation()(Branding);