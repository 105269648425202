import {EMPTY_OBJECT} from "../utils/JsObjectHelper";
import {WS_CLIENT_INFO_RECEIVED, WS_CLOSED} from "../modules/websocket/actions";

const deepEqual = require('deep-equal');

/**
 *
 * @param {ClientInfo} state
 * @param {ClientInfoAction} action
 * @return {ClientInfo}
 */
export const websocketClientInfo = (state = EMPTY_OBJECT, action)  => {

    switch (action.type) {
        case WS_CLIENT_INFO_RECEIVED:
            const newState = action.clientInfo;
            if(!deepEqual(newState, state)) {
                return newState;
            } else {
                return state;
            }
        case WS_CLOSED:
            return EMPTY_OBJECT;
        default:
            return state
    }
};
