import React from "react";
import PropTypes from "prop-types";
import { isArray, isEmptyObject } from "../../../utils/JsObjectHelper";
import { Input, Select, Alert, Checkbox, Form, Radio } from "antd";

import { withTranslation } from "react-i18next";
import { entryTypesRequestResult } from "../../../shapes/RequestResult";
import { SETTINGS_WORKFLOW_USETYPES } from "../../../utils/EmptySettings";
import BpmnVariables from "./BpmnVariables";
import FormNameValueItem from "../../controls/FormNameValueItem";

const { Option } = Select;

class WorkflowDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      objectType: null
    };
  }
  onFieldsChange = (changedFields, fields) => {
    this.props.onAttributeChange(changedFields);
  };

  entryTypeValidator = (rule, value, callback) => {
    let type = []
    let e = this.props.extFormRef.current.getFieldValue("entryTypes")
    if (value.length > 0) {
      type = [...new Set(this.props.objectListRequestResult.getData()
        .filter((oT) => value.includes(oT.type))
        .map((et) => (et.systemType)))];
    }
    if (type.length > 1) {
      callback(rule.message);
    }
    else {
      callback();
    }
  };
  radioChange = (value) => {
    this.setState({ objectType: value.target.value });
  }
  render() {
    const { t } = this.props;

    let entryTypeOptions = [];

    if (
      this.props.objectListRequestResult.getState().isDone() &&
      !isEmptyObject(this.props.objectListRequestResult.getData())
    ) {
      entryTypeOptions = this.props.objectListRequestResult
        .getData()
        // .filter((et) => {
        //   return et.systemType === "object";
        // })
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((et) => (
          <Option value={et.type} key={et.type}>
            {et.name}
          </Option>
        ));
    }

    let groupsOptions = [];

    if (
      this.props.groupListRequestResult.getState().isDone() &&
      !isEmptyObject(this.props.groupListRequestResult.getData())
    ) {
      groupsOptions = this.props.groupListRequestResult
        .getData()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((et) => (
          <Option value={et.id} key={et.id}>
            {et.name}
          </Option>
        ));
    }

    let camundaNamesOptions = [];
    if (
      this.props.workflowsRequestResult.getState().isDone() &&
      !isEmptyObject(this.props.workflowsRequestResult.getData()) &&
      this.props.workflowSettingsList !== null
    ) {
      camundaNamesOptions = this.props.workflowsRequestResult
        .getData()
        .sort((a, b) =>
          (a.name ? a.name : a.key).localeCompare(b.name ? b.name : b.key)
        )
        .map((et) => {
          /*console.log(this.props.lineageValueData.workflowUseType);
console.log(this.props.workflowSettingsList.find(w => w.camundaName === et.id && w.id !== this.props.lineageValueData.id));
console.log(et);
console.log(this.props.workflowSettingsList);
                    if (this.props.lineageValueData.workflowUseType === "onSave" && 
                        !isUndefined(this.props.workflowSettingsList.find(w => w.value.isEnabled && 
                            w.value.camundaName === et.id && 
                            w.value.workflowUseType === "onSave" &&
                            w.id !== this.props.lineageValueData.id)))
                        return <Option value={et.id} key={et.id} disabled>{et.name}</Option>;
                    else */
          return (
            <Option value={et.key} key={et.key}>
              {et.name}
            </Option>
          );
        });
    }

    let formFields = [];
    Object.keys(this.props.workflowValueData).forEach((field) => {
      formFields.push({
        name: [field],
        value: this.props.workflowValueData[field],
      });
    });

    let hiddenVariableMapping = false;
    if (this.state.objectType === "folder" || this.props.extFormRef.current && this.props.extFormRef.current.getFieldValue("objectType") === "folder") {

      hiddenVariableMapping = true;

      if (this.props.objectListRequestResult.getState().isDone() &&
        !isEmptyObject(this.props.objectListRequestResult.getData())) {
        entryTypeOptions = this.props.objectListRequestResult
          .getData()
          .filter((et) => {
            return et.systemType === "folder";
          })
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((et) => (
            <Option value={et.type} key={et.type}>
              {et.name}
            </Option>
          ));
      }
    }
    let useTypeOptions = Object.keys(SETTINGS_WORKFLOW_USETYPES).map((k) => {
      if (k === "onSave") {
        {
          return <Option value={k} key={k} disabled={hiddenVariableMapping}>
            {t(SETTINGS_WORKFLOW_USETYPES[k])}
          </Option>
        }
      }
      else {
        {
          return <Option value={k} key={k}>
            {t(SETTINGS_WORKFLOW_USETYPES[k])}
          </Option>
        }
      }
    });

    return (
      <Form
        name="workflow_form"
        layout="vertical"
        onFieldsChange={this.onFieldsChange}
        ref={this.props.extFormRef}
        fields={formFields}
      >
        {/* APHINIT NAME */}
        <Form.Item
          label={t("setup.workflowSetup.detailFormName")}
          name="workflowName"
          rules={[
            {
              required: true,
              message: t("setup.workflowSetup.detailFormNameErr"),
            },
          ]}
        >
          <Input placeholder={t("setup.workflowSetup.detailFormNameHint")} />
        </Form.Item>
        {/* APHINIT DESCRIPTION */}
        <Form.Item
          label={t("setup.workflowSetup.detailFormDescription")}
          name="workflowDescription"
        >
          <Input
            placeholder={t("setup.workflowSetup.detailFormDescriptionHint")}
          />
        </Form.Item>
        {/* CAMUNDA NAME */}
        <Form.Item
          label={t("setup.workflowSetup.detailFormCamundaName")}
          name="bpmnKey"
          rules={[
            {
              required: true,
              message: t("setup.workflowSetup.detailFormCamundaNameErr"),
            },
          ]}
        >
          <Select
            placeholder={t("setup.workflowSetup.detailFormCamundaNameHint")}
          >
            {camundaNamesOptions}
          </Select>
        </Form.Item>
        {/* USE TYPE */}
        <Form.Item
          label={t("setup.workflowSetup.detailFormUseType")}
          name="workflowUseType"
          rules={[
            {
              required: true,
              message: t("setup.workflowSetup.detailFormUseTypeErr"),
            },
          ]}
        >
          <Select placeholder={t("setup.workflowSetup.detailFormUseTypeHint")}>
            {useTypeOptions}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("setup.workflowSetup.objectType")}
          name="objectType"
        >
          <Radio.Group onChange={this.radioChange} >
            <Radio value="folder">{t("setup.workflowSetup.folder")}</Radio>
            <Radio value="entry">{t("setup.workflowSetup.entry")}</Radio>
          </Radio.Group>
          {/* <Checkbox onChange={this.checkboxChange}></Checkbox> */}
        </Form.Item>
        {/* ENTRY TYPES */}
        <Form.Item
          label={t("setup.workflowSetup.detailFormTypes")}
          name="entryTypes"
          rules={[
            {
              validator: this.entryTypeValidator,
              message: t("setup.workflowSetup.validateMesageEntryType"),
            },
          ]}
        >
          <Select
            mode="tags"
            placeholder={t("setup.workflowSetup.detailFormTypesHint")}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {entryTypeOptions}
          </Select>
        </Form.Item>
        {/* GROUPS */}
        <Form.Item
          label={t("setup.workflowSetup.detailFormGroups")}
          name="groups"
          extra={
            <Alert
              message={t("setup.workflowSetup.detailFormGroupsInfoBox")}
              type="info"
              showIcon
            />
          }
          rules={[
            {
              required: true,
              message: t("setup.workflowSetup.groupRequired"),
            },
          ]}
        >
          <Select
            mode="tags"
            placeholder={t("setup.workflowSetup.detailFormGroupsHint")}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {groupsOptions}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("setup.workflowSetup.workflowPermissionGroup")}
          name="workflowPermissionGroup"
          extra={
            <Alert
              message={t("setup.workflowSetup.workflowPermissionGroupAlert")}
              type="warning"
              showIcon
            />
          }
        >
          <Select
            allowClear
            placeholder={t("setup.workflowSetup.detailFormGroupsHint")}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {groupsOptions}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("setup.workflowSetup.bpmnVariables")}
          name="dynamicMapping"
          hidden={hiddenVariableMapping}
        >
          <BpmnVariables
            entryType={this.props.objectListRequestResult}
            formRef={this.props.extFormRef}
            useType="attribut"
          />
        </Form.Item>
        <Form.Item
          label={t("setup.workflowSetup.bpmnCustomVariable")}
          name="staticMapping"
          hidden={hiddenVariableMapping}

        >
          <BpmnVariables
            entryType={this.props.objectListRequestResult}
            formRef={this.props.extFormRef}
            useType="reserved"
          />
        </Form.Item>
        {/* {variableMapping} */}
        <Form.Item
          label={t("setup.workflowSetup.detailFormIsEnabled")}
          name="isEnabled"
          valuePropName="checked"
        >
          <Checkbox></Checkbox>
        </Form.Item>
      </Form>
    );
  }
}

export default withTranslation()(WorkflowDetailForm);

WorkflowDetailForm.propTypes = {
  extFormRef: PropTypes.any.isRequired,
  workflowValueData: PropTypes.object.isRequired,
  objectListRequestResult: entryTypesRequestResult.isRequired,
  //groupListRequestResult: groupListRequestResult.isRequired,
  onAttributeChange: PropTypes.func.isRequired,
};
