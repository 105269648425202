import React from 'react';
import {Button, Input, Steps, Row, Col, Alert, Divider, message, Form, Space } from "antd";

import { SaveOutlined, PlusOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import AttributeForm from "./AttributeForm";
import update from 'immutability-helper';
import ObjectEditLayout from "./ObjectEditLayout";
import {entryTypesRequestResult} from "../../shapes/RequestResult";
import {isUndefined, isEmptyObject, isEmptyValue} from "../../utils/JsObjectHelper";
import PropTypes from "prop-types";
import { HTML5Backend } from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import { withTranslation} from 'react-i18next'
import ColorPickerFormInput from '../controls/ColorPickerFormInput';
import { EMPTY_ENTRYTYPE_ATTRIBUTE } from '../../utils/EmptyEntryTypeAttribute';

const { Step } = Steps;

const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');

const steps = [
    {
        key: 0,
        phase: 'objectDefinition',
        title: 'Attributes',
    },
    {
        key: 1,
        phase: 'objectEditLayout',
        title: 'Edit layout',
    },
    {
        key: 2,
        phase: 'objectViewLayout',
        title: 'Display layout',
    },
];

class ObjectForm extends React.Component {

    formRef = React.createRef();
    formAttRef = React.createRef();

    constructor(props) {
        super(props);
        //this.onMount = this.onMount.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAttributeChange = this.handleAttributeChange.bind(this);
        this.onEditLayoutTagDropped = this.onEditLayoutTagDropped.bind(this);
        this.onEditLayoutActionBack = this.onEditLayoutActionBack.bind(this);
        this.onEditLayoutActionNext = this.onEditLayoutActionNext.bind(this);
        this.onEditLayoutLayoutChange = this.onEditLayoutLayoutChange.bind(this);

        this.setIsCheckingAttributes = this.setIsCheckingAttributes.bind(this);

        let formData = cloneDeep(props.data);
        if(isUndefined(formData.id)) {
            formData.id = uuidv4();
        }

        this.state = {
            formData: formData,
            visible: false,
            phase: "objectDefinition",
            phaseNo: 0,
            attNumber: formData.properties.attributes.length,
            //attFormsRefs : {},
            isCheckingAttributes: false
        };
    }

    componentDidMount() {
        this.props.onMount();
    }

    displayValidationNotification = () => {
        const {t} = this.props;
        message.error(t("setup.entryTypes.detail.validationNotification"));
    }

    setIsCheckingAttributes = (isChecking) => {
        this.setState({ isCheckingAttributes : isChecking });
    }

    /**
     * Partial Form save is done between steps. Before user edit layouts.
     * 
     * @param {*} e 
     */
    handleSubmitPartialForm = (e) => {
        e.preventDefault();
        let that = this;
        this.setIsCheckingAttributes(true);

        this.formAttRef.current.validateFields()
        .then(values => {
            that.formRef.current.validateFields()
            .then(mainValues => {
                //combine base form data with attributes form data
                let updatedState = update(this.state, {
                    formData: {
                        $merge: mainValues,
                        properties: {
                            attributes: { $set: values.attributes }
                        }
                    }
                });
                that.setState((prevState, props) => {
                    return update(prevState, {
                        formData: { $set: updatedState.formData },
                        phase: { $set: "objectEditLayout" },
                        phaseNo: { $set: 1 },
                        isCheckingAttributes : { $set: false }
                    });
                }/*, () => {console.log(this.state.formData)}*/);
            }).catch((errorInfo) => {
                that.displayValidationNotification();
                that.setIsCheckingAttributes(false);
            });
        })
        .catch((errorInfo) => {
            console.log(errorInfo);
            that.displayValidationNotification();
            that.setIsCheckingAttributes(false);
        });
    };

    /**
     * 
     * @param {*} e 
     */
    handleSubmit = (e) => {
        e.preventDefault();
        let that = this;
        
        if (this.state.phase === "objectDefinition") {
            this.formAttRef.current.validateFields()
            .then(values => {
                that.formRef.current.validateFields()
                .then(mainValues => {
                    let finalState = update(this.state, {
                        formData: {
                            $merge: mainValues,
                            properties: {
                                attributes: { $set: values.attributes }
                            }
                        }
                    });
                    that.props.onSaveObjectDefinition(finalState.formData);
                }).catch((errorInfo) => {
                    that.displayValidationNotification();
                    that.setIsCheckingAttributes(false);
                });
            })
            .catch((errorInfo) => {
                console.log(errorInfo);
                that.displayValidationNotification();
                that.setIsCheckingAttributes(false);
            });
        } else {
            this.props.onSaveObjectDefinition(this.state.formData);
        }
    };

    /*addAttributeForm = () => {
        this.setState((prevState, props) => {
            let att = EMPTY_ENTRYTYPE_ATTRIBUTE;

            return update(prevState, {
                formData: {
                    properties: {
                        attributes: {$push: [att]}
                    }
                }
            });
        });
    };*/

    handleAttributeChange = (index, fields) => {
        this.setState((prevState, props) => {
            let att = cloneDeep(prevState.formData.properties.attributes[index]);
            //Object.keys(fields).forEach((field) => {
            fields.forEach((field) => {
                att[field.name] = field.value;
            });
            return update(prevState, {
                formData: {
                    properties: {
                        attributes: {
                            [index]: {$set: att}
                        }
                    }
                }
            });
        });
        // this.state.formData.properties.attributes[name];
    };

    handleAttributeRemove = (index, doRemove) => {
        const {t} = this.props;
        
        if ((!isUndefined(this.state.formData.properties.editLayout.attributesDivDefinition) && 
            this.state.formData.properties.editLayout.attributesDivDefinition.some(d => d.attributeIndexes.includes(index))) ||
            (!isUndefined(this.state.formData.properties.viewLayout.attributesDivDefinition) && 
            this.state.formData.properties.viewLayout.attributesDivDefinition.some(d => d.attributeIndexes.includes(index)))
            ) {
            message.error(t("setup.entryTypes.detail.removeAttValidation"));
        } else {
            doRemove(index);
        }
    };

    handleColorChange = (fields) => {
        this.setState((prevState, props) => {
            return update(prevState, {
                formData: {
                    properties: {
                        typeColor: {$set: fields.value}
                    }
                }
            });
        });
    };

    onEditLayoutTagDropped = (layoutName, tag, dropResult) => {
        let attIndex = tag.index;
        this.setState((prevState, props) => {
            let attDiv = prevState.formData.properties[layoutName].attributesDivDefinition.find(x => x.attributeIndexes.includes(attIndex));
            let attDivIndex = prevState.formData.properties[layoutName].attributesDivDefinition.findIndex(x => x === attDiv);
            if(dropResult.item.index === -2) {
                let indexNumber = Math.max.apply(Math, prevState.formData.properties[layoutName].attributesDivDefinition.map(function(o) { return o.index; }));
                indexNumber = isUndefined(indexNumber) || Number.NEGATIVE_INFINITY === indexNumber ? 0 : indexNumber + 1;
                let newDiv = {
                    index: indexNumber,
                    name: "New section " + indexNumber,
                    attributeIndexes: [attIndex]
                };
                let newLayout = {
                    i: indexNumber.toString(),
                    x: 0,
                    y: indexNumber,
                    w: 24,
                    h: 1, minH: 1, maxH: 1
                };
                // create new box
                let s = prevState;
                if(isUndefined(attDiv)) {
                    // do nothing
                    s = prevState;
                } else {
                    if(attDiv.attributeIndexes.length === 1) {
                        // remove whole attDiv
                        s = update(prevState, {
                            formData : {
                                properties: {
                                    [layoutName]: {
                                        attributesDivDefinition: arr => arr.filter(item => item !== attDiv),
                                        layout: arr => arr.filter(item => item.i !== attDiv.index.toString())
                                    }
                                }
                            }
                        });
                    } else {
                        s = update(prevState, {
                            formData : {
                                properties: {
                                    [layoutName]: {
                                        attributesDivDefinition: { [attDivIndex]: {attributeIndexes: arr => arr.filter(item => item !== attIndex)}}
                                    }
                                }
                            }
                        });
                    }
                }
                return update(s, {
                    formData : {
                        properties: {
                            [layoutName]: {
                                attributesDivDefinition: { $push: [newDiv] },
                                layout: { $push: [newLayout] }
                            }
                        }
                    }
                });
            } else if(dropResult.item.index === -1) {
                // put to unused attributes
                if(isUndefined(attDiv)) {
                    // do nothing
                    return prevState;
                } else {
                    if(attDiv.attributeIndexes.length === 1) {
                        // remove whole attDiv
                        return update(prevState, {
                            formData : {
                                properties: {
                                    [layoutName]: {
                                        attributesDivDefinition: arr => arr.filter(item => item !== attDiv),
                                        layout: arr => arr.filter(item => item.i !== attDiv.index.toString())
                                    }
                                }
                            }
                        });
                    } else {
                        return update(prevState, {
                            formData : {
                                properties: {
                                    [layoutName]: {
                                        attributesDivDefinition: { [attDivIndex]: {attributeIndexes: arr => arr.filter(item => item !== attIndex)}}
                                    }
                                }
                            }
                        });
                    }
                }
            } else {
                let resultDiv = prevState.formData.properties[layoutName].attributesDivDefinition.find(x => x.index === dropResult.item.index);
                let resultDivIndex = prevState.formData.properties[layoutName].attributesDivDefinition.findIndex(x => x === resultDiv);
                if(resultDiv.attributeIndexes.includes(attIndex)) {
                    // do nothing
                    return prevState;
                } else {
                    let s = prevState;
                    if(isUndefined(attDiv)) {
                        // do nothing
                        s = prevState;
                    } else {
                        if(attDiv.attributeIndexes.length === 1) {
                            // remove whole attDiv
                            s = update(prevState, {
                                formData : {
                                    properties: {
                                        [layoutName]: {
                                            attributesDivDefinition: arr => arr.filter(item => item !== attDiv),
                                            layout: arr => arr.filter(item => item.i !== attDiv.index.toString())
                                        }
                                    }
                                }
                            });
                            //remember div index shift after remove
                            if(resultDivIndex > attDivIndex) {
                                resultDivIndex--;
                            }
                        } else {
                            s = update(prevState, {
                                formData : {
                                    properties: {
                                        [layoutName]: {
                                            attributesDivDefinition: { [attDivIndex]: {attributeIndexes: arr => arr.filter(item => item !== attIndex)}}
                                        }
                                    }
                                }
                            });
                        }
                    }
                    // always push index to resultDiv
                    return update(s, {
                        formData : {
                            properties: {
                                [layoutName]: {
                                    attributesDivDefinition: { [resultDivIndex]: {attributeIndexes: { $push: [attIndex] }}},
                                }
                            }
                        }
                    });
                }
            }
        });
    };

    onEditLayoutActionBack =() => {
        this.setState({phase: "objectDefinition", phaseNo: 0});
    };

    onEditLayoutActionNext =() => {
        this.setState({phase: "objectViewLayout", phaseNo: 2});
    };

    onEditViewLayoutActionBack =() => {
        this.setState({phase: "objectEditLayout", phaseNo: 1});
    };

    onEditLayoutLayoutChange = (layoutName, layout) => {
        this.setState((prevState, props) => {
            return update(prevState, {
                formData: {
                    properties: {
                        [layoutName]: {
                            layout: { $set: layout }
                        }
                    }
                }
            });
        });
    };

    onCopyLayoutFromEditTemplate = (layoutName) => {
        this.setState((prevState, props) => {
            let editLayoutCopy = cloneDeep(prevState.formData.properties.editLayout);
            editLayoutCopy.attributesProperties = {};

            return update(prevState, {
                formData: {
                    properties: {
                        [layoutName]: {$set: editLayoutCopy}
                    }
                }
            });
        });
    };

    onSectionNameChanged = (layoutName, name, index) => {
        this.setState((prevState, props) => {
            let layout = prevState.formData.properties[layoutName].attributesDivDefinition;
            let layout2Edit = layout.find(l => l.index === index);

            if (!isEmptyObject(layout2Edit)) {
                layout2Edit.name = name;
    
                return update(prevState, {
                    formData: {
                        properties: {
                            [layoutName]: {
                                attributesDivDefinition: { $set: layout }
                            }
                        }
                    }
                });
            }
        });
    };

    onAttributeLayoutPropertyChange = (layoutName, attributeTechName, propType, value) => {
        this.setState((prevState, props) => {
            let attProps = prevState.formData.properties[layoutName].attributesProperties;
            if(isUndefined(attProps)) {
                attProps = {};
            }
            if(isUndefined(attProps[attributeTechName])) {
                attProps[attributeTechName] = {};
            }
            attProps[attributeTechName][propType] = value;

            return update(prevState, {
                formData: {
                    properties: {
                        [layoutName]: {
                            attributesProperties: { $set: attProps }
                        }
                    }
                }
            });
        });
    };

    entryTypeTechnicalNameValidator = (rule, value, callback) => {
        if (this.props.entryTypesRequestResult != null && this.props.entryTypesRequestResult.getState().isDone()) {
            let entryTypes = this.props.entryTypesRequestResult.getData();
            if (entryTypes.find(et => {return et.type === value && et.id !== this.state.formData.id; }) != null) {
                callback(rule.message);
            }
        }
        callback();
    };

    entryTypeTechnicalNameFormatValidator = (rule, value, callback) => {
        if (/^[\w-_]+$/g.test(value)) {
            callback();
        } else {
            callback(rule.message);
        }
    };

    render() {
        const {t} = this.props;
        let stepContent = null;
        let buttons = null;
        let submitButton = <Form.Item>
                                <Button type="primary" htmlType="button" onClick={this.handleSubmit} 
                                    disabled={this.state.isCheckingAttributes || this.hasErrors()}
                                >
                                <SaveOutlined />{t('setup.entryTypes.detail.btnSubmit')}
                                </Button>
                            </Form.Item>;

        const RightButton = () => {
            return <Button type="primary" htmlType="button" onClick={this.handleSubmitPartialForm} 
                            // disabled={this.hasErrors() || this.state.isCheckingAttributes}
                            loading={this.state.isCheckingAttributes}
                            >
                            <RightOutlined />{t('setup.entryTypes.detail.btnNext')}
                        </Button>;
        }
            

        if(this.state.phase === "objectDefinition") {
            let infoBox = (isEmptyValue(this.props.data.id) ? (<Alert message="Info" type="info" showIcon
                    description={<ul>
                        <li><b>{t('setup.entryTypes.detail.infoNames')}: </b>{t('setup.entryTypes.detail.infoNamesTxt')}</li>
                        <li><b>{t('setup.entryTypes.detail.infoTechNames')}: </b>{t('setup.entryTypes.detail.infoTechNamesTxt')}<br/>
                            {t('setup.entryTypes.detail.infoLine1')}<br/>
                            {t('setup.entryTypes.detail.infoLine2')}<br/>
                            {t('setup.entryTypes.detail.infoLine3')}<br/>
                            {t('setup.entryTypes.detail.infoLine4')}
                        </li>
                        <li><b>{t('setup.entryTypes.detail.infoAttType')}: </b>{t('setup.entryTypes.detail.infoAttTypeTxt')}</li>
                        </ul>} >
                </Alert>): (<Alert message="Edit Warning" type="warning" showIcon
                    description={<ul>
                        <li><b>{t('setup.entryTypes.detail.warnObjTechName')}: </b>{t('setup.entryTypes.detail.warnObjTechNameTxt')}</li>
                        <li><b>{t('setup.entryTypes.detail.warnAttTechName')}: </b>{t('setup.entryTypes.detail.warnAttTechNameTxt')}</li>
                        <li><b>{t('setup.entryTypes.detail.infoAttType')}: </b>{t('setup.entryTypes.detail.warnAttTypeTxt')}</li>
                        </ul>} >
                </Alert>));

            stepContent=
                <div>
                    <div>
                        <Row gutter={[{ xs: 6, sm: 12, md: 24, lg: 32 }, 20]}>
                            <Col span={10}>
                                <Form //onSubmit={this.handleSubmit}
                                    name="object_form"
                                    ref={this.formRef}
                                    layout="vertical"
                                    >
                                    {this.addFormInputItem("name", this.state.formData.name, t('setup.entryTypes.detail.boxObjectName'), t('setup.entryTypes.detail.boxObjectName'), [{
                                        required: true,
                                        message: t('setup.entryTypes.detail.boxObjectNameErr')
                                    }], "100%", false)}
                                    {this.addFormInputItem("type", this.state.formData.type, t('setup.entryTypes.detail.boxObjectTechName'), t('setup.entryTypes.detail.boxObjectTechName'), 
                                        [
                                            {
                                                required: true,
                                                message: t('setup.entryTypes.detail.boxObjectTechNameErr'),
                                            },
                                            {
                                                message: t('setup.entryTypes.detail.boxObjectTechNameDuplaErr'),
                                                validator: this.entryTypeTechnicalNameValidator
                                            },
                                            {
                                                message: t('setup.entryTypes.detail.boxObjectTechNameFormatErr'),
                                                validator: this.entryTypeTechnicalNameFormatValidator
                                            }
                                        ], 
                                        "100%", !isEmptyValue(this.props.data.id))}
                                    <ColorPickerFormInput 
                                        tagText={this.state.formData.name} 
                                        colorValue={this.state.formData.properties.typeColor}
                                        formInputName="typeColor"
                                        formInputTitle={t('setup.entryTypes.detail.boxObjectColor')}
                                        onAttributeChange={this.handleColorChange}></ColorPickerFormInput>
                                </Form>
                            </Col>
                            <Col span={14}>
                                {infoBox}
                            </Col>
                        </Row>
                    </div>
                    <Divider>{t('setup.entryTypes.detail.lblAttributes')}</Divider>

                    {/* {attributeForms} */}

                    <Form 
                        ref={this.formAttRef}
                        name={'attribute_form'} 
                        layout="inline"
                        initialValues={this.state.formData.properties}
                    >
                        <Form.List name="attributes">
                            {(fields, { add, remove }) => (
                                <>
                                    <Space /*align="baseline"*/ direction="vertical" style={{width: '100%'}}>
                                        {fields.map((field, index) => (
                                            <AttributeForm 
                                                key={field.key} 
                                                index={index} 
                                                attributeData={field} 
                                                entryTypesRequestResult={this.props.entryTypesRequestResult} 
                                                extFormRef={this.formAttRef}
                                                isNew={this.state.attNumber<=index}
                                                onAttributeRemove={(i) => this.handleAttributeRemove(i,remove)}/>
                                        ))}
                                        <Form.Item>
                                            {/* <Button type="dashed" htmlType="button" onClick={this.addAttributeForm}> */}
                                            <Button type="dashed" htmlType="button" onClick={() => add(EMPTY_ENTRYTYPE_ATTRIBUTE)}>
                                                <PlusOutlined /> {t('setup.entryTypes.detail.btnAddAttribute')}
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                </>
                            )}
                        </Form.List>
                    </Form>

                </div>;
            buttons = 
                <Row>
                    <Col span={12}>
                        {/* <Form.Item shouldUpdate>
                            {() => ( */}
                                <RightButton></RightButton>
                            {/* )}
                        </Form.Item> */}
                    </Col>
                    <Col span={12} style={{textAlign:'right'}}>
                        {submitButton}
                    </Col>
                </Row>;

        } else if(this.state.phase === "objectEditLayout") {
            stepContent= 
                <div>
                    <ObjectEditLayout 
                        data={this.state.formData} 
                        layoutTypeName="editLayout"
                        onTagDropped={this.onEditLayoutTagDropped} 
                        onActionBack={this.onEditLayoutActionBack} 
                        onActionNext={this.onEditLayoutActionNext} 
                        onLayoutChange={this.onEditLayoutLayoutChange} 
                        onSectionNameChanged={this.onSectionNameChanged}
                        onAttributeLayoutPropertyChange={this.onAttributeLayoutPropertyChange}
                        generalSettingsObject={this.props.generalSettingsObject}
                        />
                </div>
            buttons = 
            <Row>
                <Col span={12}>
                    <Button type="default" htmlType="button" onClick={this.onEditLayoutActionBack} disabled={this.hasErrors()} style={{marginRight:'10px'}}>
                        <LeftOutlined />{t('setup.entryTypes.detail.btnBack')}
                    </Button>
                    <Button type="primary" htmlType="button" onClick={this.onEditLayoutActionNext} disabled={this.hasErrors()}>
                        <RightOutlined />{t('setup.entryTypes.detail.btnNext')}
                    </Button>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    {submitButton}
                </Col>
            </Row>;
        } else if(this.state.phase === "objectViewLayout") {
            stepContent = 
                <div>
                    <ObjectEditLayout 
                        data={this.state.formData} 
                        layoutTypeName="viewLayout"
                        onTagDropped={this.onEditLayoutTagDropped} 
                        onActionBack={this.onEditLayoutActionBack} 
                        onActionNext={this.onEditLayoutActionNext} 
                        onLayoutChange={this.onEditLayoutLayoutChange} 
                        onSectionNameChanged={this.onSectionNameChanged}
                        onCopyLayoutFromEditTemplate={this.onCopyLayoutFromEditTemplate}
                        onAttributeLayoutPropertyChange={this.onAttributeLayoutPropertyChange}
                        entryTypesRequestResult={this.props.entryTypesRequestResult}
                        />
                </div>

            buttons = 
            <Row>
                <Col span={12}>
                    <Button type="default" htmlType="button" onClick={this.onEditViewLayoutActionBack} disabled={this.hasErrors()} style={{marginRight:'10px'}}>
                        <LeftOutlined />{t('setup.entryTypes.detail.btnBack')}
                    </Button>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    {submitButton}
                </Col>
            </Row>;
        }

        return  <div>
                    <Steps current={this.state.phaseNo}>
                        {steps.map(item => (
                            <Step key={item.key} title={item.title} />
                        ))}
                    </Steps>
                    <div className="steps-content">
                        <DndProvider backend={HTML5Backend}>
                            {stepContent}
                        </DndProvider>
                    </div>
                    <div className="steps-action">
                        {buttons}
                    </div>
                </div>;
    }

    /**
     *
     * @param {String} name
     * @param {Object} initialValue
     * @param {String} label
     * @param {String} placeholder
     * @param {Array<Object>} rules
     * @return {*}
     */
    addFormInputItem(name, initialValue = null, label = null, placeholder = '', rules = [], boxWidth, isDisabled = false) {
        //const { getFieldDecorator, getFieldError/*, isFieldTouched, getFieldsError*/ } = this.props.form;
        // const valueError = isFieldTouched(name) && getFieldError(name);
        //const valueError =getFieldError(name);
        return <Form.Item label={label} //validateStatus={valueError ? 'error' : ''} help={valueError || ''}
            name={name}
            rules={rules}
            initialValue={initialValue}
        >
            <Input placeholder={placeholder} style={{ width: boxWidth }} allowClear={true} disabled={isDisabled}/>
        </Form.Item>;
    }

    /**
     *
     * @return {boolean}
     */
    hasErrors() {
        if (!isUndefined(this.formRef.current)) {
            const fieldsError = this.formRef.current.getFieldsError();
            //return Object.keys(fieldsError).some(field => fieldsError[field]);
            return fieldsError.some(field => field.errors.length > 0);
        } else {
            return false;
        }
    }
}

export default withTranslation() (ObjectForm);

ObjectForm.propTypes = {
    entryTypesRequestResult: entryTypesRequestResult.isRequired,
    onSaveObjectDefinition: PropTypes.func.isRequired
};