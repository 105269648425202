import FolderPropertiesPresenter from '../../components/detail/edit/FolderProperties';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchObjectList} from "../../actions/objectList";
import { fetchSettingsList } from '../../actions/settings';

const getSettingsList = (settingsListRequestResult) => {
    let settingsList = [];

    if (settingsListRequestResult.getState().isDone()) {
        settingsList = settingsListRequestResult.getData().filter(sL => sL.id === "lineage");
    }

    return settingsList;
};
const getWorkflowList = (settingsListRequestResult) =>{
    let workflowList = [];

    if (settingsListRequestResult.getState().isDone()) {
        workflowList = settingsListRequestResult.getData().filter(sL => sL.id === "workflow");
    }

    return workflowList;
}

const mapStateToProps = (state, ownProps) => {
    return {
        entryTypesRequestResult: state.objectList,
        lineageSettingsList: getSettingsList(state.settingsList),
        workflowSettingsList: getWorkflowList(state.settingsList)
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList());
            dispatch(fetchSettingsList());
        },
        disabledBtn(state){
            ownProps.disabledBtn(state);
        }
    }
};

const FolderProperties = connect(
    mapStateToProps,
    mapDispatchToProps
)(FolderPropertiesPresenter);

export default withRouter(FolderProperties);