import React from "react";
import { Input, Tag, Tooltip, Form, Space, Button } from 'antd';

import PropTypes from 'prop-types';
import { isEmptyValue } from "../../utils/JsObjectHelper";
import { withTranslation } from 'react-i18next'
import { getTextColorFromBackgroundHex } from "../../utils/ColorHelper";
import ColorPickerModal from './ColorPickerModal';

const { Search } = Input;

class ColorPickerFormInput extends React.Component {
    state = { visible: false };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleSelect = e => {
        this.props.onAttributeChange({ name: this.props.formInputName, value: e, touched: true, dirty: false });
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };


    render() {
        const { t } = this.props;

        let tagText = (isEmptyValue(this.props.tagText) ? t('app.colorPicker.lblColorPreview') : this.props.tagText);

        let tagTextColor = getTextColorFromBackgroundHex(this.props.colorValue);

        return (
            <Space>
                <Tag color={this.props.colorValue} style={{ color: tagTextColor }}>{tagText}</Tag>
                <Button type="primary" size="small" onClick={this.showModal}>{t('app.colorPicker.btnPickColor')}</Button>
                <ColorPickerModal
                    colorValue={this.props.colorValue}
                    isVisible={this.state.visible}
                    onSelect={this.handleSelect}
                    onCancel={this.handleCancel}
                    colorValidator={this.colorValidator}
                >
                </ColorPickerModal>
            </Space>)
    }
}

export default withTranslation()(ColorPickerFormInput);

ColorPickerFormInput.propTypes = {
    tagText: PropTypes.string,
    formInputName: PropTypes.string.isRequired,
    formInputTitle: PropTypes.string,
    colorValue: PropTypes.string,
    onAttributeChange: PropTypes.func.isRequired
};