import { 
    TODO_LIST_REQUESTED,
    TODO_LIST_RECEIVED,
    TODO_LIST_ERROR  } from "../actions/bpmnTodoList";
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";

/**
*
* @param {RequestResult} state
* @param {BaseAction|ReceiveEntryTypesAction|ReceiveErrorAction} action
* @return {RequestResult}
*/
export const todoList = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

switch (action.type) {

   case TODO_LIST_REQUESTED:
           return new RequestResult(RequestState.getInstanceLoading(), state.data);
   case TODO_LIST_RECEIVED:
           return new RequestResult(RequestState.getInstanceDone(), action.todoList, action.receivedAt);
   case TODO_LIST_ERROR:
           return new RequestResult(
               RequestState.getInstanceError(
                   action.errorResponseStatusCode,
                   action.errorResponseMessage ? action.errorResponseMessage : null
               ),
               state.getData()
           );
   default:
       return state
}
};
