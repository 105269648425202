import { fetchJson } from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const All_TASK_LIST_REQUESTED = "All_TASK_LIST_REQUESTED";
/**
 * Action for request of entry.
 *
 * @return {AllTaskListAction}
 */
export const requestAllTaskList = () => {
  return {
    type: All_TASK_LIST_REQUESTED,
  };
};


export const All_TASK_LIST_RECEIVED = "All_TASK_LIST_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} alltaskList
 * @return {ReceiveAllTaskListAction}
 */
export const receiveAllTaskList = (alltaskList) => {
  return {
    type: All_TASK_LIST_RECEIVED,
    alltaskList: alltaskList,
    receivedAt: Date.now(),
  };
};

export const All_TASK_LIST_ERROR = "All_TASK_LIST_ERROR";
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveAllTaskListErrorAction}
 */
export const receiveError = (
  error,
  type = All_TASK_LIST_ERROR,
  errorResponseStatusCode = null,
  errorResponseMessage = null
) => {
  return {
    type: type,
    error: error,
    errorResponseStatusCode: errorResponseStatusCode,
    errorResponseMessage: errorResponseMessage,
  };
};


/**
 * 
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchAllTaskList = (update = false) => {
  return (dispatch, getState) => {
      if (update) {
        dispatch(requestAllTaskList());
      } else {
        dispatch(requestAllTaskList());
      }

      /** @type RequestOptions options **/
      let options = {
          method: "GET"
      };
    
     return  fetchJson(`${config.url.API_URL}/bpmn/task`, options, dispatch)
     .then(json => {
        let res = [];
        if (json._embedded && json._embedded.taskDtoes && json._embedded.taskDtoes.length > 0 ) {
            res = json._embedded.taskDtoes;
        }
       dispatch(receiveAllTaskList(res));
    }
   )  
         .catch(error => {
              if(error === "Ignoring request") {
              } else if(error instanceof ResponseStatusException) {
                  const description = error.response.message ? error.response.message : null;
                  dispatch(receiveError(error, All_TASK_LIST_ERROR, error.response.status, description));
                  showError(dispatch, error.message, description);
              } else {
                  dispatch(receiveError(error, All_TASK_LIST_ERROR));
                  showError(dispatch, error.message);
              }
          });
  }
};


