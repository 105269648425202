import {createBaseOptions, fetchJson, METHOD_PATCH, METHOD_POST} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError, showSuccess} from "../utils/NotificationsHelper";
import { push } from 'connected-react-router'

export const LOAD_SETTINGS_LIST_REQUESTED = 'LOAD_SETTINGS_LIST_REQUESTED';
/**
 * Action for request of load settings list.
 *
 * @return {LoadSettingsListAction}
 */
export const requestLoadSettingsList = () => {
    return {
        type: LOAD_SETTINGS_LIST_REQUESTED
    };
};

export const LOAD_SETTINGS_LIST_RECEIVED = "LOAD_SETTINGS_LIST_RECEIVED";
/**
 *
 * @param {Array<Object>} loadSettingsList
 * @return {ReceiveLoadSettingsListAction}
 */
export const receiveLoadSettingsList = (loadSettingsList) => {
    return {
        type: LOAD_SETTINGS_LIST_RECEIVED,
        loadSettingsList: loadSettingsList,
        receivedAt: Date.now()
    }
};

export const LOAD_SETTINGS_LIST_ERROR = 'LOAD_SETTINGS_LIST_ERROR';
export const LOAD_SETTINGS_DETAIL_ERROR = 'LOAD_SETTINGS_DETAIL_ERROR';
export const LOAD_SETTINGS_SAVE_ERROR = 'LOAD_SETTINGS_SAVE_ERROR';
export const LOAD_SETTINGS_DELETE_ERROR = 'LOAD_SETTINGS_DELETE_ERROR';

/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {String} settingId
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveSettingsErrorAction}
 */
export const receiveError = (
    error,
    settingId = null,
    type = LOAD_SETTINGS_LIST_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        settingId: settingId,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};


/**
 * Fetch load settings list
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchLoadSettingsList = (update = false) => {
    return (dispatch, getState) => {
        if (update) {
            //dispatch(requestEntryDetailUpdate());
            dispatch(requestLoadSettingsList());
        } else {
            dispatch(requestLoadSettingsList());
        }

        /** @type RequestOptions options **/
        /*let options = {
            method: "GET"
        };
        options.ignore = {
            key: "fetchSettingsList",
            maxLifeTime: 60000
        };
        options.cache = {
            key: "fetchSettingsList",
            maxLifeTime: 60000,
            forceRefresh: update
        };*/

        //TODO: pridat url API
        return fetchJson(`${config.url.API_URL}/load/settings/custom/items/all`, null, dispatch)
            .then(json => {
                    let res = [];

                    /*res = [
                        {
                            id: "testid",
                            code: "LOAD_MSSQL",
                            value: {
                                customProps: [
                                    {name: "con_str", value:"muj super con string"}
                                ],
                                folders: {
                                    write: [
                                        {name: "cilova_slozka", folderId: "slozkaID"}
                                    ],
                                    read: [
                                        {name: "cteci_slozka", folderId: "slozkaID2"},
                                        {name: "cteci_slozka_2", folderId: "slozkaID3"}
                                    ]
                                }
                            }
                        }
                    ];*/
                    if (json._embedded && json._embedded.loadSettings && json._embedded.loadSettings.length > 0) {
                        res = json._embedded.loadSettings;
                    }
                    dispatch(receiveLoadSettingsList(res));
                }
            ).catch(error => {
                if(error === "Ignoring request") {
                    // TODO create some better solution with ignoring same requests
                    // do nothing, is expected state
                } else if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, null, LOAD_SETTINGS_LIST_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(error, null, LOAD_SETTINGS_LIST_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};


export const LOAD_SETTINGS_LOAD_NEW = 'LOAD_SETTINGS_LOAD_NEW';
/**
 * Action for request new load setting item.
 *
 * @return {BaseAction}
 */
export const requestEmptyNewLoadSettings = () => {
    return {
        type: LOAD_SETTINGS_LOAD_NEW,
    };
};

export const LOAD_SETTINGS_DETAIL_REQUESTED = 'LOAD_SETTINGS_DETAIL_REQUESTED';
/**
 * Action for request of load setting item.
 *
 * @param {String} settingId
 * @return {ItemIdAction}
 */
export const requestLoadSettingsDetail = (settingId) => {
    return {
        type: LOAD_SETTINGS_DETAIL_REQUESTED,
        settingId: settingId
    };
};

export const LOAD_SETTINGS_DETAIL_UPDATE_REQUESTED = "LOAD_SETTINGS_DETAIL_UPDATE_REQUESTED";
/**
 * Action for request of setting item.
 *
 * @param {String} settingId
 * @return {ItemIdAction}
 */
export const requestLoadSettingsDetailUpdate = (settingId) => {
    return {
        type: LOAD_SETTINGS_DETAIL_UPDATE_REQUESTED,
        settingId: settingId
    }
};


export const LOAD_SETTINGS_SUCCESSFULLY_SAVED = "LOAD_SETTINGS_SUCCESSFULLY_SAVED";
/**
 * Action for successfully saved of an setting item.
 *
 * @param {String} settingId
 * @return {ItemIdAction}
 */
export const receiveLoadSettingsSuccessfullySaved = (settingId) => {
    return {
        type: LOAD_SETTINGS_SUCCESSFULLY_SAVED,
        settingId: settingId
    }
};

export const LOAD_SETTINGS_DETAIL_RECEIVED = "LOAD_SETTINGS_DETAIL_RECEIVED";
/**
 *
 * @param {String} settingId
 * @param {BaseObjectDefinition} item
 * @return {ReceiveLoadSettingAction}
 */
export const receiveLoadSettingsDetail = (settingId, item) => {
    return {
        type: LOAD_SETTINGS_DETAIL_RECEIVED,
        item: item,
        settingId: settingId,
        receivedAt: Date.now()
    }
};

export const LOAD_SETTINGS_DETAIL_UNMOUNT = "LOAD_SETTINGS_DETAIL_UNMOUNT";
/**
 * action unmount detail
 * @return {BaseAction}
 */
export const unmountDetail = () => {
    return {
        type: LOAD_SETTINGS_DETAIL_UNMOUNT
    }
};


export const LOAD_SETTINGS_DETAIL_EDIT_REQUESTED = "LOAD_SETTINGS_DETAIL_EDIT_REQUESTED";
/**
 * Action for update item.
 *
 * @param {String} settingId
 * @param {BaseObjectDefinition} item
 * @return {LoadSettingsEditAction}
 */
export const requestLoadSettingsDetailEdit = (settingId, item) => {
    return {
        type: LOAD_SETTINGS_DETAIL_EDIT_REQUESTED,
        settingId: settingId,
        item: item,
    }
};

export const LOAD_SETTINGS_DETAIL_NEW_REQUESTED = "LOAD_SETTINGS_DETAIL_NEW_REQUESTED";
/**
 * Action for create new item.
 *
 * @param {Object} item
 * @return {LoadSettingsNewAction}
 */
export const requestLoadSettingsDetailNew = (item) => {
    return {
        type: LOAD_SETTINGS_DETAIL_NEW_REQUESTED,
        item: item,
    }
};

/**
 * Get new empty load setting item
 *
 * @return {function(*=, *): Promise<Object | never>}
 */
 export const fetchEmptyNewLoadSettings = () => {
    return (dispatch, getState) => {
        dispatch(requestEmptyNewLoadSettings());
    };
};

export const LOAD_SETTINGS_DELETE_REQUESTED = 'LOAD_SETTINGS_DELETE_REQUESTED';
/**
 * Action for delete of entry.
 *
 * @param {String} settingId
 * @return {ItemIdAction}
 */
export const requestLoadSettingsDelete = (settingId) => {
    return {
        type: LOAD_SETTINGS_DELETE_REQUESTED,
        settingId: settingId,
    };
};

export const LOAD_SETTINGS_SUCCESSFULLY_DELETED = "LOAD_SETTINGS_SUCCESSFULLY_DELETED";
/**
 * Action successfully deleted entry.
 *
 * @param {String} settingId
 * @return {ItemIdAction}
 */
export const receiveLoadSettingsSuccessfullyDeleted = (settingId) => {
    return {
        type: LOAD_SETTINGS_SUCCESSFULLY_DELETED,
        settingId: settingId,
    }
};


/**
 * Fetch settings detail
 *
 * @param {String} settingId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
 export const fetchLoadSettingDetail = (settingId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            dispatch(requestLoadSettingsDetailUpdate(settingId));
        } else {
            dispatch(requestLoadSettingsDetail(settingId));
        }

        return fetchJson(`${config.url.API_URL}/load/settings/custom/item/${settingId}`, null, dispatch)
            .then(json => {
                    //json['productID'] = productID;

                    /*let dummy = {
                        id: "testid",
                        code: "LOAD_MSSQL",
                        value: {
                            customProps: [
                                {name: "con_str", value:"muj super con string"}
                            ],
                            folders: {
                                write: [
                                    {name: "cilova_slozka", folderId: "slozkaID"}
                                ],
                                read: [
                                    {name: "cteci_slozka", folderId: "slozkaID2"},
                                    {name: "cteci_slozka_2", folderId: "slozkaID3"}
                                ]
                            }
                        }
                    };*/

                    dispatch(receiveLoadSettingsDetail(settingId, json));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, settingId, LOAD_SETTINGS_DETAIL_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(error, settingId, LOAD_SETTINGS_DETAIL_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};


/**
 * Save item detail
 *
 * @param {String} itemId
 * @param {BaseObjectDefinition} item
 * @return {function(*=, *): Promise<Object | never>}
 */
export const saveEditedLoadSettings = (settingId, item, callback) => {
    return (dispatch, getState) => {
        dispatch(requestLoadSettingsDetailEdit(settingId, item));
        /** @type RequestOptions */
        let options = createBaseOptions();
        //TODO PATCH or PUT ???
        options.method = METHOD_PATCH;
        options.headers["Content-Type"] = "application/json";
        //item.updated = new Date().toISOString();
        options["body"] = item;

        return fetchJson(`${config.url.API_URL}/load/settings/custom/item/`, options, dispatch)
            .then(json => {
                    dispatch(receiveLoadSettingsSuccessfullySaved(settingId));
                    dispatch(receiveLoadSettingsDetail(settingId, json));
                    if (callback) {
                        callback();
                    }
                    //Go back to list
                    dispatch(push(`/setup/loader`));
                    showSuccess(dispatch, "Success", "Load setting has been successfully updated!");
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, settingId, LOAD_SETTINGS_SAVE_ERROR, error.response.status, description));
                } else {
                    dispatch(receiveError(error, settingId, LOAD_SETTINGS_SAVE_ERROR));
                }
                showError(dispatch, "Error", "Load setting couldn't be updated!<br />Contact aphinit administrator.");
            });
    }
};

/**
 * Save new item
 *
 * @param {BaseObjectDefinition} item
 * @return {function(*=, *): Promise<Object | never>}
 */
export const saveNewLoadSettings = (item, callback) => {
    return (dispatch, getState) => {
        dispatch(requestLoadSettingsDetailNew(item));
        /** @type RequestOptions */
        let options = createBaseOptions();
        options.method = METHOD_POST;
        options.headers["Content-Type"] = "application/json";
        //item.inserted = new Date().toISOString();
        options["body"] = item;

        return fetchJson(`${config.url.API_URL}/load/settings/custom/item/`, options, dispatch)
            .then(json => {
                    dispatch(receiveLoadSettingsSuccessfullySaved(json.id));
                    dispatch(receiveLoadSettingsDetail(json.id, json));
                    if (callback) {
                        callback();
                    }
                    //Go back to list
                    dispatch(push(`/setup/loader`));
                    showSuccess(dispatch, "Success", "Load setting has been successfully inserted!");
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, null, LOAD_SETTINGS_SAVE_ERROR, error.response.status, description));
                } else {
                    dispatch(receiveError(error, null, LOAD_SETTINGS_SAVE_ERROR));
                }
                showError(dispatch, "Error", "Load setting couldn't be inserted!<br />Contact aphinit administrator.");
            });
    }
};

/**
 * Delete entry
 *
 * @param {String} itemId
 * @return {function(*=, *): Promise<Object | never>}
 */
// export const deleteEntry = (itemId) => {
//     return (dispatch, getState) => {
//
//         dispatch(requestEntryDelete(itemId));
//
//         let options = createBaseOptions();
//         options.method = METHOD_DELETE;
//
//         return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${itemId}`, options, dispatch)
//             .then(json => {
//                     //json['productID'] = productID;
//                     dispatch(receiveEntrySuccessfullyDeleted(itemId));
//                     dispatch(receiveObjectDefinitionDetail(json.id, json));
//                     dispatch(push(`/entry/${json.parent.id}`));
//                     showSuccess(dispatch, "Success", "Entry has been successfully deleted!");
//                 }
//             ).catch(error => {
//                 if(error instanceof ResponseStatusException) {
//                     const description = error.response.message ? error.response.message : null;
//                     dispatch(receiveError(itemId, error, LOAD_SETTINGS_DELETE_ERROR, error.response.status, description));
//                     showError(dispatch, error.message, description);
//                 } else {
//                     dispatch(receiveError(itemId, error, LOAD_SETTINGS_DELETE_ERROR));
//                     showError(dispatch, error.message);
//                 }
//             });
//     }
// };