export const REQUEST_IGNORED = "REQUEST_IGNORED";
export const requestIgnored = (url, key) => {
    return {
        type: REQUEST_IGNORED,
        url: url,
        key: key
    }
};

export const RESPONSE_ADDED_TO_CACHE = "RESPONSE_ADDED_TO_CACHE";
export const responseAddedToCache = (url, key, validUntil) => {
    return {
        type: RESPONSE_ADDED_TO_CACHE,
        url: url,
        key: key,
        validUntil: validUntil
    }
};

export const RESPONSE_REMOVED_FROM_CACHE = "RESPONSE_REMOVED_FROM_CACHE";
export const responseRemovedFromCache = (url, key) => {
    return {
        type: RESPONSE_REMOVED_FROM_CACHE,
        url: url,
        key: key
    }
};

export const ALL_CACHED_RESPONSES_REMOVED = "ALL_CACHED_RESPONSES_REMOVED";
export const allResponsesRemovedFromCache = () => {
    return {
        type: ALL_CACHED_RESPONSES_REMOVED
    }
};