import { fetchJson } from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const BPMN_FORM_REQUESTED = "BPMN_FORM_REQUESTED";
/**
 * Action for request of entry.
 *
 * @return {BpmnFormAction}
 */
export const requestBpmnForm = () => {
  return {
    type: BPMN_FORM_REQUESTED,
    
  };
};


export const BPMN_FORM_RECEIVED = "BPMN_FORM_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} bpmnForm
 * @return {ReceiveBpmnFormAction}
 */
export const receiveBpmnForm  = (bpmnForm) => {
  return {
    type: BPMN_FORM_RECEIVED,
    bpmnForm: bpmnForm,
    receivedAt: Date.now(),
  };
};

export const BPMN_FORM_ERROR = "BPMN_FORM_ERROR";
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveBpmnFormErrorAction}
 */
export const receiveError = (
  error,
  type = BPMN_FORM_ERROR,
  errorResponseStatusCode = null,
  errorResponseMessage = null
) => {
  return {
    
    error: error,
    errorResponseStatusCode: errorResponseStatusCode,
    errorResponseMessage: errorResponseMessage,
  };
};


/**
 * 
 * @param {String} taskId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchBpmnForm  = (taskId, update = false) => {
  return (dispatch, getState) => {
      if (update) {
        dispatch(requestBpmnForm(taskId));
      } else {
        dispatch(requestBpmnForm(taskId));
      }

      /** @type RequestOptions options **/
      let options = {
          method: "GET"
      };
    
    
     

     return  fetchJson(`${config.url.API_URL}/bpmn/task/${taskId}/deployedForm`, options, dispatch)
     .then(json => {
        let res = [];
        res = json
       dispatch(receiveBpmnForm(res));
    }
   )  
         .catch(error => {
          if(error.message === "Couldn't get successful response"){
            dispatch(receiveBpmnForm("No form"));
          }
              if(error === "Ignoring request") {
              } else if(error instanceof ResponseStatusException) {
                  const description = error.response.message ? error.response.message : null;
                  //dispatch(receiveError(taskId,error, BPMN_FORM_ERROR, error.response.status, description));
                //  showError(dispatch, error.message, description);
                  console.log(error);
              } else {
                 // dispatch(receiveError(error, BPMN_FORM_ERROR));
                 console.log(error);
                  //showError(dispatch, error.message);
              }
          });
  }
};


