import DomainSettingsPresenter from '../../components/setup/DomainSettings';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";

const mapStateToProps = (state, ownProps) => {
    return {
        
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // onMount: (entryId) => {
        //
        // },
        // onUnmount: () => {
        //
        // },
    }
};

const DomainSettings = connect(
    mapStateToProps,
    mapDispatchToProps
)(DomainSettingsPresenter);

export default withRouter(DomainSettings);