import {
    ENTRY_BREADCRUMB_REQUESTED,
    ENTRY_BREADCRUMB_UPDATE_REQUESTED,
    ENTRY_BREADCRUMB_RECEIVED,
    ENTRY_BREADCRUMB_ERROR
} from '../actions/entryDetailBreadCrumb.js';
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";
import {ENTRY_DETAIL_UNMOUNT} from "../actions/entryDetail";

/**
 *
 * @param {RequestResult} state
 * @param {BaseAction|EntryIdAction|ReceiveBreadCrumbAction|ReceiveEntryErrorAction} action
 * @return {RequestResult}
 */
export const entryDetailBreadCrumb = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

    switch (action.type) {
        case ENTRY_BREADCRUMB_REQUESTED:
            return new RequestResult(RequestState.getInstanceLoading(), null);
        case ENTRY_DETAIL_UNMOUNT:
            return new RequestResult(RequestState.getInstanceInit(), null);
        case ENTRY_BREADCRUMB_UPDATE_REQUESTED:
            return new RequestResult(RequestState.getInstanceUpdating(), state.data);
        case ENTRY_BREADCRUMB_RECEIVED:
            return new RequestResult(RequestState.getInstanceDone(), action.entries, action.receivedAt);
        case ENTRY_BREADCRUMB_ERROR:
            return new RequestResult(
                RequestState.getInstanceError(
                    action.errorResponseStatusCode,
                    action.errorResponseMessage ? action.errorResponseMessage : null
                ),
                state.getData()
            );
        default:
            return state
    }
};
