import TaskListPresenter from "../../components/bpmn/BpmnTaskList";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchTaskList } from "../../actions/bpmnTaskList";
import { fetchBpmnForm } from "../../actions/bpmnForm";
import { fetchClaimTask } from "../../apicalls/bpmn/fetchClaimTask";
import { fetchUnClaimTask } from "../../actions/bpmnUnClaim";
import { fetchTodoList } from "../../actions/bpmnTodoList";
import { fetchAllTaskList } from "../../actions/bpmnAllTaskList";
import { isUndefined } from "../../utils/JsObjectHelper";
import { fetchBpmnFormVariables } from "../../actions/bpmnFormVariables";
import { fetchComplete } from "../../apicalls/bpmn/fetchBpmnFormComplete";
import { notificationWarning } from "../../utils/NotificationsHelper";
import { fetchTaskCount } from "../../actions/bpmnTaskCount";
const mapStateToProps = (state, ownProps) => {
	return {
		alltaskListRequestResult: state.alltaskList,
		taskListRequestResult: state.taskList,
		bpmnFormRequestResult: state.bpmnForm,
		bpmnFormVariablesRequestResult: state.bpmnFormVariables,
		user: state.loggedUser.user,
		todoListRequestResult: state.todoList,
		taskId: !isUndefined(ownProps.match.params.taskID) ? ownProps.match.params.taskID : !isUndefined(ownProps.match.params.setupDetailId) ? ownProps.match.params.setupDetailId:"" ,
		showAdminPanel: ownProps.showPanel
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: () => {
			dispatch(fetchAllTaskList(true));
			dispatch(fetchTaskList(true));
			dispatch(fetchTodoList(true));
			
		},

		onFormChange: (taskId) => {
			dispatch(fetchBpmnForm(taskId));
			dispatch(fetchBpmnFormVariables(taskId));

		},

		onClaim: (taskId, t) => {
			let reloadTask = () => {
				dispatch(fetchTodoList());
				dispatch(fetchTaskList());
				dispatch(fetchTaskCount(true));
			};
			let errorClaim = () => {
				ownProps.history.push(`/bpmn`);
				dispatch(fetchTodoList());
				dispatch(fetchTaskList());
				notificationWarning(t);

			}
			fetchClaimTask(taskId, false, reloadTask, errorClaim);
		},
		unClaim: (taskId) => {
			let reloadTask = () => {
				dispatch(fetchTodoList());
				dispatch(fetchTaskList());
				dispatch(fetchTaskCount(true));
			};
			dispatch(fetchUnClaimTask(taskId, false, reloadTask));
		},
		handleComplete: (taskId, bodyVariables) => {
			let reloadTask = () => {
				!isUndefined(ownProps.match.params.taskID) ? ownProps.history.push(`/bpmn`):ownProps.history.push(`/setup/tasklist`);
				dispatch(fetchTodoList());
				dispatch(fetchTaskList());
				dispatch(fetchAllTaskList());
				dispatch(fetchTaskCount(true));

			};
			fetchComplete(taskId, false, bodyVariables, reloadTask);
		},
	};
};

const TaskList = connect(
	mapStateToProps,
	mapDispatchToProps
)(TaskListPresenter);

export default withRouter(TaskList);
