import LoadSettingDetailPresenter from '../../../components/setup/loader/LoadSettingDetail';
import { connect } from 'react-redux';
import {
    fetchLoadSettingDetail,
    fetchEmptyNewLoadSettings,
    saveEditedLoadSettings,
    saveNewLoadSettings,
    unmountDetail,
    fetchLoadSettingsList
} from "../../../actions/loadSettings";
import {fetchObjectList} from "../../../actions/objectList"
import {withRouter} from "react-router-dom";
import {isEmptyValue, isUndefined} from "../../../utils/JsObjectHelper";
import { EMPTY_LOAD_SETTINGS } from '../../../utils/EmptyLoadSettings';
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');

/**
 * 
 * @param {LoadSettingsDetailRequestResult} settingDetail 
 * @returns 
 */
const prepareLoadSettingDetail = (loadSettingDetail) => {
    let newLoadSettingDetail = null;

    if (!isUndefined(loadSettingDetail) && loadSettingDetail.getState().isDone()) {
        newLoadSettingDetail = loadSettingDetail.getData();
        //init new load setting record
        if (isEmptyValue(newLoadSettingDetail.id)) {
            newLoadSettingDetail = cloneDeep(EMPTY_LOAD_SETTINGS);
            newLoadSettingDetail.id = uuidv4();
        }
    }

    return newLoadSettingDetail;
};


const mapStateToProps = (state, ownProps) => {
    return {
        loadSettingsDetailRequestResult: state.loadSettingsDetail,
        loadSettingsDetailData: prepareLoadSettingDetail(state.loadSettingsDetail),
        loadSettingsListRequestResult: state.loadSettingsList,
        objectListRequestResult: state.objectList,
        isEdit: (!isUndefined(ownProps.match.params.setupDetailId)),
        isCreateNew: (isUndefined(ownProps.match.params.setupDetailId)),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: (settingsId = null, shouldUpdateOnly = false) => {
            if(isUndefined(settingsId)) {
                dispatch(fetchEmptyNewLoadSettings());
            } else {
                dispatch(fetchLoadSettingDetail(settingsId, shouldUpdateOnly));
            }
            dispatch(fetchLoadSettingsList(false));
            dispatch(fetchObjectList());
        },
        onUnmount: () => {
            dispatch(unmountDetail());
        },
        /**
         * @param {BaseSettinss} item
         */
        onSaveSettings: (item) => {
            if(isUndefined(ownProps.match.params.setupDetailId)) {
                // create new
                dispatch(saveNewLoadSettings(item));
            } else {
                // update existing
                dispatch(saveEditedLoadSettings(ownProps.match.params.setupDetailId, item));
            }
            //dispatch(fetchSettingsList(true));
        },
    }
};

const LineageDetail = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoadSettingDetailPresenter);

export default withRouter(LineageDetail);