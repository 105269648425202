import { fetchJson } from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const TODO_LIST_REQUESTED = "TODO_LIST_REQUESTED";
/**
 * Action for request of entry.
 *
 * @return {TodoListAction}
 */
export const requestTodoList = () => {
  return {
    type: TODO_LIST_REQUESTED,
  };
};


export const TODO_LIST_RECEIVED = "TODO_LIST_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} todoList
 * @return {ReceiveTodoListAction}
 */
export const receiveTodoList = (todoList) => {
  return {
    type: TODO_LIST_RECEIVED,
    todoList: todoList,
    receivedAt: Date.now(),
  };
};

export const TODO_LIST_ERROR = "TODO_LIST_ERROR";
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveTodoListErrorAction}
 */
export const receiveError = (
  error,
  type = TODO_LIST_ERROR,
  errorResponseStatusCode = null,
  errorResponseMessage = null
) => {
  return {
    type: type,
    error: error,
    errorResponseStatusCode: errorResponseStatusCode,
    errorResponseMessage: errorResponseMessage,
  };
};


/**
 * 
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchTodoList = (update = false) => {
  return (dispatch, getState) => {
      if (update) {
        dispatch(requestTodoList());
      } else {
        dispatch(requestTodoList());
      }

      /** @type RequestOptions options **/
      let options = {
          method: "GET"
      };
    
 
    
     

     return  fetchJson(`${config.url.API_URL}/bpmn/task/assigned/currentUser`, options, dispatch)
     .then(json => {
        let res = [];
        if (json._embedded && json._embedded.taskDtoes && json._embedded.taskDtoes.length > 0 ) {
            res = json._embedded.taskDtoes;
        }
       dispatch(receiveTodoList(res));
    }
   )  
         .catch(error => {
              if(error === "Ignoring request") {
              } else if(error instanceof ResponseStatusException) {
                  const description = error.response.message ? error.response.message : null;
                  dispatch(receiveError(error, TODO_LIST_ERROR, error.response.status, description));
                  showError(dispatch, error.message, description);
              } else {
                  dispatch(receiveError(error, TODO_LIST_ERROR));
                  showError(dispatch, error.message);
              }
          });
  }
};


