import {createBaseOptions, fetchJson, METHOD_DELETE, METHOD_PUT} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError, showSuccess} from "../utils/NotificationsHelper";

export const GROUP_USERS_REQUESTED = 'GROUP_USERS_REQUESTED';
/**
 * Action for request of users in group.
 *
 * @return {GroupUsersAction}
 */
export const requestGroupUsers = (groupId) => {
    return {
        type: GROUP_USERS_REQUESTED,
        groupId: groupId
    };
};

export const GROUP_USERS_RECEIVED = "GROUP_USERS_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} groupUsers
 * @return {ReceiveGroupUsersAction}
 */
export const receiveGroupUsers = (groupId, groupUsers) => {
    return {
        type: GROUP_USERS_RECEIVED,
        groupId: groupId,
        groupUsers: groupUsers,
        receivedAt: Date.now()
    }
};

export const GROUP_USERS_ERROR = 'GROUP_USERS_ERROR';
export const GROUP_USERS_DELETE_ERROR = 'GROUP_USERS_DELETE_ERROR';
export const GROUP_USERS_ADD_ERROR = 'GROUP_USERS_ADD_ERROR';

/**
 *
 * @param {String} groupId
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveGroupUsersErrorAction}
 */
export const receiveError = (
    groupId,
    error,
    type = GROUP_USERS_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        groupId: groupId,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};


/**
 * Fetch list of users in group
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchGroupUsers = (groupId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            //dispatch(requestEntryDetailUpdate(groupId));
        } else {
            dispatch(requestGroupUsers(groupId));
        }

        //TODO endpoint na groupUsers
        return fetchJson(`${config.url.API_URL}/auth/custom/groups/${groupId}/users`, null, dispatch)
            .then(json => {
                    let res = [];
                    if (json._embedded && json._embedded.users && json._embedded.users.length > 0) {
                        res = json._embedded.users;
                    }
                    dispatch(receiveGroupUsers(groupId, res));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(groupId, error, GROUP_USERS_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(groupId, error, GROUP_USERS_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};



export const GROUP_USERS_DELETE_REQUESTED = 'GROUP_USERS_DELETE_REQUESTED';
/**
 * Action for delete user from group.
 *
 * @param {String} groupId
 * @param {String} userId
 * @return {GroupUserDeleteAction}
 */
export const requestGroupUserDelete = (groupId, userId) => {
    return {
        type: GROUP_USERS_DELETE_REQUESTED,
        groupId: groupId,
        userId: userId
    };
};

export const GROUP_USERS_SUCCESSFULLY_DELETED = "GROUP_USERS_SUCCESSFULLY_DELETED";
/**
 * Action successfully deleted entry.
 *
 * @param {String} groupId
 * @param {String} userId
 * @return {GroupUserDeleteAction}
 */
export const receiveGroupUserSuccessfullyDeleted = (groupId, userId) => {
    return {
        type: GROUP_USERS_SUCCESSFULLY_DELETED,
        groupId: groupId,
        userId: userId
    }
};

/**
 * Delete user from group
 *
 * @param {String} groupId
 * @param {String} userId
 * @return {function(*=, *): Promise<Object | never>}
 */
export const deleteGroupUser = (groupId, userId) => {
    return (dispatch, getState) => {

        dispatch(requestGroupUserDelete(groupId, userId));

        let options = createBaseOptions();
        options.method = METHOD_DELETE;

        return fetchJson(`${config.url.API_URL}/auth/custom/users/${userId}/groups/${groupId}`, options, dispatch)
            .then(json => {
                    dispatch(receiveGroupUserSuccessfullyDeleted(groupId, userId));
                    showSuccess(dispatch, "Success", "User has been successfully removed from group!");
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(userId, error, GROUP_USERS_DELETE_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(userId, error, GROUP_USERS_DELETE_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};


export const GROUP_USERS_ADD_REQUESTED = 'GROUP_USERS_ADD_REQUESTED';
/**
 * Action for adding user to group.
 *
 * @param {String} groupId
 * @param {String} userId
 * @return {GroupUserPutAction}
 */
export const requestGroupUserAdd = (groupId, userId) => {
    return {
        type: GROUP_USERS_ADD_REQUESTED,
        groupId: groupId,
        userId: userId
    };
};

export const GROUP_USERS_SUCCESSFULLY_ADDED = "GROUP_USERS_SUCCESSFULLY_ADDED";
/**
 * Action successfully added entry.
 *
 * @param {String} groupId
 * @param {String} userId
 * @return {GroupUserPutAction}
 */
export const receiveGroupUserSuccessfullyAdded = (groupId, userId) => {
    return {
        type: GROUP_USERS_SUCCESSFULLY_ADDED,
        groupId: groupId,
        userId: userId
    }
};

/**
 * Add user to group
 *
 * @param {String} groupId
 * @param {String} userId
 * @return {function(*=, *): Promise<Object | never>}
 */
export const addGroupUser = (groupId, userId) => {
    return (dispatch, getState) => {

        dispatch(requestGroupUserAdd(groupId, userId));

        let options = createBaseOptions();
        options.method = METHOD_PUT;

        return fetchJson(`${config.url.API_URL}/auth/custom/users/${userId}/groups/${groupId}`, options, dispatch)
            .then(json => {
                    dispatch(receiveGroupUserSuccessfullyAdded(groupId, userId));
                    showSuccess(dispatch, "Success", "User has been successfully added to group!");
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(userId, error, GROUP_USERS_ADD_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(userId, error, GROUP_USERS_ADD_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};