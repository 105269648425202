import React from "react";
import {isEmptyObject} from "../../utils/JsObjectHelper";
import {objectDefinitionResultDetailShape} from "../../shapes/RequestResult";
import PropTypes from "prop-types";
import { withTranslation} from 'react-i18next'
import ObjectForm from "../../containers/object/ObjectForm";


class ObjectDefinitionWizard extends React.Component {
    componentDidMount() {
        this.props.onMount(this.props.match.params.setupDetailId);
    }

    componentWillUnmount() {
        this.props.onUnmount();
    }

    /**
     * UNSAFE and deprecated lifecycle method!
     * Seems redundand. Never happen.
     */
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.match.params.setupDetailId !== this.props.match.params.setupDetailId) {
console.log('New Mounting for wizard. Dont feed him!!!');
            this.props.onMount(nextProps.match.params.setupDetailId, true);
        }
    }

    render() {
        const {t} = this.props;

        /** @type {RequestResult} */
        const rr = this.props.objectDefinitionRequestResult;
        if(isEmptyObject(rr) || rr.getState().isLoading())
            return <div>{t('app.entry.header.msgLoading')}</div>;
        else if(isEmptyObject(rr.getData()))
            return <div>{t('app.entry.header.msgEntryNonFound')}</div>;
        else {
            /** @type {BaseObjectDefinition} */
            return <ObjectForm data={rr.getData()} onSaveObjectDefinition={this.props.onSaveObjectDefinition}/>;
        }
    }
}

export default withTranslation() (ObjectDefinitionWizard);

ObjectDefinitionWizard.propTypes = {
    objectDefinitionRequestResult: objectDefinitionResultDetailShape,
    onMount: PropTypes.func.isRequired,
    onUnmount: PropTypes.func.isRequired,
    onSaveObjectDefinition: PropTypes.func.isRequired,
    isEdit: PropTypes.bool.isRequired,
    isCreateNew: PropTypes.bool.isRequired,
};