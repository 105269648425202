import EntryDetailPresenter from '../components/EntryDetail';
import { connect } from 'react-redux';
import {deleteEntry, fetchDetail, unmountDetail} from "../actions/entryDetail";
import {withRouter} from "react-router-dom";
import {fetchDetailChildrenWithoutDescription} from "../actions/entryDetailChildren";
import {fetchDetailIncomingRelations} from "../actions/entryDetailIncomingRelations";
import {fetchDetailOutgoingRelations} from "../actions/entryDetailOutgoingRelations";
import {fetchDetailBreadCrumb} from "../actions/entryDetailBreadCrumb";
import { fetchSettingsList } from '../actions/settings';

const mapStateToProps = (state, ownProps) => {
    return {
        entryRequestResult: state.entryDetail,
        childrenRequestResult: state.entryDetailChildren,
        incomingRelationsRequestResult: state.entryDetailIncomingRelations,
        outgoingRelationsRequestResult: state.entryDetailOutgoingRelations,
        breadCrumbRequestResult: state.entryDetailBreadCrumb,
        isEdit: ("edit" === ownProps.match.params.edit),
        isCreateNew: ("createNew" === ownProps.match.params.edit || "createNewFolder" === ownProps.match.params.edit)
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: (entryId, shouldUpdateOnly = false) => {
            dispatch(fetchDetail(entryId, shouldUpdateOnly));
            dispatch(fetchDetailChildrenWithoutDescription(entryId, shouldUpdateOnly));
            dispatch(fetchDetailIncomingRelations(entryId, shouldUpdateOnly));
            dispatch(fetchDetailOutgoingRelations(entryId, shouldUpdateOnly));
            dispatch(fetchDetailBreadCrumb(entryId, shouldUpdateOnly));
            dispatch(fetchSettingsList(false));
        },
        onUnmount: () => {
            dispatch(unmountDetail());
        },
        onDelete: (entryId) => {
            dispatch(deleteEntry(entryId));
        },
    }
};

const EntryDetail = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryDetailPresenter);

export default withRouter(EntryDetail);