import {
    WS_CLOSED,
    WS_CONNECT, WS_CONNECTED,
    WS_DISCONNECT, WS_DISCONNECTED, WS_MESSAGE_PUBLISH, WS_REFRESH_TOKEN, wsClientInfoReceived, wsClosed,
    wsConnected,
    wsConnecting,
    wsDisconnected,
    wsError, wsMessagePublished,
    wsMessageReceived, wsRefreshedToken, wsStompError,
    wsTopicSubscribe,
    wsTopicSubscribed
} from "../actions";
import * as StompJs from "@stomp/stompjs";
import {EMPTY_OBJECT} from "../../../utils/JsObjectHelper";

const socketMiddleware = () => {
    let client = null;
    let lastToken = null;
    let userDefinedConf = null;
    let subscriptions = [];
    const CLIENT_INFO_TOPIC = "/user/topic/clientInfo";
    const CLIENT_INFO_APP = "/app/clientInfo";
    let connected = false;
    let clientInfo = {};
    let timerId = null;

    const onConnect = (store, clientRef, conf, token, subscribedTopics, userFunc) => (frame) => {
        // get session id
        // client.publish({destination: "/app/clientInfo", headers: {priority: 9}, body: ""});
        // client.send("/app/ping", {}, null);
        const subscriptionClient = clientRef.subscribe(CLIENT_INFO_TOPIC, (message) => {
            clientInfo = JSON.parse(message.body);
            store.dispatch(wsClientInfoReceived(clientInfo));
        });
        if(subscribedTopics !== null && subscribedTopics !== undefined && Array.isArray(subscribedTopics)) {
            for (const subscribedTopic of subscribedTopics) {
                store.dispatch(wsTopicSubscribe(subscribedTopic));
                const subscription = clientRef.subscribe(subscribedTopic, (message) => {
                    store.dispatch(wsMessageReceived(subscribedTopic, message));
                });
                store.dispatch(wsTopicSubscribed(subscribedTopic, subscription));
                subscriptions.push({topic: subscribedTopic, subscription: subscription});
            }
        }
        clientRef.publish({destination: CLIENT_INFO_APP});
        timerId = setInterval(() => {
            if(client != null && connected) {
                clientRef.publish({destination: CLIENT_INFO_APP});
            }
        }, 15 * 60 *1000)
        store.dispatch(wsConnected(conf, token, subscribedTopics, frame));
        if(userFunc !== undefined && typeof(userFunc) === 'function') {
            userFunc(frame);
        }
    };

    const onDisconnect = (store, conf, userFunc) => (frame) => {
        store.dispatch(wsDisconnected(conf, frame));
        if(userFunc !== undefined && typeof(userFunc) === 'function') {
            userFunc(frame);
        }
    };

    const onStompError = (store, conf, userFunc) => (frame) => {
         store.dispatch(wsStompError(conf, frame));
        if(userFunc !== undefined && typeof(userFunc) === 'function') {
            userFunc(frame);
        }
    };

    const onWebSocketClose = (store, conf, userFunc) => (event) => {
         store.dispatch(wsClosed(conf, event));
        if(userFunc !== undefined && typeof(userFunc) === 'function') {
            userFunc(event);
        }
    };

    const onWebSocketError = (store, conf, userFunc) => (event) => {
        store.dispatch(wsError(conf, event));
        if(userFunc !== undefined && typeof(userFunc) === 'function') {
            userFunc(event);
        }
    };

    const getBrokerUrl = (url, token) => {
        return url + "?access_token=" + token
    }

    // the middleware part of this function
    return store => next => action => {
        switch (action.type) {
            case WS_CONNECT:
                store.dispatch(wsConnecting(action.conf, action.token, action.subscribedTopics));
                userDefinedConf = JSON.parse(JSON.stringify(action.conf));
                lastToken = action.token;
                action.conf.brokerURL = getBrokerUrl(action.conf.brokerURL, action.token);
                if (client !== null) {
                    client.deactivate();
                    console.warn('StompJs Deactivate() async warning.');
                    client = null;
                    // todo remove subscriptions etc...
                }
                client = new StompJs.Client(action.conf);
                client.onConnect = onConnect(store, client, action.conf, action.token, action.subscribedTopics, action.conf.onConnect, connected);
                client.onDisconnect = onDisconnect(store, action.conf, action.conf.onDisconnect, connected);
                client.onStompError = onStompError(store, action.conf, action.conf.onStompError);
                client.onWebSocketClose = onWebSocketClose(store, action.conf, action.conf.onWebSocketClose);
                client.onWebSocketError = onWebSocketError(store, action.conf, action.conf.onWebSocketError);
                client.activate();

                break;
            case WS_CONNECTED:
                connected = true;
            break;
            case WS_DISCONNECTED:
                connected = false;
                if(timerId != null) {
                    clearInterval(timerId);
                    timerId = null;
                }
                break;
            case WS_DISCONNECT:
                if (client !== null) {
                    client.deactivate();
                }
                client = null;
                subscriptions = [];
                break;
            case WS_CLOSED:
                connected = false;
                if(timerId != null) {
                    clearInterval(timerId);
                    timerId = null;
                }
                break;
            case WS_REFRESH_TOKEN:
                lastToken = action.token;
                if (client !== null) {
                    client.brokerURL = getBrokerUrl(userDefinedConf.brokerURL, action.token);
                }
                store.dispatch(wsRefreshedToken(action.token));
                break;
            case WS_MESSAGE_PUBLISH:
                if (client !== null && connected) {
                    client.publish(action.params);
                    store.dispatch(wsMessagePublished(action.params));
                }
                break;
        }
        
        return next(action);
    };
};

export default socketMiddleware();