import {
    ALL_BRANDING_ACTIVE_ERROR,
    ALL_BRANDING_ACTIVE_RECEIVED,
    ALL_BRANDING_ACTIVE_REQUESTED
} from "../../actions/branding/allBrandingActive";
import RequestState from "../../storage/RequestState";
import RequestResult from "../../storage/RequestResult";
import { EMPTY_REQUEST_RESULT_INIT_STATE } from "../../utils/JsObjectHelper";


/**
*
* @param {RequestResult} state
* @param {BaseAction|ReceiveEntryTypesAction|ReceiveErrorAction} action
* @return {RequestResult}
*/
export const allBrandingActive = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action) => {

    switch (action.type) {
        case ALL_BRANDING_ACTIVE_REQUESTED:
            return new RequestResult(RequestState.getInstanceLoading(), state.data);
        case ALL_BRANDING_ACTIVE_RECEIVED:
            return new RequestResult(RequestState.getInstanceDone(), action.allBrandingActive, action.receivedAt);
        case ALL_BRANDING_ACTIVE_ERROR:
            return new RequestResult(
                RequestState.getInstanceError(
                    action.errorResponseStatusCode,
                    action.errorResponseMessage ? action.errorResponseMessage : null
                ),
                state.getData()
            );

        default:
            return state
    }
};
