import React from "react";
import { withRouter } from "react-router-dom";
import { EditOutlined} from '@ant-design/icons';
import { Button, Modal, Table,  Tag, Input } from 'antd';
import { withTranslation } from 'react-i18next'
import { isArray, isUndefined } from "../../utils/JsObjectHelper";
import EntryTypeTag from "../controls/EntryTypeTag";
import {isMobile} from "../../utils/AdaptiveLayout"
import { getAttributeTypeIcon } from "../../utils/EntryTypeHelper";


const { Search } = Input;

class DuplicateAttribute extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			searchNameText: [],
			isModalVisible: false,
		};

	}
	filterEntryTypes = (searchValue) => {
        this.setState({searchNameText: searchValue.split(' ')});
    }
	handleOk = () => {
		this.setState({ isModalVisible: false });

	}
	handleCancel = () => {
		this.setState({ isModalVisible: false });
	}
	showModal = () => {
		this.setState({ isModalVisible: true });
	}


	render() {
		const { t } = this.props;
		let entryTypeNameList = null;
		let entryTypeAttributeList = [];

		if (this.props.entryTypesRequestResult.getState().isDone()) {

			let EntryTypesList = this.props.entryTypesRequestResult.getData();

			entryTypeNameList = this.props.entryTypesRequestResult.getData().map((item, key) => { return { type: item.type, name: item.name, color: item.properties.typeColor }; });
			EntryTypesList.forEach((entryType) => {
				entryType.properties.attributes
					.forEach((attribute) => {
						let att = entryTypeAttributeList.find((el) => el.attributeTechName === attribute.techName);
						if (!isUndefined(att)) {
							att.entryTypes.push({ id: entryType.id, type: entryType.type, attributeType: attribute.type, attributeName: attribute.name });
						} else {
							entryTypeAttributeList.push({ attributeTechName: attribute.techName, entryTypes: [{ id: entryType.id, type: entryType.type, attributeType: attribute.type, attributeName: attribute.name }] });
						}
					})
			})
		}


		const columns = [
			{
				title: t('setup.entryTypes.attributeOverview.nameAttributeTbl1'),
				key: 'attributeTechName',
				dataIndex: 'attributeTechName',
				filteredValue: this.state.searchNameText,
				onFilter:(value, record) => record.attributeTechName.toLowerCase().includes(value.toLowerCase()),
				sorter: (a, b) => {
					return (isUndefined(a["attributeTechName"]) ? "" : a["attributeTechName"]).localeCompare((isUndefined(b["attributeTechName"]) ? "" : b["attributeTechName"]));
				}
				
			},
			Table.EXPAND_COLUMN,
			{
				
				title: t('setup.entryTypes.attributeOverview.countUsingAttributeTbl1'),
				dataIndex: 'entryTypes',
				key: 'count',
				render: (text, record) => {
					if(isArray(record.entryTypes)){
						return (<span>
							{record.entryTypes.length}
						</span>)
					}
				},
				defaultSortOrder: "descend",
				sorter: (a, b) => a.entryTypes.length - b.entryTypes.length,
			},

		];
		
		const expandedRowRender = (record) => {
			const columnsForHoverTable = [
				{
					title: t('setup.entryTypes.attributeOverview.actionTbl2'),
					key: 'id',
					render:(text, record) => (
						<span>
							<Button href={"/setup/contentedit/"+record.id} size={'small'}><EditOutlined /></Button>
						</span>
					)
				},
				{
					title:  t('setup.entryTypes.attributeOverview.nameEntryTypeTbl2'),
					dataIndex: 'type',
					key: 'type',
					render: (type, record) => {
							return <EntryTypeTag entryTypeName={type} entryTypeNameList={entryTypeNameList}></EntryTypeTag>
					}
				},
				{
					title: t('setup.entryTypes.attributeOverview.nameAttributeTbl2'),
					dataIndex: 'attributeName',
					key: 'attributeName',
					render: (tag, record) => {
									return	<Tag color="blue" key={tag}>
										{getAttributeTypeIcon(record.attributeType)} {tag}</Tag>
					}
				},
				{
					title: t('setup.entryTypes.attributeOverview.typeAttributeTbl2'),
					dataIndex: 'attributeType',
					key: 'attributeType',
				}
			]
			
			return <Table columns={columnsForHoverTable} dataSource={record.entryTypes} size='small' pagination={false} rowKey="id" scroll={{x: true,}}/>
		}


	

		let modal = <Modal title={t('setup.entryTypes.attributeOverview.modalTitle')} visible={this.state.isModalVisible} footer={null} width={isMobile()?'100%':"60%"} onCancel={this.handleCancel}>
			
				<Table
				    loading={this.props.entryTypesRequestResult.getState().isLoading()}
					columns={columns}
					dataSource={entryTypeAttributeList}
					rowKey="attributeTechName"
					size="small"
					expandedRowRender={expandedRowRender}
					title={() => <div style={{display:'flex'}}>
                        <Search
                            placeholder={t('setup.entryTypes.attributeOverview.placeholderSearch')}
                            onSearch={this.filterEntryTypes}
                            style={{ width: '100%' }}
                            allowClear
                        />
                        </div>}
					scroll={{
						x: true,
					}}
				
				/>
			
		</Modal>

		return (
			<div>
				{modal}

				<Button key="button" style={{ marginBottom:'10px',marginRight:'5px',marginLeft:'5px' }}   onClick={this.showModal}>
					{t('setup.entryTypes.attributeOverview.btnAttributesOwerview')}
				</Button>
			</div>
		);
	}
}

const DuplicateAttributeWithRouter = withRouter(DuplicateAttribute);

export default withTranslation()(DuplicateAttributeWithRouter);