import React from "react";
import { Input, Button, Alert} from 'antd';
import { withTranslation } from 'react-i18next'
const { Search } = Input;
class SearchTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: null,
            limit: 31
        };
    }

    onSearch = (value) => {
        this.setState({ searchValue: value, limit: 31 })
    };
    displayMore = () => {
        this.setState({ limit: this.state.limit + 30 })
    }

    render() {
        const { t } = this.props;
        let data = this.props.data;
        if (this.state.searchValue) {
            data = this.props.data.filter((el) => el.title.toUpperCase().includes(this.state.searchValue.toUpperCase())).sort((a, b) => {
                if (a.title.toUpperCase().startsWith(this.state.searchValue.toUpperCase())) return -1;
                if (b.title.toUpperCase().startsWith(this.state.searchValue.toUpperCase())) return 1;
                return a.title.toUpperCase().localeCompare(b.title.toUpperCase())
            })
               data = data.sort((a, b) =>{
                if (a.title.toUpperCase().startsWith(this.state.searchValue.toUpperCase())) return -1;
                if (b.title.toUpperCase().startsWith(this.state.searchValue.toUpperCase())) return 1;
            }); 
        }
        data = data.slice(0, this.state.limit);
        let button = data.length + 1 > this.state.limit ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}><Button onClick={this.displayMore} type='dashed'>Display more</Button></div> : null;
        let message = data.length === 0?  <Alert message={t('app.entry.view.childHierarchySearchNothingFound')} type="info" showIcon /> :null;
        return (<><Search
            placeholder={t('app.entry.view.childHierarchySearchPlaceholder')}
            onSearch={this.onSearch}
            style={{
                width: "100%",
                marginTop: '10px',
                marginBottom: '5px'
            }}
        />
            {this.props.renderTreeNodes(data)}
            {message}
            {button}
        </>)
    }
}
export default withTranslation()(SearchTree);