import React from "react";
import PropTypes from "prop-types";
import {bmcEntryDocShape} from "../../shapes/BmcEntryShape";
import { Table } from 'antd';
import {Link} from "react-router-dom";
import { withTranslation} from 'react-i18next'
import { isEmptyObject } from "../../utils/JsObjectHelper";
import EntryTypeTag from "../controls/EntryTypeTag";
import { entryTypeNameShape } from "../../shapes/EntryTypeNameShape";

class SearchResults extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            advSearchPageSize: (isEmptyObject(localStorage.getItem("advSearchPageSize")) ? 30 : parseInt(localStorage.getItem("advSearchPageSize")) )
        };
    }

    componentDidMount() {
        this.props.onMount();
    }

    showTotal = (total) => {
        return `(${total})`;
    };

    handlePageSizeChanged = (current, page) => {
        console.log(page);
        localStorage.setItem("advSearchPageSize", page);
        this.setState({ advSearchPageSize: page });
    };

    render() {
        const {t} = this.props;
        
        /* if(!this.props.data || this.props.data.length === 0) return <div>&nbsp;</div>; */ /*necham zobrazovat No data :)*/
        const columns = [
            /* {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                render: text => <Link to={`/entry/${text}`}>{text}</Link>,
            }, */
            {
                title: t('app.advancedSearch.resultTableName'),
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                render: (text, record) => <Link to={`/entry/${record.id}`}>{text}</Link>,
            },
            {
                title: t('app.advancedSearch.resultTableType'),
                dataIndex: 'type',
                key: 'type',
                render: tag => (
                    <span>
                        <EntryTypeTag key={tag} entryTypeName={tag} entryTypeNameList={this.props.entryTypeNameList}></EntryTypeTag>
                    </span>
                  ),
            },
            {
                title: t('app.advancedSearch.resultTableDate'),
                dataIndex: 'inserted',
                key: 'inserted',
                sorter: (a, b) => new Date(a.inserted).getTime() - new Date(b.inserted).getTime(),
                render: (text, record) => { return t('datetime', {date : new Date(text)}) },
            },
        ];
        let paginationSetup = {
            showSizeChanger: true, 
            size:"small", 
            showTotal: this.showTotal, 
            defaultPageSize: this.state.advSearchPageSize, 
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            onShowSizeChange: this.handlePageSizeChanged
        };

        return <Table rowKey="id" 
                    columns={columns} 
                    dataSource={this.props.data} 
                    size="small"
                    pagination={paginationSetup}
                    />;
    }
}

SearchResults.propTypes = {
    data: PropTypes.arrayOf(bmcEntryDocShape).isRequired,
    entryTypeNameList: PropTypes.arrayOf(entryTypeNameShape)
};

export default withTranslation() (SearchResults);