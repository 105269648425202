import Id2EntryMappingPresenter from '../../../components/setup/id2entry/Id2EntryMapping';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { fetchSettingsList } from '../../../actions/settings';
import { isUndefined } from '../../../utils/JsObjectHelper';
import { fetchObjectList } from '../../../actions/objectList';

const getIdMappingSettingsList = (settingsList) => {
    if (!isUndefined(settingsList) && settingsList.getState().isDone() ) {
        let setList = settingsList.getData().filter(eT => eT.id === "id_mapping");

        return setList;
    } else {
        return null;
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        idMapping: getIdMappingSettingsList(state.settingsList),
        entryTypesRequestResult: state.objectList,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchSettingsList(true));
            dispatch(fetchObjectList());
          
        },
    }
};

const Id2EntryMapping = connect(
    mapStateToProps,
    mapDispatchToProps
)(Id2EntryMappingPresenter);

export default withRouter(Id2EntryMapping);