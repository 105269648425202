import React from "react";
import { Col } from 'antd';
import { Icon } from '@ant-design/compatible';
//import Icon from '@ant-design/icons';
import {Link} from "react-router-dom";

class NextSectionButton extends React.Component {
    render () {
        let content = (this.props.align ==="left" ?
        [<Icon type={this.props.align} key="i"/>, this.props.title]
        :
        [this.props.title, <Icon type={this.props.align} key="i"/>]
        )

        return (
            <Col span={12}>
                <Link to={this.props.toUrl}>
                    <p  style={{textAlign: this.props.align, lineHeight: '50px'}}>
                    {content}
                    </p>
                </Link>
            </Col>
        )
    }
}

export default NextSectionButton;