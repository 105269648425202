import UserPagePresenter from '../../components/detail/UserPage';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { isArray, isUndefined } from "../../utils/JsObjectHelper";
import { fetchObjectList } from "../../actions/objectList";
import { fetchUserPage } from '../../actions/userPage';
import { fetchUserDetail } from '../../actions/userDetail';
import { fetchDetailIncomingRelations } from '../../actions/entryDetailIncomingRelations';
import { fetchDetailOutgoingRelations } from '../../actions/entryDetailOutgoingRelations';
import { fetchSettingsList } from '../../actions/settings';
import { t } from 'i18next';

/** @type {RelationsHolder} */
const EMPTY_INCOMING = { type: "incoming", relations: [] };
/** @type {RelationsHolder} */
const EMPTY_OUTGOING = { type: "outgoing", relations: [] };

/**
 *
 * @param {RequestResult} rr
 * @param {String} type incoming or outgoing
 */

const getRelations = (rr, type) => {
    if (!isUndefined(rr) && rr.getState().isDone()) {
        /** @type {Array<IncomingRelation|OutgoingRelation>} */
        let relations = rr.getData();
        if (!isUndefined(relations) && isArray(relations) && relations.length > 0 &&
            (type === "incoming" || type === "outgoing")
        ) {
            /** @type {RelationsHolder} */
            let result = {
                type: type,
                relations: []
            };
            let property = (type === "incoming") ? "source" : "target";
            for (const relation of relations) {
                /** @type {RelationHolder} */
                let relHolder = result.relations.find(x => x.name === relation.name);
                let relEntry = relation[property];
                relEntry.inserted = relation.inserted;
                if (isUndefined(relHolder)) {
                    relHolder = { name: relation.name, relatedEntries: [relEntry] };
                    result.relations.push(relHolder);
                } else {
                    relHolder.relatedEntries.push(relEntry);
                }
            }
            return result;
        }
    }
    return (type === "incoming") ? EMPTY_INCOMING : EMPTY_OUTGOING;
};



const mapStateToProps = (state, ownProps) => {
    return {
        outgoingRelationsHolder: getRelations(state.entryDetailOutgoingRelations, "outgoing"),
        entryRequestResult: state.userpage,
        entryTypesRequestResult: state.objectList,
        userDetailRequestResult: state.userDetail,
        incomingRelationsRequestResult: state.entryDetailIncomingRelations,
        outgoingRelationsRequestResult: state.entryDetailOutgoingRelations,
        user: state.loggedUser.user,
        identityId: ownProps.match.params.identityID

    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: () => {
            dispatch(fetchUserPage(ownProps.match.params.identityID,t));
            dispatch(fetchUserDetail(ownProps.match.params.identityID));
            dispatch(fetchObjectList());
            dispatch(fetchSettingsList(false));
        },
        relations: (entryId) => {
                dispatch(fetchDetailIncomingRelations(entryId,true));
                dispatch(fetchDetailOutgoingRelations(entryId,true));
        },
    }
};

const UserPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPagePresenter);

export default withRouter(UserPage);