import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import {reducer as notifications} from 'react-notification-system-redux';
import {entryDetail} from "./entryDetail";
import {entryDetailChildren} from "./entryDetailChildren";
import {entryDetailIncomingRelations} from "./entryDetailIncomingRelations";
import {entryDetailOutgoingRelations} from "./entryDetailOutgoingRelations";
import {entryDetailBreadCrumb} from "./entryDetailBreadCrumb";
import {entryVersionList} from "./entryVersionList";
import {objectList} from "./objectList";
import {objectDefinitionDetail} from "./objectDefinitionDetail";
import {userList} from "./userList";
import {userDetail} from "./userDetail";
import {groupList} from "./groupList";
import {groupDetail} from "./groupDetail";
import {groupUsers} from "./groupUsers";
import {loggedUser} from "./loggedUser";
import {entryAccess} from "./entryAccess";
import {homepage} from "./homepage";
import {websocketClientInfo} from "./websocketClientInfo";
import {editedEntries} from "./editedEntries";
import {entryCommentList} from "./entryCommentList";
import {workflowList} from "./workflowList";
import {settingsList} from "./settingsList";
import {settingsDetail} from "./settingsDetail";
import {entryDetailFiles} from "./entryFiles";
import {loadSettingsList} from "./loadSettingsList";
import {loadSettingsDetail} from "./loadSettingsDetail";
import { taskList } from './taskList';
import {bpmnForm} from './bpmnForm';
import { claimTask } from './bpmnClaimTask';
import { unClaimTask } from './bpmnUnClaimTask'; 
import { todoList } from './todoList';
import {bpmnFormVariables} from './bpmnFormVariables';
import { completeTask } from './bpmnComplete';
import { alltaskList } from './bpmnAllTaskList';
import { candidate } from './bpmnCandidate';
import { process } from './bpmnProcess';
import { taskHistory } from './bpmnTaskHistory';
import {userpage} from './userPage';
import { userCustomizations } from './userCustomizations';
import { taskCount } from './bpmnTaskCount';
import { allBrandingActive } from './branding/allBrandingActive';
export const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    notifications,
    entryDetail,
    entryDetailChildren,
    entryDetailIncomingRelations,
    entryDetailOutgoingRelations,
    entryDetailBreadCrumb,
    entryAccess,
    entryVersionList,
    objectList,
    objectDefinitionDetail,
    userList,
    userDetail,
    groupList,
    groupDetail,
    groupUsers,
    loggedUser,
    homepage,
    websocketClientInfo,
    editedEntries,
    entryCommentList,
    workflowList,
    settingsList,
    settingsDetail,
    entryDetailFiles,
    loadSettingsList,
    loadSettingsDetail,
    taskList,
    bpmnForm,
    claimTask,
    unClaimTask,
    todoList,
    bpmnFormVariables,
    completeTask,
    alltaskList,
    candidate,
    process,
    taskHistory,
    userpage,
    userCustomizations,
    taskCount,
    allBrandingActive
});