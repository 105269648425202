export default class RequestState {
    static DONE = 0;
    static LOADING = 1;
    static ERROR = 2;
    static INIT = 3;
    static INVALIDATED = 4;
    static UPDATING = 5;

    constructor(state, errorCode = null, errorMessage = null) {
        this.state = state;
        this.errorCode = errorCode;
        this.errorMessage = errorMessage;
    }
    isLoading() {
        return this.state === RequestState.LOADING;
    }

    isDone() {
        return this.state === RequestState.DONE;
    }

    isError() {
        return this.state === RequestState.ERROR;
    }

    isInit() {
        return this.state === RequestState.INIT;
    }

    isInvalidated() {
        return this.state === RequestState.INVALIDATED;
    }

    isUpdating() {
        return this.state === RequestState.UPDATING;
    }

    /**
     * Return errorCode or null, if not set.
     *
     * @returns {Number|null}
     */
    getErrorCode() {
        return this.errorCode;
    }

    getErrorMessage() {
        return this.errorMessage;
    }

    /**
     * Get instance of successfully done RequestState
     *
     * @returns {RequestState}
     */
    static getInstanceDone() {
        return new RequestState(RequestState.DONE);
    }

    /**
     * Get instance of loading RequestState
     *
     * @returns {RequestState}
     */
    static getInstanceLoading() {
        return new RequestState(RequestState.LOADING);
    }

    /**
     * Get instance of error RequestState
     *
     * @param {Number} errorCode
     * @param {String} errorMessage
     * @returns {RequestState}
     */
    static getInstanceError(errorCode = null, errorMessage = null) {
        return new RequestState(RequestState.ERROR, errorCode, errorMessage);
    }

    /**
     * Get instance of initialization RequestState
     *
     * @returns {RequestState}
     */
    static getInstanceInit() {
        return new RequestState(RequestState.INIT);
    }

    /**
     * Get instance of invalidated RequestState
     *
     * @returns {RequestState}
     */
    static getInstanceInvalidated() {
        return new RequestState(RequestState.INVALIDATED);
    }

    /**
     * Get instance of updating RequestState
     *
     * @returns {RequestState}
     */
    static getInstanceUpdating() {
        return new RequestState(RequestState.UPDATING);
    }
}
