import {fetchJson} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const ENTRYVERSION_LIST_REQUESTED = 'ENTRYVERSION_LIST_REQUESTED';
/**
 * Action for request of entry version list.
 *
 * @return {EntryVersionListAction}
 */
export const requestEntryVersionList = (entryId) => {
    return {
        type: ENTRYVERSION_LIST_REQUESTED,
        entryId: entryId
    };
};

export const ENTRYVERSION_LIST_RECEIVED = "ENTRYVERSION_LIST_RECEIVED";
/**
 *
 * @param {bmcEntryShape} entryVersionList
 * @return {ReceiveEntryVersionListAction}
 */
export const receiveEntryVersionList = (entryId, entryVersionList) => {
    return {
        type: ENTRYVERSION_LIST_RECEIVED,
        entryId: entryId,
        entryVersionList: entryVersionList,
        receivedAt: Date.now()
    }
};

export const ENTRYVERSION_LIST_ERROR = 'ENTRYVERSION_LIST_ERROR';
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveEntryVersionListErrorAction}
 */
export const receiveError = (
    error,
    type = ENTRYVERSION_LIST_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};


/**
 * Fetch entry version list
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchEntryVersionList = (entryId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            //dispatch(requestGroupUpdate());
        } else {
            dispatch(requestEntryVersionList(entryId));
        }

        //zatim jen fake dotaz na api
        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/versions`, null, dispatch)
            .then(json => {
                    let res = [];

                    if (json._embedded && json._embedded.archivedEntries && json._embedded.archivedEntries.length > 0) {
                         res = json._embedded.archivedEntries;
                    }
                    
                    res = res.sort((a,b) => (a.version > b.version) ? 1 : ((b.version > a.version) ? -1 : 0));

                    dispatch(receiveEntryVersionList(entryId, res));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, ENTRYVERSION_LIST_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(error, ENTRYVERSION_LIST_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};