import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Modal, Table, Tag, Tooltip, Select, Alert, Steps, Input, Upload, message, Descriptions, Space } from 'antd';
import { getAttributeTypeIcon } from "../../../utils/EntryTypeHelper";
import { DownloadOutlined } from '@ant-design/icons';
import { isArray, isEmptyObject, isEmptyValue, isUndefined } from "../../../utils/JsObjectHelper";
import { withTranslation } from 'react-i18next'
import cloneDeep from "lodash.clonedeep";
import { notificationError } from "../../../utils/NotificationsHelper";
import { isTablet } from "../../../utils/AdaptiveLayout";
import moment from 'moment';
import ViewValidEntry from "./ViewValidEntry";
const { Search } = Input;
const uuidv4 = require('uuid/v4');
class ImportContentStructure extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            data: [],
            advSearchPageSize: (isEmptyObject(localStorage.getItem("setupEntryTypePageSize")) ? 20 : parseInt(localStorage.getItem("setupEntryTypePageSize"))),
            selectedRowKeys: [],
            selectedEntries: [],
            selectedEntryTypes: [],
            entryList: [],
            current: 0
        };

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.listDataRequestResult.getData() !== this.props.listDataRequestResult.getData()) {
            let entryList = (this.props.listDataRequestResult.getState().isDone() ?
                this.props.listDataRequestResult.getData().filter(eT => eT.systemType === "object") : []);
            this.setState({ entryList: entryList });
        }
    }
    showTotal = (total) => {
        return `(${total})`;
    };
    handleOpenFile = (info) => {
        const reader = new FileReader();
        if (info.file.type === 'application/json') {
            reader.onloadend = this.openJSON
            reader.readAsText(info.fileList[0].originFileObj);
        }
    };
    beforeUpload = (file) => {
        const { t } = this.props;
        const isJSON = file.type === 'application/json';
        if (!isJSON) {
            notificationError(t('setup.entryTypes.importExport.importErr'));
        }
        return isJSON;
    };
    showModal = () => {
        this.setState({ visible: true });
    };
    handleCancel = () => {
        this.setState({
            data: [],
            visible: false,
            selectedRowKeys: [],
            selectedEntries: [],
            selectedEntryTypes: [],
            current: 0,
        });
    };
    filterEntryTypes = (searchValue) => {
        this.setState({ searchNameText: searchValue.split(' ') });
    }
    openJSON = (data) => {
        const { t } = this.props;
        let res = JSON.parse(data.target.result);
        if (res.programName && res.date && res.createdBy && res.countEtryType > 0 && res.data.length > 0) {
            this.setState({ data: res, visible: true });
        }
        else {
            notificationError(t('setup.entryTypes.importExport.importUnsupportedFile'));
        }
    }
    next = () => {
        this.setState({ current: this.state.current + 1 });
    };
    prev = () => {
        if (this.state.current === 1) {
            this.setState({ current: this.state.current - 1, selectedEntryTypes: [] });
        }
        else {
            this.setState({ current: this.state.current - 1 });
        }

    };
    checkRelations = (mainArray) => {
        let resultArray = [];
        mainArray.forEach(mainObject => {
            mainObject.properties.attributes.forEach(attribute => {
                if (attribute.type === "relation") {
                    if (attribute.relationEntryType.includes("##") || attribute.relationEntryType.includes("@")) {
                        let existingObject = resultArray.find(resultObject => resultObject.name === mainObject.name);
                        if (existingObject) {
                            existingObject.attributes.push({ attName: attribute.name, entryType: [{ attName: attribute.name, parent: mainObject.name, type: attribute.relationEntryType, name: attribute.relationEntryType }] });
                        } else {
                            resultArray.push({
                                name: mainObject.name,
                                attributes: [{ attName: attribute.name, entryType: [{ attName: attribute.name, parent: mainObject.name, type: attribute.relationEntryType, name: attribute.relationEntryType }] }]
                            });
                        }
                    }
                    if (isArray(attribute.relationEntryType)) {
                        attribute.relationEntryType.forEach(relationType => {
                            let matchingObject = mainArray.find(object => object.type === relationType);
                            let noValidationNeeded = attribute.editable ? attribute.editable.find((el) => el === relationType) : false;
                            if (!matchingObject && !noValidationNeeded) {
                                let existingObject = resultArray.find(resultObject => resultObject.name === mainObject.name);
                                let entryType = this.state.data.data.find((element) => element.type === relationType);
                                let name = entryType ? entryType.name : relationType;
                                if (existingObject) {
                                    let attExisting = existingObject.attributes.find((el) => el.attName === attribute.name);
                                    if (!attExisting) {
                                        let noValidobj = { attName: attribute.name, entryType: [{ attName: attribute.name, parent: mainObject.name, type: relationType, name: name }], }
                                        existingObject.attributes.push(noValidobj);
                                    }
                                    else {
                                        attExisting.entryType.push({ attName: attribute.name, parent: mainObject.name, type: relationType, name: name });
                                    }
                                } else {
                                    resultArray.push({
                                        name: mainObject.name,
                                        attributes: [{ attName: attribute.name, entryType: [{ attName: attribute.name, parent: mainObject.name, type: relationType, name: name }] }]
                                    });
                                }
                            }
                        });

                    }
                    if (!isArray(attribute.relationEntryType) && !attribute.relationEntryType.includes("##")) {
                        let matchingObject = mainArray.find(object => object.type === attribute.relationEntryType);
                        if (!matchingObject) {
                            let existingObject = resultArray.find(resultObject => resultObject.name === mainObject.name);
                            let entryType = this.state.entryList.find((el) => el.type === attribute.relationEntryType);
                            if (entryType) {
                                if (existingObject) {
                                    existingObject.attributes.push({ attName: attribute.name, entryType: [{ attName: attribute.name, parent: mainObject.name, type: attribute.relationType, name: entryType.name }] });
                                } else {
                                    resultArray.push({
                                        name: mainObject.name,
                                        attributes: [{ attName: attribute.name, entryType: [{ attName: attribute.name, parent: mainObject.name, type: attribute.relationType, name: entryType.name }] }]
                                    });
                                }
                            }
                        }
                    }
                }
            });
        });
        return resultArray;
    };

    handleOk = () => {

        const updatedArray = this.state.selectedEntries.map(obj => {
            const updatedObj = { ...obj };
            updatedObj.properties.attributes = updatedObj.properties.attributes.map(attr => {
                const updatedAttr = { ...attr };
                delete updatedAttr.editable;
                return updatedAttr;
            });
            return updatedObj;
        });
        updatedArray.forEach((item) => {
            item.id = uuidv4();
            this.props.onSaveObjectDefinition(item)
        })
        this.setState({
            data: [],
            visible: false,
            selectedRowKeys: [],
            selectedEntries: [],
            selectedEntryTypes: [],
            current: 0,
        });
    };

    handlePageSizeChanged = (current, page) => {
        localStorage.setItem("setupEntryTypePageSize", page);
        this.setState({ advSearchPageSize: page });
    };
    onSelectChange = (newSelectedRow) => {
        let data = cloneDeep(this.state.selectedEntries);
        let newSelectedRowKeys = cloneDeep(this.state.selectedRowKeys);
        let del = newSelectedRowKeys.findIndex((el) => el === newSelectedRow.type);
        let delEnt = data.findIndex((el) => el.type === newSelectedRow.type);
        if (del !== -1 && delEnt !== -1) {
            data.splice(delEnt, 1);
            newSelectedRowKeys.splice(del, 1);
        }
        else {
            data.push(newSelectedRow);
            newSelectedRowKeys.push(newSelectedRow.type);
        }
        this.setState({ selectedRowKeys: newSelectedRowKeys, selectedEntries: data });
    };
    handleChangeTypeSelection = (value) => {
        let element = JSON.parse(value);
        let data = cloneDeep(this.state.selectedEntries);
        if (element.type === 'add') {
            let entryType = this.state.data.data.find((el) => el.type === element.entry.type);
            data.push(entryType);
            this.setState({ selectedEntries: data });
        }
        else if (element.type === 'def') {
            let editEntryType = data.find((el) => el.name === element.entry.parent);
            let attribute = editEntryType.properties.attributes.find((att) => att.name === element.entry.attName);
            if (isArray(attribute.relationEntryType)) {
                let index = attribute.relationEntryType.findIndex((el) => el === element.entry.type);
                if (element.entry.name.includes('*')) {
                    element.entry.name = element.entry.name.replace('*', '')
                }
                attribute.relationEntryType[index] = `@${element.entry.name}`
            }
            else {
                attribute.relationEntryType = `@${element.entry.name}`
            }
            this.setState({ selectedEntries: data });
        }
        else if (element.type === 'del') {
            let editEntryType = data.find((el) => el.name === element.entry.parent);
            let attribute = editEntryType.properties.attributes.find((att) => att.name === element.entry.attName);
            if (isArray(attribute.relationEntryType)) {
                let index = attribute.relationEntryType.findIndex((el) => el === element.entry.type);
                attribute.relationEntryType[index] = `*${element.entry.name}`
            }
            else {
                attribute.relationEntryType = `*${element.entry.name}`
            }
            this.setState({ selectedEntries: data });
        }
    }
    deselect = (value) => {
        let selectedEntryTypes = cloneDeep(this.state.selectedEntryTypes);
        let delObj = JSON.parse(value);
        let editObj = selectedEntryTypes.find((el) => el.delName === delObj.delName);
        let index = editObj.entryTypeList.findIndex((el) => el === delObj.type[0]);
        let selectedValueIndex = editObj.selectedValue.findIndex((el) => el === value);
        editObj.selectedValue.splice(selectedValueIndex, 1);
        editObj.entryTypeList.splice(index, 1);
        if (editObj.entryTypeList.length === 0) {
            let index = selectedEntryTypes.indexOf(editObj);
            selectedEntryTypes.splice(index, 1)
        }
        this.setState({ selectedEntryTypes: selectedEntryTypes });
    }
    handleChange = (value) => {
        let selectedEntryTypes = cloneDeep(this.state.selectedEntryTypes);
        if (value.length > 0) {
            let newObj = JSON.parse(value[0]);
            let existingObject = selectedEntryTypes.find((el) => el.attName === newObj.attName && el.delName === newObj.delName);
            if (!existingObject) {
                newObj.entryTypeList = [];
                newObj.selectedValue = []
                value.forEach((el) => {
                    let obj = JSON.parse(el);
                    newObj.entryTypeList.push(obj.type[0]);
                });
                newObj.selectedValue.push(value[0])
                selectedEntryTypes.push(newObj);
            }
            if (existingObject) {
                value.forEach((el) => {
                    let obj = JSON.parse(el);
                    existingObject.entryTypeList.push(obj.type[0]);
                });
                existingObject.selectedValue.push(value[0])
                existingObject.entryTypeList = [...new Set(existingObject.entryTypeList)];
            }
            this.setState({ selectedEntryTypes: selectedEntryTypes });
        }
    };
    confirmSelected = (parent) => {
        let data = cloneDeep(this.state.selectedEntries);
        let selectedEntryTypes = this.state.selectedEntryTypes.filter((el) => el.parent === parent);
        let editEntryType = data.find((el) => el.name === parent);
        editEntryType.properties.attributes.forEach((att) => {
            if (isArray(att.relationEntryType)) {
                att.relationEntryType = att.relationEntryType.filter((el) => !el.includes("*"));
            }
        });
        if (selectedEntryTypes.length > 0) {
            selectedEntryTypes.forEach((element) => {
                let attribute = editEntryType.properties.attributes.find((att) => att.name === element.attName);
                if (isArray(attribute.relationEntryType)) {
                    let index = attribute.relationEntryType.findIndex((delObj) => delObj === element.delName);
                    if (index !== -1) {
                        attribute.relationEntryType.splice(index, 1);
                    }
                    if (element.entryTypeList) {
                        attribute.relationEntryType = attribute.relationEntryType.concat(element.entryTypeList);
                        attribute.editable = attribute.editable ? attribute.editable.concat(element.entryTypeList) : element.entryTypeList;
                        attribute.relationEntryType = [...new Set(attribute.relationEntryType)];
                    }
                }
                else {
                    if (element.entryTypeList) {
                        attribute.relationEntryType = element.entryTypeList;
                        attribute.editable = element.entryTypeList;
                    }
                }
            });
        }
        let newSelectedEntryTypes = this.state.selectedEntryTypes.filter((el) => el.parent !== parent);
        this.setState({ selectedEntries: data, selectedEntryTypes: newSelectedEntryTypes });
    };
    selectAll = (e) => {
        let newSelectedRowKeys = [];
        let data = [];
        if (e) {
            this.state.data.data.forEach((el) => {
                let matchingObject = this.state.entryList.find(object => object.type === el.type);
                if (!matchingObject) {
                    newSelectedRowKeys.push(el.type);
                    data.push(el);
                }
            });
        }
        this.setState({ selectedRowKeys: newSelectedRowKeys, selectedEntries: data })
    }

    render() {
        const { t } = this.props;
        let paginationSetup = {
            showSizeChanger: true,
            size: "small",
            showTotal: this.showTotal,
            defaultPageSize: this.state.advSearchPageSize,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            onShowSizeChange: this.handlePageSizeChanged
        };
        const columns = [
            {
                title: t('setup.entryTypes.tblName'),
                key: "name",
                width: "10%",
                render: (text, record) => (
                    <span><b>{record.name}</b></span>
                ),
                filteredValue: this.state.searchNameText,
                onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
                sorter: (a, b) => {
                    return (isUndefined(a["name"]) ? "" : a["name"]).localeCompare((isUndefined(b["name"]) ? "" : b["name"]));
                }
            },
            {
                title: t('setup.entryTypes.tblAttributes'),
                dataIndex: ["properties", "attributes"],
                key: "properties.attributes",
                width: "80%",
                render: (tags) => (
                    <span>
                        {tags.map(tag => (
                            <Tag color="blue" key={tag.name}>
                                {getAttributeTypeIcon(tag.type)} {tag.name}
                            </Tag>
                        ))}
                    </span>
                )
            },
            {
                title: t('setup.entryTypes.tblColor'),
                dataIndex: ["properties", "typeColor"],
                key: "properties.typeColor",
                width: "10%",
                render: (color) => (
                    <span>
                        <Tag color={isEmptyValue(color) ? "blue" : color}>
                            {t('setup.entryTypes.tblColor')}
                        </Tag>
                    </span>
                )
            },
            {
                title: t('setup.entryTypes.importExport.importTitleTableStatus'),
                key: "status",
                width: "10%",
                render: (text, record) => (
                    <span>{this.state.entryList.find((el) => record.name === el.name) || this.state.entryList.find((el) => record.type === el.type) ?
                        <Tag color="red" key={record.name}>{t('setup.entryTypes.importExport.importColumnStatusAlreadyExists')}</Tag> :
                        <Tag color="green" key={record.name}>{t('setup.entryTypes.importExport.importColumnStatusOk')}</Tag>
                    }</span>
                ),
            },
        ];
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onSelect: this.onSelectChange,
            onSelectAll: this.selectAll,
            getCheckboxProps: (record) => ({
                disabled: this.state.entryList.find((el) => record.name === el.name) || this.state.entryList.find((el) => record.type === el.type) ? true : false,
                key: record.type
            })
        };
        let step1 = (<div > <Search
            placeholder={t('setup.entryTypes.searchBoxHint')}
            onSearch={this.filterEntryTypes}
            style={{ width: "100%", marginBottom: '10px' }}
            allowClear
        />
            <Table
                dataSource={this.state.data.data}
                rowSelection={rowSelection}
                rowKey={record => record.type}
                size="small"
                scroll={{ x: true }}
                pagination={paginationSetup}
                columns={columns} />
        </div>);
        let step2 = null;
        const columnsForStep2 = [
            {
                title: t('setup.entryTypes.importExport.importTableTwoTitleAtt'),
                key: "name",
                dataIndex: 'attName',
                width: "10%",
            },
            {
                key: "type",
                width: "10%",
                render: (text, mainRecord) => {
                    const nonStarNameObjects = mainRecord.entryType.filter(obj => obj.name.indexOf('*') === -1);
                    const disabledBtn = nonStarNameObjects.length === 1;

                    let columnInTable = [
                        {
                            title: t('setup.entryTypes.importExport.importTableTwoTitleName'),
                            key: "name",
                            dataIndex: 'name',
                            width: "10%",
                            render: (text, record) => (
                                <span><b>{text.includes('@') ? text.replace('@', '') : text.includes('*') ? text.replace('*', '') : text}</b></span>
                            ),
                        },
                        {
                            title: t('setup.entryTypes.importExport.importTableTwoTitleAction'),
                            key: "type",
                            width: "80%",
                            render: (text, record) => {
                                let defValue = this.state.selectedEntryTypes.find((element) => element.attName === mainRecord.attName);
                                let noValidEt = !this.state.data.data.find((el) => record.type === el.type) || this.state.entryList.find((el) => record.name === el.name) || this.state.entryList.find((el) => record.type === el.type) ? true : false;
                                let options = [];
                                if (this.state.entryList) {
                                    this.state.entryList.forEach((el) => {
                                        options.push({ value: JSON.stringify({ type: [el.type], attName: mainRecord.attName, parent: record.parent, delName: record.type }), label: el.name });
                                    })
                                }
                                options = options.sort((a, b) => a.label.localeCompare(b.label));
                                let selectEt = <Select
                                    key={mainRecord.attName}
                                    showSearch={true}
                                    defaultValue={defValue? defValue.selectedValue:undefined}
                                    filterOption={(inputValue, options) => options.label.includes(inputValue)}
                                    mode='multiple'
                                    style={{
                                        width: 300,
                                    }}
                                    placeholder={t('setup.entryTypes.importExport.importPlaceholderSelectEntryType')}
                                    onChange={this.handleChange}
                                    onDeselect={this.deselect}
                                    options={options}
                                />
                                return (
                                    <>
                                        {
                                            record.name.includes('@') ? selectEt :
                                                <Select
                                                    key={record.name + "selection"}
                                                    style={{
                                                        width: 300,
                                                    }}
                                                    placeholder={t('setup.entryTypes.importExport.importPlaceholderSelectAction')}
                                                    onChange={this.handleChangeTypeSelection}
                                                    defaultValue={record.name.includes('*') ? JSON.stringify({ type: 'del', entry: record }) : null}
                                                    options={[
                                                        {
                                                            value: JSON.stringify({ type: 'add', entry: record }),
                                                            label: t('setup.entryTypes.importExport.importPlaceholderSelectOptionAdd'),
                                                            disabled: record.name.includes('#') || noValidEt ? true : false
                                                        },
                                                        {
                                                            value: JSON.stringify({ type: 'def', entry: record }),
                                                            label: t('setup.entryTypes.importExport.importPlaceholderSelectOptionChoose'),
                                                        },
                                                        {
                                                            value: JSON.stringify({ type: 'del', entry: record }),
                                                            label: t('setup.entryTypes.importExport.importPlaceholderSelectDelete'),
                                                            disabled: !record.name.includes('*') && disabledBtn
                                                        },
                                                    ]}
                                                />
                                        }
                                    </>
                                )
                            },
                        },
                        {
                            title: t('setup.entryTypes.importExport.importTitleTableStatus'),
                            key: "name",
                            width: "10%",
                            render: (text, record) => {
                                const foundEntry = this.state.entryList.find(el => el.type === record.type)||!this.state.data.data.find((el) => record.type === el.type);
                                const noValidEt = Boolean(foundEntry);
                                const isImportColumn = record.name.includes('#');
                                const isEditColumn = record.name.includes('@');
                                const isDeletColumn = record.name.includes('*')
                                const tagColor = isEditColumn ? 'cyan' : noValidEt || isDeletColumn ? 'red' : isImportColumn ? 'blue' : 'green';
                                const tagText = isEditColumn ? t('setup.entryTypes.importExport.importColumnStatusEditing')  :isDeletColumn ? t('setup.entryTypes.importExport.importColumnStatusDeleted')  : noValidEt ? t('setup.entryTypes.importExport.importColumnNoValid') :  isImportColumn ? t('setup.entryTypes.importExport.importColumnOldEt') : t('setup.entryTypes.importExport.importColumnStatusOk');
                                return (
                                    <span>
                                        <b>
                                            <Tag color={tagColor}>{tagText}</Tag>
                                        </b>
                                    </span>
                                );
                            }
                        },
                    ]

                    return <Table
                        dataSource={mainRecord.entryType}
                        columns={columnInTable}
                        pagination={false}
                        size="small"
                    />
                }
            },
        ];
        let noEntryTypeInList = [];
        if (this.state.current === 1) {
            noEntryTypeInList = this.checkRelations(this.state.selectedEntries);
            if (noEntryTypeInList.length > 0) {
                step2 = <div><Alert message={t('setup.entryTypes.importExport.importAlertStepTwo')} type="warning" showIcon />
                    {noEntryTypeInList.map((entry, index) => {
                        return (<div className="advancedSearchRow" key={index}>
                            <Alert message={<span style={{ fontWeight: 'bolder' }}>{entry.name}</span>} key={index + "alert"} type="info" showIcon />
                            <Table
                                key={index + 'table'}
                                rowKey={record => record.name}
                                dataSource={entry.attributes}
                                size="small"
                                pagination={false}
                                scroll={{ x: true }}
                                columns={columnsForStep2} />
                            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                                <Button type="primary" onClick={() => this.confirmSelected(entry.name)} style={{ marginRight: '20px', marginBottom: '10px' }}>{t('setup.entryTypes.importExport.importBtnOkSelect')}</Button>
                            </div>
                        </div>)
                    })}</div>
            }
            else {
                step2 = (<div style={{ marginTop: '20px' }}>
                    <ViewValidEntry selectedEntries={this.state.selectedEntries} listDataRequestResult={this.state.entryList}/>
                    {/* {this.state.selectedEntries.map((entry, index) => <Alert message={<span style={{ fontWeight: 'bolder' }}>{entry.name}</span>} style={{ marginBottom: '10px' }} key={entry.name + "alert"} type="success" showIcon />)} */}
                    </div>)
            }

        };
        const steps = [
            {
                title: 'Choice entry type',
                content: step1,
            },
            {
                title: 'Validation',
                content: step2,
            },
        ];
        let modal = <Modal title={t('setup.entryTypes.importExport.importModalTitle')} width="60%" key='modalImport' visible={this.state.visible} footer={false} onCancel={this.handleCancel}>

            <Descriptions style={{ marginBottom: '10px' }} size="small" column={isTablet() ? 2 : 2}>
                <Descriptions.Item label={t('setup.entryTypes.importExport.importDescriptionCreated')}>{moment(this.state.data.date).format("YYYY-MM-DD")}</Descriptions.Item>
                <Descriptions.Item label={t('setup.entryTypes.importExport.importDescriptionCreatedBy')}>{this.state.data.createdBy}</Descriptions.Item>
                <Descriptions.Item label={t('setup.entryTypes.importExport.importDescriptionExportFrom')}>{this.state.data.programName}</Descriptions.Item>
                <Descriptions.Item label={t('setup.entryTypes.importExport.importDescriptionObjCount')}>{this.state.data.countEtryType}</Descriptions.Item>
            </Descriptions>
            <Steps current={this.state.current} />

            <div style={{ maxHeight: '1050px', overflow: "auto", overflowX: "hidden", }} key={"keyMain"}>{steps[this.state.current].content}</div>
            <div
                style={{
                    marginTop: 24,
                    display: "flex",
                    justifyContent: 'flex-end'
                }}
            >
                {this.state.current < steps.length - 1 && (
                    <Button type="primary" disabled={this.state.selectedEntries.length === 0} onClick={() => this.next()}>
                        {t('setup.entryTypes.importExport.importBtnNext')}
                    </Button>
                )}
                {this.state.current === steps.length - 1 && (
                    <Button type="primary" disabled={this.state.current === 1 && noEntryTypeInList.length > 0} onClick={() => this.handleOk()}>
                        {t('setup.entryTypes.importExport.importBtnImport')}
                    </Button>
                )}
                {this.state.current > 0 && (
                    <Button style={{ margin: '0 8px', }} onClick={() => this.prev()}>
                        {t('setup.entryTypes.importExport.importBtnPrevious')}
                    </Button>
                )}
            </div>

        </Modal>
        return (
            <>
                <Tooltip placement="top" title={t('setup.entryTypes.importExport.importBtnTooltip')}>
                    <Upload
                        maxCount={1}
                        showUploadList={false}
                        beforeUpload={this.beforeUpload}
                        onChange={this.handleOpenFile}
                    >
                        <Button type="button" style={{ color: 'black', padding: '5' }} ><DownloadOutlined /></Button>
                    </Upload>
                </Tooltip>
                {modal}
            </>
        );
    }
}

const ImportContentStructureWithRouter = withRouter(ImportContentStructure);

export default withTranslation()(ImportContentStructureWithRouter);