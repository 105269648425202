import {fetchJson} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const USER_DETAIL_REQUESTED = 'USER_DETAIL_REQUESTED';
/**
 * Action for request of entry.
 *
 * @return {UserDetailAction}
 */
export const requestUserDetail = (userId) => {
    return {
        type: USER_DETAIL_REQUESTED,
        userId: userId
    };
};

export const USER_DETAIL_UPDATE_REQUESTED = 'USER_DETAIL_UPDATE_REQUESTED';
/**
 * Action for request of entry.
 *
 * @return {UserDetailAction}
 */
export const requestUserDetailUpdate = (userId) => {
    return {
        type: USER_DETAIL_UPDATE_REQUESTED,
        userId: userId
    };
};

export const USER_DETAIL_RECEIVED = "USER_DETAIL_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} userDetail
 * @return {ReceiveUserDetailAction}
 */
export const receiveUserDetail = (userId, userDetail) => {
    return {
        type: USER_DETAIL_RECEIVED,
        userDetail: userDetail,
        userId: userId,
        receivedAt: Date.now()
    }
};

export const USER_DETAIL_ERROR = 'USER_DETAIL_ERROR';
/**
 *
 * @param {String} userId
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveUserDetailErrorAction}
 */
export const receiveError = (
    userId,
    error,
    type = USER_DETAIL_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        userId: userId,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};


/**
 * Fetch user detail
 *
 * @param {String} userId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchUserDetail = (userId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            dispatch(requestUserDetailUpdate(userId));
        } else {
            dispatch(requestUserDetail(userId));
        }

        //TODO předělat na samostatný detail usera, pokud bude
        return fetchJson(`${config.url.API_URL}/auth/custom/users/${userId}`, null, dispatch)
            .then(json => {
                    dispatch(receiveUserDetail(userId, json));
                    //dispatch(receiveUserDetail(userId, res.find(u=> u.id === userId)));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(userId, error, USER_DETAIL_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(userId, error, USER_DETAIL_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};