import React from "react";
import "antd/dist/antd.css";
import {
    Drawer,
    Table,
    Space,
    Button,
    Checkbox,
    Typography
} from "antd";
import { withTranslation } from "react-i18next";
import { isUndefined } from "lodash";
import { fetchEntries } from "../../../apicalls/fetchEntries";
import { getColumnCustomAttributeProps } from "../../../utils/TableHelper";
import EntryTypeTag from "../../controls/EntryTypeTag";
import { withRouter } from "react-router-dom";
const CheckboxGroup = Checkbox.Group;
const { Text } = Typography;


class HistoryEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entryList: null,
            checkedList: this.props.settings,
        };
    }
    componentDidMount() {
        this.props.onMount();
    }
    getDetail = (data) => {
        let searchQuery = "";
        data.forEach((el) => {
            searchQuery += "id:" + el.entryID + " ";
        });
        fetchEntries(searchQuery,"userDashboardHistory", search => this.setState({ entryList: search }),null,null,null,null,30);
    };
    saveSettings = () => {
        this.props.newSettings(this.props.index, this.state.checkedList);
        this.props.closeSettings(this.props.index);
    };
    onChangeCheckbox = (list) => {
        this.setState({
            checkedList: list,
        });
    };

    render() {
        let entryTypeNameList = null;
        if (this.props.entryTypesRequestResult.getState().isDone()) {
            entryTypeNameList = this.props.entryTypesRequestResult.getData().map((item, key) => { return { type: item.type, name: item.name, color: item.properties.typeColor }; });
        }
       
        const { t } = this.props;
        let data = JSON.parse(localStorage.getItem("historyEntry"))
        if (data !== null && this.state.entryList === null) {
            this.getDetail(data);
        }
        let dataTable = []
        if (this.state.entryList && !isUndefined(data)) {
            data.forEach((element) => {
                let entry = this.state.entryList.find((el) => element.entryID === el.id)
                if (!isUndefined(entry)) {
                    entry.date = element.date
                }
                dataTable.push(entry)
            });
        }
        const columns = [
            {
                title: t("app.userDashboard.historyEntry.tblEntryName"),
                dataIndex: "name",
                key: "name",
                render: (text, record) => {
                    return (<a href={"/entry/" + record.id} target="blank" style={{ color: "#40a9ff" }}>{record.name}</a>)
                }

            },
            {
                title: t("app.userDashboard.historyEntry.tblEntryType"),
                dataIndex: "type",
                key: "type",
                render: (tag) => (
                    <EntryTypeTag entryTypeName={tag} entryTypeNameList={entryTypeNameList}></EntryTypeTag>
                  
                )
            },
            {
                ...getColumnCustomAttributeProps(
                    "date",
                    t("app.userDashboard.historyEntry.tblLastVisited"),
                    "date4human",
                    t
                ),
                defaultSortOrder: 'descend',
                onFilter: (value, record) => record.date.indexOf(value) === 0
            },

        ];

        if (this.props.settings) {
            let col = null
            this.props.settings.forEach((el) => {
                switch (el) {
                    case "inserted":
                        col = {
                            ...getColumnCustomAttributeProps(
                                "inserted",
                                t("app.userDashboard.historyEntry.tblEntryCreated"),
                                "date",
                                t
                            ),
                            onFilter: (value, record) => record.inserted.indexOf(value) === 0
                        }
                        columns.push(col)
                        break;
                    case "updated":
                        col = {
                            ...getColumnCustomAttributeProps(
                                "updated",
                                t("app.userDashboard.historyEntry.tblEntryUpdated"),
                                "date",
                                t
                            ),
                            onFilter: (value, record) => record.inserted.indexOf(value) === 0
                        }
                        columns.push(col)
                        break;

                    default:
                        break;
                }
            })
        }
        let optionsCheckbox = [
            {
                label: t("app.userDashboard.historyEntry.tblEntryCreated"),
                value: 'inserted',
            },
            {
                label: t("app.userDashboard.historyEntry.tblEntryUpdated"),
                value: 'updated',
            }
        ]
        let drawer =
            <Drawer
                height={'60%'}
                placement="top"
                closable={false}
                onClose={() => this.props.closeSettings(this.props.index)}
                size='small'
                getContainer={false}
                visible={this.props.showSettings}
                footer={
                    <Space>
                        <Button onClick={() => this.props.closeSettings(this.props.index)}>{t("app.userDashboard.historyEntry.btnCancel")}</Button>
                        <Button type="primary" onClick={this.saveSettings}>
                            {t("app.userDashboard.historyEntry.btnOk")}
                        </Button>
                    </Space>
                }
                footerStyle={{ textAlign: 'right' }}
                style={{
                    position: 'absolute',
                }}><Space>
                <Text>{t("app.userDashboard.historyEntry.labelForSetup")}</Text>
                <CheckboxGroup options={optionsCheckbox} value={this.state.checkedList} onChange={this.onChangeCheckbox} />
                </Space>
            </Drawer>

        let table = <Table
            loading={isUndefined(this.state.entryList)}
            pagination={true}
            columns={columns}
            dataSource={this.state.entryList}
            rowKey="id"
            size="small"
            scroll={{
                x: true,
            }}
        />

        return (
            <div className="site-drawer-render-in-current-wrapper">
                {drawer}
                {table}
            </div>

        )
    }
}

const HistoryEntryRouter = withRouter(HistoryEntry);


export default withTranslation()(HistoryEntryRouter);
