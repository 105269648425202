import {fetchJson} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";

export const fetchBreadcrumb = (entryId, callback) => {
    let r = fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/breadCrumb`)
    r.then(json => {
        let res = [];
        if (json._embedded && json._embedded.breadCrumbEntries && json._embedded.breadCrumbEntries.length > 0) {
            res = json._embedded.breadCrumbEntries;
        }
        callback(res);
    }).catch(error => {
        console.log(error);
        callback(null);
    });
};