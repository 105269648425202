import PropTypes from 'prop-types';

export const groupDetailsShape = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fullpath : PropTypes.string
});

export const loggedUserShape = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    firstname : PropTypes.string,
    surname : PropTypes.string,
    username : PropTypes.string,
    initials : PropTypes.string,
    groupsDetails : PropTypes.arrayOf(groupDetailsShape),
    realmRoles : PropTypes.arrayOf(PropTypes.string)
});