import { BPMN_FORM_VARIABLES_REQUESTED,
    BPMN_FORM_VARIABLES_RECEIVED,
    BPMN_FORM_VARIABLES_ERROR,

    } from "../actions/bpmnFormVariables";
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";

/**
*
* @param {RequestResult} state
* @param {BaseAction|ReceiveEntryTypesAction|ReceiveErrorAction} action
* @return {RequestResult}
*/
export const bpmnFormVariables = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

switch (action.type) {
   case BPMN_FORM_VARIABLES_REQUESTED:
       return new RequestResult(RequestState.getInstanceLoading(), state.data);
   case BPMN_FORM_VARIABLES_RECEIVED:
       return new RequestResult(RequestState.getInstanceDone(), action.bpmnFormVariables, action.receivedAt);
   case BPMN_FORM_VARIABLES_ERROR:
       return new RequestResult(
           RequestState.getInstanceError(
               action.errorResponseStatusCode,
               action.errorResponseMessage ? action.errorResponseMessage : null
           ),
           state.getData()
       );
 
   default:
       return state
}
};
