import QueryResultPresenter from '../../../components/pluginPage/widgets/QueryResult';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { isUndefined } from '../../../utils/JsObjectHelper';
import { fetchUserCustomizations } from '../../../actions/userCustomizations';
import { fetchObjectList } from '../../../actions/objectList';


const getSavedRequestList = (customizationsList) => {
    if (!isUndefined(customizationsList) && customizationsList.getState().isDone()) {
        let setList = customizationsList.getData().filter(el => el.type === "search");
		if(isUndefined(setList)){
			setList = {value:[]}
		}
        return setList;
    } else {
        return null;
    }
};


const mapStateToProps = (state, ownProps) => {
    return {
        requestList: getSavedRequestList(state.userCustomizations),
        showSettings: ownProps.showSettings,
        index: ownProps.index,
        closeSettings: ownProps.closeSettings,
        newSettings: ownProps.newSettings,
        settings: ownProps.settings,
        entryTypesRequestResult: state.objectList,
       
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: () => {
			dispatch(fetchUserCustomizations());
            dispatch(fetchObjectList());
		},
    }
};

const QueryResult = connect(
    mapStateToProps,
    mapDispatchToProps
)(QueryResultPresenter);

export default withRouter(QueryResult);