
export const isDesktop = () => {
    if (window.innerWidth > 1024) {
        return true
    }
    else {
       return false
    }
}

export const isTablet  = () => {
    if (window.innerWidth <= 800) {
        return false
    }
    else if (window.innerWidth <= 1024) {
        return true
    }
    else {
       return false
    }
}
export const isMobile   = () => {
    if (window.innerWidth <= 800) {
        return true
    }
    else {
       return false
    }
}
// export const orientationChange = () => {
//     window.addEventListener("orientationchange", function() {
//         console.log(window.orientation);
//       }, false);
      
// }