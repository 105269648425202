import React from 'react';
import { withTranslation } from 'react-i18next'
import { withRouter } from "react-router-dom";
import { Button, Card, Col, Form, Input, Modal, PageHeader, Row, Space } from 'antd';
import { InfoCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { isUndefined } from '../../utils/JsObjectHelper';
import {config} from '../../config/Config'



class InfoFormBpmn extends React.Component {
    constructor(props) {
        super(props);
        const value = props.value //props.fileUrlArrayData.length || null;
        this.state = {
            // Same name as valuePropName in getFieldDecorator ('value' is default):
            // https://ant.design/components/form/?locale=en-US#getFieldDecorator(id,-options)-parameters
            value
        };
    }

    copyValue = (value) => {
        function fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }

        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(value);
            return;
        }
        navigator.clipboard.writeText(value).then(function () {
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    };


    render() {
        console.log(this.state.value);
        const { t } = this.props;
        let initial = { token: this.props.token, rest: `${config.url.BPMN_URL}/engine-rest/engine/default` }
        let info = () => {
            Modal.info({
                title: t('setup.bpmn.bpmnInfoTitle'),
                content: (
                    <div>
                        {t('setup.bpmn.bpmnInfoContent')}
                    </div>
                ),
                onOk() {
                },
            });
        };
        return <div>
            <PageHeader
                title={t('setup.bpmn.bpmnInfoSetup')}
                subTitle={t('setup.bpmn.bpmnInfoSetupDescription')}
                extra={[
                    <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} onClick={info}>
                    </Button>,
                ]}
            >
            </PageHeader>
            <div className="steps-content">
                <Row align="top" gutter={[30, 20]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Card>
                            <Form
                                name="info_form"
                                initialValues={initial}
                                onFinish={this.onFinish}
                                layout="vertical"
                            >
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Form.Item label={t('setup.bpmn.labelRest')}
                                            name="rest"
                                        >
                                            <Input  ></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Button title={t('app.genericControls.copyValue2ClipboardButton.hint')} onClick={() => this.copyValue(initial.rest)} style={{marginTop:"30px"}} type="link" ><CopyOutlined style={{ fontSize: '20px' }} /></Button>

                                    </Col>
                                </Row>
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Form.Item label={t('setup.bpmn.labelToken')}
                                            name="token"
                                        >
                                            <Input  ></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Button  title={t('app.genericControls.copyValue2ClipboardButton.hint')} onClick={() => this.copyValue(initial.token)} style={{marginTop:"30px"}} type="link" ><CopyOutlined style={{ fontSize: '20px' }} /></Button>

                                    </Col>
                                </Row>

                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>;
    }
}


const InfoFormBpmnRouter = withRouter(InfoFormBpmn);
export default withTranslation()(InfoFormBpmnRouter);
