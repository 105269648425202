import React from "react";
import PropTypes from 'prop-types';
import { Tag } from "antd";
import { getTextColorFromBackgroundHex } from "../../utils/ColorHelper";
import { isEmptyObject, isEmptyValue, isUndefined } from "../../utils/JsObjectHelper";
import { entryTypeNameShape } from "../../shapes/EntryTypeNameShape";

class EntryTypeTag extends React.Component {
    render () {
        let entryTypeTag = null;

        if (!isEmptyObject(this.props.entryTypeNameList) && this.props.entryTypeNameList.length > 0) {
            let thisType = this.props.entryTypeNameList.find(t=>t.type===this.props.entryTypeName);
            if(!isUndefined(thisType)){
                let tagTextColor = getTextColorFromBackgroundHex(thisType.color);
                entryTypeTag = <Tag color={isEmptyValue(thisType.color) ? "blue" : thisType.color} style={{color:tagTextColor}}>{thisType.name}</Tag>;
            }
            else{
                entryTypeTag = <Tag color="blue">{this.props.entryTypeName}</Tag>;
            }
           
        } else {
            entryTypeTag = <Tag color="blue">{this.props.entryTypeName}</Tag>;
        }

        return entryTypeTag;
    }
}

export default EntryTypeTag;

EntryTypeTag.propTypes = {
    entryTypeName: PropTypes.string.isRequired,
    entryTypeNameList: PropTypes.arrayOf(entryTypeNameShape)
}