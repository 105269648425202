import ExportContentSructurePresenter from '../../../components/setup/ImportExport/ExportContentSructure';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";




const mapStateToProps = (state, ownProps) => {
    return {
        listDataRequestResult: state.objectList,
        user: state.loggedUser.user,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
};

const ExportContentSructure = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportContentSructurePresenter);

export default withRouter(ExportContentSructure);