import CreateNewButtonPresenter from '../../components/header/CreateNewButton';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {isUndefined} from "../../utils/JsObjectHelper";

const mapStateToProps = (state, ownProps) => {
    return {
        entryRequestResult: state.entryDetail,
        visible: (!isUndefined(state.entryDetail) &&
            !isUndefined(state.entryDetail.state) &&
            state.entryDetail.state.isDone() &&
            !isUndefined(state.entryDetail.data) &&
            ("folder" === state.entryDetail.data.type || "home" === state.entryDetail.data.type) &&
            !state.router.location.pathname.endsWith("/createNew") &&
            !state.router.location.pathname.endsWith("/createNewFolder") &&
            !state.router.location.pathname.endsWith("/edit") &&
            state.router.location.pathname.indexOf("/entry/") > -1
        ),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    }
};

const CreateNewButton = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateNewButtonPresenter);

export default withRouter(CreateNewButton);