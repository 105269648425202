import EntryNewPresenter from '../../components/detail/EntryNew';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {saveNewValues} from "../../actions/entryDetail";
import {isUndefined} from "../../utils/JsObjectHelper";
import {fetchObjectList} from "../../actions/objectList";
import { getGeneralSettingsObject } from '../../utils/GeneralSettingsHelper';
import { fetchSettingsList } from '../../actions/settings';

const mapStateToProps = (state, ownProps) => {
    return {
        parentEntryRequestResult: state.entryDetail,
        folderCreation: !isUndefined(ownProps.match.params.edit) && ("createNewFolder" === ownProps.match.params.edit),
        entryTypesRequestResult: state.objectList,
        generalSettingsObject: getGeneralSettingsObject(state.settingsList)
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList());
            dispatch(fetchSettingsList(false));
        },
        onSaveNew(parentEntryId, values) {
            dispatch(saveNewValues(parentEntryId, values));
        },
    }
};

const EntryNew = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryNewPresenter);

export default withRouter(EntryNew);