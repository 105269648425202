import { fetchJson } from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const UN_CLAIM_TASK_REQUESTED = "UN_CLAIM_TASK_REQUESTED";
/**
 * Action for request of entry.
 *
 * @return {UnClaimTaskAction}
 */
export const requestUnClaimTask = (taskId) => {
  return {
    type: UN_CLAIM_TASK_REQUESTED,
    
  };
};


export const UN_CLAIM_TASK_RECEIVED = "UN_CLAIM_TASK_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} unClaimTask
 * @return {ReceiveUnClaimTaskAction}
 */
export const receiveUnClaimTask  = (unClaimTask) => {
  return {
    type: UN_CLAIM_TASK_RECEIVED,
    unClaimTask: unClaimTask,
    receivedAt: Date.now(),
  };
};

export const UN_CLAIM_TASK_ERROR = "UN_CLAIM_TASK_ERROR";
/**
 *
 * @param {String} taskId
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveUnClaimTaskErrorAction}
 */
export const receiveError = (
  taskId,
  error,
  type = UN_CLAIM_TASK_ERROR,
  errorResponseStatusCode = null,
  errorResponseMessage = null
) => {
  return {
    type: type,
    taskId:taskId,
    error: error,
    errorResponseStatusCode: errorResponseStatusCode,
    errorResponseMessage: errorResponseMessage,
  };
};


/**
 * 
 * @param {String} taskId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchUnClaimTask  = (taskId, update = false,callback) => {
  return (dispatch, getState) => {
      if (update) {
        dispatch(requestUnClaimTask(taskId));
      } else {
        dispatch(requestUnClaimTask(taskId));
      }

      /** @type RequestOptions options **/
      let options = {
          method: "POST"
      };
    
   

     return  fetchJson(`${config.url.API_URL}/bpmn/task/${taskId}/unclaim`, options, dispatch)
     .then(json => {
        let res = [];
        res = json
        if(callback){
          callback()
        }
       dispatch(receiveUnClaimTask(res));
    }
   )  
         .catch(error => {
              if(error === "Ignoring request") {
              } else if(error instanceof ResponseStatusException) {
                  const description = error.response.message ? error.response.message : null;
                  dispatch(receiveError(taskId,error, UN_CLAIM_TASK_ERROR, error.response.status, description));
                  showError(dispatch, error.message, description);
              } else {
                  dispatch(receiveError(taskId,error, UN_CLAIM_TASK_ERROR));
                  showError(dispatch, error.message);
              }
          });
  }
};


