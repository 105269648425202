import {createBaseOptions, fetchJson, METHOD_GET} from "../../utils/SuperAgentFetch";
import {config} from "../../config/Config";


export const fetchBpmnProcessForm = (workflowId,callback) => {
    
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_GET;
    options.headers["Content-Type"] = "application/json";
    let res =""
    let r = fetchJson(`${config.url.API_URL}/bpmn/workflow/${workflowId}/process-definition/deployed-start-form`, options) // /async
    r.then(json => {
        res = json
        callback(json);
    })
    .catch(error => {
        if(error.response.status === 404){
            let e = 'noform'
            callback(e)
        }
        
    });
   
};