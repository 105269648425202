import HomepagePresenter from '../components/Homepage';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchHomepage} from "../actions/homepage";

const mapStateToProps = (state, ownProps) => {
    return {
        homepageResult: state.homepage
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: () => {
            dispatch(fetchHomepage());
        },
    }
};

const Homepage = connect(
    mapStateToProps,
    mapDispatchToProps
)(HomepagePresenter);

export default withRouter(Homepage);