import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import { Card, Row, Col, Skeleton, Table, Divider, Input, Button, Modal, Alert } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import NextSectionButton from '../controls/NextSectionButton'
import { withTranslation} from 'react-i18next'

import UserDetail from "../setup/UserDetail"
import UserEdit from "../setup/UserEdit"
import { isEmptyObject, isEmptyValue, isUndefined } from "../../utils/JsObjectHelper";
import {isTablet,isMobile} from '../../utils/AdaptiveLayout'

const { Search } = Input;

class Users extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showUserDetailModal:false,
            searchNameText: [],
            showUserDialog: false,
            isUserDialogCreateNew: true,
            userFormData: {},
            translates : {
                isSet : false,
                deleteTitle : "",
                deleteOk : "",
                deleteCancel : ""
            },
            advSearchPageSize: (isEmptyObject(localStorage.getItem("setupUsersPageSize")) ? 30 : parseInt(localStorage.getItem("setupUsersPageSize")) )
        };

        this.onMount = this.onMount.bind(this);
        this.filterUsers = this.filterUsers.bind(this);
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.setStateTranslates = this.setStateTranslates.bind(this);
    }

    showTotal = (total) => {
        return `(${total})`;
    };

    handlePageSizeChanged = (current, page) => {
        localStorage.setItem("setupUsersPageSize", page);
        this.setState({ advSearchPageSize: page });
    };

    componentDidMount() {
        this.onMount(this.props.userDetailId);
    }

    onMount(userId) {
        this.props.onMount(userId);
    }

    filterUsers = (searchValue) => {
        this.setState({searchNameText: searchValue.split(' ')});
    }

    showUserDetail = (record) => {
        this.props.onUserChange(record.id);
        this.props.history.push(`/setup/users/` + record.id);
        if(isMobile()||isTablet()){
            this.setState({showUserDetailModal:true})
        }
    }

    showDeleteConfirm = () => {
        Modal.confirm({
            title: this.state.translates.deleteTitle,
            content: <p><b>{this.props.userDetailRequestResult.getData().userName}</b><br/>{this.props.userDetailRequestResult.getData().email}</p>,
            okText: this.state.translates.deleteOk,
            okType: 'danger',
            cancelText: this.state.translates.deleteCancel,
            onOk() {
                console.log('OK');
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    showEditUser = () => {
        this.setState({
            isUserDialogCreateNew: false,
            userFormData: this.props.userDetailRequestResult.getData(),
            showUserDialog: true
        });
    }

    showNewUser = () => {
        this.setState({
            isUserDialogCreateNew: true,
            userFormData: {key: null, email: '', userName: ''},
            showUserDialog: true
        });
    }

    closeEditUser = () => {
        this.setState({
            showUserDialog: false
        });
    }

    setStateTranslates = (translator) => {
        this.setState({
            translates : {
                isSet : true,
                deleteTitle : translator('setup.userManagement.usrDeleteTitle'),
                deleteOk : translator('setup.userManagement.usrDeleteYes'),
                deleteCancel : translator('setup.userManagement.usrDeleteNo')
            }
        });
    };
    closeDetailModal = ()=>{
        this.setState({showUserDetailModal:false})
    }


    render () {
        const {t} = this.props;

        if (!this.state.translates.isSet) {
            this.setStateTranslates(t);
        }

        let columns = [
            {
                title: t('setup.userManagement.tblEmail'),
                dataIndex: 'email',
                key: 'email',
                filteredValue: this.state.searchNameText,
                onFilter: (value, record) => (!isEmptyValue(record.email) && record.email.toLowerCase().includes(value.toLowerCase())) || (isEmptyValue(record.name) && record.name.toLowerCase().includes(value.toLowerCase())),
                sorter: (a, b) => {
                    return (isUndefined(a["email"]) ? "" : a["email"]).localeCompare((isUndefined(b["email"]) ? "" : b["email"]));
                }
            },
            {
                title: t('setup.userManagement.tblUserName'),
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => {
                    return (isUndefined(a["name"]) ? "" : a["name"]).localeCompare((isUndefined(b["name"]) ? "" : b["name"]));
                }
            },
        ];

        let userModalTitle = (this.state.isUserDialogCreateNew ? t('setup.userManagement.usrNewTitle') : t('setup.userManagement.usrEditTitle'));

        let userList = (this.props.userListRequestResult.getState().isLoading() ? [] : this.props.userListRequestResult.getData());

        let userDetail = (this.props.userDetailRequestResult.getState().isLoading() ? {} : this.props.userDetailRequestResult.getData());

        let paginationSetup = {
            showSizeChanger: true, 
            size:"small", 
            showTotal: this.showTotal, 
            defaultPageSize: this.state.advSearchPageSize, 
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            onShowSizeChange: this.handlePageSizeChanged
        };
        let detailForDesktop =   
        <Card 
            // title="User detail"
            actions={[
                <Button onClick={this.showEditUser} type="default" disabled={true || !this.props.showDetail}>
                    <EditOutlined key="edit" title="Edit" /> {t('setup.userManagement.usrDetailBtnEdit')}
                </Button>,
                <Button onClick={this.showDeleteConfirm} type="danger" disabled={true || !this.props.showDetail}>
                    <DeleteOutlined key="delete" title="Delete"/> {t('setup.userManagement.usrDetailBtnDelete')}
                </Button>
            ]}
        >
            <Modal
                title={userModalTitle}
                visible={this.state.showUserDialog}
                onCancel={this.closeEditUser}
                footer={null}
            >
                <UserEdit 
                    userData={this.state.userFormData} 
                    isCreateNew={this.state.isUserDialogCreateNew}
                    onCancel={this.closeEditUser}
                    onSave={this.props.onUserSave}>
                </UserEdit>
            </Modal>
               {isMobile()||isTablet()?null: <Alert message={t('setup.userManagement.usrEditInfoBox')} type="info" showIcon></Alert>}
            <Skeleton loading={!this.props.showDetail}>
            {/* <Skeleton loading={!this.props.showDetail} active> */}
                <UserDetail data={userDetail} loading={this.props.userDetailRequestResult.getState().isLoading()}></UserDetail>
            </Skeleton>
        </Card>
    let detail = detailForDesktop
    if(isMobile()||isTablet()){
        detail = <Modal
        visible={this.state.showUserDetailModal}
        footer={null}
        onCancel={this.closeDetailModal}
      
    >
        {detailForDesktop}
    </Modal>
    }

        return (
            <div>
                <Divider>{t('setup.userManagement.userSection')}</Divider>
                <Row gutter={[{ xs: 6, sm: 12, md: 24, lg: 32 }, 20]}>
                    <Col span={isMobile()||isTablet()?24:10}>
                        <Table 
                            dataSource={userList} 
                            columns={columns} 
                            size="small"
                            loading={this.props.userListRequestResult.getState().isLoading()}
                            pagination={paginationSetup}
                            rowKey="name"
                            scroll={
                               { x:true}
                            }
                            title={() => <div style={{display:'flex'}}>
                                <Button disabled={true} type="primary" style={{marginRight: '15px'}} onClick={this.showNewUser}><PlusOutlined /> {t('setup.userManagement.btnAddUser')}</Button>
                                <Search
                                    placeholder={t('setup.userManagement.boxSearchUsersHint')}
                                    onSearch={this.filterUsers}
                                    style={{ width: '100%' }}
                                    allowClear
                                />
                                </div>}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {this.showUserDetail(record)}, // click row
                                };
                            }}
                        />
                    </Col>
                    <Col span={14}>
                    {detail}
                    </Col>
                    {/* <Col span={14}>
                        <Card 
                            // title="User detail"
                            actions={[
                                <Button onClick={this.showEditUser} type="default" disabled={true || !this.props.showDetail}>
                                    <EditOutlined key="edit" title="Edit" /> {t('setup.userManagement.usrDetailBtnEdit')}
                                </Button>,
                                <Button onClick={this.showDeleteConfirm} type="danger" disabled={true || !this.props.showDetail}>
                                    <DeleteOutlined key="delete" title="Delete"/> {t('setup.userManagement.usrDetailBtnDelete')}
                                </Button>
                            ]}
                        >
                            <Modal
                                title={userModalTitle}
                                visible={this.state.showUserDialog}
                                onCancel={this.closeEditUser}
                                footer={null}
                            >
                                <UserEdit 
                                    userData={this.state.userFormData} 
                                    isCreateNew={this.state.isUserDialogCreateNew}
                                    onCancel={this.closeEditUser}
                                    onSave={this.props.onUserSave}>
                                </UserEdit>
                            </Modal>
                                <Alert message={t('setup.userManagement.usrEditInfoBox')} type="info" showIcon></Alert>
                            <Skeleton loading={!this.props.showDetail}>
                            <Skeleton loading={!this.props.showDetail} active>
                                <UserDetail data={userDetail} loading={this.props.userDetailRequestResult.getState().isLoading()}></UserDetail>
                            </Skeleton>
                        </Card>

                    </Col> */}
                </Row>
                <Divider dashed ></Divider>
                <Row >
                    <Col span={12}></Col>
                    <NextSectionButton align="right" title={t('setup.userManagement.groupSection')} toUrl="/setup/groups"></NextSectionButton>
                </Row>
            </div>
        );
    }
}

const UsersWithRouter = withRouter(Users);

export default withTranslation() (UsersWithRouter);

UsersWithRouter.propTypes = {
    onUserSave: PropTypes.func.isRequired,
};
