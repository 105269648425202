import React from "react";
import { Button, Modal, Space, Form, } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next'
const predefinedColors = [
    {
        name: "Dust Red",
        colors: [/*"#fff1f0",*/"#ffccc7", "#ffa39e", "#ff7875", "#ff4d4f", "#f5222d", "#cf1322", "#a8071a", "#820014", "#5c0011"]
    },
    {
        name: "Volcano",
        colors: [/*"#fff2e8",*/"#ffd8bf", "#ffbb96", "#ff9c6e", "#ff7a45", "#fa541c", "#d4380d", "#ad2102", "#871400", "#610b00"]
    },
    {
        name: "Sunset Orange",
        colors: [/*"#fff7e6",*/"#ffe7ba", "#ffd591", "#ffc069", "#ffa940", "#fa8c16", "#d46b08", "#ad4e00", "#873800", "#612500"]
    },
    {
        name: "Calendula Gold",
        colors: [/*"#fffbe6",*/"#fff1b8", "#ffe58f", "#ffd666", "#ffc53d", "#faad14", "#d48806", "#ad6800", "#874d00", "#613400"]
    },
    {
        name: "Sunrise Yellow",
        colors: [/*"#feffe6",*/"#ffffb8", "#fffb8f", "#fff566", "#ffec3d", "#fadb14", "#d4b106", "#ad8b00", "#876800", "#614700"]
    },
    {
        name: "Lime ",
        colors: [/*"#fcffe6",*/"#f4ffb8", "#eaff8f", "#d3f261", "#bae637", "#a0d911", "#7cb305", "#5b8c00", "#3f6600", "#254000"]
    },
    {
        name: "Polar Green",
        colors: [/*"#f6ffed",*/"#d9f7be", "#b7eb8f", "#95de64", "#73d13d", "#52c41a", "#389e0d", "#237804", "#135200", "#092b00"]
    },
    {
        name: "Cyan",
        colors: [/*"#e6fffb",*/"#b5f5ec", "#87e8de", "#5cdbd3", "#36cfc9", "#13c2c2", "#08979c", "#006d75", "#00474f", "#002329"]
    },
    {
        name: "Daybreak Blue",
        colors: [/*"#e6f7ff",*/"#bae7ff", "#91d5ff", "#69c0ff", "#40a9ff", "#1890ff", "#096dd9", "#0050b3", "#003a8c", "#002766"]
    },
    {
        name: "Geek Blue",
        colors: [/*"#f0f5ff",*/"#d6e4ff", "#adc6ff", "#85a5ff", "#597ef7", "#2f54eb", "#1d39c4", "#10239e", "#061178", "#030852"]
    },
    {
        name: "Golden Purple",
        colors: [/*"#f9f0ff",*/"#efdbff", "#d3adf7", "#b37feb", "#9254de", "#722ed1", "#531dab", "#391085", "#22075e", "#120338"]
    },
    {
        name: "Magenta",
        colors: [/*"#fff0f6",*/"#ffd6e7", "#ffadd2", "#ff85c0", "#f759ab", "#eb2f96", "#c41d7f", "#9e1068", "#780650", "#520339"]
    }
];

class ColorPickerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color:null
        };
    }
    handleColorChange = (event) => {
        const colorValue = event.target.value;
        const hexValue = "#" + colorValue.slice(1);
        this.setState({color:hexValue})
    }
    handleOk = ()=>{
        if(this.state.color){
            this.props.onSelect(this.state.color)
        }   

    }
    render() {
        const { t } = this.props;
        let colorButtons = [];

        predefinedColors.forEach(cDef => {
            let btns = cDef.colors.map(c => {
                if (c === this.props.colorValue) {
                    return <Button key={c} type="primary" style={{ backgroundColor: c }} onClick={f => { this.props.onSelect(c) }}> </Button>;
                } else {
                    return <Button key={c} style={{ backgroundColor: c }} onClick={f => { this.props.onSelect(c) }}> </Button>;
                }
            });

            colorButtons.push(<span key={cDef.name}><Space size={0}>{btns}</Space> <b>{cDef.name}</b></span>);
        });

        return (
            <Modal
                title={t('app.colorPicker.modalTitle')}
                visible={this.props.isVisible}
                //onOk={this.handleOk}
                onCancel={this.props.onCancel}
                width="450px"
                footer={false}
                // footer={[<Button key="back" onClick={this.props.onCancel}>
                //     {t('app.colorPicker.modalBtnReturn')}
                // </Button>]}
            >
                {colorButtons}
                <div style={{ marginTop: '20px' }}>
                  <span style={{marginRight:'10px'}}>{t('app.colorPicker.customColor')}</span>
                  <input type="color" style={{backgroundColor:'white',border:'none'}} id="head" name="head" value={this.state.color?this.state.color:this.props.colorValue} onChange={this.handleColorChange}></input>
                  <Button style={{marginLeft:'10px'}} type="primary"  size="small" disabled={!this.state.color} onClick={this.handleOk}>OK</Button>
                </div>

            </Modal>
        )
    }
}

export default withTranslation()(ColorPickerModal);

ColorPickerModal.propTypes = {
    colorValue: PropTypes.string,
    isVisible: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};