import { 
    TASK_COUNT_REQUESTED,
    TASK_COUNT_RECEIVED,
    TASK_COUNT_ERROR  } from "../actions/bpmnTaskCount";
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";

/**
*
* @param {RequestResult} state
* @param {BaseAction|ReceiveEntryTypesAction|ReceiveErrorAction} action
* @return {RequestResult}
*/
export const taskCount = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

switch (action.type) {

   case TASK_COUNT_REQUESTED:
           return new RequestResult(RequestState.getInstanceLoading(), state.data);
   case TASK_COUNT_RECEIVED:
           return new RequestResult(RequestState.getInstanceDone(), action.taskCount, action.receivedAt);
   case TASK_COUNT_ERROR:
           return new RequestResult(
               RequestState.getInstanceError(
                   action.errorResponseStatusCode,
                   action.errorResponseMessage ? action.errorResponseMessage : null
               ),
               state.getData()
           );
   default:
       return state
}
};
