import React from "react";
import update from 'immutability-helper';
import { Link } from "react-router-dom";
import { Button, Input, Popconfirm, message, Divider, Form, Popover, Space,Empty } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined, SisternodeOutlined, PlusCircleOutlined,NodeIndexOutlined } from '@ant-design/icons';
import PropTypes from "prop-types";
import { baseEntryWithParentShape } from "../../shapes/BmcEntryShape";
import AttributesSection from '../../containers/detail/AttributesSection'
import FolderProperties from '../../containers/detail/FolderProperties'
import FolderWorkflowEvents from '../../containers/detail/FolderWorkflowEvents';
import { fetchCurrentEntryVersionId } from '../../apicalls/fetchEntries';
import { EMPTY_FOLDER_PROPERTIES_DEFINITION } from '../../utils/EmptyFolderPropertiesDefinition';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import '../../froala/plugins/drawio';
import '@fortawesome/fontawesome-free/js/all';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins.pkgd.min.css';
import '../../froala_custom.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

import FroalaEditor from 'react-froala-wysiwyg';
import { relationsHolderShape } from "../../shapes/RelationShapes";
import { isUndefined, isEmptyValue, isEmptyObject } from "../../utils/JsObjectHelper";

import { withTranslation } from 'react-i18next'
import EntryEditIncomingRelations from "../../containers/detail/EntryEditIncomingRelations";

import { getFroalaConfiguration } from '../../froala/FroalaConfigurationHelper';

// Include special components if required.
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

const cloneDeep = require('lodash.clonedeep');

class EntryEditForm extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            name: props.data.name,
            description: props.data.description,
            properties: (
                props.data.type === "folder" ?
                    //FOLDER
                    (!isUndefined(props.data.properties) ? props.data.properties : EMPTY_FOLDER_PROPERTIES_DEFINITION) :
                    //OSTATNI TYPY
                    (!isUndefined(props.data.properties) ? props.data.properties : {})
            ),
            newValuesForSubmit: null,
            submitting: false,
            rewritePopconfirmVisible: false,
            outgoingRelationsHolderState: cloneDeep(this.props.outgoingRelationsHolder),
            deleteEntryType: false,
            popoverAtt: false,
            popoverRel: false,
            popoverRelIncoming:false,
            //attFormsRefs : {}
        };

        //ensure workflow setup for old entries
        if (props.data.type === "folder" && isUndefined(this.state.properties.workflowEvents)) {
            this.state.properties.workflowEvents = { onSave: "" };
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFinalSubmit = this.handleFinalSubmit.bind(this);
        this.cancelFinalSubmit = this.cancelFinalSubmit.bind(this);
        this.handleRelationChanged = this.handleRelationChanged.bind(this);
        this.descriptionChange = this.descriptionChange.bind(this);
        this.handlePropertyChange = this.handlePropertyChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        //state changed to error
        if (this.props.entryState !== prevProps.entryState && this.props.entryState.isError()) {
            this.cancelFinalSubmit();
        }

        //TODO: vyřešit změnu dat při component updatu -> update formu
        if (this.props.data !== prevProps.data) {
            this.setState({ name: this.props.data.name, description: this.props.data.description });
        }
        const targetElement = document.querySelector('.fr-btn-grp.fr-float-right');
        const blockToInsert = document.getElementById('mybuttons');
        
        if (targetElement && targetElement.parentNode && blockToInsert) {
            if (targetElement.nextSibling !== blockToInsert) {
                targetElement.parentNode.insertBefore(blockToInsert, targetElement.nextSibling);
                blockToInsert.style.display = 'block';
            }
        }
    }
    displayValidationNotification = () => {
        const { t } = this.props;
        message.error(t("app.entry.edit.msgValidationNotification"));
    }

    handleSubmit = (formValues) => {
        console.log(formValues);
        let isAttHasError = false;
        //Tohle doresit az se budou resit atributy
        /*Object.keys(this.state.attFormsRefs).forEach(frm => {
            this.state.attFormsRefs[frm].props.form.validateFields((err, values) => {
                if (err) {
                    isAttHasError = true;
                }
            });
        });*/

        this.formRef.current.validateFields()
            .then(values => {
                this.setState({ submitting: true });
                let newEntry = {
                    name: null,
                    type: null,
                    description: null,
                    properties: {}
                };
                //if(this.props.folderCreation) values["type"] = "folder";
                newEntry.name = values["name"];
                newEntry.description = values["description"];
                newEntry.type = values["type"];
                if (!isUndefined(values.properties) && !isEmptyValue(values.properties)) {
                    newEntry.properties = values.properties;
                } else {
                    newEntry.properties = this.state.properties;
                }
                if (!isUndefined(this.state.incomingRelations)) {
                    newEntry.incomingRelations = this.state.incomingRelations;
                }
                if (!isUndefined(this.state.outgoingRelations)) {
                    newEntry.outgoingRelations = this.state.outgoingRelations;
                }

                this.setState({ newValuesForSubmit: newEntry, submitting: true });
                fetchCurrentEntryVersionId(this.props.data.id, this.compareActualVersionNumber);
            })
            .catch(errorInfo => {
                console.log(errorInfo);
                this.displayValidationNotification();
            });


        /*this.props.form.validateFields((err, values) => {
            if (!err && !isAttHasError) {
                let newValues = Object.assign({}, values);
                if(!isUndefined(this.state.properties) && !isEmptyValue(this.state.properties)) {
                    //newValues.properties = JSON.stringify(this.state.properties);
                    newValues.properties = this.state.properties;
                }
                if(!isUndefined(this.state.incomingRelations)) {
                    newValues.incomingRelations = this.state.incomingRelations;
                }
                if(!isUndefined(this.state.outgoingRelations)) {
                    newValues.outgoingRelations = this.state.outgoingRelations;
                }
                this.setState({newValuesForSubmit: newValues, submitting: true});

                fetchCurrentEntryVersionId(this.props.data.id, this.compareActualVersionNumber);
            } else {
                this.displayValidationNotification();
            }
        });*/
    };

    compareActualVersionNumber = (actualVersionNumber) => {
        if (isEmptyValue(actualVersionNumber)) {
            this.setState({ submitting: false });
            //TODO informovat, ze se ukladat nebude, protoze se nedari zjistit aktualni verzi
        }

        if (actualVersionNumber !== this.props.data.version) {
            //Inform user about conflict in versions
            this.setState({ rewritePopconfirmVisible: true });
        } else {
            //No conflict in versions -> SAVE
            this.handleFinalSubmit();
        }
    }

    handleFinalSubmit = () => {
        let newValues = this.state.newValuesForSubmit
        if (this.props.data.properties && this.props.data.properties.childrenPosition) {
            newValues.properties.childrenPosition = this.props.data.properties.childrenPosition
        }
        this.props.onSaveEdit(this.props.data.id, newValues);
    }

    cancelFinalSubmit = () => {
        this.setState({ newValuesForSubmit: null, submitting: false, rewritePopconfirmVisible: false });
    }


    handleRelationChanged(type, values) {
        if (type === "incoming") {
            this.setState({ incomingRelations: values });
        } else if (type === "outgoing") {
            this.setState({ outgoingRelations: values });
        }
    }

    handlePropertyChange(values) {
        if (!isUndefined(values) && Object.keys(values).length > 0) {
            this.setState((prevState, props) => {
                let propValues = (!isUndefined(prevState.properties) ? cloneDeep(prevState.properties) : {});
                let attVal = values[Object.keys(values)[0]];
                propValues[attVal.name] = attVal.value;
                return { properties: propValues };
            });
        }
    }

    descriptionChange = (text) => {
        this.formRef.current.setFieldsValue({ description: text })
    };
    handleDeleteEntryType = (param) => {
        this.setState({ deleteEntryType: param })
    }

    /*onFolderPropertyChanged = (values) => {
        if (!isUndefined(values) && Object.keys(values).length > 0) {
            this.setState((prevState, props) => {
                let propValues = (!isUndefined(prevState.properties) ? cloneDeep(prevState.properties) : {});
                let attVal = values[Object.keys(values)[0]];
                propValues[Object.keys(values)[0]] = attVal;
                return {properties: propValues};
            });
        }
    }
    onFolderPropertyWorkflowChanged = (values) => {
        if (!isUndefined(values) && Object.keys(values).length > 0) {
            this.setState((prevState, props) => {
                let propValues = (!isUndefined(prevState.properties) ? cloneDeep(prevState.properties) : {});
                let attVal = values[Object.keys(values)[0]];
                propValues.workflowEvents[Object.keys(values)[0]] = attVal;
                return {properties: propValues};
            });
        }
    }*/
    /*saveAttFormRef = (index, ref) => {
        if (!isUndefined(ref) ) {
            this.setState((prevState, props) => {
                return update(prevState, {
                    attFormsRefs: {
                        [index]: {$set: ref}
                    }
                });
            });
        }
    };*/

    handleEditorBlur = () => {
        const editor = this.formRef.current.getFieldInstance('description').editor;
        if (editor) {
            editor.selection.save();
        }
    };
    handleSelectChange = (value) => {
        const editor = this.formRef.current.getFieldInstance('description').editor;
        if (editor) {
            this.setState({ popoverAtt: false, popoverRel: false,popoverRelIncoming:false })
            editor.events.focus(true);
            editor.selection.restore();
            editor.html.insert(value);
        }
    };
    popoverChange = (value) => {
        this.setState({ popoverAtt: value,popoverRel:false,popoverRelIncoming:false  });
    }
    popoverRelChange = (value) => {
        this.setState({ popoverRel: value,popoverAtt:false,popoverRelIncoming:false });
    }
    popoverRelIncomingChange = (value) => {
        this.setState({ popoverRelIncoming: value,popoverAtt:false,popoverRel:false  });
    }
    getRelationName = (sourceType, attType) => {
        let entryDef = this.props.objectListRequestResult.getData().find(eT => eT.type === sourceType);
        if (!isEmptyObject(entryDef) && !isUndefined(entryDef.properties.attributes)) {
            let attDef = entryDef.properties.attributes.find(att => att.techName === attType);
            return (!isEmptyObject(attDef) && !isEmptyObject(attDef.name)) ? attDef.name : attType;
        }
        return attType;
    };
    render() {
        const { t } = this.props;
        let froalaConfig = getFroalaConfiguration(this.props.data.id, t);
        froalaConfig.events = {
            'blur': this.handleEditorBlur
        }
        let token = sessionStorage.getItem("kcToken");
        if (token) {
            froalaConfig.requestHeaders = {
                Authorization: 'Bearer ' + token
            };
        }

        let buttonList = [];
        let myButtons = null;
        let froalaEditor = null;
        let relationsButtonList = [];
        let incomingRelationsBtn = [];
        if (this.props.data.systemType === 'object' && this.props.objectListRequestResult.getState().isDone() && !isEmptyObject(this.props.objectListRequestResult.getData())) {
            let entryType = this.props.objectListRequestResult.getData().find((el) => el.type === this.props.data.type);
            entryType.properties.attributes.forEach((el) => {
                if (el.type === "hyperlink") {
                    buttonList.push(<Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{[${el.techName}|link]}}`)}>{el.name}</Button>);
                }
                if(el.type === "date"){
                    buttonList.push(<Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{[${el.techName}|date]}}`)}>{el.name}</Button>); 
                }
                if (el.type !== "boolean" && el.type !== "relation" && el.type !== "hyperlink"&& el.type !== "fileUrlArray"&&el.type !== "date") {
                    buttonList.push(<Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{[${el.techName}]}}`)}>{el.name}</Button>);
                }
                if (el.type === 'relation') {
                    let relationStyleList = <Space direction="vertical" size="small">
                        <Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{(${el.techName}|text)}}`)}>{t('app.entry.edit.insertAttRelationText')}</Button>
                        <Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{(${el.techName}|tag)}}`)}>{t('app.entry.edit.insertAttRelationTag')}</Button>
                        <Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{(${el.techName}|table)}}`)}>{t('app.entry.edit.insertAttRelationTable')}</Button>
                        <Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{(${el.techName}|ul)}}`)}>{t('app.entry.edit.insertAttRelationUl')}</Button>
                    </Space>;
                    relationsButtonList.push(<Popover
                        content={relationStyleList}
                        placement="right"
                        trigger="hover"
                        title={t('app.entry.edit.insertAttRelation')}>
                        {el.name}
                    </Popover>);
                }
            });
            if(this.props.incomingRelationsHolder && this.props.incomingRelationsHolder.relations && this.props.incomingRelationsHolder.relations.length>0){
                this.props.incomingRelationsHolder.relations.forEach((element)=>{
                    let relationEntryType = this.props.objectListRequestResult.getData().find((el)=>el.type == element.relatedEntries[0].type );
                    let nameRel = this.getRelationName(relationEntryType.type,element.name)
                    let relationStyleList = <Space direction="vertical" size="small">
                        <Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{{${element.name}|text}}}`)}>{t('app.entry.edit.insertAttRelationText')}</Button>
                        <Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{{${element.name}|tag}}}`)}>{t('app.entry.edit.insertAttRelationTag')}</Button>
                        <Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{{${element.name}|table}}}`)}>{t('app.entry.edit.insertAttRelationTable')}</Button>
                        <Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{{${element.name}|ul}}}`)}>{t('app.entry.edit.insertAttRelationUl')}</Button>
                    </Space>;
                    incomingRelationsBtn.push(<Popover
                        content={relationStyleList}
                        placement="right"
                        trigger="hover"
                        title={t('app.entry.edit.insertAttRelation')}>
                        {nameRel}{`  (${relationEntryType.name})`}
                    </Popover>);
                })
            }

            froalaEditor = <Form.Item label={t('app.entry.edit.lblDescription')}
                name="description"
                valuePropName="model"
            >
                <FroalaEditor
                    config={froalaConfig}
                    //model={(!isUndefined(this.formRef.current) ? this.formRef.current.getFieldValue("description") : null)}
                    onModelChange={this.descriptionChange}
                    events={{
                        'blur': this.handleEditorBlur
                    }}
                />
            </Form.Item>
        }
        else {
            froalaEditor = <Form.Item label={t('app.entry.edit.lblDescription')}
                name="description"
                valuePropName="model"
            >
                <FroalaEditor
                    config={froalaConfig}
                    //model={(!isUndefined(this.formRef.current) ? this.formRef.current.getFieldValue("description") : null)}
                    onModelChange={this.descriptionChange}
                />
            </Form.Item>
        }
        myButtons = <div style={{ marginTop: '15px', display: 'none' }} id="mybuttons">
        <Space direction="horizontal" size="middle">
            <span title={t('app.entry.edit.insertAttTitle')}>
            <Popover
                visible={this.state.popoverAtt}
                content={<Space direction="vertical" size="small">{buttonList}</Space>}
                placement="bottom"
                trigger="click"
                onVisibleChange={this.popoverChange}>
                <PlusCircleOutlined style={{ fontSize: '18px', }} />
            </Popover>
            </span>
            <span title={t('app.entry.edit.insertAttRelOutTitle')}>
            <Popover
                visible={this.state.popoverRel}
                content={<Space direction="vertical" size="small">{relationsButtonList}</Space>}
                placement="bottom"
                trigger="click"
                
                onVisibleChange={this.popoverRelChange}>
                <SisternodeOutlined style={{ fontSize: '18px', }} />
            </Popover></span>
            <span title={t('app.entry.edit.insertAttRelIncTitle')}>
            <Popover
                visible={this.state.popoverRelIncoming}
                content={incomingRelationsBtn.length>0?<Space direction="vertical" size="small">{incomingRelationsBtn}</Space>:<Empty  />}
                placement="bottom"
                trigger="click"
                
                onVisibleChange={this.popoverRelIncomingChange}>
                    <NodeIndexOutlined style={{ fontSize: '18px', }}/>
                    
            </Popover></span>
        </Space>
    </div>
        let attSection = null;
        let incomingRelationsSection = null;
        let folderSection = null;

        if (this.props.data.type === "folder" || this.props.data.type === "home") {
            if (this.props.data.type === "folder") {
                folderSection =
                    <FolderProperties
                        // propertyData={this.state.properties}
                        // onPropertyChange={this.onFolderPropertyChanged}
                        disabledBtn={this.handleDeleteEntryType}
                        formRef={this.formRef}
                    ></FolderProperties>;
                attSection = <><span>{t('app.entry.edit.lblEntryRelatedWorkflow')} </span> <div style={{ border: '1px solid #d9d9d9', padding: '15px', borderRadius: '10px', marginTop: '5px' }}>
                    <FolderWorkflowEvents
                    // propertyData={this.state.properties.workflowEvents}
                    // onPropertyChange={this.onFolderPropertyWorkflowChanged}
                    ></FolderWorkflowEvents></div></>;
            }
        } else {
            //Other entries than folder and home has user defined attributes
            attSection = <AttributesSection
                data={this.props.data}
                relationData={this.state.outgoingRelationsHolderState}
                onRelationChange={this.handleRelationChanged}
                onPropertyChange={this.handlePropertyChange}
                isEditMode={true}
                formRef={this.formRef}
            //saveAttFormRef={this.saveAttFormRef}
            >
            </AttributesSection>
        }

        //incoming relations can be only edited on objects
        if (this.props.data.systemType === "object") {
            incomingRelationsSection = <EntryEditIncomingRelations
                relationHolder={this.props.incomingRelationsHolder}
                onRelationChange={(values) => this.handleRelationChanged("incoming", values)}
            >
            </EntryEditIncomingRelations>;
        }

        return <Form onFinish={this.handleSubmit}
            ref={this.formRef}
            name="entry_edit_form"
            layout="vertical"
            initialValues={this.state}
        >
            <Form.Item label={t('app.entry.edit.lblName')}
                name="name"
                rules={[{ required: true, message: t('app.entry.edit.msgFillName') }]}
            >
                <Input placeholder={t('app.entry.edit.lblNameHint')} style={{ width: '100%' }} allowClear={true} />
            </Form.Item>

            {folderSection}
            {froalaEditor}
            {myButtons}

            {attSection}

            {incomingRelationsSection}

            <Divider />
            <Form.Item shouldUpdate>
                {() => {
                    return [
                        <Popconfirm
                            placement="topLeft"
                            title={<div><p><b>{t('app.entry.edit.msgVersionConflict')}</b></p><p>{t('app.entry.edit.msgVersionConflictLine1')}<br />{t('app.entry.edit.msgVersionConflictLine2')}</p></div>}
                            visible={this.state.rewritePopconfirmVisible}
                            //onVisibleChange={this.handleVisibleChange}
                            onConfirm={this.handleFinalSubmit}
                            onCancel={this.cancelFinalSubmit}
                            okText="Force rewrite"
                            cancelText="Cancel save"
                            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                            key="btnsConfirm"
                        >&nbsp;
                        </Popconfirm>,
                        <Button type="primary" htmlType="submit" style={{ marginRight: '10px' }} icon={<SaveOutlined />}
                            disabled={
                                isUndefined(this.formRef.current) ||
                                (!isUndefined(this.formRef.current) &&
                                    (
                                        //!this.formRef.current.isFieldsTouched(["name","type"]) ||
                                        !!this.formRef.current.getFieldsError().filter(({ errors }) => errors.length).length
                                    )
                                ) || this.state.deleteEntryType
                            }
                            loading={this.state.submitting}
                            key="btnsSave"
                        >{t('app.entry.edit.btnVersionConflictSave')}</Button>,
                        <Link to={`/entry/${this.props.data.id}`} key="btnsCancel"><Button type="primary" disabled={this.state.deleteEntryType} icon={<CloseOutlined />}>{t('app.entry.edit.btnVersionConflictClose')}</Button></Link>
                    ]
                }}
            </Form.Item>
        </Form>;
    }
}

export default withTranslation()(EntryEditForm);

EntryEditForm.propTypes = {
    data: baseEntryWithParentShape.isRequired,
    onSaveEdit: PropTypes.func.isRequired,
    incomingRelationsHolder: relationsHolderShape.isRequired,
    outgoingRelationsHolder: relationsHolderShape.isRequired,
};