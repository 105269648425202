import React from "react";
import { Button, Card } from 'antd';
import { ApartmentOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { withTranslation} from 'react-i18next'
import {bmcEntryShape} from "../../../shapes/BmcEntryShape";
import EntryManipulation from "../../../containers/detail/EntryManipulation"
import EntryOnDemandWorkflowList  from "../../../containers/detail/EntryOnDemandWorkflowList"
import { Link } from "react-router-dom";
import EntryUpload from "./EntryUpload"
import {config} from "../../../config/Config";
import { downloadFile } from "../../../utils/FileHelper";

class EntryActions extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            uploadVisible: false
        }

        this.uploadDialogOpen = this.uploadDialogOpen.bind(this);
        this.onUploadDialogBack = this.onUploadDialogBack.bind(this);
    }
    

    uploadDialogOpen = () => {
        this.setState({ uploadVisible : true });
    };

    downloadTemplate = () => {
        downloadFile(`${config.url.API_URL}/bmcEntries/custom/item/${this.props.entry.id}/bulkLoad/template/xlsx`, `${this.props.entry.name}_header.xlsx`);
    };

    downloadEntries = () => {
        downloadFile(`${config.url.API_URL}/bmcEntries/custom/item/${this.props.entry.id}/bulkLoad/templateWithData/xlsx`, `${this.props.entry.name}_data.xlsx`);
    };

    onUploadDialogBack = () => {
        this.setState({ uploadVisible : false });
    };

    render() {
        const {t} = this.props;

        let lineagePanel = null;
        let uploadPanel = null;

        if (this.props.entry.systemType === "object") {
            lineagePanel = <Card type="inner" size="small" 
                title={t('app.entry.tools.actions.boxLineage')} 
                style={{marginBottom: '20px'}}
                bodyStyle={{display:'grid'}}>
                <Link to={`/lineage/${this.props.entry.id}`} target="_blank">
                    <Button key="lineage" name="lineage" icon={<ApartmentOutlined />} style={{width: '100%'}}>{t('app.entry.tools.actions.btnLineage')}</Button>
                </Link>
            </Card>;
        }

        if (this.props.entry.systemType === "folder" && this.props.entry.userEntryAccess === "EDIT") {
            uploadPanel = <Card type="inner" size="small" 
                title={t('app.entry.tools.actions.boxUpload')} 
                style={{marginBottom: '20px'}}
                bodyStyle={{display:'grid'}}>
                    <Button key="downloadHeader" name="downloadHeader" icon={<DownloadOutlined />} style={{width: '100%', marginBottom:'10px'}} onClick={this.downloadTemplate}>{t('app.entry.tools.actions.btnDownloadHeader')}</Button>
                    <Button key="download" name="download" icon={<DownloadOutlined />} style={{width: '100%', marginBottom:'10px'}} onClick={this.downloadEntries}>{t('app.entry.tools.actions.btnDownloadFolderData')}</Button>
                    <Button key="upload" name="upload" icon={<UploadOutlined />} style={{width: '100%'}} onClick={this.uploadDialogOpen}>{t('app.entry.tools.actions.btnUpload')}</Button>
            </Card>;
        }

        return <div>
            {lineagePanel}
            <EntryManipulation entry={this.props.entry}></EntryManipulation>
            <EntryOnDemandWorkflowList entry={this.props.entry}></EntryOnDemandWorkflowList>
            {uploadPanel}
            <EntryUpload 
                visible={this.state.uploadVisible} 
                entry={this.props.entry}
                onBack={this.onUploadDialogBack}
            ></EntryUpload>
        </div>;
    }
};

export default withTranslation() (EntryActions);

EntryActions.propTypes = {
    entry: bmcEntryShape.isRequired
};