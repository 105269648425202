import {createBaseOptions, fetchJson, METHOD_DELETE, } from "../../utils/SuperAgentFetch";
import {config} from "../../config/Config";


export const fetchDeleteCandidate = (taskId,identity,callback) => {
    console.log(identity);
   
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_DELETE;
    options.headers["Content-Type"] = "application/json";
    options["body"] = identity;
    
   
    let r = fetchJson(`${config.url.API_URL}/bpmn/task/${taskId}/identityLink/multi`, options) // /async
    r.then(json => {
        console.log("ok");
        callback()   
    })
    .catch(error => {
        console.log('error');
        callback()
    });
    

};