import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchEntryCommentList, unmountEntryComments, saveNewComment} from "../../actions/entryComments";
import EntryCommentsPresenter from '../../components/detail/view/EntryComments';
import { isEmptyObject, isEmptyValue } from "../../utils/JsObjectHelper";


const getCommentsTree = (entryCommentList, loggedUser) => {
    let commentsTree = [];

    if (!isEmptyObject(entryCommentList) && entryCommentList.getState().isDone()) {
        let rootComments = entryCommentList.getData().filter(c => isEmptyValue(c.parentId));

        rootComments.forEach(c => {
            commentsTree.push(buildComment(c, entryCommentList, loggedUser));
        });
    }

    return commentsTree;
}

const buildComment = (entryComment, entryCommentList, loggedUser) => {
    let newComment = {
        id: entryComment.id,
        content: entryComment.content,
        author: entryComment.authorName,
        authorid: entryComment.authorId,
        authorInitials: getAuthorInitials(entryComment.authorName),
        created: entryComment.created,
        likes: 0,
        dislikes: 0,
        selectedAction: "",
        subComments: [],
    };

    if (!isEmptyValue(entryComment.likes)) {
        if (!isEmptyValue(entryComment.likes.likes)) {
            newComment.likes = entryComment.likes.likes.length;
            if (entryComment.likes.likes.includes(loggedUser.user.id)) {
                newComment.selectedAction = "liked";
            }
        }
        if (!isEmptyValue(entryComment.likes.dislikes)) {
            newComment.dislikes = entryComment.likes.dislikes.length;
            if (entryComment.likes.dislikes.includes(loggedUser.user.id)) {
                newComment.selectedAction = "disliked";
            }
        }
    }

    let subComments = entryCommentList.getData().filter(c => c.parentId === entryComment.id);

    subComments.forEach(c => {
        newComment.subComments.push(buildComment(c, entryCommentList, loggedUser));
    });

    return newComment;
}

const getAuthorInitials = (authorName) => {
    let nameParts = authorName.split(" ");
    let initials = "";

    nameParts.forEach(n => {
        initials += n.substring(0, 1);
    })

    return initials
}


const mapStateToProps = (state, ownProps) => {
    return {
        entryCommentListRequestResult: state.entryCommentList,
        loggedUser: state.loggedUser,
        commentListData: getCommentsTree(state.entryCommentList, state.loggedUser),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: (entryId, shouldUpdateOnly = false) => {
            dispatch(fetchEntryCommentList(entryId, shouldUpdateOnly));
        },
        onUnmount: () => {
            dispatch(unmountEntryComments());
        },
        onCommentCreate: (entryId, parentId, authorId, authorName, content) => {
            let newComment = {
                //id: "com2",
                parentId: parentId,
                //authorId: authorId,
                //authorName: authorName,
                content: content,
                //likes: { likes:[], dislikes: []},
            };
            dispatch(saveNewComment(entryId, newComment));
        },
    }
};

const EntryComments = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryCommentsPresenter);

export default withRouter(EntryComments);