import React from "react";
import { Select } from 'antd';
import { ClockCircleOutlined, FieldBinaryOutlined, FieldNumberOutlined, FieldStringOutlined, LinkOutlined, ShareAltOutlined, UnorderedListOutlined,  } from '@ant-design/icons';
import {isEmptyObject} from "./JsObjectHelper";

const Option = Select.Option;


/**
 * According to possible EntryTypes, returns AntDesign Select Options containing all common Attributes.
 * 
 * @param {*} entryTypesRequestResultData EntryType RequestResult data
 * @param {*} allowedEntryTypesList array of possible EntryTypes in options
 * @param {boolean} onlySearchable return only searchable attributes (default false)
 * @param {boolean} includeRelations include relation type attributes in result (default false)
 * @param {boolean} showTypeIcons  include attribute type icon in options
 * @returns 
 */
export const getEntryTypeAttributesSelectOptions = (
        entryTypesRequestResultData, 
        allowedEntryTypesList, 
        onlySearchable = false, 
        includeRelations = false, 
        showTypeIcons = false
    ) => {
    let selectOptions = [];
    let myTypes = entryTypesRequestResultData.filter(oT => allowedEntryTypesList.includes(oT.type))
    myTypes.forEach(t => {
        t.properties.attributes
        .filter(att => 
            ["string", "number", "boolean", "date", "hyperlink", "lov", (includeRelations ? "relation" : "N/A")].includes(att.type) 
            && (!onlySearchable || att.isSearchable)
        )
        .forEach(att=> {
            let optAtt = selectOptions.find(oA => oA.techName === att.techName);
            if (isEmptyObject(optAtt)) {
                selectOptions.push({ techName: att.techName, name: att.name, fromType: [t.name], type: att.type });
            } else {
                optAtt.fromType.push(t.name);
            }
        });
    });

    selectOptions = selectOptions
        .sort((a,b) => a.name.localeCompare(b.name))
        .map(
            oA => {
                let typeIcon = null;
                if(showTypeIcons) {
                    typeIcon = getAttributeTypeIcon(oA.type);
                }
            
                return <Option value={oA.techName} key={oA.techName} title={`${oA.name} (${oA.fromType.join(', ')})`}>
                    {typeIcon} <span>{`${oA.name} (${oA.fromType.join(', ')})`}</span>
                </Option>;
            }
        );
        
    return selectOptions;
};


/**
 * Returns antdesign icon for attribute type
 * 
 * @param {string} type attribute type name
 * @returns 
 */
export const getAttributeTypeIcon = (type) => {
    let typeIcon = null;
    switch (type) {
        case "localizedString":
        case "string":
            typeIcon = <FieldStringOutlined />;
            break;
        case "number":
            typeIcon = <FieldNumberOutlined />;
            break;
        case "boolean":
            typeIcon = <FieldBinaryOutlined />;
            break;
        case "date":
            typeIcon = <ClockCircleOutlined />;
            break;
        case "hyperlink":
            typeIcon = <LinkOutlined />;
            break;
        case "lov":
            typeIcon = <UnorderedListOutlined />;
            break;
        case "relation":
            typeIcon = <ShareAltOutlined />;
            break;
        default:
            break;
    }

    return typeIcon;
}