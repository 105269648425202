import UsersPresenter from '../../components/setup/Users';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchUserList} from "../../actions/userList";
import {fetchUserDetail} from "../../actions/userDetail";
import {isUndefined} from "../../utils/JsObjectHelper";

const mapStateToProps = (state, ownProps) => {
    return {
        userListRequestResult: state.userList,
        showDetail: (!isUndefined(ownProps.match.params.setupDetailId) ? true : false),
        userDetailId : (!isUndefined(ownProps.match.params.setupDetailId) ? ownProps.match.params.setupDetailId : ""),
        userDetailRequestResult : state.userDetail, //(ownProps.match.params.setupDetailId !== undefined ? state.userDetail : {}),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: (userId) => {
            dispatch(fetchUserList());
            dispatch(fetchUserDetail(userId));
        },

        onUserChange: (userId) => {
            dispatch(fetchUserDetail(userId));
        },

        onUserSave(userId, values) {
            console.log(userId);
            console.log(values);
        },
    }
};

const Users = connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersPresenter);

export default withRouter(Users);