import { createBaseOptions, fetchJson, METHOD_DELETE } from "../../utils/SuperAgentFetch";
import { config } from "../../config/Config";
import { isNumber } from "../../utils/JsObjectHelper";

export const fetchDeleteEntryType = (type, callback) => {


    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_DELETE;
    options.headers["Content-Type"] = "application/json";

    let r = fetchJson(`${config.url.API_URL}/entryTypes/custom/itemByType/${type}/hardDelete`, options);
    r.then(json => {
        console.log("json");
       if(!json){
        callback()
       }


    }).catch(error => {
        callback("error")
        console.log(error);
    });
};

