import {isUndefined} from "./JsObjectHelper";

/**
 * Return true if logged user is admin
 *
 * @param {Object} state
 * @returns {boolean} true if logged user is admin
 */
export const isAdminUser = (state) => {
    return hasRole(state, "AP_ADMIN");
};

/**
 * Return true if logged user has setup rights
 *
 * @param {Object} state
 * @returns {boolean} true if logged user has setup rights
 */
 export const isSetupUser = (state) => {
    return hasRole(state, "AP_SETUP") || hasRole(state, "AP_ADMIN") || hasRole(state, "BPMN_ADMIN")|| hasRole(state, "AP_SETUP_USER");
};

/**
 * Return true if logged user has role
 *
 * @param {Object} state
 * @param {String} roleName
 * @returns {boolean} true if logged user has role
 */
export const hasRole = (state, roleName) => {
    if(!isUndefined(state) && !isUndefined(state.loggedUser) && !isUndefined(state.loggedUser.user) && !isUndefined(state.loggedUser.user.realmRoles)) {
        return (state.loggedUser.user.realmRoles.includes(roleName));
    }
    return false;
};