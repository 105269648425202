import React from 'react';
import { Button } from 'antd';
import { isEmptyValue } from '../../utils/JsObjectHelper';
import { withTranslation } from 'react-i18next';


class CopyValue2ClipboardButton extends React.Component {


    constructor(props) {
        super(props);
        const value = props.value //props.fileUrlArrayData.length || null;
        this.state = {
            // Same name as valuePropName in getFieldDecorator ('value' is default):
            // https://ant.design/components/form/?locale=en-US#getFieldDecorator(id,-options)-parameters
            value
        };
    }
    
    handleChange = (value) => {
        this.setState({ value : value });
        const { onChange } = this.props;
        if (onChange) {
            // This will provide the form with changes
            onChange(value);
        }
    };

    copyFileLink = (evnt) => {
        function fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }

        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(this.state.value);
            return;
        }
        navigator.clipboard.writeText(this.state.value).then(function() {
            console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    };

    render() {
        const {t} = this.props;

        return <Button title={t('app.genericControls.copyValue2ClipboardButton.hint')} type="text" onClick={this.copyFileLink}>{this.state.value}</Button>;
    }
}

export default withTranslation() (CopyValue2ClipboardButton);