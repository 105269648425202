import EntryFriendsPresenter from '../../components/detail/EntryFriends';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchObjectList} from "../../actions/objectList";
import { getGeneralSettingsObject } from '../../utils/GeneralSettingsHelper';
import { fetchSettingsList } from '../../actions/settings';



const mapStateToProps = (state, ownProps) => {
    return {
        entryTypesRequestResult: state.objectList,
        generalSettingsObject: getGeneralSettingsObject(state.settingsList)
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList());
            dispatch(fetchSettingsList(false));
        },
    }
};

const EntryFriends = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryFriendsPresenter);

export default withRouter(EntryFriends);
