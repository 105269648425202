import React from "react";
import {Input, Button, Checkbox, Tag} from "antd";
import { SearchOutlined } from '@ant-design/icons';
//import { highlightSearchedText } from "./TextHighlighter";
import { isArray, isEmptyValue, isUndefined } from "./JsObjectHelper";
import { getTextColorFromBackgroundHex } from "./ColorHelper";

/**
 * Returns props for AntDesign Column which has quick search and order enabled.
 * 
 * @param {string} dataIndex data index name in data for column
 * @param {Function} handleSearchFunction 
 * @param {Function} handleResetFunction 
 * @param {Function} columnRenderFunction 
 * @param {Object} translator i18n translator instance
 * @param {Object} searchInput will serve as Ref for searchbox
 * @returns 
 */
export const getColumnSearchProps = (dataIndex, handleSearchFunction, handleResetFunction, columnRenderFunction, translator, searchInput) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
                ref={node => {
                    searchInput = node;
                }}
                placeholder={translator('app.entry.view.tblEntriesBtnSearch') + ` ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearchFunction(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
                type="primary"
                onClick={() => handleSearchFunction(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined/>}
                size="small"
                style={{ width: 90, marginRight: 8 }}
            >
                {translator('app.entry.view.tblEntriesBtnSearch')}
            </Button>
            <Button onClick={() => handleResetFunction(clearFilters)} size="small" style={{ width: 90 }}>
                {translator('app.entry.view.tblEntriesBtnReset')}
            </Button>
        </div>
    ),
    filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
        record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => searchInput.select());
        }
    },
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a[dataIndex].localeCompare(b[dataIndex]),
    render: (text, record) => columnRenderFunction(text, record, dataIndex)
});


/**
 * Returns AntDesign table Column definition
 * 
 * @param {*} dataIndex name of data attribute for column
 * @param {*} title 
 * @param {*} type type of data in column (date, date4human, number, string, boolean, hyperlink, identity)
 * @param {*} t i18n translator instance
 * @param {any} otherOptions object with custom options {identityDataIndex: data index for identity column type}
 * @returns 
 */
export const getColumnCustomAttributeProps = (dataIndex, title, type, t, otherOptions) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: (a, b) => {
        /*let aVal = (dataIndex.indexOf('.') > 0 ? a[dataIndex.split('.')[0]][dataIndex.split('.')[1]] : a[dataIndex]);
        let bVal = (dataIndex.indexOf('.') > 0 ? b[dataIndex.split('.')[0]][dataIndex.split('.')[1]] : b[dataIndex]);*/
        let aVal = (isArray(dataIndex) ? a[dataIndex[0]][dataIndex[1]] : a[dataIndex]);
        let bVal = (isArray(dataIndex) ? b[dataIndex[0]][dataIndex[1]] : b[dataIndex]);

        if (isEmptyValue(aVal)) return -1;
        if (isEmptyValue(bVal)) return 1;

        switch (type) {
            case "date4human":
            case "date":
                return new Date(aVal) - new Date(bVal);
            case "number":
                return aVal - bVal;
            case "identity":
            case "string":
                return aVal.localeCompare(bVal)
            case "hyperlink":
                return aVal.label.localeCompare(bVal.label)
            case "boolean":
                if (isUndefined(aVal) && isUndefined(bVal)) return 0;
                else if (aVal && !bVal) return 1;
                else if (!aVal && bVal) return -1;
                else return 0;
            default:
                return false;
        }
    },
    render: (text,record) => {
        switch (type) {
            case "date4human":
            case "date":
                let dateValue = new Date(text);
                let dateValFormatted = text; //use value as fallback option
                //Check if date is valid
                if (!isEmptyValue(text) && dateValue instanceof Date && !isNaN(dateValue)) {
                    if (type === "date4human") {
                        return <span title={t('datetime', {date : dateValue})}>{t('dateFromNow', {date : dateValue})}</span>;
                    }

                    if (dateValue.getMinutes() === 0 && dateValue.getHours() === 0) {
                        dateValFormatted = t('date', {date : dateValue});
                    } else {
                        dateValFormatted = t('datetime', {date : dateValue});
                    }
                }
                return dateValFormatted;
            case "string":
                return <span title={text}>{(isEmptyValue(text) ? "" : (text.length > 50 ? `${text.substring(0, 50)}...` : text))}</span>;
            case "boolean":
                return <Checkbox checked={text} disabled={true}></Checkbox>
            case "hyperlink":
                if (!isUndefined(text) && !isEmptyValue(text) && !isEmptyValue(text.url)) {
                    let hyperlinkLabel = (!isEmptyValue(text.label) ? text.label : text.url);
                    return <a href={text.url} target="_blank" rel="noopener noreferrer">{hyperlinkLabel}</a>;
                }
                return null;
            case "identity":
                if (!isUndefined(otherOptions) && !isUndefined(otherOptions.identityDataIndex) && !isEmptyValue(otherOptions.identityDataIndex)) {
                    let identityId = (isArray(otherOptions.identityDataIndex) ? record[otherOptions.identityDataIndex[0]][otherOptions.identityDataIndex[1]] : record[otherOptions.identityDataIndex]);
                    return <a href={"/id2entry/" + identityId} target="_blank" rel="noopener noreferrer">{text}</a>;
                }
                return <span title={text}>{(isEmptyValue(text) ? "" : (text.length > 50 ? `${text.substring(0, 50)}...` : text))}</span>;
            default:
                return text;
        }
    }
});

/**
 * 
 * 
 * @param {string} text 
 * @param {Object} record 
 * @param {Object} translator i18n translator instance
 * @returns 
 */
export const dateColumnRenderer = (text,record,translator) => {
    let dateValue = new Date(text);
    let dateValFormatted = text; //use value as fallback option
    //Check if date is valid
    if (!isEmptyValue(text) && dateValue instanceof Date && !isNaN(dateValue)) {
        if (dateValue.getMinutes() === 0 && dateValue.getHours() === 0) {
            dateValFormatted = translator('date', {date : dateValue});
        } else {
            dateValFormatted = translator('datetime', {date : dateValue});
        }
    }
    return dateValFormatted;
};


/**
 * Returns AntDesign table Column definition for Aphinit NameValues as TAGs
 * 
 * @param {*} dataIndex name of data attribute for column
 * @param {*} title 
 * @param {*} attributeDef Aphinit Attribute definition
 * @param {*} t i18n translator instance
 * @returns 
 */
export const getColumnNameValueTag = (dataIndex, title, attributeDef, t) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    filters: attributeDef.lovArray.map(
        lovItm => {
            return { text: lovItm.name, value: lovItm.value };
        }
    ),
    onFilter: (value, record) => {
        let recVal = (isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex]);
        if (isUndefined(recVal)) {
            return false;
        }
        return recVal.indexOf(value) === 0;
    },
    render: (text,record) => {
        let recVal = (isArray(dataIndex) ? (!isEmptyValue(record[dataIndex[0]]) ? record[dataIndex[0]][dataIndex[1]] : null) : record[dataIndex]);
        
        if (isEmptyValue(recVal)) return null;
        
        let recObj = { name: text };
        if (!isUndefined(attributeDef.lovArray)) {
            let foundLoV = attributeDef.lovArray.find(lov => lov.value === recVal);
            if (!isUndefined(foundLoV)) {
                recObj = foundLoV;
            }
        }

        let tagTextColor = getTextColorFromBackgroundHex(recObj.color);
        return (
            <Tag color={recObj.color} style={{color:tagTextColor}}>
                {recObj.name}
            </Tag>
        );
    }
});