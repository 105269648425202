import {fetchJson} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";

export const HOMEPAGE_REQUESTED = 'HOMEPAGE_REQUESTED';
/**
 * Action for request of entry.
 *
 * @return {BaseAction}
 */
export const requestHomepage = () => {
    return {
        type: HOMEPAGE_REQUESTED
    };
};


export const HOMEPAGE_RECEIVED = "HOMEPAGE_RECEIVED";
/**
 *
 * @param {String} itemId
 * @return {ItemIdAction}
 */
export const receiveHomepage = (itemId) => {
    return {
        type: HOMEPAGE_RECEIVED,
        itemId: itemId,
        receivedAt: Date.now()
    }
};

export const HOMEPAGE_ERROR = 'HOMEPAGE_ERROR';
/**
 *
 * @param {Object} error
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveErrorAction}
 */
export const receiveError = (
    error,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: HOMEPAGE_ERROR,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};

/**
 * Fetch homepage entry id
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchHomepage = (update = false) => {
    return (dispatch, getState) => {
        if (update) {
            dispatch(requestHomepage());
        } else {
            dispatch(requestHomepage());
        }
        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/homepage`, null, dispatch)
            .then(json => {
                    let res = "";
                    if (json && json.content) {
                        res = json.content;
                    }
                    dispatch(receiveHomepage(res));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, error.response.status, description));
                } else {
                    dispatch(receiveError(error));
                }
            });
    }
};
