import EntryEditIncomingRelationsPresenter from '../../components/detail/edit/EntryEditIncomingRelations';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchObjectList} from "../../actions/objectList";
import { isEmptyValue } from '../../utils/JsObjectHelper';

const getPossibleIncomingRelationEntryTypes = (entryDetail, entryTypes) => {
    let inRelEntryTypeList = [];

    if (entryDetail.getState().isDone() && entryTypes.getState().isDone()) {
        let thisDetailEntry = entryDetail.getData();
        entryTypes.getData().forEach(eT => {
            //filter only attributes of "relation" type with relation to current entry type
            let eTRelations = eT.properties.attributes.filter(att => att.type === "relation" && att.relationEntryType.indexOf(thisDetailEntry.type) > -1);
            if (!isEmptyValue(eTRelations)) {
                //Check if relation has folders specified and if so if this entrys parent is in deffinition
                eTRelations = eTRelations.filter(att => isEmptyValue(att.relationEntryParent) || att.relationEntryParent.indexOf(thisDetailEntry.parent.id) > -1 );
                if (!isEmptyValue(eTRelations)) {
                    inRelEntryTypeList.push({ name: eT.name, type: eT.type, relations: eTRelations });
                }
            }
        });
    }

    return inRelEntryTypeList;
}

const mapStateToProps = (state, ownProps) => {
    return {
        entryRequestResult: state.entryDetail,
        entryTypesRequestResult: state.objectList,
        possibleIcomingRelationEntryTypes: getPossibleIncomingRelationEntryTypes(state.entryDetail, state.objectList),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList());
        },
    }
};

const EntryEditIncomingRelations = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryEditIncomingRelationsPresenter);

export default withRouter(EntryEditIncomingRelations);