import { createBaseOptions,fetchJson,METHOD_PATCH} from "../utils/SuperAgentFetch";
import { config } from "../config/Config";



/**
 * 
 * @param {String} taskId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchSaveEditedEntry = (entryId, values,callback) => {
console.log(values);
    /** @type RequestOptions options **/
    let options = createBaseOptions();
    options.method = METHOD_PATCH;
    options.headers["Content-Type"] = "application/json";
    options["body"] = values;

    return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}`, options)
        .then(json => {
            if (callback) {
                callback()
            }
        }
        )
        .catch(error => {
            console.log(error);
        });

}


