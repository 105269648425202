export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
/**
 * Action for login requested.
 *
 * @return {BaseAction}
 */
export const requestUserLogin = () => {
    return {
        type: LOGIN_REQUESTED
    };
};

export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
/**
 * Action for login requested.
 *
 * @return {BaseAction}
 */
export const requestUserLogout = () => {
    return {
        type: LOGOUT_REQUESTED
    };
};

export const SUCCESSFUL_LOGIN = 'SUCCESSFUL_LOGIN';
/**
 * Action for request of entry.
 *
 * @return {BaseAction}
 */
export const successfulLogin = (keycloak) => {
    return {
        type: SUCCESSFUL_LOGIN,
        data: {
            token: keycloak.token,
            refreshToken: keycloak.refreshToken,
            tokenParsed: keycloak.tokenParsed
        }
    };
};

export const SUCCESSFUL_UPDATE_TOKEN = 'SUCCESSFUL_UPDATE_TOKEN';
/**
 * Action for request of entry.
 *
 * @return {BaseAction}
 */
export const successfulTokenUpdate = (keycloak) => {
    return {
        type: SUCCESSFUL_UPDATE_TOKEN,
        data: {
            token: keycloak.token,
            refreshToken: keycloak.refreshToken,
            tokenParsed: keycloak.tokenParsed
        }
    };
};