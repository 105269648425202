import React from "react";
import "antd/dist/antd.css";
import {
    Drawer,
    Space,
    Button,
    Typography,
    Select,
    Input,
    Form,
    Result
} from "antd";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import SearchResults from "../../../containers/search/SearchResults";
import { fetchEntries } from "../../../apicalls/fetchEntries";
import { isUndefined } from "../../../utils/JsObjectHelper";
const { Text } = Typography;
const { Option } = Select;

class QueryResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchedData: [],
            status: null
        };
    }
    componentDidMount(){
        if (!isUndefined(this.props.settings) && !isUndefined(this.props.settings.setup) && this.state.searchedData.length < 1 &&  this.props.entryTypesRequestResult.getData()) {
            this.handleAdvanceSearch(this.props.settings.setup);
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.settings !== this.props.settings ){
            if(this.props.settings && this.props.settings.setup){
                this.handleAdvanceSearch(this.props.settings.setup);
            }
            
        }
        if (!isUndefined(this.props.settings) && !isUndefined(this.props.settings.setup) && this.state.searchedData.length < 1 && prevProps.entryTypesRequestResult.getData() != this.props.entryTypesRequestResult.getData()) {
            this.handleAdvanceSearch(this.props.settings.setup)
        }
    };
    handleAdvanceSearch = (setupId) => {
        let entryTypes = this.props.entryTypesRequestResult.getData();
        let id = setupId;
        let request = this.props.requestList.find((el) => el.id === id);
        let entryTypeLive = null
        if (isUndefined(request)) {
            this.setState({ status: 'deletedQuery' });
        }
        if (!isUndefined(request)) {
            entryTypeLive = entryTypes.find((el) => el.type === request.value.advancedType);
            if (isUndefined(entryTypeLive)) {
                this.setState({ status: 'deletedEntryType' });
            }
        }

        if (!isUndefined(entryTypeLive)) {
            let callbackSearchFnc = (data) => {
                this.setState({
                    searchedData: data,
                    status: false
                });
            };
            fetchEntries(request.value.advancedValue, 'queryResult' + this.props.index, callbackSearchFnc, request.value.advancedType, null, null, request.value.dynamicConditions, 100);
        }

    };

    saveSettings = (value) => {
        if (!isUndefined(value.setup)) {
            this.handleAdvanceSearch(value.setup)
        }
        this.props.newSettings(this.props.index, value);
        this.props.closeSettings(this.props.index);
    };

    render() {
        const { t } = this.props;
        let options = null;
        if (this.props.requestList) {
            options = this.props.requestList.map((el) => <Option key={el.id} value={el.id}>{el.name}</Option>);
        }
        let drawer =
            <Form
                initialValues={this.props.settings}
                onFinish={this.saveSettings}
            >
                <Drawer
                    height={'100%'}
                    placement="top"
                    closable={false}
                    onClose={() => this.props.closeSettings(this.props.index)}
                    size='small'
                    getContainer={false}
                    visible={this.props.showSettings}
                    footer={
                        <Space>
                            <Button onClick={() => this.props.closeSettings(this.props.index)}>{t("app.userDashboard.queryResult.btnCancel")}</Button>
                            <Button htmlType="submit" type="primary" >
                                {t("app.userDashboard.queryResult.btnOk")}
                            </Button>
                        </Space>
                    }
                    footerStyle={{ textAlign: 'right' }}
                    style={{
                        position: 'absolute',
                    }}>   <Form.Item
                        label={t("app.userDashboard.queryResult.labelForSetupSelect")}
                        name='setup'>
                        <Select >
                            {options}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={t("app.userDashboard.queryResult.labelForSetupInput")}
                        name='title'>
                        <Input showCount minLength={1} maxLength={20} />
                    </Form.Item>
                </Drawer>
            </Form>

        let searchResult = <SearchResults data={this.state.searchedData} />;

        if(this.state.searchedData.length === 0 && isUndefined(this.props.settings)){
            searchResult = <Result title={t("app.userDashboard.queryResult.infoSelectQuery")}  subTitle={<span className="subTitleQueryResult" > {t("app.userDashboard.queryResult.infoSelectQuerySubTitle")}</span>}/>;
        }
        // if (this.props.entryTypesRequestResult.getData() && this.state.searchedData.length > 0) {
        //     searchResult = <SearchResults data={this.state.searchedData} />
        // }
        if (this.state.status === 'deletedEntryType') {
            searchResult = <Result
                status="404"
                title={t("app.userDashboard.queryResult.errorEntryTypeDeleted")} />;
        }
        if (this.state.status === 'deletedQuery') {
            searchResult = <Result
                status="500"
                title={t("app.userDashboard.queryResult.errorQueryDeleted")} />;
        }
        return (
            <div className="site-drawer-render-in-current-wrapper">
                {drawer}
                {searchResult}
            </div>

        )
    }
}


const QueryResultRouter = withRouter(QueryResult);

export default withTranslation()(QueryResultRouter);
