import {createBaseOptions, fetchJson, METHOD_PATCH, METHOD_POST} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import { push } from 'connected-react-router'
import {showError, showSuccess} from "../utils/NotificationsHelper";
import { fetchDetail } from "./entryDetail";
import { fetchDetailBreadCrumb } from "./entryDetailBreadCrumb";
import { isUndefined } from "../utils/JsObjectHelper";

export const ENTRY_MOVE_REQUESTED = 'ENTRY_MOVE_REQUESTED';
/**
 * Action for request of entry move.
 *
 * @return {EntryMoveAction}
 */
export const requestEntryMove = (entryId) => {
    return {
        type: ENTRY_MOVE_REQUESTED,
        entryId: entryId
    };
};

export const ENTRY_MOVED = "ENTRY_MOVED";
/**
 *
 * @return {ReceiveEntryMoveAction}
 */
export const receiveEntryMoved = (entryId) => {
    return {
        type: ENTRY_MOVED,
        entryId: entryId,
        receivedAt: Date.now()
    }
};

export const ENTRY_MOVE_ERROR = 'ENTRY_MOVE_ERROR';
/**
 *
 * @param {String} entryId
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {EntryManipulatonErrorAction}
 */
export const receiveError = (
    entryId,
    error,
    type = ENTRY_MOVE_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        entryId: entryId,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};


/**
 * Move Entry to another parent folder
 *
 * @return {function(*=, *): Promise<Object | never>}
 */
export const moveEntry = (entryId, entryManipulationDefinition,afterMoveCallback) => {
    return (dispatch, getState) => {
        dispatch(requestEntryMove(entryId));

        /** @type RequestOptions */
        let options = createBaseOptions();
        options.method = METHOD_POST;
        options.headers["Content-Type"] = "application/json";
        options["body"] = entryManipulationDefinition;

        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/move`, options, dispatch)
            .then(json => {
                dispatch(receiveEntryMoved(entryId));
                if (!isUndefined(json)  && !isUndefined(afterMoveCallback)) {
                    afterMoveCallback();
                } else {
                    dispatch(fetchDetail(entryId, true));
                    dispatch(fetchDetailBreadCrumb(entryId, true));
                    dispatch(push(`/entry/${entryId}`));
                    showSuccess(dispatch, "Success", "Entry has been successfully moved!");
                }
                    // dispatch(fetchDetail(entryId, true));
                    // dispatch(fetchDetailBreadCrumb(entryId, true));
                    // dispatch(push(`/entry/${entryId}`));
                    
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(entryId, error, ENTRY_MOVE_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(entryId, error, ENTRY_MOVE_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};


export const ENTRY_COPY_REQUESTED = 'ENTRY_COPY_REQUESTED';
/**
 * Action for request of entry copy.
 *
 * @return {EntryCopyAction}
 */
export const requestEntryCopy = (entryId) => {
    return {
        type: ENTRY_COPY_REQUESTED,
        entryId: entryId
    };
};

export const ENTRY_COPY_SUCCESSFUL = "ENTRY_COPY_SUCCESSFUL";
/**
 *
 * @return {ReceiveEntryCopyAction}
 */
export const receiveEntryCopySuccessful = (entryId) => {
    return {
        type: ENTRY_COPY_SUCCESSFUL,
        entryId: entryId,
        receivedAt: Date.now()
    }
};

export const ENTRY_COPY_ERROR = 'ENTRY_COPY_ERROR';


/**
 * Copy Entry to another parent folder
 *
 * @return {function(*=, *): Promise<Object | never>}
 */
export const copyEntry = (entryId, entryManipulationDefinition, afterCopyCallback) => {
    return (dispatch, getState) => {
        dispatch(requestEntryCopy(entryId));

        /** @type RequestOptions */
        let options = createBaseOptions();
        options.method = METHOD_POST;
        options.headers["Content-Type"] = "application/json";
        options["body"] = entryManipulationDefinition;

        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/copy`, options, dispatch)
            .then(json => {
                    dispatch(receiveEntryCopySuccessful(entryId));

                    if (!isUndefined(json) && !isUndefined(json.id) && !isUndefined(afterCopyCallback)) {
                        afterCopyCallback(json.id);
                    } else {
                        dispatch(fetchDetail(entryId, true));
                        dispatch(fetchDetailBreadCrumb(entryId, true));
                        dispatch(push(`/entry/${entryId}`));
                        showSuccess(dispatch, "Success", "Entry has been successfully coppied!");
                    }

                    
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(entryId, error, ENTRY_COPY_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(entryId, error, ENTRY_COPY_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};