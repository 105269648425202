import SearchHeaderPresenter from '../../components/header/SearchHeader';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchObjectList} from "../../actions/objectList";

const mapStateToProps = (state, ownProps) => {
    return {
        entryTypesRequestResult: state.objectList,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList());
        },
    }
};

const SearchHeader = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchHeaderPresenter);

export default withRouter(SearchHeader);