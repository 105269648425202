import React from "react";
import { notification } from 'antd';
import {isEmptyObject, isEmptyValue} from "../../../utils/JsObjectHelper";
import PropTypes from "prop-types";
import {entryResultDetailShape} from "../../../shapes/RequestResult";
import { withTranslation} from 'react-i18next'

const openNotification = (notificationKey, noticeDescription, notifyMessage) => {
    notification.warning({
        message: notifyMessage,
        description: noticeDescription,
        key: notificationKey,
        closeIcon: <span style={{display:"none"}}></span>,
        duration: 0
    });
};

const closeNotification = (key) => {
    notification.destroy(key);
};

class EntryEditConcurrency extends React.Component {

    componentWillUnmount() {
        if (this.props.entryRequestResult.getState().isDone()) {
            closeNotification(this.props.entryRequestResult.getData().id);
        }
    }

    render() {
        const {t} = this.props;

        /** @type {RequestResult} */
        const rr = this.props.entryRequestResult;
        if(
            !(isEmptyObject(rr) || rr.getState().isLoading() || isEmptyObject(rr.getData()))
        ) {
            /** @type {BaseEntryWithParent} */
            const entry = rr.getData();

            if (this.props.isEditedBySameUser || !isEmptyValue(this.props.editedByOtherUserName)) {
                openNotification(
                    entry.id,
                    (!isEmptyValue(this.props.editedByOtherUserName) ? t('app.entry.edit.msgOtherUserEdit') + this.props.editedByOtherUserName : t('app.entry.edit.msgSameUserEdit')),
                    t('app.entry.edit.msgconcurrentEdit')
                );
            } else {
                closeNotification(entry.id);
            }
            
            return null;
        } else {
            return null;
        }
    }
}
export default withTranslation() (EntryEditConcurrency);

EntryEditConcurrency.propTypes = {
    entryRequestResult: entryResultDetailShape.isRequired,
    isEditedBySameUser: PropTypes.bool.isRequired,
    editedByOtherUserName: PropTypes.string,
};

