import { fetchJson } from "../../utils/SuperAgentFetch";
import ResponseStatusException from "../../exceptions/ResponseStatusException";
import {config} from "../../config/Config";
import {showError} from "../../utils/NotificationsHelper";

export const ALL_BRANDING_ACTIVE_REQUESTED = "ALL_BRANDING_ACTIVE_REQUESTED";
/**
 * Action for request of entry.
 * 
 * @return {AllBrandingActiveAction}
 */
export const requestAllBrandingActive = () => {
  return {
    type: ALL_BRANDING_ACTIVE_REQUESTED,
  };
};


export const ALL_BRANDING_ACTIVE_RECEIVED = "ALL_BRANDING_ACTIVE_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} allBrandingActive
 * @return {ReceiveAllBrandingActiveAction}
 */
export const receiveAllBrandingActive = (allBrandingActive) => {
  return {
    type: ALL_BRANDING_ACTIVE_RECEIVED,
    allBrandingActive: allBrandingActive,
    receivedAt: Date.now(),
  };
};

export const ALL_BRANDING_ACTIVE_ERROR = "ALL_BRANDING_ACTIVE_ERROR";
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveAllBrandingActiveErrorAction}
 */
export const receiveError = (
  error,
  type = ALL_BRANDING_ACTIVE_ERROR,
  errorResponseStatusCode = null,
  errorResponseMessage = null
) => {
  return {
    type: type,
    error: error,
    errorResponseStatusCode: errorResponseStatusCode,
    errorResponseMessage: errorResponseMessage,
  };
};


/**
 * 
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchAllBrandingActive = (update = false) => {
  return (dispatch, getState) => {
      if (update) {
        dispatch(requestAllBrandingActive());
      } else {
        dispatch(requestAllBrandingActive());
      }

      /** @type RequestOptions options **/
      let options = {
          method: "GET"
      };
    
     return  fetchJson(`${config.url.API_URL}/branding/custom/items/active`, options, dispatch)
     .then(json => {
        let res = [];
        if (json._embedded && json._embedded.brandings && json._embedded.brandings.length > 0 ) {
            res = json._embedded.brandings;
        }
       dispatch(receiveAllBrandingActive(res));
    }
   )  
         .catch(error => {
              if(error === "Ignoring request") {
              } else if(error instanceof ResponseStatusException) {
                  const description = error.response.message ? error.response.message : null;
                  dispatch(receiveError(error, ALL_BRANDING_ACTIVE_ERROR, error.response.status, description));
                  showError(dispatch, error.message, description);
              } else {
                  dispatch(receiveError(error, ALL_BRANDING_ACTIVE_ERROR));
                  showError(dispatch, error.message);
              }
          });
  }
};


