import {createBaseOptions, fetchJson, METHOD_PUT,} from "../../utils/SuperAgentFetch";
import {config} from "../../config/Config";


export const fetchDueDate = (taskId,date,callback) => {
   
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_PUT;
    options.headers["Content-Type"] = "application/json";
    options["body"] = date;
    
   
    let r = fetchJson(`${config.url.API_URL}/bpmn/task/${taskId}`, options) // /async
    r.then(json => {
        console.log('ok');
        callback()   
    })
    .catch(error => {
        console.log(date);
        console.log(error);
    });
    

};