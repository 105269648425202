import React from 'react';
import PropTypes from "prop-types";
import {fileUrlArrayShape} from "../../../shapes/FileUrlArrayShapes";
import FileUrlAddForm from './FileUrlAddForm'
import {Button, Table} from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import { withTranslation} from 'react-i18next';
import { isEmptyValue, isUndefined } from '../../../utils/JsObjectHelper';
import moment from 'moment';

const cloneDeep = require('lodash.clonedeep');

class FileUrlArrayAttribute extends React.Component {
    constructor(props) {
        super(props);
        const value = props.fileUrlArrayData//props.fileUrlArrayData.length || null;
        this.state = {
            // Same name as valuePropName in getFieldDecorator ('value' is default):
            // https://ant.design/components/form/?locale=en-US#getFieldDecorator(id,-options)-parameters
            value
        };

        this.fileAdded = this.fileAdded.bind(this);
    }

    handleChange = (value) => {
        this.setState({ value : value });
        const { onChange } = this.props;
        if (onChange) {
            // This will provide the form with changes
            //onChange({ ...this.state.value, ...value });
            //onChange(Object.assign({}, this.state, value));
            onChange(value);
        }
    };

    fileAdded(fileName, fileUrl) {
        let newFileObj = {name: fileName, url: fileUrl, created: moment(Date.now())};

        let thisAttArray = cloneDeep(this.state.value);
        if(isUndefined(thisAttArray)) {
            thisAttArray = [];
            thisAttArray.push(newFileObj);
        } else {
            thisAttArray.push(newFileObj);
        }
        this.handleChange(thisAttArray);
        //this.props.onFileAdd(this.props.attName, fileName, fileUrl);
    };

    onFileDeleted = (record) => {
        let thisAttArray = cloneDeep(this.state.value);
        if(!isUndefined(thisAttArray)) {
            let datePartRecord = record.created;
            if (datePartRecord instanceof moment) {
                datePartRecord = datePartRecord.valueOf()
            }
            thisAttArray = thisAttArray.filter(f => !(
                f.name === record.name && f.url === record.url && 
                (f.created instanceof moment ? f.created.valueOf() : f.created) === datePartRecord
            ));
            this.handleChange(thisAttArray);
        }
        //this.props.onFileDeleted(this.props.attName, record);
    };

    hideModal = () => {
        this.setState({addModalVisible : false});
    };

    render() {
        const {t} = this.props;

        let columns = [
            {
                title: t('app.entry.attributes.tabFileName'),
                dataIndex: 'name',
                key: 'name',
                //width: 150,
                //fixed: 'left',
                sorter: (a, b) => a.name.localeCompare(b.name.length),
            },
            {
                title: t('app.entry.attributes.tabFileUrl'),
                dataIndex: 'url',
                key: 'url',
                render: (text, record) => (
                    <span>
                        <a href={record.url} target="_blank" rel="noopener noreferrer">
                            {(isEmptyValue(record.url) ? "" : (record.url.length > 50 ? `${record.url.substring(0, 50)}...` : record.url))}
                        </a>
                    </span>
                ),
            },
            {
                title: t('app.entry.attributes.tabFileDate'),
                dataIndex: 'created',
                key: 'created',
                //width: 150,
                render: (text, record) => {
                    let dateValFormatted = text; //use value as fallback option
                    if (!isUndefined(text)) {
                        let dateValue = new Date(text);
                        //Check if date is valid
                        if (dateValue instanceof Date && !isNaN(dateValue)) {
                            if (dateValue.getMinutes() === 0 && dateValue.getHours() === 0) {
                                dateValFormatted = t('date', {date : dateValue});
                            } else {
                                dateValFormatted = t('datetime', {date : dateValue});
                            }
                        }
                    }
                    return <span>
                        {dateValFormatted}
                    </span>;
                }
            }
        ];

        if (this.props.contentType === "image" && !this.props.isEditMode) {
            columns.unshift({
                title: t('app.entry.attributes.tabFileThumbnail'),
                dataIndex: 'url',
                key: 'img',
                render: (text, record) => (
                    <img src={record.url} height="80px" alt={record.name}></img>
                ),
                //fixed: 'left',
            });
        }
        
        if (this.props.isEditMode) {
            columns.push({
                title: '',
                dataIndex: 'action',
                width: '42px',
                render : (text, record) => {
                    return <Button 
                        onClick={() => this.onFileDeleted(record)}
                        icon={<DeleteOutlined />} size="small" type="danger" ghost></Button>;
                }
            });

            return <Table 
                        key="fileTable"
                        columns={columns} 
                        dataSource={this.state.value} 
                        size="small"
                        pagination={false}
                        title={() => <FileUrlAddForm onFileAdd={this.fileAdded}></FileUrlAddForm>}
                        //scroll={{ x: 600}} //, y: 300 
                        rowKey={record => {
                            let datePart = record.created;
                            if (datePart instanceof moment) {
                                datePart = datePart.valueOf()
                            }
                            //console.log(`${record.name}|${record.url}|${datePart}`);
                            return `${record.name}|${record.url}|${datePart}`;
                        }}
                    />;
        } else {
            return <div className="ant-form-item">
                    <div className="ant-form-item-label">
                        <b>{this.props.title}</b>
                    </div>
                    <Table 
                        columns={columns} 
                        dataSource={this.props.fileUrlArrayData} 
                        size="small"
                        pagination={false}
                        rowKey={record => {
                            let datePart = record.created;
                            if (datePart instanceof moment) {
                                datePart = datePart.valueOf()
                            }
                            return `${record.name}|${record.url}|${datePart}`;
                        }}
                    />
                </div>;
        }
    }
}

export default withTranslation() (FileUrlArrayAttribute);

FileUrlArrayAttribute.propTypes = {
    isEditMode: PropTypes.bool.isRequired,
    attName: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
    fileUrlArrayData: PropTypes.arrayOf(fileUrlArrayShape),//.isRequired,
};