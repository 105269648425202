import {createBaseOptions, fetchJson, METHOD_PATCH} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";
import { notificationError } from "../utils/NotificationsHelper";

export const fetchBulkUpdateAttributsEntries = (obj,callback) => {
    
       
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_PATCH;
    options.headers["Content-Type"] = "application/json";
    options["body"] = obj;

    let r = fetchJson(`${config.url.API_URL}/bmcEntries/custom/items`, options);
    r.then(json => {
        console.log(json);
        callback(json);
       
    }).catch(error => {
        console.log(error);
        notificationError("Error",error)
    });
};



