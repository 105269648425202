import HistoryEntryPresenter from '../../../components/pluginPage/widgets/HistoryEntry';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { fetchObjectList } from '../../../actions/objectList'; 




const mapStateToProps = (state, ownProps) => {
    return {
        entryTypesRequestResult: state.objectList
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList());        
        },
    }
};

const HistoryEntry = connect(
    mapStateToProps,
    mapDispatchToProps
)(HistoryEntryPresenter);

export default withRouter(HistoryEntry);
