import {LOGIN_REQUESTED, LOGOUT_REQUESTED, SUCCESSFUL_LOGIN, SUCCESSFUL_UPDATE_TOKEN} from "../actions/loggedUser";
import * as initials from "initials";
import Cookies from 'universal-cookie';
import {getBaseDomainName} from "../utils/UrlHelper";


const EMPTY_LOGGED_USER = {loginRequested: false, token: null, refreshToken: null, tokenParsed: null};

const cookies = new Cookies();



/**
 *
 * @param state
 * @param {BaseAction|ReceiveErrorAction} action
 * @return Object
 */
export const loggedUser = (state = EMPTY_LOGGED_USER, action)  => {


    /**
     * options (object): Support all the cookie options from RFC 6265
     *   path (string): cookie path, use / as the path if you want your cookie to be accessible on all pages
     *   expires (Date): absolute expiration date for the cookie
     *   maxAge (number): relative max age of the cookie from when the client receives it in seconds
     *   domain (string): domain for the cookie (sub.domain.com or .allsubdomains.com)
     *   secure (boolean): Is only accessible through HTTPS?
     *   httpOnly (boolean): Is only the server can access the cookie?
     *   sameSite (boolean|none|lax|strict): Strict or Lax enforcement
     */
    let cookieProps = { path: '/', domain: getBaseDomainName(), sameSite: 'none', secure: true};

    switch (action.type) {
        case LOGIN_REQUESTED:
            sessionStorage.setItem("kcToken", null);
            cookies.set("kcToken", null, cookieProps);
            return Object.assign({}, EMPTY_LOGGED_USER, { loginRequested: true });
        case LOGOUT_REQUESTED:
            sessionStorage.setItem("kcToken", null);
            cookies.set("kcToken", null, cookieProps);
            return Object.assign({}, EMPTY_LOGGED_USER);
        case SUCCESSFUL_LOGIN:
        case SUCCESSFUL_UPDATE_TOKEN:
            if(state.token !== action.data.token || state.refreshToken !== action.data.refreshToken) {
                sessionStorage.setItem("kcToken", action.data.token);
                cookies.set("kcToken", action.data.token,  cookieProps);
                let userData = {};
                if(action.data.tokenParsed) {
                    /** @type {LoggedUser} */
                    let user = {};
                    user.firstname = action.data.tokenParsed.given_name;
                    user.surname = action.data.tokenParsed.family_name;
                    user.name = action.data.tokenParsed.name;
                    user.username = action.data.tokenParsed.user_name;
                    user.initials = initials(user.name);
                    user.id = action.data.tokenParsed.sub;
                    user.groupsDetails = action.data.tokenParsed.groupsDetails;
                    user.realmRoles = action.data.tokenParsed.realm_access.roles;
                    userData.user = user;
                }
                return Object.assign({}, EMPTY_LOGGED_USER, { loginRequested: false }, action.data, userData);
            }
            return state;
        default:
            return state
    }
};