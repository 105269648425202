import { fetchJson } from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const BPMN_FORM_VARIABLES_REQUESTED = "BPMN_FORM_VARIABLES_REQUESTED";
/**
 * Action for request of entry.
 *
 * @return {BpmnFormVariablesAction}
 */
export const requestBpmnFormVariables = () => {
  return {
    type: BPMN_FORM_VARIABLES_REQUESTED,
    
  };
};


export const BPMN_FORM_VARIABLES_RECEIVED = "BPMN_FORM_VARIABLES_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} bpmnFormVariables
 * @return {ReceiveBpmnFormVariablesAction}
 */
export const receiveBpmnFormVariables  = (bpmnFormVariables) => {
  return {
    type: BPMN_FORM_VARIABLES_RECEIVED,
    bpmnFormVariables: bpmnFormVariables,
    receivedAt: Date.now(),
  };
};

export const BPMN_FORM_VARIABLES_ERROR = "BPMN_FORM_VARIABLES_ERROR";
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveBpmnFormErrorAction}
 */
export const receiveError = (
  error,
  type = BPMN_FORM_VARIABLES_ERROR,
  errorResponseStatusCode = null,
  errorResponseMessage = null
) => {
  return {
    type: type,
    error: error,
    errorResponseStatusCode: errorResponseStatusCode,
    errorResponseMessage: errorResponseMessage,
  };
};


/**
 * 
 * @param {String} taskId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchBpmnFormVariables  = (taskId, update = false) => {
  return (dispatch, getState) => {
      if (update) {
        dispatch(requestBpmnFormVariables(taskId));
      } else {
        dispatch(requestBpmnFormVariables(taskId));
      }

      /** @type RequestOptions options **/
      let options = {
          method: "GET"
      };
    
 
     return  fetchJson(`${config.url.API_URL}/bpmn/task/${taskId}/formVariables?deserializeValues=false`, options, dispatch)
     .then(json => {
        let res = [];
        res = json
       dispatch(receiveBpmnFormVariables(res));
    }
   )  
         .catch(error => {
              if(error === "Ignoring request") {
              } else if(error instanceof ResponseStatusException) {
                  const description = error.response.message ? error.response.message : null;
                 // dispatch(receiveError(error, BPMN_FORM_VARIABLES_ERROR, error.response.status, description));
                 // showError(dispatch, error.message, description);
                 console.log(error);
              } else {
                  //dispatch(receiveError(error, BPMN_FORM_VARIABLES_ERROR));
                  //showError(dispatch, error.message);
                  console.log(error);
              }
          });
  }
};


