import React from 'react';
import { isArray, isEmptyObject, mergeDeep } from "../../../utils/JsObjectHelper";
import PropTypes from "prop-types";
import update from 'immutability-helper';
import { entryTypesRequestResult, settingResultDetailShape } from "../../../shapes/RequestResult";
import WorkflowDetailForm from "./WorkflowDetailForm"
import { withTranslation } from 'react-i18next'
import { withRouter } from "react-router-dom";
import { Row, Col, PageHeader, Button, Modal, Card } from 'antd';
import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { EMPTY_SETTINGS, EMPTY_SETTINGS_WORKFLOW } from '../../../utils/EmptySettings';

const cloneDeep = require('lodash.clonedeep');

class WorkflowDetail extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.onMount = this.onMount.bind(this);
        this.onHandleSubmit = this.onHandleSubmit.bind(this);

        let initFormaData = cloneDeep(EMPTY_SETTINGS);
        initFormaData.value = cloneDeep(EMPTY_SETTINGS_WORKFLOW);

        this.state = {
            formData: initFormaData,
            showAddNewHierarchy: false,
        };
    }

    componentDidMount() {
        this.onMount();
    };

    /**
     * 
     * @param {*} prevProps 
     * @param {*} prevState 
     */
    componentDidUpdate(prevProps, prevState) {
        if (!isEmptyObject(this.props.settingsDetailData) && isEmptyObject(prevProps.settingsDetailData)) {
            let v = cloneDeep(this.props.settingsDetailData);
            v.value = mergeDeep(cloneDeep(EMPTY_SETTINGS_WORKFLOW), v.value)

            this.setState({ formData: v });
        }
    }

    onMount() {
        this.props.onMount(this.props.match.params.setupDetailId);
    };

    componentWillUnmount() {
        this.props.onUnmount();
    }

    onHandleFormChange = (formInputs) => {
        formInputs.forEach((field) => {
            let fieldName = (isArray(field.name) ? field.name.at(-1) : field.name);
            let fieldValue = field.value;
            if (fieldName === 'objectType') {
                this.setState((prevState, props) => {
                    return update(prevState, {
                        formData: {
                            value: {
                                [fieldName]: { $set: fieldValue },
                                entryTypes: { $set: [] },
                                staticMapping: { $set: [] },
                                workflowUseType:{$set: "onRunByUser"},
                                dynamicMapping: { $set: [] }
                            }
                        }
                    });
                }
                );
            }
            else {
                this.setState((prevState, props) => {
                    return update(prevState, {
                        formData: {
                            value: {
                                [fieldName]: { $set: fieldValue }
                            }
                        }
                    });
                }
                );
            }

        });
    };

    onHandleSubmit = () => {
        this.formRef.current.validateFields()
            .then(values => {
                console.log('==============then======================');
                this.props.onSaveSettings(this.state.formData);
            })
            .catch(errorInfo => {
                console.log('===============catch======================');
                console.log(errorInfo);
                return;
            });
    };

    render() {
        const { t } = this.props;

        let info = () => {
            Modal.info({
                title: t('setup.workflowSetup.infoTitleDetail'),
                content: (
                    <div>
                        {t('setup.workflowSetup.infoContentDetail')}
                    </div>
                ),
                onOk() {
                },
            });
        };

        /** @type {RequestResult} */
        const rr = this.props.settingsDetailRequestResult;
        if (isEmptyObject(rr) || rr.getState().isLoading())
            return <div>{t('app.entry.header.msgLoading')}</div>;
        else if (isEmptyObject(rr.getData()))
            return <div>{t('app.entry.header.msgEntryNonFound')}</div>;
        else {

            return <div>
                <PageHeader
                    title={t('setup.workflowSetup.headerDetail')}
                    subTitle={t('setup.workflowSetup.headerDetailLabel')}
                    extra={[
                        <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                        </Button>,
                    ]}
                >
                </PageHeader>
                <div className="steps-content">
                    <Row align="top" gutter={[30, 20]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Card>
                                <WorkflowDetailForm
                                    workflowValueData={this.state.formData.value}
                                    extFormRef={this.formRef}
                                    onAttributeChange={this.onHandleFormChange}
                                    objectListRequestResult={this.props.objectListRequestResult}
                                    groupListRequestResult={this.props.groupListRequestResult}
                                    workflowsRequestResult={this.props.workflowsRequestResult}
                                    workflowSettingsList={this.props.workflowSettingsList}
                                >
                                </WorkflowDetailForm>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Row className="steps-action" justify="end" type="flex">
                    <Col>
                        <Button icon={<SaveOutlined />} type="primary" onClick={this.onHandleSubmit}>
                            {this.props.isCreateNew ? t('setup.workflowSetup.detailBtnCreate') : t('setup.workflowSetup.detailBtnSave')}
                        </Button>
                    </Col>
                </Row>
            </div>;
        }
    }
}

const WorkflowDetailWithRouter = withRouter(WorkflowDetail);
export default withTranslation()(WorkflowDetailWithRouter);

WorkflowDetail.propTypes = {
    settingsDetailRequestResult: settingResultDetailShape.isRequired,
    //settingsDetailData: PropTypes.arrayOf(Object),
    objectListRequestResult: entryTypesRequestResult.isRequired,
    onSaveSettings: PropTypes.func.isRequired

};