import React from "react";
import { Comment, Tooltip, Avatar, Button, Input, Spin, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { LikeFilled, DislikeFilled, LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import { withTranslation} from 'react-i18next'
import { isEmptyValue } from "../../../utils/JsObjectHelper";

const cloneDeep = require('lodash.clonedeep');

const { TextArea } = Input;

const Editor = ({ onChange, onSubmit, onCancel, submitting, value, translator }) => (
    <div>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                {translator('app.entry.tools.comments.btnAddComment')}
            </Button>
            <Button htmlType="submit" onClick={onCancel} type="dashed" shape="circle" icon={<CloseOutlined />} style={{marginLeft:"10px"}}>
            </Button>
        </Form.Item>
    </div>
);

class EntryComments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isCommentCreating: false,
            commentCreatingId: null,
            commentValue: null,
            commentSaving: false,
            fallbackCommentArray: [],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.hideCommentEditor = this.hideCommentEditor.bind(this);
        this.generateCommentLevel = this.generateCommentLevel.bind(this);
    }

    componentDidMount() {
        this.props.onMount(this.props.entry.id);
    }

    /**
     * UNSAFE and deprecated lifecycle method!
     */
    /*UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.hideCommentEditor();
    }*/
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.entryCommentListRequestResult.getState() !== this.props.entryCommentListRequestResult.getState()) {
            //After update hide comment editor. Update occures after comment creation
            if(this.props.entryCommentListRequestResult.getState().isUpdating()) {
                this.hideCommentEditor();
            }
        }
    }

    handleSubmit = () => {
        if (!this.state.commentValue || !this.state.isCommentCreating) {
            return;
        }

        this.setState({
            commentSaving: true,
            fallbackCommentArray : cloneDeep(this.props.commentListData),
        });

        this.props.onCommentCreate(
            this.props.entry.id, //entryId
            this.state.commentCreatingId, //parentId
            this.props.loggedUser.user.id, //authorId
            this.props.loggedUser.user.name, //authorName
            this.state.commentValue //content
            );
    };

    hideCommentEditor = () => {
        this.setState({
            isCommentCreating: false,
            commentSaving: false,
            commentValue: null,
            commentCreatingId: null,
        });
    };

    handleCancel = () => {
        this.hideCommentEditor();
    };

    handleChange = e => {
        this.setState({
            commentValue: e.target.value,
        });
    };

    handleAddComment = (parentCommentId) => {
        this.setState({
            isCommentCreating: true,
            commentCreatingId: parentCommentId,
        });
    };

    generateCommentLevel = (data, translator) => {
        let commentRendered = [];

        //generate comments
        data.forEach(element => {
            let subContent = null;

            if (!isEmptyValue(element.subComments)) {
                subContent = this.generateCommentLevel(element.subComments, translator);
            }
            
            if (this.state.commentCreatingId === element.id) {

                if (isEmptyValue(subContent)) {
                    subContent = [];
                }

                subContent.unshift(
                    <Editor
                        key="commentEditor"
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit}
                        onCancel={this.handleCancel}
                        submitting={this.state.commentSaving}
                        value={this.state.commentValue}
                        translator={translator}
                    />
                );
            }

            let likeIcon = element.selectedAction === 'liked' ? <LikeFilled /> : <LikeOutlined />;
            let dislikeIcon = element.selectedAction === 'disliked' ? <DislikeFilled /> : <DislikeOutlined />;

            let addComment = () => {
                this.handleAddComment(element.id);
            }

            const actions = [
                <Tooltip key="comment-basic-like" title="Like">
                    <span>
                        {likeIcon}
                        <span className="comment-action">{element.likes}</span>
                    </span>
                </Tooltip>,
                <Tooltip key="comment-basic-dislike" title="Dislike">
                    <span>
                        {dislikeIcon}
                        <span className="comment-action">{element.dislikes}</span>
                    </span>
                </Tooltip>,
                <span key="comment-basic-reply-to" onClick={addComment}>{translator('app.entry.tools.comments.btnReplyTo')}</span>,
            ];

            commentRendered.push(
                <Comment
                    key={element.id}
                    actions={actions}
                    author={<a  href={"/id2entry/" + element.authorid} target="_blank" style={{color:"#40a9ff"}}>{element.author}</a>}
                    avatar={
                        <Avatar
                            style={{ backgroundColor: '#223b6f'}}
                            alt={element.author}
                        >
                            {element.authorInitials}
                        </Avatar>
                    }
                    content={
                        <p>{element.content}</p>
                    }
                    datetime={
                        <Tooltip title={translator('datetime', {date : new Date(element.created)})}>
                            <span>{translator('dateFromNow', {date : new Date(element.created)})}</span>
                        </Tooltip>
                    }
                >
                    {subContent}
                </Comment>
            );
        });

        return commentRendered;
    };

    render() {
        let comments = null;
        let newCommentPanel = null;

        const {t} = this.props;
        
        //Dont show spinner if comments are only updating
        let showSpinner = !this.props.entryCommentListRequestResult.getState().isDone() && !this.props.entryCommentListRequestResult.getState().isUpdating();

        if (this.props.commentListData.length > 0 || this.state.fallbackCommentArray.length > 0) {
            comments = this.generateCommentLevel((this.props.commentListData.length > 0 ? this.props.commentListData : this.state.fallbackCommentArray), t);
        } else {
            
        }

        if (!showSpinner) {
            if (!this.state.isCommentCreating) {
                let addCommentNew = () => {
                    this.handleAddComment(null);
                }
                newCommentPanel = <Button onClick={addCommentNew} type="primary">
                                {t('app.entry.tools.comments.btnNewThread')}
                            </Button>
            } else {
                if (isEmptyValue(this.state.commentCreatingId)) {
                    newCommentPanel = <Editor
                                    key="commentEditor"
                                    onChange={this.handleChange}
                                    onSubmit={this.handleSubmit}
                                    onCancel={this.handleCancel}
                                    submitting={this.state.commentSaving}
                                    value={this.state.commentValue}
                                    translator={t}
                                />
                }
            }
        }

        return (
            <Spin spinning={showSpinner} size="large">
                <div>
                    {newCommentPanel}
                </div>
                <div>
                    {comments}
                </div>
            </Spin>
        );
    }
}

export default withTranslation() (EntryComments);