import {
  createBaseOptions,
  fetchJson,
  METHOD_POST,
} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import { config } from "../config/Config";
import { showError } from "../utils/NotificationsHelper";

export const COMPLETE_TASK_REQUESTED = "COMPLETE_TASK_REQUESTED";
/**
 * Action for request of entry.
 *
 * @return {CompleteTaskAction}
 */
export const requestCompleteTask = () => {
  return {
    type: COMPLETE_TASK_REQUESTED,
  };
};

export const COMPLETE_TASK_RECEIVED = "COMPLETE_TASK_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} completeTask
 * @return {ReceiveCompleteTaskAction}
 */
export const receiveCompleteTask = (completeTask) => {
  return {
    type: COMPLETE_TASK_RECEIVED,
    completeTask: completeTask,
    receivedAt: Date.now(),
  };
};

export const COMPLETE_TASK_ERROR = "COMPLETE_TASK_ERROR";
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveCompleteTaskTaskErrorAction}
 */
export const receiveError = (
  error,
  type = COMPLETE_TASK_ERROR,
  errorResponseStatusCode = null,
  errorResponseMessage = null
) => {
  return {
    type: type,
    error: error,
    errorResponseStatusCode: errorResponseStatusCode,
    errorResponseMessage: errorResponseMessage,
  };
};

/**
 *
 * @param {String} taskId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */

export const fetchComplete = (taskId, update = false, variables, callback) => {
  return (dispatch, getState) => {
    let variabl = {
      variables,
      withVariablesInReturn: true,
    };

    let options = createBaseOptions();
    options.method = METHOD_POST;
    options.headers["Content-Type"] = "application/json";
    options["body"] = variabl;

    return fetchJson( `${config.url.API_URL}/bpmn/task/${taskId}/complete`,  options, dispatch )
      .then((json) => {
        let res = [];
        res = json;
        if (callback) {
          callback();
        }
        dispatch(receiveCompleteTask(res));
      })
      .catch((error) => {
        if (error === "Ignoring request") {
        } else if (error instanceof ResponseStatusException) {
          const description = error.response.message
            ? error.response.message
            : null;
          dispatch(
            receiveError(
              taskId,
              error,
              COMPLETE_TASK_ERROR,
              error.response.status,
              description
            )
          );
          showError(dispatch, error.message, description);
        } else {
          dispatch(receiveError(error, COMPLETE_TASK_ERROR));
          showError(dispatch, error.message);
        }
      });
  };
};
