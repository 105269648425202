import {
    LOAD_SETTINGS_LOAD_NEW,
    LOAD_SETTINGS_DETAIL_REQUESTED,
    LOAD_SETTINGS_DETAIL_UPDATE_REQUESTED,
    LOAD_SETTINGS_DETAIL_RECEIVED,
    LOAD_SETTINGS_DETAIL_UNMOUNT,
    LOAD_SETTINGS_DETAIL_ERROR, LOAD_SETTINGS_SAVE_ERROR, LOAD_SETTINGS_DELETE_ERROR
} from '../actions/loadSettings.js';
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";
import {EMPTY_LOAD_SETTINGS} from "../utils/EmptyLoadSettings";

/**
 *
 * @param {RequestResult} state
 * @param {BaseAction|ReceiveLoadSettingsAction|ReceiveLoadSettingsErrorAction|LoadSettingsEditAction|LoadSettingsNewAction|ItemIdAction} action
 * @return {RequestResult}
 */
export const loadSettingsDetail = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

    switch (action.type) {
        case LOAD_SETTINGS_DETAIL_REQUESTED:
            return new RequestResult(RequestState.getInstanceLoading(), null);
        case LOAD_SETTINGS_LOAD_NEW:
            return new RequestResult(RequestState.getInstanceDone(), EMPTY_LOAD_SETTINGS);
        case LOAD_SETTINGS_DETAIL_UNMOUNT:
            return new RequestResult(RequestState.getInstanceInit(), null);
        case LOAD_SETTINGS_DETAIL_UPDATE_REQUESTED:
            return new RequestResult(RequestState.getInstanceUpdating(), state.data);
        case LOAD_SETTINGS_DETAIL_RECEIVED:
            return new RequestResult(RequestState.getInstanceDone(), action.item, action.receivedAt);
        case LOAD_SETTINGS_DETAIL_ERROR:
        case LOAD_SETTINGS_SAVE_ERROR:
        case LOAD_SETTINGS_DELETE_ERROR:
            return new RequestResult(
                RequestState.getInstanceError(
                    action.errorResponseStatusCode,
                    action.errorResponseMessage ? action.errorResponseMessage : null
                ),
                state.getData()
            );
        default:
            return state
    }
};
