import PropTypes from "prop-types";

export const baseObjectDefinitionShape = PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    properties: PropTypes.object,
    inserted: PropTypes.string,
    updated: PropTypes.string,
    deleted: PropTypes.string
});