import {createBaseOptions, fetchJson, METHOD_POST, METHOD_PUT} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError, showSuccess} from "../utils/NotificationsHelper";
import { push } from 'connected-react-router'

export const OBJECT_DEFINITION_LOAD_NEW = 'OBJECT_DEFINITION_LOAD_NEW';
/**
 * Action for request new item.
 *
 * @return {BaseAction}
 */
export const requestEmptyNewObjectDefinition = () => {
    return {
        type: OBJECT_DEFINITION_LOAD_NEW,
    };
};

export const OBJECT_DEFINITION_DETAIL_REQUESTED = 'OBJECT_DEFINITION_DETAIL_REQUESTED';
/**
 * Action for request of item.
 *
 * @param {String} itemId
 * @return {ItemIdAction}
 */
export const requestObjectDefinitionDetail = (itemId) => {
    return {
        type: OBJECT_DEFINITION_DETAIL_REQUESTED,
        itemId: itemId
    };
};

export const OBJECT_DEFINITION_DETAIL_UPDATE_REQUESTED = "OBJECT_DEFINITION_DETAIL_UPDATE_REQUESTED";
/**
 * Action for request of entry.
 *
 * @param {String} itemId
 * @return {ItemIdAction}
 */
export const requestObjectDefinitionDetailUpdate = (itemId) => {
    return {
        type: OBJECT_DEFINITION_DETAIL_UPDATE_REQUESTED,
        itemId: itemId
    }
};


export const OBJECT_DEFINITION_SUCCESSFULLY_SAVED = "OBJECT_DEFINITION_SUCCESSFULLY_SAVED";
/**
 * Action for successfully saved of an item.
 *
 * @param {String} itemId
 * @return {ItemIdAction}
 */
export const receiveEntrySuccessfullySaved = (itemId) => {
    return {
        type: OBJECT_DEFINITION_SUCCESSFULLY_SAVED,
        itemId: itemId
    }
};

export const OBJECT_DEFINITION_DETAIL_RECEIVED = "OBJECT_DEFINITION_DETAIL_RECEIVED";
/**
 *
 * @param {String} itemId
 * @param {BaseObjectDefinition} item
 * @return {ReceiveObjectDefinitionAction}
 */
export const receiveObjectDefinitionDetail = (itemId, item) => {
    return {
        type: OBJECT_DEFINITION_DETAIL_RECEIVED,
        item: item,
        itemId: itemId,
        receivedAt: Date.now()
    }
};

export const OBJECT_DEFINITION_DETAIL_UNMOUNT = "OBJECT_DEFINITION_DETAIL_UNMOUNT";
/**
 * action unmount detail
 * @return {BaseAction}
 */
export const unmountDetail = () => {
    return {
        type: OBJECT_DEFINITION_DETAIL_UNMOUNT
    }
};

export const OBJECT_DEFINITION_DETAIL_ERROR = 'OBJECT_DEFINITION_DETAIL_ERROR';
export const OBJECT_DEFINITION_SAVE_ERROR = 'OBJECT_DEFINITION_SAVE_ERROR';
export const OBJECT_DEFINITION_DELETE_ERROR = 'OBJECT_DEFINITION_DELETE_ERROR';
/**
 *
 * @param {String} itemId
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveEntryErrorAction}
 */
export const receiveError = (
    itemId,
    error,
    type = OBJECT_DEFINITION_DETAIL_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        itemId: itemId,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};

export const OBJECT_DEFINITION_DETAIL_EDIT_REQUESTED = "OBJECT_DEFINITION_DETAIL_EDIT_REQUESTED";
/**
 * Action for update item.
 *
 * @param {String} itemId
 * @param {BaseObjectDefinition} item
 * @return {EntryEditAction}
 */
export const requestObjectDefinitionDetailEdit = (itemId, item) => {
    return {
        type: OBJECT_DEFINITION_DETAIL_EDIT_REQUESTED,
        itemId: itemId,
        item: item,
    }
};

export const OBJECT_DEFINITION_DETAIL_NEW_REQUESTED = "OBJECT_DEFINITION_DETAIL_NEW_REQUESTED";
/**
 * Action for create new item.
 *
 * @param {BaseObjectDefinition} item
 * @return {EntryEditAction}
 */
export const requestObjectDefinitionDetailNew = (item) => {
    return {
        type: OBJECT_DEFINITION_DETAIL_NEW_REQUESTED,
        item: item,
    }
};

/**
 * Fetch item detail
 *
 * @param {String} itemId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchDetail = (itemId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            dispatch(requestObjectDefinitionDetailUpdate(itemId));
        } else {
            dispatch(requestObjectDefinitionDetail(itemId));
        }

        return fetchJson(`${config.url.API_URL}/objectDefinitions/${itemId}`, null, dispatch)
            .then(json => {
                    //json['productID'] = productID;
                    dispatch(receiveObjectDefinitionDetail(itemId, json));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(itemId, error, OBJECT_DEFINITION_DETAIL_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(itemId, error, OBJECT_DEFINITION_DETAIL_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};


/**
 * Save item detail
 *
 * @param {String} itemId
 * @param {BaseObjectDefinition} item
 * @return {function(*=, *): Promise<Object | never>}
 */
export const saveEditedObjectDefinition = (itemId, item) => {
    return (dispatch, getState) => {
        dispatch(requestObjectDefinitionDetailEdit(itemId, item));
        /** @type RequestOptions */
        let options = createBaseOptions();
        //TODO PATCH or PUT ???
        options.method = METHOD_PUT;
        options.headers["Content-Type"] = "application/json";
        item.updated = new Date().toISOString();
        options["body"] = item;

        return fetchJson(`${config.url.API_URL}/objectDefinitions/${itemId}`, options, dispatch)
            .then(json => {
                    dispatch(receiveEntrySuccessfullySaved(itemId));
                    dispatch(receiveObjectDefinitionDetail(itemId, json));
                    // TODO push to detail??
                    //dispatch(push(`/setup/contentedit/${itemId}`));
                    //Go back to list
                    dispatch(push(`/setup/content`));
                    showSuccess(dispatch, "Success", "Object definition has been successfully updated!");
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(itemId, error, OBJECT_DEFINITION_SAVE_ERROR, error.response.status, description));
                } else {
                    dispatch(receiveError(itemId, error, OBJECT_DEFINITION_SAVE_ERROR));
                }
                showError(dispatch, "Error", "Object definition couldn't be updated!<br />Contact aphinit administrator.");
            });
    }
};

/**
 * Save new item
 *
 * @param {BaseObjectDefinition} item
 * @return {function(*=, *): Promise<Object | never>}
 */
export const saveNewObjectDefinition = (item) => {
    return (dispatch, getState) => {
        dispatch(requestObjectDefinitionDetailNew(item));
        /** @type RequestOptions */
        let options = createBaseOptions();
        options.method = METHOD_POST;
        options.headers["Content-Type"] = "application/json";
        item.inserted = new Date().toISOString();
        options["body"] = item;

        return fetchJson(`${config.url.API_URL}/objectDefinitions`, options, dispatch)
            .then(json => {
                    //json['productID'] = productID;
                    dispatch(receiveEntrySuccessfullySaved(json.id));
                    dispatch(receiveObjectDefinitionDetail(json.id, json));
                    // TODO push to detail??
                    //dispatch(push(`/setup/contentedit/${json.id}`));
                    //Go back to list
                    dispatch(push(`/setup/content`));
                    showSuccess(dispatch, "Success", "Object definition has been successfully inserted!");
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(null, error, OBJECT_DEFINITION_SAVE_ERROR, error.response.status, description));
                } else {
                    dispatch(receiveError(null, error, OBJECT_DEFINITION_SAVE_ERROR));
                }
                showError(dispatch, "Error", "Object definition couldn't be inserted!<br />Contact aphinit administrator.");
            });
    }
};

/**
 * Get new empty item
 *
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchEmptyNewObjectDefinition = () => {
    return (dispatch, getState) => {
        dispatch(requestEmptyNewObjectDefinition());
    };
};

export const OBJECT_DEFINITION_DELETE_REQUESTED = 'OBJECT_DEFINITION_DELETE_REQUESTED';
/**
 * Action for delete of entry.
 *
 * @param {String} itemId
 * @return {ItemIdAction}
 */
export const requestEntryDelete = (itemId) => {
    return {
        type: OBJECT_DEFINITION_DELETE_REQUESTED,
        itemId: itemId
    };
};

export const OBJECT_DEFINITION_SUCCESSFULLY_DELETED = "OBJECT_DEFINITION_SUCCESSFULLY_DELETED";
/**
 * Action successfully deleted entry.
 *
 * @param {String} itemId
 * @return {ItemIdAction}
 */
export const receiveEntrySuccessfullyDeleted = (itemId) => {
    return {
        type: OBJECT_DEFINITION_SUCCESSFULLY_DELETED,
        itemId: itemId
    }
};

/**
 * Delete entry
 *
 * @param {String} itemId
 * @return {function(*=, *): Promise<Object | never>}
 */
// export const deleteEntry = (itemId) => {
//     return (dispatch, getState) => {
//
//         dispatch(requestEntryDelete(itemId));
//
//         let options = createBaseOptions();
//         options.method = METHOD_DELETE;
//
//         return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${itemId}`, options, dispatch)
//             .then(json => {
//                     //json['productID'] = productID;
//                     dispatch(receiveEntrySuccessfullyDeleted(itemId));
//                     dispatch(receiveObjectDefinitionDetail(json.id, json));
//                     dispatch(push(`/entry/${json.parent.id}`));
//                     showSuccess(dispatch, "Success", "Entry has been successfully deleted!");
//                 }
//             ).catch(error => {
//                 if(error instanceof ResponseStatusException) {
//                     const description = error.response.message ? error.response.message : null;
//                     dispatch(receiveError(itemId, error, OBJECT_DEFINITION_DELETE_ERROR, error.response.status, description));
//                     showError(dispatch, error.message, description);
//                 } else {
//                     dispatch(receiveError(itemId, error, OBJECT_DEFINITION_DELETE_ERROR));
//                     showError(dispatch, error.message);
//                 }
//             });
//     }
// };