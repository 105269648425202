import React from "react";
import "antd/dist/antd.css";
import {
    Drawer,
    Button,
    Space,
    Table,
    Input,
    Radio,
    Typography 

} from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { getColumnCustomAttributeProps } from "../../../utils/TableHelper";
const { Text } = Typography;


class MyTasks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newTitle: [],
            setup: this.props.settings,
        };
    }
    componentDidMount() {
        this.props.onMount();
    }
    saveSettings = () => {
        this.props.newSettings(this.props.index, this.state.setup);
        this.props.closeSettings(this.props.index);
    };
    
    onChange = (e) => {
        this.setState({
            setup: e.target.value
            })
        //  let obj = { title: e.target.value }
        // this.setState({
        //     newTitle: obj
        // })
    };


    render() {
        const { t } = this.props;
        let myTaskListData = this.props.myTasks.getState().isLoading() ? [] : this.props.myTasks.getData();
        const columns = [
            {
                title: t('setup.usersMapping.tblAction'),
                key: "action",
                render: (text, record) => {
                    return (<span>
                        <a href={"/bpmn/" + record.id}><PlayCircleOutlined /> {t("app.userDashboard.myTasks.tblBtnShow")}</a>
                    </span>)
                }
            },
            {
                title: t("app.bpmn.titleName"),
                dataIndex: "name",
                key: "name",
            },
            {
                ...getColumnCustomAttributeProps(
                    "created",
                    t("app.bpmn.titleCreated"),
                    "date4human",
                    t
                ),
                sortOrder: this.props.settings === 'created' ? 'descend' : null,
            },
            {
                ...getColumnCustomAttributeProps(
                    "due",
                    t("app.bpmn.titleDueDate"),
                    "date4human",
                    t
                ),
                onFilter: (value, record) => record.due.indexOf(value) === 0,
                sortOrder: this.props.settings === 'dueDate' ? 'descend' : null,
            },
            {
                title: t("app.bpmn.titlePriority"),
                dataIndex: "priority",
                key: "priority",
                width: "10%",
                sorter: (a, b) => a.priority - b.priority,
                sortOrder: this.props.settings === 'priority' ? 'descend' : null,
            },
        ];


        let drawer =
            <Drawer
                height={'60%'}
                placement="top"
                closable={false}
                onClose={() => this.props.closeSettings(this.props.index)}
                size='small'
                getContainer={false}
                visible={this.props.showSettings}
                footer={
                    <Space>
                        <Button onClick={() => this.props.closeSettings(this.props.index)}>{t("app.userDashboard.myTasks.btnCancel")}</Button>
                        <Button type="primary" onClick={this.saveSettings}>
                            {t("app.userDashboard.myTasks.btnOk")}
                        </Button>
                    </Space>
                }
                footerStyle={{ textAlign: 'right' }}
                style={{
                    position: 'absolute',
                }}>
                    <Space>
                    <Text>{t("app.userDashboard.myTasks.labelForSetup")}</Text>
                <Radio.Group onChange={this.onChange} value={this.state.setup}>
                    <Radio value={"created"}>{t("app.userDashboard.myTasks.radioCreated")}</Radio>
                    <Radio value={"dueDate"}> {t("app.userDashboard.myTasks.radioDueDate")}</Radio>
                    <Radio value={"priority"}> {t("app.userDashboard.myTasks.radioPriority")}</Radio>
                </Radio.Group>
                </Space>
                {/* <Input showCount maxLength={20} onChange={this.onChange} /> */}

            </Drawer>

        let table = <Table
            columns={columns}
            dataSource={myTaskListData}
            size="small"
            rowKey="id"
            loading={this.props.myTasks
                .getState()
                .isLoading()}
        />
        return (
            <div className="site-drawer-render-in-current-wrapper">
                {drawer}
                {table}
            </div>

        )
    }
}
const MyTasksRouter = withRouter(MyTasks);

export default withTranslation()(MyTasksRouter);
