import EntryEditConcurrencyPresenter from '../../components/detail/edit/EntryEditConcurrency';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {isEmptyObject, isEmptyValue} from "../../utils/JsObjectHelper";


/**
 *
 * @param {ClientInfo} clientInfo
 * @param {Map<String, CacheEditedEntry>} editedEntries
 * @param {String} entryId
 * @returns {boolean}
 */
const isEditedBySameUser = (clientInfo, editedEntries, entryId) => {
    if(!isEmptyObject(clientInfo)) {
        let editedEntry = getEditedEntry(editedEntries, entryId);
        if(editedEntry != null) {
            for (const editor of editedEntry.editors) {
                if (editor.sessionId === clientInfo.sessionId && editor.username === clientInfo.username) {
                    // it's edited in actual window
                } else if (editor.username === clientInfo.username) {
                    // it's edited in other window (another session id)
                    return true;
                }
            }
        }
    }
    return false;
}

/**
 *
 * @param {ClientInfo} clientInfo
 * @param {Map<String, CacheEditedEntry>} editedEntries
 * @param {String} entryId
 * @returns {String|null}
 */
const editedByOtherUserName = (clientInfo, editedEntries, entryId) => {
    if(!isEmptyObject(clientInfo)) {
        let editedEntry = getEditedEntry(editedEntries, entryId);
        if(editedEntry != null) {
            for (const editor of editedEntry.editors) {
                if (editor.sessionId !== clientInfo.sessionId && editor.username !== clientInfo.username) {
                    return editor.username;
                }
            }
        }
    }
    return null;
}

/**
 *
 * @param {Map<String, CacheEditedEntry>} editedEntries
 * @param {String} entryId
 * @returns {CacheEditedEntry|null}
 */
const getEditedEntry = (editedEntries, entryId) => {
    if(!isEmptyObject(editedEntries) && !isEmptyValue(entryId)) {
        if(!isEmptyObject(editedEntries[entryId])) {
            return editedEntries[entryId];
        }
    }
    return null;
}

const mapStateToProps = (state, ownProps) => {
    return {
        entryRequestResult: state.entryDetail,
        isEditedBySameUser: isEditedBySameUser(state.websocketClientInfo, state.editedEntries, ownProps.match.params.entryID),
        editedByOtherUserName: editedByOtherUserName(state.websocketClientInfo, state.editedEntries, ownProps.match.params.entryID),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
};

const EntryEditConcurrency = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryEditConcurrencyPresenter);

export default withRouter(EntryEditConcurrency);