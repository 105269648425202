import { Link } from "react-router-dom";
import React from "react";
import { Breadcrumb, Menu } from "antd";
import PropTypes from "prop-types";
import { idNameTypeWithFoldersShape } from "../shapes/IdNameShapes";
import { isEmptyValue, isUndefined } from "../utils/JsObjectHelper";


const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    const hasNoPath = isEmptyValue(route.path);
    return last || hasNoPath ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={route.path}>{route.breadcrumbName}</Link>
    );
};

class EntryDetailBreadCrumb extends React.Component {

    render() {
        const routes = [];
        if (!this.props.entries && !this.props.entries.length < 1) return <p>&nbsp;</p>;
        this.props.entries.forEach((entry) => {
            if (entry.hasOwnProperty("subFolders") && !isUndefined(entry.subFolders) && entry.subFolders.length > 0) {
                let items = [];
                let menu = (<Menu className="scrollable"  style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                        {items}
                    </Menu>);
                for (const folder of entry.subFolders) {
                    items.push(<Menu.Item key={folder.id}><a href={`/entry/${folder.id}`}>{folder.name}</a></Menu.Item>)
                }
                routes.push(<Breadcrumb.Item overlay={menu}><a href={`/entry/${entry.id}`}> {entry.name}</a> </Breadcrumb.Item>);
            }
            else {
                routes.push(<Breadcrumb.Item><a href={`/entry/${entry.id}`}>{entry.name}</a> </Breadcrumb.Item>);
            }
            if (entry.gapMarker) {
                routes.push(<Breadcrumb.Item>...</Breadcrumb.Item>);
            }
        });
        return <Breadcrumb> {routes}</Breadcrumb>;
    }
}

EntryDetailBreadCrumb.propTypes = {
    entries: PropTypes.arrayOf(idNameTypeWithFoldersShape).isRequired
};

export default EntryDetailBreadCrumb;

