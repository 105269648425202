import GeneralPresenter from '../../components/setup/General';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchSettingsList, saveEditedSettings} from "../../actions/settings";
import { getGeneralSettingsObject } from '../../utils/GeneralSettingsHelper';

const mapStateToProps = (state, ownProps) => {
    return {
        generalSettingsObject: getGeneralSettingsObject(state.settingsList)
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchSettingsList(true));
        },
        /**
         * @param {BaseSettings} item
         */
         onSaveSettings: (item) => {
            dispatch(saveEditedSettings("general", "general", item, ()=>{dispatch(fetchSettingsList(true))}));
        },
    }
};

const General = connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneralPresenter);

export default withRouter(General);