import React from "react";
import PropTypes from "prop-types";
import {friendsEntryShape} from "../../shapes/BmcEntryShape";
import { Progress, Table } from 'antd';
import {Link} from "react-router-dom";
import { withTranslation} from 'react-i18next'

class EntryFriendsResults extends React.Component {

    render() {
        const {t} = this.props;

        if(!this.props.data || this.props.data.length === 0) return <div>&nbsp;</div>;
        const columns = [
            {
                title: t('app.entry.tools.context.tblName'),
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => <Link to={`/entry/${record.id}`}>{text}</Link>,
                /* render: text => <Link to={`/entry/${text}`}>{text}</Link>, */
            },
            /*{
                title: t('app.entry.tools.context.tblDistance'),
                dataIndex: 'level',
                key: 'level',
            },
            {
                title: t('app.entry.tools.context.tblRelCount'),
                dataIndex: 'relNum',
                key: 'relNum',
            },*/
            {
                title: t('app.entry.tools.context.tblScore'),
                dataIndex: 'contextScore',
                key: 'contextScore',
                width: '200px',
                render: (text, record) => <Progress strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068',
                  }}
                  percent={Math.round(text*100)} 
                  size="small" />,
            }
        ];
        return <Table rowKey="id" columns={columns} dataSource={this.props.data} />;
    }
}

EntryFriendsResults.propTypes = {
    data: PropTypes.arrayOf(friendsEntryShape).isRequired,
};

export default withTranslation() (EntryFriendsResults);