import PropTypes from 'prop-types';

export const bpmnTaskShape = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    assignee: PropTypes.string,
    created: PropTypes.string,
    due: PropTypes.string,
    followUp: PropTypes.string,
    delegationState: PropTypes.string,
    description: PropTypes.string,
    executionId: PropTypes.string,
    owner: PropTypes.string,
    parentTaskId: PropTypes.string,
    priority: PropTypes.number,
    processDefinitionId: PropTypes.string,
    processInstanceId: PropTypes.string,
    caseExecutionId: PropTypes.string,
    caseDefinitionId: PropTypes.string,
    caseInstanceId: PropTypes.string,
    taskDefinitionKey: PropTypes.string,
    suspended: PropTypes.bool,
    formKey: PropTypes.string,
    camundaFormRef: PropTypes.arrayOf({
        key: PropTypes.string,
        binding: PropTypes.string,
        version: PropTypes.number,
    }),
    tenantId: PropTypes.string,
});

