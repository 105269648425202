export const EMPTY_FOLDER_PROPERTIES_DEFINITION = { 
    childObjectEntryTypes: [], 
    childFolderEntryTypes: [], 
    workflowEvents: { onSave: "" }, 
    folderIcon: "folder-open", 
    folderIconColor: "#223b6f",
    subFolderDisplayType: "icons",
    childrenDisplayType: "table",
    childrenDisplayAttributes: [],
    childrenDisplayTechAttr: [],
    childrenDisplayHierarchyDefId: null,
    displayTechAttributesFirst: false,
    workflowForEntry:{onSave: [],onRunByUser:[]},
    workflowForFolder:{onRunByUser:[]},
    folderDisplayLayout:'description'
};