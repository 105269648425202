import {fetchJson} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const GROUP_LIST_REQUESTED = 'GROUP_LIST_REQUESTED';
/**
 * Action for request of entry.
 *
 * @return {GroupListAction}
 */
export const requestGroupList = () => {
    return {
        type: GROUP_LIST_REQUESTED
    };
};

export const GROUP_LIST_RECEIVED = "GROUP_LIST_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} groupList
 * @return {ReceiveGroupListAction}
 */
export const receiveGroupList = (groupList) => {
    return {
        type: GROUP_LIST_RECEIVED,
        groupList: groupList,
        receivedAt: Date.now()
    }
};

export const GROUP_LIST_ERROR = 'GROUP_LIST_ERROR';
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveUserListErrorAction}
 */
export const receiveError = (
    error,
    type = GROUP_LIST_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};


/**
 * Fetch group list
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchGroupList = (update = false) => {
    return (dispatch, getState) => {
        if (update) {
            //dispatch(requestGroupUpdate());
        } else {
            dispatch(requestGroupList());
        }

        //TODO endpoint na userlist
        return fetchJson(`${config.url.API_URL}/auth/custom/groups`, null, dispatch)
            .then(json => {
                    let res = [];

                    if (json._embedded && json._embedded.groups && json._embedded.groups.length > 0) {
                         res = json._embedded.groups;
                    }
                    dispatch(receiveGroupList(res));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, GROUP_LIST_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(error, GROUP_LIST_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};