import React from "react";
import { PageHeader, Button, Modal, Card, Row, Col, Alert, notification } from 'antd';
import { InfoCircleOutlined, FullscreenOutlined, FolderOutlined } from '@ant-design/icons';
import { withTranslation} from 'react-i18next'
import { callFullReindex } from "../../apicalls/callReindex";

const openNotificationWithIcon = (type, translator) => {
    notification[type]({
        message: (type==="success" ? translator('setup.reindex.notificationReindexSuccessfulTitle') : translator('setup.reindex.notificationReindexErrorTitle')),
        description: (type==="success" ? translator('setup.reindex.notificationReindexSuccessfulText') : translator('setup.reindex.notificationReindexErrorText')),
        placement: 'bottomRight'
        });
};

class Reindex extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fullReindexRunning: false,
        };
    }

    onRunFullReindex = () => {
        this.setState({ fullReindexRunning : true });
        callFullReindex(this.onFullReindexFinished);
    };

    onFullReindexFinished = (isSuccess) => {
        const {t} = this.props;
        this.setState({ fullReindexRunning : false });
        openNotificationWithIcon((isSuccess ? "success" : "error"), t);
    };

    render () {
        const {t} = this.props;

        let info = () => {
            Modal.info({
                title: t('setup.reindex.infoBoxTitle'),
                content: (
                    <div>
                         {t('setup.reindex.infoBoxContent')} 
                    </div>
                ),
                onOk() {
                },
            });
        };

        let fullReindexInfoBox = (this.state.fullReindexRunning ? 
            <Alert message={t('setup.reindex.fullReindexRunningInfoBox')} type="warning" showIcon />
            :
            <Alert message={t('setup.reindex.fullReindexInfoBox')} type="info" showIcon />
            );

        return <div>
            <PageHeader
                title={t('setup.reindex.headerDetail')}
                subTitle={t('setup.reindex.headerDetailLabel')}
                extra={[
                    <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                    </Button>,
                ]}
            >
            </PageHeader>
            <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <Card 
                        title={t('setup.reindex.fullReindexTitle')}
                        actions={[
                            <Button icon={<FullscreenOutlined />} loading={this.state.fullReindexRunning} onClick={this.onRunFullReindex}>{t('setup.reindex.btnFullReindex')}</Button>
                        ]}
                    >
                        {fullReindexInfoBox}
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <Card 
                        title={t('setup.reindex.folderReindexTitle')}
                        actions={[
                            <Button icon={<FolderOutlined />} disabled>{t('setup.reindex.btnFolderReindex')}</Button>
                        ]}
                    >

                    </Card>
                </Col>
            </Row>
            
        </div>;
    }
}

export default withTranslation() (Reindex);
