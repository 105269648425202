import { fetchJson } from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import { config } from "../config/Config";
import { showError } from "../utils/NotificationsHelper";

export const CLAIM_TASK_REQUESTED = "CLAIM_TASK_REQUESTED";
/**
 * Action for request of entry.
 *
 * @return {ClaimTaskAction}
 */
export const requestClaimTask = () => {
	return {
		type: CLAIM_TASK_REQUESTED,

	};
};


export const CLAIM_TASK_RECEIVED = "CLAIM_TASK_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} claimTask
 * @return {ReceiveClaimTaskAction}
 */
export const receiveClaimTask = (claimTask) => {
	return {
		type: CLAIM_TASK_RECEIVED,
		claimTask: claimTask,
		receivedAt: Date.now(),
	};
};

export const CLAIM_TASK_ERROR = "CLAIM_TASK_ERROR";
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveClaimTaskErrorAction}
 */
 export const receiveError = (
    error,
    type = CLAIM_TASK_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};


/**
 * 
 * @param {String} taskId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchClaimTask = (taskId, update = false, callback, errorClaim) => {
	return (dispatch, getState) => {
		if (update) {
			dispatch(requestClaimTask(taskId));
		} else {
			dispatch(requestClaimTask(taskId));
		}

		/** @type RequestOptions options **/
		let options = {
			method: "POST"
		};



		return fetchJson(`${config.url.API_URL}/bpmn/task/${taskId}/claim/currentUser`, options, dispatch)
			.then(json => {
				let res = [];
				res = json
				if (callback) {
					callback()
				}
				dispatch(receiveClaimTask(res));
			}
			)
			.catch(error => {
				if (error === "Ignoring request") {
				} else if (error instanceof ResponseStatusException) {
					const description = error.response.message ? error.response.message : null;
					if (description.indexOf('TaskAlreadyClaimedException') > -1 && errorClaim) {
						errorClaim()
					}
					else {
						dispatch(receiveError(dispatch, error, CLAIM_TASK_ERROR, error.response.status, description));
						showError(dispatch, error.message, description);
					}

				}
				 else {
					dispatch(receiveError(error, CLAIM_TASK_ERROR));
					showError(dispatch, error.message);
				}
			});
	}
};


