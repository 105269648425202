import PluginPageIndexPresenter from "../../components/pluginPage/PluginPageIndex";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchUserCustomizations } from "../../actions/userCustomizations";
import { fetchNewUserCustomizations } from "../../apicalls/fetchNewUserCustomizations";
import { fetchUpdateUserCustomizations } from "../../apicalls/fetchUpdateUserCustomizations";
import { isUndefined } from "../../utils/JsObjectHelper";

const getDashboard = (customizationsList) => {
    if (!isUndefined(customizationsList) && customizationsList.getState().isDone()) {
        let setList = customizationsList.getData().find(el => el.type === "dashboard");
		if(isUndefined(setList)){
			setList = {value:[]}
		}
        return setList;
    } else {
        return null;
    }
};

const mapStateToProps = (state, ownProps) => {
	return {
		myPlugins: getDashboard(state.userCustomizations),
		setupState: state.userCustomizations.getState(),
		user: state.loggedUser.user
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: () => {
			dispatch(fetchUserCustomizations());
		},
		updateCustomization: (settings) => {
			fetchUpdateUserCustomizations(settings);
		},

	};
};

const PluginPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(PluginPageIndexPresenter);

export default withRouter(PluginPage);
