import {createBaseOptions, fetchJson, METHOD_POST} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const ENTRY_COMMENT_LIST_REQUESTED = 'ENTRY_COMMENT_LIST_REQUESTED';
/**
 * Action for request of entry comments.
 *
 * @param {String} entryId
 * @return {EntryCommentListAction}
 */
export const requestEntryCommentList = (entryId) => {
    return {
        type: ENTRY_COMMENT_LIST_REQUESTED,
        entryId: entryId
    };
};

export const ENTRY_COMMENT_LIST_UPDATE_REQUESTED = 'ENTRY_COMMENT_LIST_UPDATE_REQUESTED';
/**
 * Action for request of entry comments.
 *
 * @param {String} entryId
 * @return {EntryCommentListAction}
 */
export const requestEntryCommentListUpdate = (entryId) => {
    return {
        type: ENTRY_COMMENT_LIST_UPDATE_REQUESTED,
        entryId: entryId
    };
};

export const ENTRY_COMMENT_LIST_RECEIVED = "ENTRY_COMMENT_LIST_RECEIVED";
/**
 *
 * @param {Object[]} entryCommentList
 * @return {ReceiveEntryCommentListAction}
 */
export const receiveEntryCommentList = (entryCommentList) => {
    return {
        type: ENTRY_COMMENT_LIST_RECEIVED,
        entryCommentList: entryCommentList,
        receivedAt: Date.now()
    }
};

export const ENTRY_COMMENT_LIST_UNMOUNT = "ENTRY_COMMENT_LIST_UNMOUNT";
/**
 * action unmount entry comments
 * @return {BaseAction}
 */
export const unmountEntryComments = () => {
    return {
        type: ENTRY_COMMENT_LIST_UNMOUNT
    }
};

export const ENTRY_COMMENT_NEW_REQUESTED = "ENTRY_COMMENT_NEW_REQUESTED";
/**
 * Action for create new comment.
 *
 * @param {Object} item
 * @return {EntryEditAction}
 */
export const requestEntryCommentNew = (item) => {
    return {
        type: ENTRY_COMMENT_NEW_REQUESTED,
        item: item,
    }
};

export const ENTRY_COMMENT_SUCCESSFULLY_SAVED = "ENTRY_COMMENT_SUCCESSFULLY_SAVED";
/**
 * Action for successfully saved of a comment.
 *
 * @param {String} itemId
 * @return {ItemIdAction}
 */
export const receiveEntryCommentSuccessfullySaved = (itemId) => {
    return {
        type: ENTRY_COMMENT_SUCCESSFULLY_SAVED,
        itemId: itemId
    }
};


export const ENTRY_COMMENT_LIST_ERROR = 'ENTRY_COMMENT_LIST_ERROR';
export const ENTRY_COMMENT_SAVE_ERROR = 'ENTRY_COMMENT_SAVE_ERROR';
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveUserListErrorAction}
 */
export const receiveError = (
    error,
    type = ENTRY_COMMENT_LIST_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};


/**
 * Fetch entry comments list
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchEntryCommentList = (entryId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            dispatch(requestEntryCommentListUpdate(entryId));
        } else {
            dispatch(requestEntryCommentList(entryId));
        }

        //TODO endpoint na comment list (/${entryId}) api/bmcEntries/custom/item/{entryId}/comments
        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/comments`, null, dispatch)
            .then(json => {
                    let res = [];

                    if (json._embedded && json._embedded.entryComments && json._embedded.entryComments.length > 0) {
                        res = json._embedded.entryComments;
                    }

                    dispatch(receiveEntryCommentList(res));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, ENTRY_COMMENT_LIST_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(error, ENTRY_COMMENT_LIST_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};

/**
 * Save new comment
 *
 * @param {string} entryId
 * @param {Object} item
 * @return {function(*=, *): Promise<Object | never>}
 */
export const saveNewComment = (entryId, item) => {
    return (dispatch, getState) => {
        dispatch(requestEntryCommentNew(item));
        /** @type RequestOptions */
        let options = createBaseOptions();
        options.method = METHOD_POST;
        options.headers["Content-Type"] = "application/json";
        options["body"] = item;

        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/comment`, options, dispatch)
            .then(json => {
                    dispatch(receiveEntryCommentSuccessfullySaved(json.id));
                    dispatch(fetchEntryCommentList(entryId, true));
                    //dispatch(receiveObjectDefinitionDetail(json.id, json));
                    //showSuccess(dispatch, "Success", "Object definition has been successfully inserted!");
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(null, error, ENTRY_COMMENT_SAVE_ERROR, error.response.status, description));
                } else {
                    dispatch(receiveError(null, error, ENTRY_COMMENT_SAVE_ERROR));
                }
                showError(dispatch, "Error", "Comment couldn't be inserted!<br />Contact aphinit administrator.");
            });
    }
};