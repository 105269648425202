import RelationVisualizationPresenter from '../../components/object/RelationVisualization';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { isEmptyValue } from '../../utils/JsObjectHelper';

const getCytoscapeOptions = (lineageContainer) => {
    let options = {
        container: lineageContainer,
        elements: [
        ],
        style: [
            {
                selector: 'node',
                style: {
                    'label': 'data(label)',
                    'font-size': '12px',
                    'text-valign': 'center',
                    'text-halign': 'center',
                    "shape": "rectangle",
                    'background-color': '#A3ADFF',
                    "text-wrap": "wrap",
                    "text-max-width": 100,
                    'width': 150,
                    'border-color': '#000',
                    'border-width': 1,
                    'border-opacity': 0.3,
                    "padding": "6px",
                }
            },
            {
                selector: 'edge',
                style: {
                    'label': 'data(label)',
                    'width': 2,
                    'target-arrow-shape': 'triangle',
                    'line-color': '#9dbaea',
                    'target-arrow-color': '#3632a8',
                    'curve-style': 'bezier', /*'unbundled-bezier', 'segments', 'taxi' */
                    /*"edge-distances": "node-position",*/
                    'font-size': '11px',
                    'text-valign': 'top',
                    //"edge-text-rotation": "autorotate",
                    'text-outline-width' : '1px',
                    'text-outline-color' : '#ffffff',
                    'loop-direction': '0deg',
                    'loop-sweep': '90deg',
                    'control-point-step-size': 100,
                }
            },
            {
                slector: '.loop',
                style: {
                    /*'curve-style': 'loop'*/
                    'loop-direction': '0deg',
                    'loop-sweep': '90deg',
                    /*"edge-distances": "node-position"
                    'edge-distances': 'control-point-weights',
                    'control-point-step-size': 10*/
                }
            }
        ],
        wheelSensitivity: 0.5,
    };

    return options;
};

/**
 * 
 * @returns 
 */
 const getLayoutOptions = () => {
    let options = {
        name: 'klay',
        // dagre algo options, uses default value on undefined
        nodeSep: 20, // the separation between adjacent nodes in the same rank
        edgeSep: 30, // the separation between adjacent edges in the same rank
        rankSep: 70, // the separation between adjacent nodes in the same rank
        rankDir: 'LR', // 'TB' for top to bottom flow, 'LR' for left to right,
        minLen: function minLen(edge) {
            return 1;
        }, // number of ranks to keep between the source and target of the edge
        edgeWeight: function edgeWeight(edge) {
            return 1;
        }, // higher weight edges are generally made shorter and straighter than lower weight edges
        // general layout options
        fit: true, // whether to fit to viewport
        padding: 50, // fit padding
        spacingFactor: undefined, // Applies a multiplicative factor (>0) to expand or compress the overall area that the nodes take up
        nodeDimensionsIncludeLabels: true, // whether labels should be included in determining the space used by a node
        animate: true, // whether to transition the node positions
        animateFilter: function animateFilter(node, i) {
            return true;
        }, // whether to animate specific nodes when animation is on; non-animated nodes immediately go to their final positions
        animationDuration: 200, // duration of animation in ms if enabled
        animationEasing: undefined, // easing of animation if enabled
        boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
        transform: function transform(node, pos) {
            return pos;
        }, // a function that applies a transform to the final node position
        ready: function ready() { }, // on layoutready
        stop: function stop() { }, // on layoutstop
        klay: {
            // Following descriptions taken from http://layout.rtsys.informatik.uni-kiel.de:9444/Providedlayout.html?algorithm=de.cau.cs.kieler.klay.layered
            addUnnecessaryBendpoints: true, // Adds bend points even if an edge does not change direction.
            aspectRatio: 10.6, // The aimed aspect ratio of the drawing, that is the quotient of width by height
            borderSpacing: 30, // Minimal amount of space to be left to the border
            compactComponents: false, // Tries to further compact components (disconnected sub-graphs).
            crossingMinimization: 'LAYER_SWEEP', // Strategy for crossing minimization.
            /* LAYER_SWEEP The layer sweep algorithm iterates multiple times over the layers, trying to find node orderings that minimize the number of crossings. The algorithm uses randomization to increase the odds of finding a good result. To improve its results, consider increasing the Thoroughness option, which influences the number of iterations done. The Randomization seed also influences results.
            INTERACTIVE Orders the nodes of each layer by comparing their positions before the layout algorithm was started. The idea is that the relative order of nodes as it was before layout was applied is not changed. This of course requires valid positions for all nodes to have been set on the input graph before calling the layout algorithm. The interactive layer sweep algorithm uses the Interactive Reference Point option to determine which reference point of nodes are used to compare positions. */
            cycleBreaking: 'GREEDY', // Strategy for cycle breaking. Cycle breaking looks for cycles in the graph and determines which edges to reverse to break the cycles. Reversed edges will end up pointing to the opposite direction of regular edges (that is, reversed edges will point left if edges usually point right).
            /* GREEDY This algorithm reverses edges greedily. The algorithm tries to avoid edges that have the Priority property set.
            INTERACTIVE The interactive algorithm tries to reverse edges that already pointed leftwards in the input graph. This requires node and port coordinates to have been set to sensible values.*/
            direction: 'RIGHT', // Overall direction of edges: horizontal (right / left) or vertical (down / up)
            /* UNDEFINED, RIGHT, LEFT, DOWN, UP */
            edgeRouting: 'ORTHOGONAL', // Defines how edges are routed (POLYLINE, ORTHOGONAL, SPLINES)
            edgeSpacingFactor: 5, //2 ; 0.5 Factor by which the object spacing is multiplied to arrive at the minimal spacing between edges.
            feedbackEdges: true, // Whether feedback edges should be highlighted by routing around the nodes.
            fixedAlignment: 'BALANCED', // Tells the BK node placer to use a certain alignment instead of taking the optimal result.  This option should usually be left alone.
            inLayerSpacingFactor: 2, // 1.5 Factor by which the usual spacing is multiplied to determine the in-layer spacing between objects.
            layoutHierarchy: true, // Whether the selected layouter should consider the full hierarchy
            linearSegmentsDeflectionDampening: 0.5, //10.5 ; 0.3 Dampens the movement of nodes to keep the diagram from getting too large.
            mergeEdges: false, // Edges that have no ports are merged so they touch the connected nodes at the same points.
            mergeHierarchyCrossingEdges: false, // If hierarchical layout is active, hierarchy-crossing edges use as few hierarchical ports as possible.
            nodeLayering: 'INTERACTIVE', // Strategy for node layering.
            nodePlacement: 'BRANDES_KOEPF', // Strategy for Node Placement
            randomizationSeed: 1, // Seed used for pseudo-random number generators to control the layout algorithm; 0 means a new seed is generated
            routeSelfLoopInside: false, // Whether a self-loop is routed around or inside its node.
            separateConnectedComponents: true, // Whether each connected component should be processed separately
            spacing: 10, //30 Overall setting for the minimal amount of space to be left between objects
            thoroughness: 20 // How much effort should be spent to produce a nice layout.. 7
        }
    };
    return options;
};

/**
 * 
 * @param {Object} elem 
 * @param {String} class
 * @returns 
 */
 const createNodeElement = (elem, className) => {
    let classArr = [elem.type];

    if ( !isEmptyValue(className)) {
        classArr.push(className);
    }

    return {
        group: 'nodes', // 'nodes' for a node, 'edges' for an edge
        // NB the group field can be automatically inferred for you but specifying it
        // gives you nice debug messages if you mis-init elements
        data: { // element data (put json serialisable dev data here)
            id: elem.id, // mandatory (string) id for each element, assigned automatically on undefined
            parent: elem.parent, // indicates the compound node parent id; not defined => no parent
            // (`parent` can be effectively changed by `eles.move()`)
            label: elem.name,
            type: elem.type
        },
        selected: false, // whether the element is selected (default false)
        selectable: true, // whether the selection state is mutable (default true)
        locked: false, // when locked a node's position is immutable (default false)
        grabbable: true, // whether the node can be grabbed and moved by the user
        pannable: false, // whether dragging the node causes panning instead of grabbing
        classes: classArr // an array (or a space separated string) of class names that the element has
    };
};

/**
 * 
 * @param {Object} edg 
 * @returns 
 */
const createEdgeElement = (edg) => {
    return {
        group: 'edges',
        data: {
            id: `${edg.s}#${edg.t}`, //edg.s + '#' + edg.t,
            // inferred as an edge because `source` and `target` are specified:
            source: edg.s, // the source node id (edge comes from this node)
            //portsource: edg.s,
            target: edg.t,  // the target node id (edge goes to this node)
            label: edg.name,
            sourceType: edg.st,
            targetType: edg.tt,
            relType: edg.type
        },

        pannable: true // whether dragging on the edge causes panning
    };
};

const mapStateToProps = (state, ownProps) => {
    return {
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCytoscapeOptions: (lineageContainer) => {
            return getCytoscapeOptions(lineageContainer);
        },
        getLayoutOptions: () => {
            return getLayoutOptions();
        },
        createNodeElement: (elem, className) => {
            return createNodeElement(elem, className);
        },
        createEdgeElement: (edg) => {
            return createEdgeElement(edg);
        },
    }
};

const RelationVisualization = connect(
    mapStateToProps,
    mapDispatchToProps
)(RelationVisualizationPresenter);

export default withRouter(RelationVisualization);