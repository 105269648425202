import React from "react";
import { Button, Card } from 'antd';
import { CopyOutlined, EditOutlined, SwapOutlined } from '@ant-design/icons';
import { withTranslation} from 'react-i18next'
import {bmcEntryShape} from "../../../shapes/BmcEntryShape";
import EntryManipulationDialog from "./EntryManipulationDialog";
import {Link} from "react-router-dom";

class EntryManipulation extends React.Component {
    constructor(props) {
        super(props);
        this.onMoveClick = this.onMoveClick.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleManipulationAction = this.handleManipulationAction.bind(this);
        this.handleAfterCopy = this.handleAfterCopy.bind(this);
        
        this.state = {
            showManipulationDialog: false,
            isCopyMode: false,
            newCopyId: null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.entryRequestResult.getState() !== this.props.entryRequestResult.getState()) {
            //After move or copy hide dialog
            if(this.props.entryRequestResult.getState().isUpdating()) {
                this.handleDialogClose();
            }
        }
    };

    onMoveClick = () => {
        this.setState({showManipulationDialog: true, isCopyMode: false});
    };

    onCopyClick = () => {
        this.setState({showManipulationDialog: true, isCopyMode: true});
    };

    handleDialogClose = () => {
        this.setState({showManipulationDialog: false, newCopyId: null});
    };

    handleManipulationAction = (manipulationObject) => {
        console.log(manipulationObject);
        if (this.state.isCopyMode) {
            this.props.onCopy(this.props.entry.id, manipulationObject, this.handleAfterCopy);
        } else {
            this.props.onMove(this.props.entry.id, manipulationObject);
        }
    };

    handleAfterCopy = (newEntryId) => {
        this.setState({ newCopyId: newEntryId });
    };

    render() {
        const {t} = this.props;

        let manipulationPanel = null;

        if (this.props.entry.userEntryAccess === 'EDIT' &&
            (this.props.entry.systemType === "object" || 
            (this.props.entry.systemType === "folder" && this.props.entry.type !== "home"))) {
            let copyButton = null;
            //COPY is now only allowed for objects. NOT folders.
            if (this.props.entry.systemType === "object") {
                copyButton = <Button key="copy" name="copy" icon={<CopyOutlined />} style={{marginBottom:'10px'}} 
                                onClick={this.onCopyClick}>
                                    {t('app.entry.tools.actions.btnCopy')}
                            </Button>;
            }

            manipulationPanel = <Card type="inner" size="small" 
                title={t('app.entry.tools.actions.boxManipulation')} 
                style={{marginBottom: '20px'}} 
                bodyStyle={{display:'grid'}}>
                <Button key="move" name="move" icon={<SwapOutlined />} style={{marginBottom:'10px'}} 
                    onClick={this.onMoveClick}>
                        {t('app.entry.tools.actions.btnMove')}
                </Button>
                {copyButton}
                <Link key="edit" name="edit" to={`/entry/${this.props.entry.id}/edit`}><Button style={{width: '100%'}} icon={<EditOutlined />}>{t('app.entry.tools.actions.btnEdit')}</Button></Link>
                {/*<Button key="delete" name="delete" icon="delete" type="danger" style={{marginBottom:'10px'}}>{t('app.entry.tools.actions.btnCopy')}</Button> */}
            </Card>;
        }
        let entryArray = [this.props.entry]

        return <div>
            {manipulationPanel}
            <EntryManipulationDialog 
                entry={entryArray}
                isVisible={this.state.showManipulationDialog}
                isCopyMode={this.state.isCopyMode}
                onAction={this.handleManipulationAction}
                onCancel={this.handleDialogClose}
                newAfterCopyEntryId={this.state.newCopyId}
            >
            </EntryManipulationDialog>
        </div>;
    }
};

export default withTranslation() (EntryManipulation);

EntryManipulation.propTypes = {
    entry: bmcEntryShape.isRequired
};