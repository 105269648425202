import React from "react";
import "antd/dist/antd.css";
import {
	Form,
	Input,
	Button,
	Radio,
	Checkbox,
	Select,
	Typography,
	Descriptions,
	InputNumber,
	Empty

} from "antd";
import HtmlParser from "react-html-parser";
import { withTranslation } from "react-i18next";
import { isEmptyValue, isUndefined } from "../../utils/JsObjectHelper";
import { notificationSuccess } from "../../utils/NotificationsHelper";
const { Option } = Select;
const { Title } = Typography;

class FormBpmn extends React.Component {
	constructor(props) {
		super(props);

		//this.handleSubmit = this.handleSubmit.bind(this);
	}
	formRef = React.createRef();


	handleSubmit = (formValues,) => {
		const { t } = this.props;
		this.props.complete(formValues)
		if (this.props.showOk) {
			notificationSuccess(t("app.bpmn.alertTaskSuccessTitle"), t("app.bpmn.alertTaskSuccessDescription"));
		}
		this.formRef.current.resetFields();
	};
	render() {
		const { t } = this.props;
		const validateMessages = {
			required: t("app.bpmn.required"),
		};
		let formData = isUndefined(this.props.data) ? [] : this.props.data;
		let formVariables = isUndefined(this.props.variables) ? null : this.props.variables;
		let FormItem = [];
		let Formular = null;
		if (this.props.data === "No form") {
			Formular = <Empty description={<span>{t("app.bpmn.noForm")}</span>}></Empty>
		}
		if (!isUndefined(this.props.data) && this.props.data.components && !isUndefined(formVariables)) {
			formData.components.forEach((element, index) => {
				console.log(element);

				let ElementForm = null;
				// switch(element["type"])
				switch (element["type"]) {
					case "textfield":
						const { required, minLength: min, maxLength: max, regularExpressionPattern: regular } = { ...element["validate"] }
						const re = new RegExp(regular)
						ElementForm = (
							<Form.Item
								label={element["label"]}
								name={[element["key"], "value"]}
								key={index}
								extra={
									element["description"] ? (
										<Descriptions.Item label={element["description"]}>
											{element["description"]}
										</Descriptions.Item>
									) : undefined
								}
								shouldUpdate
								rules={element["validate"] ? [
									{ required },
									{ min },
									{ max },
									{
										validator: (rule, value, callback) => {
											if (re.test(value)) {
												callback(rule.message);
											}
											callback();
										},
										message: t("app.bpmn.validateRegularExpression"),
									}
								] : undefined}
							>
								<Input disabled={this.props.showForm} />
							</Form.Item>
						);
						break;

					case "number":
						ElementForm = (
							<Form.Item
								label={element["label"]}
								name={[element["key"], "value"]}
								key={index}
								shouldUpdate
								rules={element["validate"] ? [{ required: element["validate"].required }, { type: "number", max: element["validate"].max }, { type: "number", min: element["validate"].min }] : undefined}
								extra={
									element["description"] ? (
										<Descriptions.Item label={element["description"]}>
											{element["description"]}
										</Descriptions.Item>
									) : undefined
								}
							>
								<InputNumber disabled={this.props.showForm} style={{ width: '100%' }} />
							</Form.Item>
						);
						break;
					case "checkbox":
						ElementForm = (
							<Form.Item shouldUpdate={true} key={index} >
								{() => {
									let svalue = isUndefined(this.formRef.current) ? true : isEmptyValue(this.formRef.current.getFieldValue(element['key']))
									return (
										<Form.Item
											label={element["label"]}
											name={[element["key"], "value"]}
											key={index}
											valuePropName="checked"
											extra={
												element["description"] ? (
													<Descriptions.Item label={element["description"]}>
														{element["description"]}
													</Descriptions.Item>
												) : undefined
											}
										>
											<Checkbox

												disabled={this.props.showForm}
												indeterminate={isEmptyValue(
													formVariables[element["key"]]
												) && svalue}

											>
												{element["label"]}
											</Checkbox>
										</Form.Item>
									);
								}}
							</Form.Item>
						);
						break;
					case "radio":
						ElementForm = (
							<Form.Item
								label={element["label"]}
								name={[element["key"], "value"]}
								key={index}
								rules={element["validate"] ? [{ required: element["validate"].required }] : undefined}
								extra={
									element["description"] ? (
										<Descriptions.Item label={element["description"]}>
											{element["description"]}
										</Descriptions.Item>
									) : undefined
								}
							>
								<Radio.Group disabled={this.props.showForm} onChange={this.changeRadio}>
									{element["values"].map((el, indexradio) => (
									
										<Radio  value={el["value"]} key={indexradio}>
									{el["label"]}
										</Radio>
									))}
								</Radio.Group>
							</Form.Item>
						);
						break;
					case "select":
						ElementForm = (
							<Form.Item
								label={element["label"]}
								name={[element["key"], "value"]}
								key={index}
								rules={element["validate"] ? [{ required: element["validate"].required }] : undefined}
								extra={
									element["description"] ? (
										<Descriptions.Item label={element["description"]}>
											{element["description"]}
										</Descriptions.Item>
									) : undefined
								}
							>
								<Select
									// defaultValue={element["label"]}
									style={{ width: 120 }}
									id={element["id"]}
									disabled={this.props.showForm}
								>
									{element["values"].map((el, indexSelect) => (
										<Option value={el["value"]} key={indexSelect}>
											{el["label"]}
										</Option>
									))}
								</Select>
							</Form.Item>
						);
						break;
					case "text":
						let text = HtmlParser(element["text"])
						ElementForm = (
							<Form.Item key={index}>
								<div className="textFormBpmn" >{text}</div>
							</Form.Item>
						);
						break;

					default:

						break;
				}
				FormItem.push(ElementForm);
			});
			Formular = (<>
				{/* <h1>{formData.id}</h1> */}
				<Form
					ref={this.formRef}
					layout={"vertical"}
					className="form"
					validateMessages={validateMessages}
					onFinish={this.handleSubmit}
					initialValues={formVariables}
				>

					{FormItem}
					<Form.Item>
						<Button type="primary" htmlType="submit" disabled={this.props.showForm}>
							{t("app.bpmn.complete")}

						</Button>
					</Form.Item>
				</Form>
			</>
			);


		}


		return (

			<div className="formReady">

				{Formular}
			</div>


		);
	}
}

export default withTranslation()(FormBpmn);
