import React from "react";
import { Button, Modal, Space,Input } from 'antd';
import PropTypes from 'prop-types';
//import ButtonGroup from "antd/lib/button/button-group";
import { withTranslation} from 'react-i18next'
import { Icon } from '@ant-design/compatible';

const predefinedIcons = [
    {
        name: "Basic",
        icons: ["read", "book", "gift", "tool", "tags", "picture", "notification", "calendar","calculator", "schedule", "environment", "experiment", "code", "control", "pushpin","warning","coffee","compass",
        "eye", "eye-invisible",    "highlight",  "info-circle", "layout", "lock", "mail", "message", "mobile", "phone", "printer", "profile", "reload", "search", "setting", "share-alt", "unlock","desktop" ],
       icons2: []
    },
    {
        name: "State",
        icons: ["question-circle","stop","exclamation-circle","plus-circle","minus-circle","check-circle", "clock-circle", "close-circle", "bulb","fire","bug","alert", "safety", "star",
        "check-square", "pause-circle", "play-circle", "plus-square", "minus-square","left-circle","up-circle","right-circle", ],
        icons2: []
    },
    {
        name: "Users",
        icons: ["user", "user-add", "user-delete", "team", "man", "woman", "contacts", "solution", "skin", "smile", "frown", "meh","idcard","robot",
        "customer-service", "usergroup-add", "usergroup-delete"],
        icons2: []
    },
    {
        name: "Manipulation",
        icons: ["save", "delete", "snippets", "reconciliation", "audit", "paper-clip", "interaction","like","dislike",
        "copy",  "edit", "scissor", "undo", "redo", "zoom-in", "zoom-out", "login", "logout"],
        icons2: []
    },
    {
        name: "Data",
        icons: ["folder", "folder-open","folder-add","file", "file-zip", "file-text", "file-done", "file-protect", "file-add", "file-excel","file-image","file-pdf","file-ppt",
         "file-search", "file-sync",  "file-word","database", "table", "border-horizontal", "hdd","usb", "bars", "container", "cloud-upload", "cloud-download", "cloud-sync", "cloud-server", "export", "import", "scan",    "fork", "merge-cells", "project",
         "disconnect", "api", "dashboard",   "link", "block", "cluster", "deployment-unit",  "gateway", "global",   "key",   "menu", "monitor", "number", "percentage",  "red-envelope",  "rest",  "file-unknown", 
         "security-scan", "select", "shake",  "shopping-cart",  "sync",   "to-top", "trademark", "transaction", "upload",   "wifi",   "audio",  "sound",  "qrcode", "barcode", "font-size", "font-colors",],
        icons2: []
    },
    {
        name: "Infrastructure",
        icons: [ "home", "bank", "laptop",  "apartment",  "inbox","build","car","clear","rocket",
        "bell",  "bug",    "camera", "carry-out", "download", "filter",  "flag", 
         "funnel-plot",  "gold", "heart", "hourglass", "html5",  "insurance",   "left-square", "medicine-box", 
        "money-collect",      "play-square",  "pound-circle",   "property-safety", "right-square",  "safety-certificate",
          "shop", "shopping",    "switcher", "tag",  "thunderbolt",  "trophy",  "up-square",  "video-camera", "wallet",  ],
        icons2: []
    },
    {
        name: "Graph",
        icons: ["project", "area-chart", "pie-chart", "bar-chart", "dot-chart", "line-chart", "fund", "fall", "rise", "sliders", "box-plot", "branches","console-sql","radar-chart"],
        icons2: []
    },
    {
        name: "Brand and Logos",
        icons: ["github", "twitter", "youtube", "gitlab", "linkedin", "google", "amazon", "slack", "instagram","android", "apple", "windows","appstore","codepen","code-sandbox",
        "facebook", "dropbox", "dribbble", "behance", "sketch", "chrome", "ie","skype"],
        icons2: []
    }
];




class IconPickerModal extends React.Component {
    state = {
        search: '',
    };

    handleSearch = (e) => {
        this.setState({ search: e.target.value });
    };
    
    render () {
        const {t} = this.props;
        const { search } = this.state;
        let iconButtons = [];

        predefinedIcons.forEach( cDef => {
            let btns = cDef.icons.filter(c => c.includes(search)).map(c => {
                let iconObject = <Icon type={c}></Icon>;
                if (c === this.props.iconValue) {
                    return <Button key={c} icon={iconObject} title={c}
                                type="primary" 
                                onClick={ f => {this.props.onSelect(c)}} 
                                size="large"
                                style={{fontSize: 'large'}}
                                ></Button>;
                } else {
                    return <Button key={c} icon={iconObject} title={c} 
                            onClick={ f => {this.props.onSelect(c)}} 
                            size="large"
                            style={{fontSize: 'large'}}
                        ></Button>;
                }
            });
            if (btns.length > 0) {
                iconButtons.push(<div key={cDef.name} style={{ overflowWrap: "break-word" }}><span><b>{cDef.name}</b></span><br />{btns}</div>);
            }
            // iconButtons.push(<div key={cDef.name} style={{overflowWrap:"break-word"}}><span><b>{cDef.name}</b></span><br/>{btns}</div>);
        });

        return (
            <Modal
                title={t('app.iconPicker.modalTitle')}
                visible={this.props.isVisible}
                //onOk={this.handleOk}
                onCancel={this.props.onCancel}
                width="800px"
                style={{overflow:'auto'}}
                footer={[<Button key="back" onClick={this.props.onCancel}>
                            {t('app.colorPicker.modalBtnReturn')}
                        </Button>]}
                >
                    <Input placeholder="Search icons" value={search} style={{marginBottom:"20px"}} onChange={this.handleSearch} />
                    {iconButtons}
            </Modal>
        )
    }
}

export default withTranslation() (IconPickerModal);

IconPickerModal.propTypes = {
    iconValue: PropTypes.string,
    isVisible: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};