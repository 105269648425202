import React from 'react';
import SearchHeader from "../containers/header/SearchHeader";
import { withRouter, Link } from "react-router-dom";
import logoAph from '../aphnit_simple_wide.svg';
import { Avatar, Popover, Menu, Row, Col, Select, Badge, } from 'antd';
import { FlagOutlined, SettingOutlined, LogoutOutlined, CalendarOutlined, UserOutlined, DashboardOutlined, } from '@ant-design/icons';
import CreateNewButton from "../containers/header/CreateNewButton";
import PropTypes from "prop-types";
import { loggedUserShape } from "../shapes/LoggedUserShapes";
import { config } from "../config/Config";
import { isUndefined } from '../utils/JsObjectHelper';
import { withTranslation } from 'react-i18next'
import { isDesktop, isMobile, isTablet } from '../utils/AdaptiveLayout';
import AppHeaderMobile from './adaptive/AppHeaderMobile';

const { Option } = Select;

class AppHeader extends React.Component {

    state = {
        visible: false,
        flippedScreen: null,
        mainLogo:null,
    };

    constructor(props) {
        super(props);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }
    componentDidMount() {
        this.props.onMount();
        this.interval = setInterval(() => {
            this.props.onInterval();
        }, 305000);

    }
    componentDidUpdate(prevProps,prevState){
        if(prevProps.allBranding !== this.props.allBranding ){
            if(this.props.allBranding.data && this.props.allBranding.data.length>0){
                let logo = this.props.allBranding.data.find((el)=>el.id==='mainLogo');
                if(logo && logo.active&& logo.value && logo.value.url &&  this.state.mainLogo !== logo.value.url){
                    this.setState({mainLogo:logo.value.url});
                }
                else{
                    this.setState({mainLogo:logoAph}); 
                }
            }
            else{
                this.setState({mainLogo:logoAph}); 
            }
        }
        
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    menuSelected = (e) => {
        if (e.key && e.key !== "language") {
            this.handleVisibleChange(false);
        }
    };

    handleLanguageChange(value) {
        //sessionStorage.setItem("i18nextLng", value);
        //cookies.set("i18next", value);
        this.props.i18n.changeLanguage(value);
    };

    handleVisibleChange = visible => {
        this.setState({ visible });
    };
    flippedScreen = () => {
        this.setState({ flippedScreen: window.orientation });
        // window.location.reload()
    }

    render() {
        const { t } = this.props;
        window.addEventListener('orientationchange', this.flippedScreen, false);
        let taskCount = this.props.taskCount.data === null ? 0 : this.props.taskCount.getData();
        let localTask = localStorage.getItem('taskCount');
        let localTaskCount = localTask ? JSON.parse(localTask) : 0;
        if (!localTask) {
            localStorage.setItem('taskCount', taskCount);
        }
        let showDot = false;
        let newTask = taskCount
        if (!isUndefined(localTaskCount) && !isUndefined(taskCount) && this.props.taskCount.data !== null) {
            if (localTaskCount > taskCount && taskCount !== -1) {
                localTaskCount = taskCount;
                if (taskCount !== 0) {
                    showDot = true;
                }
                localStorage.setItem('taskCount', taskCount);
            }
            if (localTaskCount < taskCount && taskCount !== -1) {
                newTask = taskCount - localTaskCount;
                showDot = false;
            }
            if (localTaskCount === taskCount && taskCount !== -1 && taskCount !== 0) {
                showDot = true;
            }
        };
        let content = (
            <Menu
                style={{ width: 256 }}
                mode="inline"
                onClick={this.menuSelected}
            >
                <Menu.Item key="language"><FlagOutlined />&nbsp;
                    <Select defaultValue={this.props.i18n.language} style={{ width: 180 }} onChange={this.handleLanguageChange}>
                        <Option value="en">English</Option>
                        <Option value="cs">Česky</Option>
                        <Option value="nl">Dutch</Option>
                    </Select>
                </Menu.Item>
                {/* <Menu.Item key="user"><UserOutlined />{t('app.header.userMenuUserDetail')}</Menu.Item> */}
                {/* <Menu.Item key="tasklist"><a href={config.url.BPMN_URL + `/app/tasklist/default/`} target="_blank" rel="noopener noreferrer"><CalendarOutlined />&nbsp;{t('app.header.userMenuTaskList')}</a></Menu.Item> */}
                <Menu.Item key="userdashboard"><Link to={`/userdashboard`}><DashboardOutlined />&nbsp;{t('app.header.userDashboard')}</Link></Menu.Item>
                <Menu.Item key="tasklist" title={taskCount === -1 ? t('app.bpmn.notificationInvalidTokenDescription') : null}>
                    <Link to={`/bpmn`}><CalendarOutlined />&nbsp;{t('app.header.userMenuTaskList')}{taskCount !== -1 ? <Badge count={taskCount} ></Badge> : null}&nbsp;
                        {newTask !== taskCount ? <a style={{ color: '#52c41a', }}> +{newTask}</a> : null}
                    </Link>
                </Menu.Item>
                {this.props.hasSetupRight && <Menu.Item key="setup"><Link to={`/setup`}><SettingOutlined />&nbsp;{t('app.header.userMenuSetup')}</Link></Menu.Item>}
                <Menu.Item key="logout" onClick={this.props.onLogout}><LogoutOutlined />&nbsp;{t('app.header.userMenuLogOut')}</Menu.Item>
            </Menu>
        );


        let header = (<div>
            {this.state.mainLogo?<div className="header_logo_adaptive" >
                <Link to={`/`}>
                    <img src={this.state.mainLogo} alt="Logo" width="100%" height="100%" />
                </Link>
            </div>:<div className="emptyLogo" ></div>}
            <div>
                <Row type="flex" justify="space-between" >
                    <Col className="mainSearchHolder">
                        <SearchHeader />
                    </Col>
                    <Col style={{ display: 'flex' }}>
                        <div style={{ marginRight: 20 }}>
                            <CreateNewButton />
                        </div>
                        <div className="avatar">
                            <Popover
                                placement="bottomRight"
                                title={<a href={"/id2entry/" + this.props.loggedUser.id} target="_blank" ><UserOutlined /> {this.props.loggedUser.name}</a>}
                                // title={this.props.loggedUser && this.props.loggedUser.name}
                                content={content}
                                //trigger="click"
                                visible={this.state.visible}
                                onVisibleChange={this.handleVisibleChange}
                            >
                                <Badge key="taskCount" count={newTask} dot={showDot} >
                                    <Avatar style={{ backgroundColor: '#223b6f', verticalAlign: 'middle' }} size="large">
                                        {this.props.loggedUser && this.props.loggedUser.initials}
                                    </Avatar>
                                </Badge>
                            </Popover>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>)
        if (isTablet() || isMobile()) {
            header = <AppHeaderMobile allBranding={this.props.allBranding} taskCount={this.props.taskCount} onLogout={this.props.onLogout} loggedUser={this.props.loggedUser} isAdmin={this.props.isAdmin} hasSetupRight={this.props.hasSetupRight} />
        }



        return (<>{header}</>
             /* <div style={{ float: 'left'}}>
                    <SearchHeader />
                </div>
                <div style={{float: "right"}} className="avatar">
                    <Popover placement="bottomRight" title="Martin Veselý" content={content} trigger="click">
                        <Avatar style={{ backgroundColor: '#223b6f', verticalAlign: 'middle' }} size="large">
                            MV
                        </Avatar>
                    </Popover>
                </div>
                <div style={{ float: 'right', marginRight: 20}}><CreateNewButton /></div> */)

    }
}

const AppHeaderWithRouter = withRouter(AppHeader);

export default withTranslation()(AppHeaderWithRouter);

AppHeaderWithRouter.propTypes = {
    onLogout: PropTypes.func.isRequired,
    loggedUser: loggedUserShape,
    isAdmin: PropTypes.bool.isRequired,
    hasSetupRight: PropTypes.bool.isRequired
};