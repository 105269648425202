import React from "react";
import { Result } from 'antd';

class DoesNotExist extends React.Component {
    render () {

        let titleText = (this.props.titleText !== "" ? this.props.titleText : "404");
        
        return (
            <Result
                status="404"
                title={titleText}
                subTitle="Sorry, the page you visited does not exist."
            />
        );
    }
}

export default DoesNotExist;