import {createBaseOptions, fetchJson, METHOD_POST,} from "../../utils/SuperAgentFetch";
import {config} from "../../config/Config";


export const fetchIdentityLink = (taskId,identity,callback) => {
   
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_POST;
    options.headers["Content-Type"] = "application/json";
    options["body"] = identity;
    
   
    let r = fetchJson(`${config.url.API_URL}/bpmn/task/${taskId}/identityLink/multi`, options) // /async
    r.then(json => {
        console.log('ok');
        callback()   
    })
    .catch(error => {
        console.log(taskId);
        console.log('error');
        callback()
    });
    

};