import {
    LOAD_SETTINGS_LIST_REQUESTED,
    LOAD_SETTINGS_LIST_RECEIVED,
    LOAD_SETTINGS_LIST_ERROR,
} from '../actions/loadSettings.js';
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";

/**
 *
 * @param {RequestResult} state
 * @param {BaseAction|ReceiveLoadSettingsAction|ReceiveLoadSettingsErrorAction} action
 * @return {RequestResult}
 */
export const loadSettingsList = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

    switch (action.type) {
        case LOAD_SETTINGS_LIST_REQUESTED:
            return new RequestResult(RequestState.getInstanceLoading(), state.data);
        case LOAD_SETTINGS_LIST_RECEIVED:
            return new RequestResult(RequestState.getInstanceDone(), action.loadSettingsList, action.receivedAt);
        case LOAD_SETTINGS_LIST_ERROR:
            return new RequestResult(
                RequestState.getInstanceError(
                    action.errorResponseStatusCode,
                    action.errorResponseMessage ? action.errorResponseMessage : null
                ),
                state.getData()
            );
        default:
            return state
    }
};
