import {createBaseOptions, fetchJson, METHOD_GET} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";
import { notificationError } from "../utils/NotificationsHelper";

export const fetchIdentityListNoEntry = (callback) => {
    let res = null
        
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_GET;
    options.headers["Content-Type"] = "application/json";

    let r = fetchJson(`${config.url.API_URL}/identityToEntries/custom/items/withoutAssignedEntries`, options);
    r.then(json => {
        if (json._embedded && json._embedded.dbUsers && json._embedded.dbUsers.length > 0 ) {
             res = json._embedded.dbUsers;
             callback(res);
        }
       
    }).catch(error => {
        if(error && error.response && error.response.response  && error.response.response.body){
            notificationError(error.response.response.body.error, error.response.response.body.error_description)
        }
        console.log(error);
        callback([]);
    });
};

