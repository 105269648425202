import React from "react";
import { Select, Space, Alert, Input } from "antd";
import { withTranslation } from "react-i18next";
import { getEntryTypeAttributesSelectOptions } from "../../../utils/EntryTypeHelper";
import {
	isUndefined,
	isEmptyValue,
	isArray,
} from "../../../utils/JsObjectHelper";
import { element } from "prop-types";

//let AttributesRelace = [];
const cloneDeep = require("lodash.clonedeep");

const { Option, OptGroup } = Select;

class BpmnVariablesValue extends React.Component {
	constructor(props) {
		super(props);
		const value = this.props.value;
		this.state = {
			value,
			isItemEdit: true,
		};
	}

	editItemModal = (entryTypesData, listEntry, itemList) => {
		//let entryTypes = [];
		let itemListArr = itemList.split(".");
		let isFolder = entryTypesData.filter((element) => listEntry.includes(element.type)).find(el => el.systemType === 'folder');
		let selectDataTemp = [
			{
				attTypes: listEntry,
				selValue: itemListArr[0],
				showChildren: !isUndefined(isFolder),
				showUser: true
			},
		];

		this.editItemModalRecursive(entryTypesData, itemListArr, selectDataTemp);

		return selectDataTemp;
	};

	editItemModalRecursive = (entryTypesData, valueArr, selectDataTemp, index = 0) => {
		if (valueArr.length < index + 1) return;
		if (isUndefined(selectDataTemp[index])) {
			return;
		}
		let tempET = selectDataTemp[index].attTypes;
		let tempVal = valueArr[index];
		let newSelDataObj = {
			attTypes: [],
			selValue: valueArr[index + 1],
			showChildren: valueArr[index] === "@@parent@@",
			showUser: false
		};
		if(valueArr[index] === "@@parent@@" || valueArr[index] === "@@children@@" ){
			selectDataTemp.push(newSelDataObj);
		}
		
		let tempETObj = entryTypesData.filter((el) => tempET.includes(el.type));
		let foundET = tempETObj.find((el) => !isUndefined(el.properties) && !isUndefined(el.properties.attributes.find((a) => a.techName === tempVal)));
		if (!isUndefined(foundET)) {
			newSelDataObj.attTypes = foundET.properties.attributes.find((a) => a.techName === tempVal).relationEntryType;
		}
		if (!isUndefined(newSelDataObj.attTypes) && !isEmptyValue(newSelDataObj.attTypes)) {
			if (!isArray(newSelDataObj.attTypes)) {
				newSelDataObj.attTypes = [newSelDataObj.attTypes];
			}
			selectDataTemp.push(newSelDataObj);
		}

		if (valueArr.length > index + 1)
			this.editItemModalRecursive(entryTypesData, valueArr, selectDataTemp, ++index);

	};

	checkIfRelationAtt = (entryTypesData, EntryTypesList, attTechName) => {
		let myTypes = entryTypesData.filter((oT) =>
			EntryTypesList.includes(oT.type)
		);


		return !isUndefined(myTypes.find(t => !isUndefined(t.properties.attributes.find(att => att.type === "relation" && att.techName === attTechName))));
	}

	handleChange = (index, value, selectData) => {
		let newValue = (isEmptyValue(this.props.value) ? "|#|" : this.props.value).split('.');

		if (newValue.length >= index) {
			newValue.splice(index);
		}

		newValue.push(value);
			if (this.checkIfRelationAtt(this.props.entryType.getData(), selectData[index].attTypes, value)) {
				newValue.push("|#|");
			}
			if(value === "@@parent@@" || value === "@@children@@" ){
				newValue.push("|#|");
			}
	


		this.setState({ value: newValue.join(".") });

		const { onChange } = this.props;
		if (onChange) {
			let a = newValue.join(".");
			onChange(a);
		}
	};
	
	optionPredefined = (showChildren, showUser) => {
		const { t } = this.props;
		let option = []

		option.push(
			<Option key={"@@parent@@"} value={"@@parent@@"}>
				{t("setup.workflowSetup.optionBpmnReservedParent")}
			</Option>
		);

		if (showChildren) {
			option.push(
				<Option key={"@@children@@"} value={"@@children@@"}>
					{t("setup.workflowSetup.optionBpmnReservedChildren")}
				</Option>
			);
		}
		if (showUser) {
			option.push(
				<Option key={"@@userName@@"} value={"@@userName@@"}>
					{t("setup.workflowSetup.optionBpmnReservedUserName")}
				</Option>,
				<Option key={"@@userId@@"} value={"@@userId@@"}>
					{t("setup.workflowSetup.optionBpmnReservedUserId")}
				</Option>);
		}

		option.push(
			
			
			<Option key={"@@entryName@@"} value={"@@entryName@@"}>
				{t("setup.workflowSetup.optionBpmnReservedNameEntry")}
			</Option>,
			<Option key={"@@entryDescription@@"} value={"@@entryDescription@@"}>
				{t("setup.workflowSetup.optionBpmnReservedEntryDescription")}
			</Option>,
			<Option key={"@@entryCreated@@"} value={"@@entryCreated@@"}>
				{t("setup.workflowSetup.optionBpmnReservedEntryCreated")}
			</Option>,
			<Option key={"@@entryCreatedById@@"} value={"@@entryCreatedById@@"}>
				{t("setup.workflowSetup.optionBpmnReservedEntryCreatedById")}
			</Option>,
			<Option key={"@@entryCreatedByName@@"} value={"@@entryCreatedByName@@"}>
				{t("setup.workflowSetup.optionBpmnReservedEntryCreatedByName")}
			</Option>,
			<Option key={"@@entryModified@@"} value={"@@entryModified@@"}>
				{t("setup.workflowSetup.optionBpmnReservedEntryModified")}
			</Option>,
			<Option key={"@@entryModifiedByName@@"} value={"@@entryModifiedByName@@"}>
				{t("setup.workflowSetup.optionBpmnReservedEntryModifiedByName")}
			</Option>,
			<Option key={"@@entryModifiedById@@"} value={"@@entryModifiedById@@"}>
				{t("setup.workflowSetup.optionBpmnReservedEntryModifiedById")}
			</Option>,
			<Option key={"@@entryType@@"} value={"@@entryType@@"}>
				{t("setup.workflowSetup.optionBpmnReservedEntryType")}
			</Option>,
			<Option key={"@@entryId@@ "} value={"@@entryId@@"}>
				{t("setup.workflowSetup.optionBpmnReservedEntryId")}
			</Option>
		)

		return option

	};

	render() {

		const { t } = this.props;
		let entryTypes = this.props.entryType.getData();
		let select = [];
		let selectDataObject = [];

		selectDataObject = this.editItemModal(this.props.entryType.getData(), this.props.list, (isEmptyValue(this.props.value) ? "|#|" : this.props.value));

		selectDataObject.forEach((att, i) => {
			let options = getEntryTypeAttributesSelectOptions(entryTypes, att.attTypes, false, true, true);

			let predefinedOptions = this.optionPredefined(att.showChildren, att.showUser)

			if (i !== 0) {
				options = options.filter((el) => {
					let varExist = selectDataObject.find(
						(e, index) => e.selValue === el.key && i !== index
					);
					return !(!isUndefined(varExist) && !isEmptyValue(varExist.attTypes.filter((value) => att.attTypes.includes(value)))
					);
				});
			}

			// if (isEmptyValue(options)) {
			// 	select.push(<Alert key={i + "alert"} message={t("setup.workflowSetup.warningNoAditionalOptionsInRelation")} type="warning" showIcon />);
			// }
			
				select.push(
					<Select
						showSearch
						placeholder={t("setup.workflowSetup.bpmnAtributesSelectPlaceholder")}
						defaultValue={att.selValue === '|#|' ? null : att.selValue}
						key={i + att.selValue}
						onChange={(e) => this.handleChange(i, e, selectDataObject)}
						filterOption={(input, option) => option.title.toLowerCase().includes(input.toLowerCase())}
					>
						<OptGroup label={t("setup.workflowSetup.selectGroupAtributs")}>
							{options}
						</OptGroup>
						<OptGroup label={t("setup.workflowSetup.selectGroupSystemValue")}>
							{predefinedOptions}
						</OptGroup>
					</Select>
				);
		
		});

		return (
			<Space direction="vertical" size="middle" style={{ display: "flex" }}>
				{select}
			</Space>
		);
	}
}
export default withTranslation()(BpmnVariablesValue);
