import PropTypes from 'prop-types';

export const bpmnFormShape = PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    schemaVersion: PropTypes.number,
    components: PropTypes.arrayOf({
      
    }),

});