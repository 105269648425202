import {
    SETTINGS_LOAD_NEW,
    SETTINGS_DETAIL_REQUESTED,
    SETTINGS_DETAIL_UPDATE_REQUESTED,
    SETTINGS_DETAIL_RECEIVED,
    SETTINGS_DETAIL_UNMOUNT,
    SETTINGS_DETAIL_ERROR, SETTINGS_SAVE_ERROR, SETTINGS_DELETE_ERROR
} from '../actions/settings.js';
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";
import {EMPTY_SETTINGS} from "../utils/EmptySettings";
const cloneDeep = require('lodash.clonedeep');

/**
 *
 * @param {RequestResult} state
 * @param {BaseAction|ReceiveSettingsAction|ReceiveSettingsErrorAction} action
 * @return {RequestResult}
 */
export const settingsDetail = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

    switch (action.type) {
        case SETTINGS_DETAIL_REQUESTED:
            return new RequestResult(RequestState.getInstanceLoading(), null);
        case SETTINGS_LOAD_NEW:
            return new RequestResult(RequestState.getInstanceDone(), cloneDeep(EMPTY_SETTINGS));
        case SETTINGS_DETAIL_UNMOUNT:
            return new RequestResult(RequestState.getInstanceInit(), null);
        case SETTINGS_DETAIL_UPDATE_REQUESTED:
            return new RequestResult(RequestState.getInstanceUpdating(), state.data);
        case SETTINGS_DETAIL_RECEIVED:
            return new RequestResult(RequestState.getInstanceDone(), action.item, action.receivedAt);
        case SETTINGS_DETAIL_ERROR:
        case SETTINGS_SAVE_ERROR:
        case SETTINGS_DELETE_ERROR:
            return new RequestResult(
                RequestState.getInstanceError(
                    action.errorResponseStatusCode,
                    action.errorResponseMessage ? action.errorResponseMessage : null
                ),
                state.getData()
            );
        default:
            return state
    }
};
