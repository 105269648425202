import ImportContentSructurePresenter from '../../../components/setup/ImportExport/ImportContentStructure';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {
    saveNewObjectDefinition,
} from "../../../actions/objectDefinitionDetail";


const mapStateToProps = (state, ownProps) => {
    return {
        listDataRequestResult: state.objectList,
        user: state.loggedUser.user,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSaveObjectDefinition: (item) => {
                // create new
                 dispatch(saveNewObjectDefinition(item));
        },
    }
};

const ImportContentSructure = connect(
    mapStateToProps,
    mapDispatchToProps
)(ImportContentSructurePresenter);

export default withRouter(ImportContentSructure);