import EntryOnDemandWorkflowListPresenter from '../../components/detail/view/bpmn/EntryOnDemandWorkflowList';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { isUndefined } from '../../utils/JsObjectHelper';
//import { fetchObjectList } from '../../actions/objectList';
import { fetchSettingsList } from '../../actions/settings';
import { SETTINGS_WORKFLOW_USETYPE_ONRUNBYUSER } from '../../utils/EmptySettings';

const getOnDemandWorkflowSettingsList = (settingsList/*, objectList*/) => {
    if (!isUndefined(settingsList) && settingsList.getState().isDone() /*&& !isUndefined(objectList) && objectList.getState().isDone()*/) {
        let setList = settingsList.getData()
            .filter(
                eT => eT.id === "workflow" && 
                eT.value.workflowUseType === SETTINGS_WORKFLOW_USETYPE_ONRUNBYUSER && 
                eT.value.isEnabled
            );
        /*let objList = objectList.getData();
        setList.forEach(element => {
            let objNameList = element.value.entryTypes.map(eT => { return objList.find(obj=>obj.type === eT).name;});
    
            element.value.entryTypesNameList = objNameList;
        });*/

        return setList;
    } else {
        return null;
    }
};


const mapStateToProps = (state, ownProps) => {
    return {
        workflowSettingsList: getOnDemandWorkflowSettingsList(state.settingsList/*, state.objectList*/),
        loggedUser: state.loggedUser,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            //dispatch(fetchObjectList());
            dispatch(fetchSettingsList(true));
        },
    }
};

const EntryOnDemandWorkflowList = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryOnDemandWorkflowListPresenter);

export default withRouter(EntryOnDemandWorkflowList);