import {
    ENTRY_DETAIL_REQUESTED,
    ENTRY_DETAIL_UPDATE_REQUESTED,
    ENTRY_DETAIL_RECEIVED,
    ENTRY_DETAIL_UNMOUNT,
    ENTRY_DETAIL_ERROR,
    ENTRY_SAVE_ERROR,
    ENTRY_DELETE_ERROR,
    ENTRY_EDIT_EVENT_ERROR
} from '../actions/entryDetail';
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";

/**
 *
 * @param {RequestResult} state
 * @param {BaseAction|EntryIdAction|ReceiveEntryAction|ReceiveEntryErrorAction} action
 * @return {RequestResult}
 */
export const entryDetail = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

    switch (action.type) {
        case ENTRY_DETAIL_REQUESTED:
            return new RequestResult(RequestState.getInstanceLoading(), null);
        case ENTRY_DETAIL_UNMOUNT:
            return new RequestResult(RequestState.getInstanceInit(), null);
        case ENTRY_DETAIL_UPDATE_REQUESTED:
            return new RequestResult(RequestState.getInstanceUpdating(), state.data);
        case ENTRY_DETAIL_RECEIVED:
            return new RequestResult(RequestState.getInstanceDone(), action.entry, action.receivedAt);
        case ENTRY_DETAIL_ERROR:
        case ENTRY_SAVE_ERROR:
        case ENTRY_DELETE_ERROR:
        case ENTRY_EDIT_EVENT_ERROR:
            return new RequestResult(
                RequestState.getInstanceError(
                    action.errorResponseStatusCode,
                    action.errorResponseMessage ? action.errorResponseMessage : null
                ),
                state.getData()
            );
        default:
            return state
    }
};
