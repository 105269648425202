import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Modal, Table, Tag, Tooltip, Steps, Input, Alert, Select } from 'antd';
import { isArray, isEmptyObject, isEmptyValue, isUndefined } from "../../../utils/JsObjectHelper";
import { getAttributeTypeIcon } from "../../../utils/EntryTypeHelper";
import { ToTopOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { config } from "../../../config/Config";
import cloneDeep from "lodash.clonedeep";
const { Search } = Input;
class ExportContentStructure extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            entryList: [],
            advSearchPageSize: (isEmptyObject(localStorage.getItem("setupEntryTypePageSize")) ? 20 : parseInt(localStorage.getItem("setupEntryTypePageSize"))),
            selectedRowKeys: [],
            selectedEntries: [],
            current: 0,
        };

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.listDataRequestResult.getData() !== this.props.listDataRequestResult.getData()) {
            let entryList = (this.props.listDataRequestResult.getState().isDone() ?
                this.props.listDataRequestResult.getData().filter(eT => eT.systemType === "object") : []);
            this.setState({ entryList: entryList });
        }
    }
    filterEntryTypes = (searchValue) => {
        this.setState({ searchNameText: searchValue.split(' ') });
    }
    showModal = () => {
        this.setState({ visible: true });
    };
    checkRelationsExist = (mainArray) => {
        let resultArray = [];
        mainArray.forEach(mainObject => {
            mainObject.properties.attributes.forEach(attribute => {
                if (attribute.type === "relation") {
                    if (isArray(attribute.relationEntryType)) {
                        attribute.relationEntryType.forEach(relationType => {
                            if (!relationType.includes("##")) {
                                let matchingObject = mainArray.find(object => object.type === relationType);
                                if (!matchingObject) {
                                    let existingObject = resultArray.find(resultObject => resultObject.name === mainObject.name);
                                    let entryType = this.state.entryList.find((el) => el.type === relationType);
                                    if (existingObject) {
                                        let attExisting = existingObject.attributes.find((el) => el.attName === attribute.name);
                                        if (!attExisting) {
                                            let noValidobj = { attName: attribute.name, entryType: [{ attName: attribute.name, parent: mainObject.name, type: relationType, name: entryType.name }], }
                                            existingObject.attributes.push(noValidobj);
                                        }
                                        else {
                                            attExisting.entryType.push({ attName: attribute.name, parent: mainObject.name, type: relationType, name: entryType.name });
                                        }
                                    } else {
                                        resultArray.push({
                                            name: mainObject.name,
                                            attributes: [{ attName: attribute.name, entryType: [{ attName: attribute.name, parent: mainObject.name, type: relationType, name: entryType.name }] }]
                                        });
                                    }
                                }
                            }
                        });
                    }
                    else if (!attribute.relationEntryType.includes("##")) {
                        let matchingObject = mainArray.find(object => object.type === attribute.relationEntryType);
                        if (!matchingObject) {
                            let existingObject = resultArray.find(resultObject => resultObject.name === mainObject.name);
                            let entryType = this.state.entryList.find((el) => el.type === attribute.relationEntryType);
                            if (existingObject) {
                                existingObject.attributes.push({ attName: attribute.name, entryType: [{ attName: attribute.name, parent: mainObject.name, type: attribute.relationEntryType, name: entryType.name }] });
                            } else {
                                resultArray.push({
                                    name: mainObject.name,
                                    attributes: [{ attName: attribute.name, entryType: [{ attName: attribute.name, parent: mainObject.name, type: attribute.relationEntryType, name: entryType.name }] }]
                                });
                            }
                        }
                    }
                }
            });
        });

        return resultArray;
    }
    handleOk = () => {
        if (this.state.selectedEntries) {
            let data = this.state.selectedEntries.map((el) => {
                el.properties.attributes.forEach((attribute) => {
                    if (attribute.type === "relation" && attribute.relationEntryParent && attribute.relationEntryParent.length > 0) {
                        attribute.relationEntryParent = null;
                    }
                }); el.id = null; el._links = null; return el
            });

            let exportObject = { programName: `${document.location.host}   ${config.version.number}`, date: new Date(), createdBy: this.props.user.name, countEtryType: data.length, data: data, }
            const blob = new Blob([JSON.stringify(exportObject)], { type: "application/json" });
            const url = URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = 'aphinit-content-structure.json';
            a.click();
            this.setState({ visible: false, selectedEntries: [], selectedRowKeys: [], current: 0 });
        }
    };
    handleCancel = () => {
        this.setState({ visible: false });
    };
    showTotal = (total) => {
        return `(${total})`;
    };
    handlePageSizeChanged = (current, page) => {
        localStorage.setItem("setupEntryTypePageSize", page);
        this.setState({ advSearchPageSize: page });
    };
    onSelectChange = (newSelectedRow) => {
        let data = cloneDeep(this.state.selectedEntries);
        let newSelectedRowKeys = cloneDeep(this.state.selectedRowKeys);
        let del = newSelectedRowKeys.findIndex((el) => el === newSelectedRow.id);
        let delEnt = data.findIndex((el) => el.id === newSelectedRow.id);
        if (del !== -1 && delEnt !== -1) {
            data.splice(delEnt, 1);
            newSelectedRowKeys.splice(del, 1);
        }
        else {
            data.push(newSelectedRow);
            newSelectedRowKeys.push(newSelectedRow.id);
        }
        this.setState({ selectedRowKeys: newSelectedRowKeys, selectedEntries: data });
    };
    next = () => {
        this.setState({ current: this.state.current + 1 });
    };
    prev = () => {
        this.setState({ current: this.state.current - 1 });
    };
    handleChange = (value) => {
        let element = JSON.parse(value);
        console.log(element);
        let data = cloneDeep(this.state.selectedEntries);
        if (element.type === 'add') {
            let entryType = this.state.entryList.find((el) => el.type === element.entry.type)
            data.push(entryType);
            this.setState({ selectedEntries: data });
        }
        else if (element.type === 'def') {
            let editEntryType = data.find((el) => el.name === element.entry.parent);
            let attribute = editEntryType.properties.attributes.find((att) => att.name === element.entry.attName);
            if (isArray(attribute.relationEntryType)) {
                let index = attribute.relationEntryType.findIndex((el) => el === element.entry.type);
                attribute.relationEntryType[index] = `##${element.entry.name}`;
            }
            else {
                attribute.relationEntryType = `##${element.entry.name}`;
            }
            this.setState({ selectedEntries: data });
        }
    }
    selectAll = () => {
        if (this.state.selectedEntries.length === this.state.entryList.length) {
            this.setState({ selectedRowKeys: [], selectedEntries: [] });
        }
        else {
            let newSelectedRowKeys = [];
            this.state.entryList.forEach((el) => newSelectedRowKeys.push(el.id));
            this.setState({ selectedRowKeys: newSelectedRowKeys, selectedEntries: this.state.entryList });
        }
    }
    render() {
        const { t } = this.props;
        let paginationSetup = {
            showSizeChanger: true,
            size: "small",
            showTotal: this.showTotal,
            defaultPageSize: this.state.advSearchPageSize,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            onShowSizeChange: this.handlePageSizeChanged
        };
        const columns = [
            {
                title: t('setup.entryTypes.tblName'),
                key: "name",
                width: "10%",
                render: (text, record) => (
                    <span><b>{record.name}</b></span>
                ),
                filteredValue: this.state.searchNameText,
                onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
                sorter: (a, b) => {
                    return (isUndefined(a["name"]) ? "" : a["name"]).localeCompare((isUndefined(b["name"]) ? "" : b["name"]));
                }
            },
            {
                title: t('setup.entryTypes.tblAttributes'),
                dataIndex: ["properties", "attributes"],
                key: "properties.attributes",
                width: "80%",
                render: (tags) => (
                    <span>
                        {tags.map(tag => (
                            <Tag color="blue" key={tag.name}>
                                {getAttributeTypeIcon(tag.type)} {tag.name}
                            </Tag>
                        ))}
                    </span>
                )
            },
            {
                title: t('setup.entryTypes.tblColor'),
                dataIndex: ["properties", "typeColor"],
                key: "properties.typeColor",
                width: "10%",
                render: (color) => (
                    <span>
                        <Tag color={isEmptyValue(color) ? "blue" : color}>
                            {t('setup.entryTypes.tblColor')}
                        </Tag>
                    </span>
                )
            },
        ];
        const { selectedRowKeys, } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onSelectAll: this.selectAll,
            onSelect: this.onSelectChange,
        };
        let step1 = (<div > <Search
            placeholder={t('setup.entryTypes.searchBoxHint')}
            onSearch={this.filterEntryTypes}
            style={{ width: "100%", marginBottom: '10px' }}
            allowClear
        />
            <Table
                dataSource={this.state.entryList}
                rowSelection={rowSelection}
                rowKey={record => record.id}
                size="small"
                scroll={{ x: true }}
                loading={this.props.listDataRequestResult.getState().isLoading()}
                pagination={paginationSetup}
                columns={columns} />
        </div>);
        let step2 = null;
        const columnsForStep2 = [
            {
                title: t('setup.entryTypes.importExport.exportTableTwoTitleAtt'),
                key: "name",
                width: "10%",
                render: (text, record) => (
                    <span><b>{record.attName}</b></span>
                ),
            },
            {
                key: "nameEntryType",
                width: "10%",
                render: (text, mainRecord) => {
                    let columnInStep2Table = [
                        {
                            title: t('setup.entryTypes.importExport.exportTableTwoTitleEntryType'),
                            key: "nameEntryType",
                            width: "10%",
                            render: (text, record) => {
                                return <span><b>{record.name ? record.name : null}</b></span>
                            },
                        },
                        {
                            title: t('setup.entryTypes.importExport.exportTableTwoTitleAction'),
                            key: "select",
                            width: "10%",
                            render: (text, record) => (

                                <Select
                                    key={record.name}
                                    style={{
                                        width: 200,
                                    }}
                                    placeholder={t('setup.entryTypes.importExport.exportPlaceholderSelectAction')}
                                    onChange={this.handleChange}
                                    options={[
                                        {
                                            value: JSON.stringify({ type: 'add', entry: record }),
                                            label: t('setup.entryTypes.importExport.exportPlaceholderSelectOptionAdd'),
                                        },
                                        {
                                            value: JSON.stringify({ type: 'def', entry: record }),
                                            label: t('setup.entryTypes.importExport.exportPlaceholderSelectOptionConfigure'),
                                        },
                                    ]}
                                />
                            ),
                        },
                    ]
                    return <Table
                        dataSource={mainRecord.entryType}
                        columns={columnInStep2Table}
                        pagination={false}
                        size="small"
                    />
                }
            },

        ];
        let noEntryTypeInList = [];
        if (this.state.current === 1) {
            noEntryTypeInList = this.checkRelationsExist(this.state.selectedEntries);
            if (noEntryTypeInList.length > 0) {
                step2 = <div><Alert message={t('setup.entryTypes.importExport.exportAlertStepTwo')} type="warning" showIcon />
                    {noEntryTypeInList.map((entry, index) => {
                        return (<div className="advancedSearchRow">
                            <Alert message={<span style={{ fontWeight: 'bolder' }}>{entry.name}</span>} key={entry.name + "alert"} type="info" />
                            <Table
                                key={entry.name + 'table'}
                                dataSource={entry.attributes}
                                size="small"
                                scroll={{ x: true }}

                                columns={columnsForStep2} />
                        </div>)
                    })}
                </div>
            }
            else {
                step2 = (<div style={{ marginTop: '20px' }}>
                    {this.state.selectedEntries.map((entry, index) => <Alert message={<span style={{ fontWeight: 'bolder' }}>{entry.name}</span>} style={{ marginBottom: '10px' }} key={entry.name + "alert"} type="success" showIcon />)}</div>)
            }

        };

        const steps = [
            {
                title: 'Choice entry type',
                content: step1,
            },
            {
                title: 'Validation',
                content: step2,
            },
        ];
        let modal = <Modal title={t('setup.entryTypes.importExport.exportModalTitle')} width="60%" key='modalExport' visible={this.state.visible} footer={false} onOk={this.handleOk} onCancel={this.handleCancel}>
            <Steps current={this.state.current} />

            <div style={{ maxHeight: '1050px', overflow: "auto", overflowX: "hidden", }}>{steps[this.state.current].content}</div>
            <div
                style={{
                    marginTop: 24,
                    display: "flex",
                    justifyContent: 'flex-end'
                }}
            >
                {this.state.current < steps.length - 1 && (
                    <Button type="primary" onClick={() => this.next()}>
                        {t('setup.entryTypes.importExport.exportBtnNext')}
                    </Button>
                )}
                {this.state.current === steps.length - 1 && (
                    <Button type="primary" disabled={this.state.current === 1 && noEntryTypeInList.length > 0} onClick={() => this.handleOk()}>
                        {t('setup.entryTypes.importExport.exportBtnexport')}
                    </Button>
                )}
                {this.state.current > 0 && (
                    <Button
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={() => this.prev()}
                    >
                        {t('setup.entryTypes.importExport.exportBtnPrevious')}
                    </Button>
                )}
            </div>
        </Modal>

        return (
            <>
                {modal}
                <Tooltip placement="top" title={t('setup.entryTypes.importExport.exportBtnTooltip')}>
                    <Button type="button" onClick={this.showModal} style={{ color: 'black', padding: '5', marginLeft: '5px', marginRight: '5px' }}><ToTopOutlined /></Button>

                </Tooltip>
            </>
        );
    }
}

const ExportContentStructureWithRouter = withRouter(ExportContentStructure);

export default withTranslation()(ExportContentStructureWithRouter);