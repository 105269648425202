import { createBaseOptions, fetchJson, METHOD_GET } from "../../utils/SuperAgentFetch";
import { config } from "../../config/Config";

export const fetchUseEntryTypeInDashboard = (type, callback) => {


    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_GET;
    options.headers["Content-Type"] = "application/json";

    let r = fetchJson(`${config.url.API_URL}/entryTypes/custom/itemByType/${type}/deleteConfiguration`, options);
    r.then(json => {
        let res = [];

        if (json._embedded && json._embedded.userCustomizationCounts && json._embedded.userCustomizationCounts.length > 0) {
            res = json._embedded.userCustomizationCounts;
            callback(res);
        }


    }).catch(error => {
        console.log(error);
        // callback(error);
    });
};

