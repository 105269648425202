import React from "react";
import { Button, Drawer, Modal, Space, Tabs } from 'antd';
import { 
    KeyOutlined, 
    DeploymentUnitOutlined, 
    InfoCircleOutlined, 
    FileOutlined, 
    ThunderboltOutlined, 
    ClockCircleOutlined, 
    MessageOutlined,
    ApartmentOutlined,
    ToolOutlined,
    CloseOutlined
} from '@ant-design/icons';
import {bmcEntryShape} from "../../../shapes/BmcEntryShape";
import EntryTechnicalInfo from "./EntryTechnicalInfo"
import EntryActions from "./EntryActions"
import EntryFriends from "../../../containers/detail/EntryFriends";
import EntryAccess from "../../../containers/detail/EntryAccess";
import EntryVersionList from "../../../containers/detail/EntryVersionList";
import EntryComments from "../../../containers/detail/EntryComments";
import {fetchEntryCommentCount} from '../../../apicalls/fetchEntryCommentCount';
import { isEmptyValue } from "../../../utils/JsObjectHelper";
import { withTranslation} from 'react-i18next'
import {withRouter} from "react-router-dom";
import EntryFiles from "../../../containers/detail/EntryFiles";

const { TabPane } = Tabs;
const ButtonGroup = Button.Group;

class EntryToolsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            width: window.innerWidth,
            actionKey: "contextengine",
            commentCount: "-"
        };
    }
      
    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);

        fetchEntryCommentCount(this.props.entry.id, this.updateCommentCount);
    };

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    };

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    showDrawer = (actionKeySelected) => {
        let actionKey = this.state.actionKey;

        //lineage smeruje na stranku lineage misto otevirani tools panelu
        if (actionKeySelected && actionKeySelected === "lineage") {
            //this.props.history.push(`/lineage/` + this.props.entry.id);
            //return ;
            window.open("/lineage/" + this.props.entry.id, "_blank");
            return ;
        }

        if (actionKeySelected && actionKeySelected !== "tools") {
            actionKey = actionKeySelected;
        }

        this.setState({
            visible: true,
            actionKey: actionKey
        });
    };

    updateCommentCount = (commentCount) => {
        this.setState({
            commentCount: (isEmptyValue(commentCount) ? "0" : commentCount.toString())
        });
    };

    onChangeTab = (tabKey) => {
        this.setState({
            actionKey: tabKey
        });
    };

    onClose = () => {
        fetchEntryCommentCount(this.props.entry.id, this.updateCommentCount);

        this.setState({
            visible: false,
        });
    };


    render() {
        const {t} = this.props;
        const { width } = this.state;
        const WIDTH_LIMIT = 800;

        const isMobile = width <= WIDTH_LIMIT;

        let accessPanel = null;
        if (this.props.entry.userEntryAccess === 'EDIT' && this.props.entry.systemType === "folder") {
            accessPanel = <TabPane
                            tab={
                                <span>
                                    <KeyOutlined />
                                    {t('app.entry.tools.tabAccessRightsTitle')}
                                </span>
                            }
                            key="accessrights"
                        >
                            <EntryAccess entry={this.props.entry} ></EntryAccess>
                        </TabPane>;
        }

        const innerPanel = <Tabs /*defaultActiveKey={this.state.actionKey}*/
                                type="card"
                                onTabClick={this.onChangeTab} 
                                activeKey={this.state.actionKey} 
                                size="small"
                                //tabBarGutter={10}
                                >
                                <TabPane
                                    tab={
                                        <span>
                                            <DeploymentUnitOutlined />
                                            {t('app.entry.tools.tabContextEngineTitle')}
                                        </span>
                                    }
                                    key="contextengine"
                                >
                                    <EntryFriends entry={this.props.entry} ></EntryFriends>
                                </TabPane>
                                <TabPane
                                    tab={
                                        <span>
                                            <InfoCircleOutlined />
                                            {t('app.entry.tools.tabTechnicalDataTitle')}
                                        </span>
                                    }
                                    key="techdata"
                                >
                                    <EntryTechnicalInfo entry={this.props.entry} ></EntryTechnicalInfo>
                                </TabPane>
                                <TabPane
                                    tab={
                                        <span>
                                            <FileOutlined />
                                            {t('app.entry.tools.tabFileList')}
                                        </span>
                                    }
                                    key="filelist"
                                >
                                    <EntryFiles entry={this.props.entry}></EntryFiles>
                                </TabPane>
                                {accessPanel}
                                <TabPane
                                    tab={
                                        <span>
                                            <ThunderboltOutlined />
                                            {t('app.entry.tools.tabActions')}
                                        </span>
                                    }
                                    key="actions"
                                >
                                    <EntryActions entry={this.props.entry}></EntryActions>
                                </TabPane>
                                <TabPane
                                    tab={
                                        <span>
                                            <ClockCircleOutlined />
                                            {t('app.entry.tools.tabVersionsTitle')}
                                        </span>
                                    }
                                    key="versions"
                                >
                                    <EntryVersionList entry={this.props.entry}></EntryVersionList>
                                </TabPane>
                                <TabPane
                                    tab={
                                        <span>
                                            <MessageOutlined />
                                            {t('app.entry.tools.tabCommentsTitle')}
                                        </span>
                                    }
                                    key="comments"
                                >
                                    <EntryComments entry={this.props.entry}></EntryComments>
                                </TabPane>

                            </Tabs>;
        
        let lineageButton = null;
        if (this.props.entry.systemType === "object") {
            lineageButton = <Button onClick={()=>this.showDrawer("lineage")} key="lineage" name="lineage" icon={<ApartmentOutlined />}>
                              <span className="toolsPanelBtnText">{t('app.entry.tools.btnLineage')}</span>
                            </Button>;
        };
        
        const panelButtons = <Space size={0}>
                                {lineageButton}
                                <Button onClick={()=>this.showDrawer("versions")} key="versions" name="versions" icon={<ClockCircleOutlined />}>
                                    <span className="toolsPanelBtnText">{this.props.entry.version}</span>
                                </Button>
                                <Button onClick={()=>this.showDrawer("comments")} key="comments" name="comments" icon={<MessageOutlined />}>
                                    <span className="toolsPanelBtnText">{this.state.commentCount}</span>
                                </Button>
                                <Button onClick={()=>this.showDrawer("tools")} key="tools" name="tools" icon={<ToolOutlined />}>
                                <span className="toolsPanelBtnText">{t('app.entry.tools.btnToolsPanelText')}</span>
                                </Button>
                            </Space>

        if (isMobile) {
            return (
                <span>
                    {panelButtons}
                    <Modal
                        title={t('app.entry.tools.toolsPanelTitle')}
                        visible={this.state.visible}
                        width={'100%'}
                        height={'100%'}
                        onOk={this.onClose}
                        onCancel={this.onClose}
                        footer={[
                            <Button key="back" onClick={this.onClose}>
                            {t('app.entry.tools.btnModalReturn')}
                            </Button>,
                        ]}
                    >
                        {innerPanel}
                    </Modal>
                </span>
            );
            } else {
            return (
                <span>
                    {panelButtons}
                    <Drawer
                        title={t('app.entry.tools.toolsPanelTitle')}
                        placement="right"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.visible}
                        width={WIDTH_LIMIT}
                        extra={
                              <Button type='link' onClick={this.onClose}><CloseOutlined style={{color:'black',fontSize:'24px'}}/></Button>
                          }
                    >
                        {innerPanel}
                    </Drawer>
                </span>
            );
        }
    }
}

EntryToolsPanel.propTypes = {
    entry: bmcEntryShape.isRequired
};

const EntryToolsPanelWithRouter = withRouter(EntryToolsPanel);

export default withTranslation() (EntryToolsPanelWithRouter);