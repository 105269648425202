import React from "react";
import { Card, Table, Tag } from "antd";
import { LockOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { entryResultDetailShape, incomingRelationsRequestResult, outgoingRelationsRequestResult, entryTypesRequestResult } from "../../../shapes/RequestResult";
import EntryTypeTag from '../../controls/EntryTypeTag';
import { isEmptyObject, isUndefined } from "../../../utils/JsObjectHelper";
import { Link } from "react-router-dom";
import { getColumnSearchProps } from "../../../utils/TableHelper";
import { highlightSearchedText } from "../../../utils/TextHighlighter";


class EntryUserRelationTable extends React.Component {

    state = {
        searchText: '',
        searchedColumn: '',
    };

    getRelationName = (sourceType, attType) => {
        let entryDef = this.props.entryTypesRequestResult.getData().find(eT => eT.type === sourceType);
        if (!isEmptyObject(entryDef) && !isUndefined(entryDef.properties.attributes)) {
            let attDef = entryDef.properties.attributes.find(att => att.techName === attType);
            return (!isEmptyObject(attDef) && !isEmptyObject(attDef.name)) ? attDef.name : attType;
        }
        return attType;
    };

    handleRenderTextColumn = (text, record, dataIndex, inOutKey) => {
        const { t } = this.props;

        if (text === "@@@RESTRICTED@@@") {
            return <Tag
                color="red"
                style={{ borderStyle: 'dashed' }}
                title={t('app.entry.attributes.relationIsHiddenMessage')}
            >
                <LockOutlined /> {t('app.entry.attributes.relationIsHiddenTagTitle')}
            </Tag>;
        }

        return this.state.searchedColumn === dataIndex ? (
            (inOutKey === 1 ?
                <Link to={`/entry/${record.id}`}>{highlightSearchedText(text, this.state.searchTextIncomming)}</Link> :
                <Link to={`/entry/${record.id}`}>{highlightSearchedText(text, this.state.searchTextOutgoing)}</Link>)
        ) : (
            <Link to={`/entry/${record.id}`}>{text}</Link>
        );
    };

    handleSearch = (selectedKeys, confirm, dataIndex, inOutKey) => {
        confirm();
        if (inOutKey === 1) {
            this.setState({
                searchTextIncomming: selectedKeys[0],
                searchedColumn: dataIndex,
            });
        } else {
            this.setState({
                searchTextOutgoing: selectedKeys[0],
                searchedColumn: dataIndex,
            });
        }
    };

    handleReset = (clearFilters, inOutKey) => {
        clearFilters();
        if (inOutKey === 1) {
            this.setState({
                searchTextIncomming: ''
            });
        } else {
            this.setState({
                searchTextOutgoing: ''
            });
        }
    };

    showTotal = (total) => {
        return `(${total})`;
    };

    render() {
        const { t } = this.props;

        let entryTypeNameList = [];

        if (this.props.entryTypesRequestResult.getState().isDone()) {
            entryTypeNameList = this.props.entryTypesRequestResult.getData().map((item, key) => { return { type: item.type, name: item.name, color: item.properties.typeColor }; });
        }

        /** @type {RequestResult} */
        const rr = this.props.entryRequestResult;
        /** @type {RequestResult} */
        const rrIncRelations = this.props.incomingRelationsRequestResult;
        /** @type {RequestResult} */
        const rrOutRelations = this.props.outgoingRelationsRequestResult;

        /** @type {Array<IncomingRelation>|null} */
        let incomingRelations = [];
        /** @type {Array<OutgoingRelation>|null} */
        let outgoingRelations = [];

        let entry = null;
        let isLoadingData = false;

        if (this.props.entryRequestResult) {
            entry = rr;

            if (!isEmptyObject(rrIncRelations) && rrIncRelations.getState().isDone() && !isEmptyObject(rrIncRelations.getData())) {
                incomingRelations = rrIncRelations.getData();
                isLoadingData = false

            }

            if (!isEmptyObject(rrOutRelations) && rrOutRelations.getState().isDone() && !isEmptyObject(rrOutRelations.getData())) {
                outgoingRelations = rrOutRelations.getData();
                isLoadingData = false

            }

            if (isEmptyObject(rrIncRelations) || !rrIncRelations.getState().isDone() || isEmptyObject(rrOutRelations) || !rrOutRelations.getState().isDone()) {
                isLoadingData = true;

            }
        } else {
            isLoadingData = true;
        }
        const expandedRowRender = (parentRow) => {
            let columnsIn = [
                {
                    title: t('app.entry.view.tblRelationsEntry'),
                    dataIndex: 'entry',
                    key: 'entry',
                    ...getColumnSearchProps('entry',
                        (selectedKeys, confirm, dataIndex) => { this.handleSearch(selectedKeys, confirm, dataIndex, parentRow.key) },
                        (clearFilters) => { this.handleReset(clearFilters, parentRow.key) },
                        (text, record, dataIndex) => { return this.handleRenderTextColumn(text, record, dataIndex, parentRow.key); },
                        t, this.searchInput)
                },
                {
                    title: t('app.entry.view.tblEntriesType'),
                    dataIndex: 'entryType',
                    key: 'entryType',
                    render: (text, record) => <EntryTypeTag entryTypeName={text} entryTypeNameList={entryTypeNameList}></EntryTypeTag>,
                    filters: [],
                    onFilter: (value, record) => record.entryType.indexOf(value) === 0
                },
                {
                    title: t('app.entry.view.tblRelationsRelType'),
                    dataIndex: 'relType',
                    key: 'relType',
                    filters: [],
                    sorter: (a, b) => a.relType.localeCompare(b.relType),
                    onFilter: (value, record) => record.relType.indexOf(value) === 0
                }
            ];

            let dataIn = [];
            switch (parentRow.key) {
                case 1:
                    dataIn = incomingRelations.map(
                        (item, key) => {
                            return {
                                id: item.source.id,
                                entry: item.source.name,
                                entryType: item.source.type,
                                relType: this.getRelationName(item.source.type, item.name),
                                key: key
                            };
                        }
                    );
                    columnsIn[1].filters = Array.from(new Set(incomingRelations.map(itm => itm.source.type))).map(
                        itmName => {
                            let typeNameList = entryTypeNameList.find(t => t.type === itmName);
                            return { text: (!isUndefined(typeNameList) ? typeNameList.name : itmName), value: itmName };
                        }
                    );
                    columnsIn[2].filters = Array.from(new Set(incomingRelations.map(itm => { return this.getRelationName(itm.source.type, itm.name) }))).map(
                        itmName => {
                            return { text: itmName, value: itmName };
                        }
                    );
                    break;
                case 2:
                    dataIn = outgoingRelations.map(
                        (item, key) => {
                            return {
                                id: item.target.id,
                                entry: item.target.name,
                                entryType: item.target.type,
                                relType: this.getRelationName(entry.type, item.name),
                                key: key
                            };
                        }
                    );
                    columnsIn[1].filters = Array.from(new Set(outgoingRelations.map(itm => itm.target.type))).map(
                        itmName => {
                            let typeNameList = entryTypeNameList.find(t => t.type === itmName);
                            return { text: (!isUndefined(typeNameList) ? typeNameList.name : itmName), value: itmName };
                        }
                    );
                    columnsIn[2].filters = Array.from(new Set(outgoingRelations.map(itm => itm.name))).map(
                        itmName => {
                            return { text: this.getRelationName(entry.type, itmName), value: this.getRelationName(entry.type, itmName) };
                        }
                    );
                    break;
                default:
                    break;
            }
            let paginationSetup = {
                showSizeChanger: true,
                size: "small",
                showTotal: this.showTotal,
                defaultPageSize: '30', //this.state.childTablePageSize,
                pageSizeOptions: ['10', '20', '30', '50', '100'],
                //onShowSizeChange: this.handlePageSizeChanged
            };

            return <Table columns={columnsIn} dataSource={dataIn} pagination={paginationSetup} size="small" />;
        };

        const columns = [
            { title: t('app.entry.view.tblRelationsType'), dataIndex: 'type', key: 'type' },
            { title: t('app.entry.view.tblRelationsCount'), dataIndex: 'count', key: 'count' }
        ];

        let tabData = [
            {
                key: 1,
                type: t('app.entry.view.tblRelationsInTitle'),
                count: (incomingRelations != null ? incomingRelations.length : 0)
            },
            {
                key: 2,
                type: t('app.entry.view.tblRelationsOutTitle'),
                count: (outgoingRelations != null ? outgoingRelations.length : 0)
            }
        ];

        return <Card title={t('app.entry.view.cardRelations')} className="entryViewRelationPrint">
            <Table
                className="components-table-demo-nested"
                expandedRowRender={expandedRowRender}
                columns={columns}
                dataSource={tabData}
                size="middle"
                pagination={false}
                scroll={{ x: true }}
                loading={isLoadingData}
            />
        </Card>;
    }
}

export default withTranslation()(EntryUserRelationTable);

