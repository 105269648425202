import EntryChildHierarchyPresenter from '../../components/detail/view/EntryChildHierarchy';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { fetchSettingsList } from '../../actions/settings';
import { getGeneralSettingsObject } from '../../utils/GeneralSettingsHelper';

const getSettingsList = (settingsListRequestResult) => {
    let settingsList = [];

    if (settingsListRequestResult.getState().isDone()) {
        settingsList = settingsListRequestResult.getData().filter(sL => sL.id === "lineage");
    }

    return settingsList;
};

const mapStateToProps = (state, ownProps) => {
    return {
        lineageSettingsList: getSettingsList(state.settingsList),
        generalSettingsObject: getGeneralSettingsObject(state.settingsList),
        entryID : ownProps.match.params.entryID,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchSettingsList());
        },
    }
};

const EntryChildHierarchy = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryChildHierarchyPresenter);

export default withRouter(EntryChildHierarchy);