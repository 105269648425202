import React from "react";
import { withTranslation} from 'react-i18next';
import AdvancedSearchGroup from './AdvancedSearchGroup';
import AdvancedSearchCondition from './AdvancedSearchCondition';
import ButtonGroup from "antd/lib/button/button-group";
import { Button } from "antd";
import { PlusOutlined, ColumnWidthOutlined } from '@ant-design/icons';
import PropTypes, { object } from 'prop-types';
const cloneDeep = require('lodash.clonedeep');

class AdvancedSearchSubQuery extends React.Component {


    addCondition = () => {
        let data = cloneDeep(this.props.searchData);
        data.push({ 
            type: 'condition', 
            operand: (this.props.searchData.length > 0 ? 'AND' : ''), 
            condition: { 
                attribute: null, 
                conditionType: null, 
                value1: null, 
                value2:null 
            }
        });
        this.props.onDataChanged(data);
    };

    addGroup = () => {
        let data = cloneDeep(this.props.searchData);
        //data.push({ type: 'group', operand: (this.props.searchData.length > 0 ? 'AND' : ''), groupData: { conditions: [] } });
        data.push({ type: 'group', operand: (this.props.searchData.length > 0 ? 'AND' : ''), conditions: [] });
        this.props.onDataChanged(data);
    };

    handleGroupChange = (key, data) => {
        let dataCopy = cloneDeep(this.props.searchData);
        dataCopy[key].conditions = data;
        this.props.onDataChanged(dataCopy);
    };

    handleOperandChange = (key, operand) => {
        let dataCopy = cloneDeep(this.props.searchData);
        dataCopy[key].operand = operand;
        this.props.onDataChanged(dataCopy);
    };

    handleConditionChange = (key, conditionData) => {
        let dataCopy = cloneDeep(this.props.searchData);
        dataCopy[key].condition = conditionData;
        this.props.onDataChanged(dataCopy);
    };

    handleRemoveCondition = (key) => {
        let dataCopy = cloneDeep(this.props.searchData);
        if (key === 0 && dataCopy.length > 1) {
            dataCopy[1].operand = "";
        }
        //console.log(dataCopy.filter((_, i) => i !== key));
        this.props.onDataChanged(dataCopy.filter((_, i) => i !== key));
    };

    render() {
        const {t} = this.props;

        let queryAndGroupArray = this.props.searchData.map((condition, idx) => {
            switch (condition.type) {
                case 'condition':
                    return <AdvancedSearchCondition key={idx} 
                                dataIndex={idx}
                                operand={condition.operand} 
                                queryData={condition.condition}
                                entryTypeAttributes={this.props.entryTypeAttributes}
                                onOperandChange={this.handleOperandChange}
                                onConditionChanged={this.handleConditionChange}
                                onRemoveCondition={this.handleRemoveCondition}
                                >
                                </AdvancedSearchCondition>;
                case 'group':
                    return <AdvancedSearchGroup key={idx} 
                                dataIndex={idx}
                                operand={condition.operand} 
                                groupData={condition.conditions}
                                entryTypeAttributes={this.props.entryTypeAttributes}
                                onDataChanged={this.handleGroupChange}
                                onOperandChange={this.handleOperandChange}
                                onRemoveGroup={this.handleRemoveCondition}
                                >
                                </AdvancedSearchGroup>;
                default:
                    return null;
            }
        });

        return <div>
            {queryAndGroupArray}
            <div>
                <ButtonGroup size="small" className="advancedSearchAddbuttons">
                    <Button icon={<PlusOutlined />} style={{marginBottom:'10px'}} type="dashed" size="small" onClick={this.addCondition} >{t('app.advancedSearch.btnAddCondition')}</Button>
                    <Button icon={<ColumnWidthOutlined />}style={{marginBottom:'22px'}} type="dashed" size="small" onClick={this.addGroup} >{t('app.advancedSearch.btnAddGroup')}</Button>
                </ButtonGroup>
            </div>
        </div>;
    }
}

AdvancedSearchSubQuery.propTypes = {
    searchData: PropTypes.arrayOf(object),
    entryTypeAttributes: PropTypes.arrayOf(object),
    onDataChanged: PropTypes.func.isRequired,
};

export default withTranslation() (AdvancedSearchSubQuery);