import LoadSettingListPresenter from '../../../components/setup/loader/LoadSettingList';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchLoadSettingsList} from "../../../actions/loadSettings";

const mapStateToProps = (state, ownProps) => {
    return {
        loadSettingsList: state.loadSettingsList
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchLoadSettingsList(true));
        },
    }
};

const LoadSettingList = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoadSettingListPresenter);

export default withRouter(LoadSettingList);