import React from "react";
import {bmcEntryShape} from "../../../shapes/BmcEntryShape";
import { Table, Button, Select, Checkbox, Switch, Row, Col } from 'antd';
import { CloseCircleTwoTone, EyeTwoTone, EditTwoTone, ContainerTwoTone } from '@ant-design/icons';
import {isUndefined} from "../../../utils/JsObjectHelper";
import { withTranslation} from 'react-i18next'

const cloneDeep = require('lodash.clonedeep');

const { Option } = Select;

class EntryAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accessList: [],
            applyOnSubFolders: true,
            holdLoading: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.onApplyAccessRights = this.onApplyAccessRights.bind(this);
        this.onApplyAccessRights = this.onApplyAccessRights.bind(this);
    }

    componentDidMount() {
        this.props.onMount(this.props.entry.id);
    }

    /**
     * UNSAFE and deprecated lifecycle method!
     */
    /*UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
console.log(nextProps.groupAccessList);
console.log(this.props.groupAccessList);
        if (nextProps.groupAccessList !== this.props.groupAccessList) {
            this.setState({
                accessList: cloneDeep(nextProps.groupAccessList), 
                holdLoading: false
            });
        }
    }*/
    componentDidUpdate(prevProps, prevState) {
        if (this.props.groupAccessList !== prevProps.groupAccessList) {
            this.setState({
                accessList: cloneDeep(this.props.groupAccessList),
                holdLoading: false
            });
        }
    }

    handleChange (value, rec) {
        if (rec.key.split('##').length > 1) {
            let newState = cloneDeep(this.state.accessList);

            let updatedRecord = newState.find( r => r.groupId === rec.key.split('##')[0]);

            if(!isUndefined(updatedRecord)) {
                updatedRecord.accessRightNew = value;
                this.setState({accessList: newState});
            }
        }
    }

    toggleChecked = e => {
        this.setState({ applyOnSubFolders: e.target.checked });
    }

    onApplyAccessRights () {
        this.setState({
            holdLoading: true
        });
        this.props.onSaveAccessChanges(this.state.accessList, this.props.entry.id, this.state.applyOnSubFolders);
    }

    render() {
        const {t} = this.props;

        let columns = [
            {
                title: t('app.entry.tools.access.tblGroupName'),
                dataIndex: 'groupName',
                key: 'group',
                width: '70%',
            },
            {
                title: t('app.entry.tools.access.tblAccessLevel'),
                dataIndex: 'accessRightNew',
                key: 'accessLevel',
                width: '20%',
                render: (accessRightNew, record) => {
                    return <Select key={record.groupId} 
                                defaultValue={accessRightNew} 
                                style={{ width: 200 }} 
                                onChange={this.handleChange}>
                        <Option key={record.groupId + "##NONE"} value="NONE" style={{backgroundColor:'#fff1f0'}}>
                            <div title={t('app.entry.tools.access.accessNone')}><CloseCircleTwoTone twoToneColor="red"/>&nbsp;{t('app.entry.tools.access.accessNone')}</div>
                        </Option>
                        <Option key={record.groupId + "##VIEW"} value="VIEW" style={{backgroundColor:'#fff7e6'}}>
                            <div title={t('app.entry.tools.access.accessView')}><EyeTwoTone twoToneColor="#ffd400" />&nbsp;{t('app.entry.tools.access.accessView')}</div>
                        </Option>
                        <Option key={record.groupId + "##EDIT"} value="EDIT" style={{backgroundColor:'#f6ffed'}}>
                            <div title={t('app.entry.tools.access.accessEdit')}><EditTwoTone twoToneColor="green"/>&nbsp;{t('app.entry.tools.access.accessEdit')}</div>
                        </Option>
                        <Option key={record.groupId + "##VIEW_PARENT_EDIT_CHILD"} value="VIEW_PARENT_EDIT_CHILD" style={{backgroundColor:'#e6f7ff'}}>
                            <div title={t('app.entry.tools.access.accessVParentEChild')}><ContainerTwoTone twoToneColor="blue"/>&nbsp;{t('app.entry.tools.access.accessVParentEChild')}</div>
                        </Option>
                    </Select>;
                }
            },
            {
                title: t('app.entry.tools.access.tblChanged'),
                dataIndex: 'name',
                key: 'changed',
                width: '10%',
                render: (text, record) => (
                    <Switch key={record.groupId} disabled checked={record.accessRightNew !== record.accessRightOrigin}></Switch>
                )
            },
        ];

        let isLoading = !this.props.groupListRequestResult.getState().isDone() || !this.props.entryAccessRequestResult.getState().isDone();

        let tabFooter = () => <Row>
            <Col span={12}>
                <Checkbox checked={this.state.applyOnSubFolders} onChange={this.toggleChecked}>{t('app.entry.tools.access.lblAplyOnSubFolder')}</Checkbox>
            </Col>
            <Col span={12} style={{textAlign: 'right'}}>
                <Button type="primary" onClick={this.onApplyAccessRights}>{t('app.entry.tools.access.btnApply')}</Button>
            </Col>
        </Row>;

        return (
            <div>
                <Table 
                    columns={columns}
                    dataSource={this.state.accessList}
                    size="small"
                    pagination={false}
                    loading={isLoading || this.state.holdLoading}
                    footer={tabFooter}
                    rowKey={record => record.groupId}
                    scroll={{
                        x:true
                    }
                    }
                >
                </Table>
            </div>
        );
    }
}


EntryAccess.propTypes = {
    entry: bmcEntryShape.isRequired
};

export default withTranslation() (EntryAccess);