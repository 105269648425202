import React from "react";
import "antd/dist/antd.css";
import {
	Button,
	Table,
	Input,
	Space,
	Row,
	Col,
	Divider,
	Tabs,
	PageHeader,
	Modal,
	Skeleton,
	Drawer
} from "antd";
import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import TaskDetail from "./TaskDetail";
import { getColumnCustomAttributeProps } from "../../utils/TableHelper";
import { isEmptyValue, isUndefined, isEmptyObject } from "../../utils/JsObjectHelper";
import { highlightSearchedText } from "../../utils/TextHighlighter";
import { isTablet,isMobile, isDesktop } from "../../utils/AdaptiveLayout";


const { TabPane } = Tabs;

class BpmnTaskList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			childTablePageSize: (isEmptyObject(localStorage.getItem("bpmnPageSize")) ? 30 : parseInt(localStorage.getItem("bpmnPageSize"))),
			searchText: "",
			searchedColumn: "",
			detailTask: [],
			activeKey: "2",
			visible: false,
		};
	}
	componentDidMount() {
		this.props.onMount();

	}
	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.taskId !== this.props.taskId ||
			(prevProps.taskListRequestResult.getState() !==
				this.props.taskListRequestResult.getState() &&
				this.props.taskId !== "") ||
			(prevProps.alltaskListRequestResult.getState() !==
				this.props.alltaskListRequestResult.getState() &&
				this.props.taskId !== "") ||
			(prevProps.todoListRequestResult.getState() !==
				this.props.todoListRequestResult.getState() &&
				this.props.taskId !== "")

		) {
			let TaskListData = this.props.taskListRequestResult.getState().isLoading() ? [] : this.props.taskListRequestResult.getData();
			let AllTask = this.props.alltaskListRequestResult.getState().isLoading() ? [] : this.props.alltaskListRequestResult.getData();
			let TodoListData = this.props.todoListRequestResult.getState().isLoading() ? [] : this.props.todoListRequestResult.getData();
			let FormData = this.props.bpmnFormRequestResult.getState().isLoading() ? [] : this.props.bpmnFormRequestResult.getState().isError() ? [] : this.props.bpmnFormRequestResult.getData();
			if (AllTask.length > 0 && AllTask.find((t) => t.id === this.props.taskId) && !isUndefined(this.props.showAdminPanel)) {
				let record = AllTask.find((t) => t.id === this.props.taskId);
				this.setState({
					detailTask: record,
					activeKey: "2"
				}, ()=> {this.props.onFormChange(record.id);});
			}
			if (TaskListData.length > 0 && TaskListData.find((t) => t.id === this.props.taskId) && isUndefined(this.props.showAdminPanel)) {
				let record = TaskListData.find((t) => t.id === this.props.taskId);
				//this.props.onFormChange(record.id); //record.id
				this.setState({
					detailTask: record,
					activeKey: "1"
				},()=> {this.props.onFormChange(record.id);});
			}
			if (TodoListData.length > 0 && TodoListData.find((t) => t.id === this.props.taskId) && isUndefined(this.props.showAdminPanel)) {
				let todoList = TodoListData.find((t) => t.id === this.props.taskId);
				//this.props.onFormChange(todoList.id); //record.id
				this.setState({
					detailTask: todoList,
					activeKey: "2"
				},()=> {this.props.onFormChange(todoList.id);});
			}
			if (isEmptyValue(this.props.taskId && FormData === [])) {
				this.setState({
					detailTask: null,
				});
			}
		}
	}
	handlePageSizeChanged = (current, page) => {
		localStorage.setItem("bpmnPageSize", page);
		this.setState({ childTablePageSize: page });
	};
	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						this.handleSearch(selectedKeys, confirm, dataIndex)
					}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => this.handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							this.setState({
								searchText: selectedKeys[0],
								searchedColumn: dataIndex,
							});
						}}
					>
						Filter
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex]
					.toString()
					.toLowerCase()
					.includes(value.toLowerCase())
				: "",
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select(), 100);
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				// <Highlighter
				//   highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
				//   searchWords={[this.state.searchText]}
				//   autoEscape
				//   textToHighlight={text ? text.toString() : ""}
				// />
				highlightSearchedText((text ? text.toString() : ""), this.state.searchText)
			) : (
				text
			),
	});
	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};
	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: "" });
	};
	showDetailTask(record) {
		this.props.history.push(`/bpmn/` + record.id)
		if (!isUndefined(this.props.showAdminPanel)) {
			this.props.history.push(`/setup/tasklist/` + record.id);
		}

	}
	handleActiveKey = (activeKey) => {
		this.setState({ activeKey });
	};
	onClose =()=>{
		this.setState({visible:false})
	}
	render() {
		const { t } = this.props;
		let TaskListData = this.props.taskListRequestResult.getState().isLoading() ? undefined : this.props.taskListRequestResult.getData();
		let TodoListData = this.props.todoListRequestResult.getState().isLoading() ? undefined : this.props.todoListRequestResult.getData();
		let FormData = this.props.bpmnFormRequestResult.getState().isLoading() ? [] : this.props.bpmnFormRequestResult.getState().isError() ? [] : this.props.bpmnFormRequestResult.getData();
		let info = () => {
			Modal.info({
				title: t('setup.bpmn.bpmnInfoTitleTaskList'),
				content: (
					<div>
						{t('setup.bpmn.bpmnInfoTextTaskList')}
					</div>
				),
				onOk() {
				},
			});
		};
		let paginationSetup = {
			showSizeChanger: true,
			size: "small",
			showTotal: this.showTotal,
			defaultPageSize: this.state.childTablePageSize,
			pageSizeOptions: ['10', '20', '30', '50', '100'],
			onShowSizeChange: this.handlePageSizeChanged
		};
		let VariablesData = this.props.bpmnFormVariablesRequestResult.getState().isLoading() ? null : this.props.bpmnFormVariablesRequestResult.getData();
		let TodoLength = !isUndefined(TodoListData) ? `(${TodoListData.length})` : undefined;
		let TaskLength = !isUndefined(TaskListData) ? `(${TaskListData.length})` : undefined;
		let localTask = localStorage.getItem('taskCount');
		let localTaskCount = localTask? JSON.parse(localTask): null;
		if (isUndefined(localTaskCount) && !isUndefined(TodoListData) || !isUndefined(TodoListData) && localTaskCount !== TodoListData.length) {
            localStorage.setItem('taskCount', TodoListData.length);
        }

		let header = <Divider plain> {t("app.bpmn.bpmnLabel")} </Divider>
		if (this.props.showAdminPanel) {
			TaskListData = this.props.alltaskListRequestResult.getState().isLoading() ? [] : this.props.alltaskListRequestResult.getData();
			TaskLength = !isUndefined(TaskListData) ? "(" + TaskListData.length + ")" : undefined;
			header = <PageHeader
				key="334"
				title={t('setup.bpmn.bpmnTaskSetup')}
				subTitle={t('setup.bpmn.bpmnTaskSetupDescription')}
				extra={[
					<Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary"*/ onClick={info} >
					</Button>,
				]}
			>
			</PageHeader>
		}
		const columns = [
			{
				title: t("app.bpmn.titleName"),
				dataIndex: "name",
				key: "name",
				...this.getColumnSearchProps("name"),

			},
			{
				...getColumnCustomAttributeProps(
					"created",
					t("app.bpmn.titleCreated"),
					"date4human",
					t
				),
			},
			{
				...getColumnCustomAttributeProps(
					"due",
					t("app.bpmn.titleDueDate"),
					"date4human",
					t
				),
				onFilter: (value, record) => record.due.indexOf(value) === 0
			},
			{
				...getColumnCustomAttributeProps(
					"existsCandidate",
					t("app.bpmn.titleCandidate"),
					"boolean",
					t
				)
			
			},
			{
				title: t("app.bpmn.titlePriority"),
				dataIndex: "priority",
				key: "priority",
				width: "10%",
				defaultSortOrder: "descend",
				sorter: (a, b) => a.priority - b.priority,
			},
		];
		const columnsTodo = [
			{
				title: t("app.bpmn.titleName"),
				dataIndex: "name",
				key: "name",
				...this.getColumnSearchProps("name"),

			},
			{
				...getColumnCustomAttributeProps(
					"created",
					t("app.bpmn.titleCreated"),
					"date4human",
					t
				),
			},
			{
				...getColumnCustomAttributeProps(
					"due",
					t("app.bpmn.titleDueDate"),
					"date4human",
					t
				),
				onFilter: (value, record) => record.due.indexOf(value) === 0
			},
			{
				title: t("app.bpmn.titlePriority"),
				dataIndex: "priority",
				key: "priority",
				width: "10%",
				defaultSortOrder: "descend",
				sorter: (a, b) => a.priority - b.priority,
			},
		];
		let detail =	<Col span={14}>
		<TaskDetail
			taskid={this.props.taskId}
			taskData={this.state.detailTask}
			showAdminPanel={this.props.showAdminPanel}
			todoData={TodoListData}
			formData={FormData}
			loading={this.props.bpmnFormRequestResult.getState().isLoading()}
			claim={this.props.onClaim}
			unClaim={this.props.unClaim}
			User={this.props.user.name}
			Variables={VariablesData}
			tabKey={this.handleActiveKey}
			closeDrawer={this.onClose}
			complete={this.props.handleComplete}
		/>
	</Col>
	if(isMobile()||isTablet()){
		detail= <Drawer width={isTablet()?"70%":'100%'} placement="right" onClose={this.onClose} visible={this.state.visible}>
		
		<TaskDetail
			taskid={this.props.taskId}
			taskData={this.state.detailTask}
			showAdminPanel={this.props.showAdminPanel}
			todoData={TodoListData}
			formData={FormData}
			loading={this.props.bpmnFormRequestResult.getState().isLoading()}
			claim={this.props.onClaim}
			unClaim={this.props.unClaim}
			User={this.props.user.name}
			Variables={VariablesData}
			tabKey={this.handleActiveKey}
			closeDrawer={this.onClose}
			complete={this.props.handleComplete}
		/>
		
		</Drawer>
	
	}
		let main = <Skeleton active />
		if(!isUndefined(TodoListData) && !isUndefined(TaskListData) ){
			main =<Row >
			<Col span={isDesktop()?10:24}>
				<Tabs
					activeKey={this.state.activeKey}
					onChange={this.handleActiveKey}

				>
					<TabPane
						tab={t("app.bpmn.allTasks")+TaskLength}
						key={this.props.showAdminPanel ? 2 : 1}
						forceRender={true}
						
					>
						<Table
							pagination={paginationSetup}
							columns={this.props.showAdminPanel ? columnsTodo :columns}
							dataSource={TaskListData}
							size="small"
							rowKey="id"
							scroll={{
								x: true,
							  }}
							loading={this.props.taskListRequestResult
								.getState()
								.isLoading()}
							onRow={(record, rowIndex) => {
								return {
									onClick: (event) => {
										this.showDetailTask(record);
										this.setState({visible:true})
									},
								};
							}}
						/>
					</TabPane>
					{this.props.showAdminPanel ? null :
						<TabPane
							tab={t("app.bpmn.myTasks")+TodoLength}
							key="2"
							forceRender={true}
						>
							<Table
								columns={columnsTodo}
								dataSource={TodoListData}
								size="small"
								rowKey="id"
								loading={this.props.todoListRequestResult
									.getState()
									.isLoading()}
								onRow={(record, rowIndex) => {
									return {
										onClick: (event) => {
											this.showDetailTask(record);
											this.setState({visible:true})
										},
									};
								}}
							/>
						</TabPane>}
				</Tabs>
			</Col>
		{detail}
		</Row>
		}
		return (<>
			{header}
			{main}
		</>);
	}
}

const TaskListWithRouter = withRouter(BpmnTaskList);

export default withTranslation()(TaskListWithRouter);
