import React from 'react';
import PropTypes, { object } from "prop-types";
import { withTranslation } from 'react-i18next'
import {withRouter} from "react-router-dom";
import { isEmptyObject, mergeDeep } from '../../../utils/JsObjectHelper';
import { Button, Card, Col, Form, Input, Modal, PageHeader, Row } from 'antd';
import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { entryTypesRequestResult, loadSettingResultDetailShape, loadSettingResultShape } from '../../../shapes/RequestResult';
import CopyValue2ClipboardButton from '../../controls/CopyValue2ClipboardButton';
import FormNameValueItem from '../../controls/FormNameValueItem';
import LoadFormNameFolderItem from './LoadFormNameFolderItem';


class LoadSettingDetail extends React.Component {
    
    /*constructor(props) {
        super(props);
    }*/

    componentDidMount() {
        this.props.onMount(this.props.match.params.setupDetailId);
    };

    componentWillUnmount() {
        this.props.onUnmount();
    }

    loadSettingCodeValidator = (rule, value, callback) => {
        if (this.props.loadSettingsListRequestResult != null && this.props.loadSettingsListRequestResult.getState().isDone()) {
            let loaderSetups = this.props.loadSettingsListRequestResult.getData();
            if (loaderSetups.find(et => {return et.code === value && et.id !== this.props.loadSettingsDetailData.id; }) != null) {
                callback(rule.message);
            }
        }
        callback();
    };

    onFinish = (values) => {
        let loadSetupValues = mergeDeep(this.props.loadSettingsDetailData, values);
        console.log(loadSetupValues);
        this.props.onSaveSettings(values);
    };

    render() {
        const {t} = this.props;

        let info = () => {
            Modal.info({
                title: t('setup.loader.infoTitleDetail'),
                content: (
                    <div>
                        {t('setup.loader.infoContentDetail')}
                    </div>
                ),
                onOk() {
                },
            });
        };

        /** @type {RequestResult} */
        const rr = this.props.loadSettingsDetailRequestResult;
        if(isEmptyObject(rr) || rr.getState().isLoading())
            return <div>{t('app.entry.header.msgLoading')}</div>;
        else if(isEmptyObject(rr.getData()))
            return <div>{t('app.entry.header.msgEntryNonFound')}</div>;
        else {
            return <div>
                    <PageHeader
                        title={t('setup.loader.headerDetail')}
                        subTitle={t('setup.loader.headerDetailLabel')}
                        extra={[
                            <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                            </Button>,
                        ]}
                    >
                    </PageHeader>
                    <Form
                        name="loader_form" layout="vertical"
                        initialValues={this.props.loadSettingsDetailData}
                        onFinish={this.onFinish}
                    >
                        <div className="steps-content">
                            <Row align="top" gutter={[30, 20]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Card>
                                            <Form.Item label={t('setup.loader.detailFormId')}
                                                name="id"
                                                >
                                                    <CopyValue2ClipboardButton></CopyValue2ClipboardButton>
                                            </Form.Item>

                                            <Form.Item label={t('setup.loader.detailFormCode')}
                                                name="code"
                                                rules={[
                                                    { required: true, message: t('setup.loader.detailFormCodeErr') }, 
                                                    { validator : this.loadSettingCodeValidator, message: t('setup.loader.detailFormCodeDuplaErr')}
                                                ]}>
                                                <Input placeholder={t('setup.loader.detailFormCodeHint')}/>
                                            </Form.Item>

                                            <Form.Item label={t('setup.loader.detailFormCustomProps')}
                                                name={["value", "customProps"]}
                                                >
                                                    <FormNameValueItem></FormNameValueItem>
                                            </Form.Item>

                                            <Form.Item label={t('setup.loader.detailFormFolderWrite')}
                                                name={["value", "folders", "write"]}
                                                >
                                                    <LoadFormNameFolderItem></LoadFormNameFolderItem>
                                            </Form.Item>

                                            <Form.Item label={t('setup.loader.detailFormFolderRead')}
                                                name={["value", "folders", "read"]}
                                                >
                                                    <LoadFormNameFolderItem></LoadFormNameFolderItem>
                                            </Form.Item>

                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <Row className="steps-action" justify="end" type="flex">
                            <Col>
                            <Form.Item>

                                <Button icon={<SaveOutlined />} type="primary" htmlType="submit">
                                    {this.props.isCreateNew ? t('setup.loader.detailBtnCreate') : t('setup.loader.detailBtnSave')}
                                </Button>
                            </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>;
        }
    }
}

const LoadSettingDetailWithRouter = withRouter(LoadSettingDetail);
export default withTranslation() (LoadSettingDetailWithRouter);

LoadSettingDetail.propTypes = {
    loadSettingsDetailRequestResult: loadSettingResultDetailShape,
    loadSettingsDetailData: object,
    loadSettingsListRequestResult: loadSettingResultShape,
    objectListRequestResult: entryTypesRequestResult.isRequired,
    isEdit: PropTypes.bool,
    isCreateNew: PropTypes.bool,
    onSaveSettings: PropTypes.func.isRequired

};