import React from 'react';
import { withTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DragSource, DropTarget } from 'react-dnd';
import { Icon } from '@ant-design/compatible';
import { Button, Alert } from 'antd';
import { isUndefined } from '../../../utils/JsObjectHelper';
import {EMPTY_FOLDER_PROPERTIES_DEFINITION} from "../../../utils/EmptyFolderPropertiesDefinition";
const Types = {
    CARD: 'folder'
};

const cardSource = {
    beginDrag(props) {
        return {
            id: props.id
        };
    }
};

const cardTarget = {
    hover(props, monitor) {
        const dragId = monitor.getItem().id;
        if (dragId !== props.id) {
            props.moveCard(dragId, props.id);
        }
    }
};

const DraggableSubFolderCard = DropTarget(
    Types.CARD,
    cardTarget,
    connect => ({
        connectDropTarget: connect.dropTarget(),
    }),
)(
    DragSource(
        Types.CARD,
        cardSource,
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging(),
        }),
    )(SubFolderCard)
);

function SubFolderCard({ connectDragSource, connectDropTarget, isDragging, title, id, iconName, iconColor }) {
    const opacity = isDragging ? 0 : 1;
    return connectDragSource(connectDropTarget(
        <span className='entrySubFolderEditPosition' style={{ opacity: opacity }}>
            <div className="entrySubFolderCard" style={{ padding: '24px' }}>
                <div className="folderInfo" title={title}>
                    <Icon theme="outlined" type={iconName} style={{ width: "100%", color: iconColor }} /><br />
                    <div className="folder-card-title">{title}</div>
                </div>
            </div>
        </span>
    ));
}

class SubFoldersEditChildPosition extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            folders: []
        };
    }
    componentDidMount(){
        if(this.props.childsDataList){
            let data = this.sortArrayByAnotherArray(this.props.childsDataList,this.props.ids);
            this.setState({ folders: data });
        } 
    }
    sortArrayByAnotherArray = (childsDataList, ids) => {
        let data = childsDataList.filter(fol => { return fol.type === "folder" }).sort((a, b) => a.name.localeCompare(b.name));

        if (ids && ids.length > 0) {
            let idSet = new Set(ids);
            let rest = [];
            data = data.filter(item => {
                if (idSet.has(item.id)) {
                    return true;
                } else {
                    rest.push(item);
                    return false;
                }
            });

            rest.sort((a, b) => a.name.localeCompare(b.name));

            data.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));

            return data.concat(rest);
        }
        else {
            return data
        }
    }

    moveCard = (dragId, hoverId) => {
        const { folders } = this.state;
        const dragCard = folders.find(card => card.id === dragId);
        const hoverCard = folders.find(card => card.id === hoverId);
        const dragCardIndex = folders.indexOf(dragCard);
        const hoverCardIndex = folders.indexOf(hoverCard);

        // Creates a new array for updated state
        let newFolders = [...folders];
        // Removes dragged card from its original location
        newFolders.splice(dragCardIndex, 1);
        // Inserts dragged card at the new location
        newFolders.splice(hoverCardIndex, 0, dragCard);
        this.setState({ folders: newFolders });
    }
    onSavePosition = (def) => {
        let newPosition = this.state.folders.map((el) => el.id);
        let entry = this.props.entry;
        let newJson = {
            name: entry.name,
            description: entry.description,
            properties: {
                childFolderEntryTypes: entry.properties.childFolderEntryTypes,
                childObjectEntryTypes: entry.properties.childObjectEntryTypes,
                folderIcon: entry.properties.folderIcon,
                folderIconColor: entry.properties.folderIconColor,
                subFolderDisplayType: entry.properties.subFolderDisplayType,
                childrenDisplayType: entry.properties.childrenDisplayType,
                childrenDisplayHierarchyDefId: entry.properties.childrenDisplayHierarchyDefId,
                displayTechAttributesFirst: entry.properties.displayTechAttributesFirst,
                childrenDisplayTechAttr: entry.properties.childrenDisplayTechAttr,
                childrenDisplayAttributes: entry.properties.childrenDisplayAttributes,
                workflowForFolder: entry.properties.workflowForFolder,
                workflowForEntry: entry.properties.workflowForEntry,
                childrenPosition:newPosition,
                folderDisplayLayout: entry.properties.folderDisplayLayout?entry.properties.folderDisplayLayout:'description',
            },
        }
        if (def==='default') {
            newJson.properties.childrenPosition = [];
        }
        this.props.onSaveEdit(this.props.entryId, newJson);
        this.props.onEditChildPosition();
    }

    render() {
        const { t } = this.props;
        const { folders } = this.state;
        return (<>
            <Alert
                message={t('app.entry.view.folderPositionChanges.alertMessage')}
                description={t('app.entry.view.folderPositionChanges.alertText')}
                type="warning"
                showIcon
                style={{ marginBottom: '10px' }}
            />
            <DndProvider backend={HTML5Backend}>
                {folders.map((folder, i) => { 
                    let iconFolder = folder.properties && folder.properties.folderIcon?folder.properties.folderIcon:EMPTY_FOLDER_PROPERTIES_DEFINITION.folderIcon;
                    let colorIcon = folder.properties && folder.properties.folderIconColor?folder.properties.folderIconColor:EMPTY_FOLDER_PROPERTIES_DEFINITION.folderIconColor;
                  return  <DraggableSubFolderCard key={folder.id} index={i} id={folder.id} title={folder.name} iconName={iconFolder} iconColor={colorIcon} moveCard={this.moveCard} {...folder} />}
                   
                )}
            </DndProvider>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button onClick={() => this.onSavePosition('default')} style={{ marginLeft: '3px' }}>{t('app.entry.view.folderPositionChanges.btnDefault')}</Button>
                <Button onClick={() => this.props.onEditChildPosition()} style={{ marginLeft: '3px' }}>{t('app.entry.view.folderPositionChanges.btnCancel')}</Button>
                <Button onClick={this.onSavePosition} style={{ marginLeft: '3px' }} type='primary'>{t('app.entry.view.folderPositionChanges.btnSave')}</Button>
            </div>
        </>
        );
    }
}


export default withTranslation()(SubFoldersEditChildPosition);
