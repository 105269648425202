import {createBaseOptions, fetchJson, METHOD_POST} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";
import { isArray, isEmptyValue } from "../utils/JsObjectHelper";

/**
 * Fetch result from Context Engine for Entry. Result is only from called object type.
 * 
 * @param {string} entryId 
 * @param {string} objectType 
 * @param {number} maxLevel 
 * @param {Function<Array[Object]>} callback 
 */
export const fetchEntryContextEngine = (entryId, objectType, maxLevel, callback) => {
    let r = fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/findFriends/${objectType}/${maxLevel}`);
    r.then(json => {
        let res = [];
        const keys = {};
        if (json._embedded && json._embedded.friendBmcEntries && json._embedded.friendBmcEntries.length > 0) {
            json._embedded.friendBmcEntries.forEach((r) => {
                if(keys[r.id] === undefined) {
                    keys[r.id] = 1;
                    res.push(r);
                }
            });
        }
        callback(res);
    })
};

/**
 * 
 * @param {string} entryId 
 * @param {string} objectType 
 * @param {number} maxLevel 
 * @param {Array[string]} entryParentIdArr 
 * @param {Function<Array[Object]>} callback 
 */
export const fetchEntryContextEngineByParents = (entryId, objectType, maxLevel, entryParentIdArr = null, callback) => {
    let searchedTerm = {
        objectType: objectType,
        maxLevel: maxLevel
    };

    if (!isEmptyValue(entryParentIdArr)) {
        if (!isArray(entryParentIdArr)) {
            entryParentIdArr = [entryParentIdArr];
        }
        searchedTerm["parentIds"] = entryParentIdArr;
    }
    
    let options = createBaseOptions();
    options.method = METHOD_POST;
    options.headers["Content-Type"] = "application/json";
    options["body"] = searchedTerm;

    let r = fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/findFriends`, options);
    r.then(json => {
        let res = [];
        const keys = {};
        if(json._embedded && json._embedded.friendBmcEntries && json._embedded.friendBmcEntries.length > 0) {
            json._embedded.friendBmcEntries.forEach((r) => {
                if(keys[r.id] === undefined) {
                    keys[r.id] = 1;
                    res.push(r);
                }
            });
        } 
        callback(res);
    });    
};