import React from 'react';
import PropTypes, { string } from "prop-types";
import { withTranslation} from 'react-i18next';
import { Collapse, Descriptions, Divider } from 'antd';
import { relationsHolderShape } from '../../../shapes/RelationShapes';
import { isUndefined } from '../../../utils/JsObjectHelper';
import RelationAttributeTagCloud from '../../../containers/detail/RelationAttributeTagCloud';

const cloneDeep = require('lodash.clonedeep');
const { Panel } = Collapse;

class EntryEditIncomingRelations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            originalRelations: cloneDeep(props.relationHolder),
            relationData : props.relationHolder.relations
        };
        this.onCloseTag = this.onCloseTag.bind(this);
        this.onAddTag = this.onAddTag.bind(this);
    }

    /**
     * 
     * @param {*} relationName 
     * @param {*} entry 
     */
    onAddTag = (relationName, entry) => {
        let holder = cloneDeep(this.state.relationData);
        let replaceRelations = holder.find(x => { return x.name === relationName });
        if(isUndefined(replaceRelations)) {
            replaceRelations = {name: relationName, relatedEntries: [entry]};
            holder.push(replaceRelations);
        } else {
            replaceRelations.relatedEntries.push(entry);
        }
        let relValues = {
            type : "incoming",
            relations : holder
        };
        this.props.onRelationChange(relValues);
        this.setState({ relationData : holder});
    };

    /**
     * 
     * @param {*} relationName 
     * @param {*} entry 
     */
    onCloseTag = (relationName, entryId) => {
        let holder = cloneDeep(this.state.relationData);
        let replaceRelations = holder.find(x => { return x.name === relationName });
        replaceRelations.relatedEntries = replaceRelations.relatedEntries.filter(x => {
            return x.id !== entryId
        });
        let relValues = {
            type : "incoming",
            relations : holder
        };
        this.props.onRelationChange(relValues);
        this.setState({ relationData : holder});
    };

    render() {
        const {t} = this.props;

        //console.log(this.props.relationHolder);
        //console.log(this.props.entryRequestResult);
        //console.log(this.props.entryTypesRequestResult);
        //console.log(this.props.possibleIcomingRelationEntryTypes);

        let inRelTypesDescriptionItems = null;

        inRelTypesDescriptionItems = this.props.possibleIcomingRelationEntryTypes.map(rel => {
            //console.log(rel);
            let relationsTagClouds = [];
            rel.relations.forEach(element => {
                let relations = this.state.relationData.find(e => {return e.name === element.techName});
                if (isUndefined(relations)) {
                    relations = {name: element.techName, relatedEntries: []};
                } else {
                    relations = {name: relations.name, relatedEntries: relations.relatedEntries.filter(re => re.type === rel.type)};
                }

                //original incoming relations cannot be changed. So get original state for this relation
                let dontTouchRelationIds = [];
                if (!isUndefined(this.state.originalRelations.relations.find(e => {return e.name === element.techName}))) {
                    dontTouchRelationIds = this.state.originalRelations.relations.find(e => {return e.name === element.techName}).relatedEntries.map(re => re.id);
                }
                
                relationsTagClouds.push(
                    <RelationAttributeTagCloud 
                        isEditMode={true}
                        isForIncomingRelations={true}
                        title={element.name}
                        relationHolder={relations}
                        entryType={[rel.type]}
                        entryParent={[]}
                        onCloseTag={this.onCloseTag}
                        onAddTag={this.onAddTag}
                        key={element.techName}
                        attributeTechName={element.techName}
                        dontTouchIds={dontTouchRelationIds}
                        //attributesProperties={this.props.attributesProperties}
                    >
                    </RelationAttributeTagCloud>
                );
            });
            return <Descriptions.Item key={rel.type} label={rel.name}>{relationsTagClouds}</Descriptions.Item>;
        });

        return <div>
                <Divider>{t('app.entry.edit.dividerIncomingRelationsTitle')}</Divider>
                <Collapse /*defaultActiveKey={['1']}*/>
                    <Panel header={t('app.entry.edit.incomingRelationsCollapseTitle')} key="1">
                        <Descriptions bordered column={1} title={t('app.entry.edit.incomingRelationsDescriptionsTitle')} size="middle">
                            {inRelTypesDescriptionItems}
                        </Descriptions>
                    </Panel>
                </Collapse>
            </div>;
    }
}

export default withTranslation() (EntryEditIncomingRelations);

EntryEditIncomingRelations.propTypes = {
    relationHolder: relationsHolderShape.isRequired,
    onRelationChange: PropTypes.func.isRequired,
    //entryRequestResult: , //from container
    //entryTypesRequestResult: , //from container
}