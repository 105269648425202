import React from 'react';
import PropTypes, { string } from "prop-types";
import { relationHolderShape } from "../../../shapes/RelationShapes";
import { Select, Tag, Popover, Tooltip, Progress } from "antd";
import { LockOutlined, InfoCircleOutlined, DeploymentUnitOutlined, SearchOutlined, PlusOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { TweenOneGroup } from 'rc-tween-one';
import { fetchEntries } from '../../../apicalls/fetchEntries';
import { isEmptyValue, isArray, isUndefined, isEmptyObject } from '../../../utils/JsObjectHelper';
import { withTranslation } from 'react-i18next';
import EntryTypeTag from '../../controls/EntryTypeTag';
import RelationAttributeTableView from '../../../containers/detail/attributes/RelationAttributeTableView';
import { fetchEntryContextEngineByParents } from '../../../apicalls/fetchEntryContextEngine';
import EntryNewModal from '../../../containers/detail/EntryNewModal';
import debounce from 'lodash/debounce';
import {
    ATTRIBUTE_DISPLAYTYPE_OPTIONS_EDIT,
    ATTRIBUTE_DISPLAYTYPE_OPTIONS_VIEW,
    ATTRIBUTE_PROPERTYTYPE_DISPLAYTYPE,
    ATTRIBUTE_PROPERTYTYPE_USE_CONTEXTENGINE,
    ATTRIBUTE_PROPERTYTYPE_CONTEXTENGINE_DEPTH
} from '../../../utils/EmptyObjectDefinitions';
import { fetchEntryDetail,/*, fetchEntryDetailMinimal*/ 
fetchEntryDetailMinimal} from '../../../apicalls/fetchEntryDetail';

const { Option, OptGroup } = Select;

const CONTEXTENGINE_LIMIT_PERTYPE = 5;
const CONTEXTENGINE_LIMIT_PROMPT = 0.7;

class RelationAttributeTagCloud extends React.Component {
    constructor(props) {
        super(props);

        let foundAttDisplayType = null;

        if (!isUndefined(props.attributesProperties) &&
            !isUndefined(props.attributesProperties[props.attributeTechName])) {
            if (!isUndefined(props.attributesProperties[props.attributeTechName][ATTRIBUTE_PROPERTYTYPE_DISPLAYTYPE])) {
                foundAttDisplayType = props.attributesProperties[props.attributeTechName][ATTRIBUTE_PROPERTYTYPE_DISPLAYTYPE];
            }
        }

        this.state = {
            inputVisible: false,
            createNewModalVisible: false,
            searchedData: [],
            restrictedFolders: [],
            toolTipVisible: false,
            toolTipVisibleIsEDITError: false,
            contextEngineFinds: [],
            attributeDisplayType: foundAttDisplayType,
            isSearchBoxLoading: false,
            useContextEngine: false,
            tooltipParentName:null,
        };

        /*if (props.isEditMode && !this.props.isForIncomingRelations) {
            this.initContextEngine(props.entryType);
        }*/


        this.handleClose = this.handleClose.bind(this);
        this.showInput = debounce(this.showInput, 500);
        this.handleSearch = debounce(this.handleSearch, 500);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.focusInput = this.focusInput.bind(this);
        this.hideInput = this.hideInput.bind(this);
        this.selectRef = React.createRef();

        this.prepareRestrictedFolderNames(props);
    }

    componentDidMount() {
        if (!isUndefined(this.props.attributesProperties) &&
            !isUndefined(this.props.attributesProperties[this.props.attributeTechName])) {
            if (isUndefined(this.state.attributeDisplayType) && !isUndefined(this.props.attributesProperties[this.props.attributeTechName][ATTRIBUTE_PROPERTYTYPE_DISPLAYTYPE])) {
                this.setState({ attributeDisplayType: this.props.attributesProperties[this.props.attributeTechName][ATTRIBUTE_PROPERTYTYPE_DISPLAYTYPE] });
            }
            if (!isUndefined(this.props.attributesProperties[this.props.attributeTechName][ATTRIBUTE_PROPERTYTYPE_USE_CONTEXTENGINE])) {
                this.setState(
                    { useContextEngine: this.props.attributesProperties[this.props.attributeTechName][ATTRIBUTE_PROPERTYTYPE_USE_CONTEXTENGINE] },
                    () => { this.initContextEngine(this.props.entryType); }
                );
            }
        }
    }

    initContextEngine = (entryTypes) => {
        let contextEngineDepth = 6;
        if (!isUndefined(this.props.attributesProperties) &&
            !isUndefined(this.props.attributesProperties[this.props.attributeTechName]) &&
            !isUndefined(this.props.attributesProperties[this.props.attributeTechName][ATTRIBUTE_PROPERTYTYPE_CONTEXTENGINE_DEPTH])) {
            contextEngineDepth = this.props.attributesProperties[this.props.attributeTechName][ATTRIBUTE_PROPERTYTYPE_CONTEXTENGINE_DEPTH];
        }
        if (this.state.useContextEngine) {
            entryTypes.forEach(eT => {
                fetchEntryContextEngineByParents(this.props.entryId, eT, contextEngineDepth, this.props.entryParent, this.handleContextEngineReceived);
            });
        }
    };

    handleContextEngineReceived = (contextEngineResult) => {
        let filtered = contextEngineResult.sort((a, b) => b.contextScore - a.contextScore)
            .filter(
                ((cE) => isUndefined(this.props.relationHolder.relatedEntries.find((rE) => rE.id === cE.id)))
            );

        filtered = filtered.slice(0, CONTEXTENGINE_LIMIT_PERTYPE);

        this.setState(state => {
            return { contextEngineFinds: state.contextEngineFinds.concat(filtered) }
        });
    };

    handleClose = (entryId, e) => {
        // e.preventDefault(); 
        this.props.onCloseTag(this.props.relationHolder.name, entryId);
    };

    handleSearch = (value) => {
        //let fetchValue = {query : value, type: this.props.entryType};
        fetchEntries(value, "relationAtriuteTagCloud", searchData => this.handleDataSearched(searchData), this.props.entryType, null, this.props.entryParent);
    };

    handleDataSearched = (searchData) => {
        //TODO: Vyřešit, jestli mám EDIT práva
        /*if (this.props.isForIncomingRelations) {
            searchData = searchData.filter(sD => sD.rdf);
        }*/
        console.log(searchData);
        this.setState({ searchedData: searchData })
    };

    openCreateNewModal = () => {
        this.setState({ createNewModalVisible: true });
    };

    handleCreateNewModalClose = () => {
        this.setState({ createNewModalVisible: false });
    };

    prepareRestrictedFolderNames(props) {
        if (!isEmptyValue(props.entryParent) && isArray(props.entryParent)) {
            let searchQuery = "";
            props.entryParent.forEach((id) => {
                searchQuery += "id:" + id + " ";
            });
            fetchEntries(searchQuery, "prepareRestrictedFolderNames", restFolders => this.setState({ restrictedFolders: restFolders }));
        }
    };

    handleSearchChange = (value) => {
        if (this.props.isForIncomingRelations) {
            //incoming relations must check EDIT right first
            this.setState({ isSearchBoxLoading: true });
            fetchEntryDetail(value.split('|#|')[1], (data) => this.checkSelectedEntryForEdit(data, value))
        } else {
            this.handleSearchChangeAftercheck(value);
        }
    };

    checkSelectedEntryForEdit = (entryDetail, selectedValue) => {
        this.setState({ isSearchBoxLoading: false });
        if (entryDetail.userEntryAccess !== "EDIT") {
            //User dont have rights. Scratch adding and display message.
            this.setState({ toolTipVisible: true, toolTipVisibleIsEDITError: true });
        } else {
            //Rights are ok. Continue adding relation
            this.handleSearchChangeAftercheck(selectedValue);
        }
    };

    handleSearchChangeAftercheck = (value) => {
        let entry;
        const inputType = value.split('|#|')[0];
        const idValue = value.split('|#|')[1];

        //Check relation duplicity
        if (this.props.relationHolder.relatedEntries.find(rE => rE.id === idValue)) {
            this.setState({ toolTipVisible: true });
            return;
        }

        let searchEntry = null;
        switch (inputType) {
            case "search":
                searchEntry = this.state.searchedData.find(sD => sD.id === idValue);
                break;
            case "context":
                searchEntry = this.state.contextEngineFinds.find(cE => cE.id === idValue);
                break;
            default:
                //Oh no no no
                return;
        }

        entry = { id: searchEntry.id, name: searchEntry.name, type: searchEntry.type };

        this.props.onAddTag(this.props.relationHolder.name, entry);
        this.setState({ inputVisible: false, toolTipVisible: false, toolTipVisibleIsEDITError: false });
    };

    showInput = () => {
        /*if (!isUndefined(this.props.attributesProperties) && !isUndefined(this.props.attributesProperties[this.props.attributeTechName])) {
            let thisAttributeProperties = this.props.attributesProperties[this.props.attributeTechName];
            if (this.props.isEditMode && !isUndefined(thisAttributeProperties[ATTRIBUTE_PROPERTYTYPE_DISPLAYTYPE])) {
                let displayType = thisAttributeProperties[ATTRIBUTE_PROPERTYTYPE_DISPLAYTYPE];
                if (displayType === "preload") {
                    fetchEntries("*", searchData => this.setState({ searchedData: searchData }), this.props.entryType, null, this.props.entryParent);
                }
            }
        }*/
        if (this.props.isEditMode && this.state.attributeDisplayType === ATTRIBUTE_DISPLAYTYPE_OPTIONS_EDIT.preload.name) {
            fetchEntries("*", "relationAtriuteTagCloud2", searchData => this.setState({ searchedData: searchData.sort((a, b) => a.name.localeCompare(b.name)) }), this.props.entryType, null, this.props.entryParent);
        }
        this.setState({ inputVisible: true }, () => this.focusInput());
    };

    hideInput = () => {
        this.setState({ inputVisible: false, toolTipVisible: false, toolTipVisibleIsEDITError: false });
    };

    focusInput = () => {
        this.selectRef.current.focus();
    };

    handleNewEntryCreated = (newEntry) => {
        let entry = { id: newEntry.id, name: newEntry.name, type: newEntry.type };

        this.props.onAddTag(this.props.relationHolder.name, entry);
        this.setState({ inputVisible: false, createNewModalVisible: false, toolTipVisible: false, toolTipVisibleIsEDITError: false });
    };

    relAttTagRenderer = (props) => {
        const { label, value, closable, onClose } = props;
        let styleCls = (closable ? "relAttTagForRenderer" : "relAttTagDontTouchForRenderer");
        let clr = (closable ? "#e6f7ff" : "#fff0f6");
        return (
            <Tag
                color={clr}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3, borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.85)' }}
                className={styleCls}
            >
                {label}
            </Tag>
        );
    };
    toolTipVisibleChanged = (visible, parent) => {
        if (visible) {
            fetchEntryDetailMinimal(parent, (data) => this.setState({tooltipParentName : data.name}));
        } else {
            this.setState({ tooltipParentName : null});
        }
    };

    render() {
        const { t } = this.props;

        let entryTypeNameList = [];
        let relationsEditLayout = null;
        let contextEnginePrompt = null;

        /** @type {RelationHolder} */
        let rHolder = this.props.relationHolder;
        let relationDisplayHolder = null;

        let isDisplayTypePreload = this.state.attributeDisplayType === ATTRIBUTE_DISPLAYTYPE_OPTIONS_EDIT.preload.name;

        if (this.props.entryTypesRequestResult.getState().isDone()) {
            entryTypeNameList = this.props.entryTypesRequestResult.getData().map((item, key) => { return { type: item.type, name: item.name, color: item.properties.typeColor }; });
        }

        if (this.props.isEditMode) {
            /*
            * EDIT
            */
            let selectedTags = [];
            const tagOptions = rHolder.relatedEntries.sort((a, b) => a.name.localeCompare(b.name)).map(entry => {
                //let tagText = entry.name;
                let tagElem = null;
                let isDontTouch = false;

                if (entry.name !== "@@@RESTRICTED@@@") {
                    isDontTouch = (!isEmptyObject(this.props.dontTouchIds) && this.props.dontTouchIds.indexOf(entry.id) > -1);
                    tagElem = entry.name;
                } else {
                    tagElem = (
                        <span title={t('app.entry.attributes.relationIsHiddenMessage')} >
                            <LockOutlined /> {t('app.entry.attributes.relationIsHiddenTagTitle')}
                        </span>
                    );
                    isDontTouch = true;
                }
                selectedTags.push(entry.id);
                return {
                    label: tagElem,
                    value: entry.id,
                    disabled: isDontTouch,
                };
            });

            //Edit version od Taglist
            relationDisplayHolder = selectedTags.length > 0 ? <Select
                mode={'multiple'}
                style={{ width: '100%' }}
                maxTagCount={'responsive'}
                options={tagOptions}
                value={selectedTags}
                tagRender={(props) => this.relAttTagRenderer(props, this)}
                optionFilterProp="label"
                onDeselect={this.handleClose}
            >
            </Select> : null;
            let tooltipText = t('app.entry.header.msgLoading');

            if (!isEmptyValue(this.state.tooltipParentName)) {
                tooltipText = t('app.header.searchBoxParentFolderName') + this.state.tooltipParentName;
            }

            //const options = this.state.searchedData.map(d => <Option key={d.id}>{d.name + " (" + d.type + ")"}</Option>);
            const options = this.state.searchedData.map(d =>
                <Option key={"search|#|" + d.id}>
                    <Tooltip placement="bottom" onVisibleChange={(visible) => this.toolTipVisibleChanged(visible, d.parent)} title={tooltipText}>
                        <div>
                            <span>{d.name}</span>
                            <span style={{ float: 'right' }}><EntryTypeTag entryTypeName={d.type} entryTypeNameList={entryTypeNameList}></EntryTypeTag></span>
                        </div>
                    </Tooltip>
                </Option>
            );

            //Restricted Folders - info icon and popover in search box
            let selectSuffix = null;
            if (this.state.restrictedFolders.length > 0) {
                let restFolderNamesPanel = this.state.restrictedFolders.map(d => <li key={d.id}><Link to={`/entry/${d.id}`}>{d.name}</Link></li>);
                restFolderNamesPanel = <ul>{restFolderNamesPanel}</ul>;

                selectSuffix = <Popover placement="topRight" title={t('app.entry.attributes.lblRelationRestricted')} content={restFolderNamesPanel} trigger="hover">
                    <LockOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Popover>;
            }

            //Context engine finds
            let contextEngineItems = null;
            if (!this.props.isForIncomingRelations) {
                contextEngineItems = this.state.contextEngineFinds
                    .sort((a, b) => b.contextScore - a.contextScore)
                    .map(cE =>
                        <Option key={"context|#|" + cE.id}>
                            <div>
                                <div style={{ width: '20%', float: 'left', marginRight: '5px' }}>
                                    <Progress strokeColor={{
                                        '0%': '#108ee9',
                                        '100%': '#87d068',
                                    }}
                                        percent={Math.round(cE.contextScore * 100)}
                                        size="small" />
                                </div>
                                <span>{cE.name}</span>
                                <span style={{ float: 'right' }}><EntryTypeTag entryTypeName={cE.type} entryTypeNameList={entryTypeNameList}></EntryTypeTag></span>
                            </div>
                        </Option>
                    );
            }

            if (!this.props.isForIncomingRelations && !this.state.inputVisible &&
                this.state.contextEngineFinds.filter(cE => cE.contextScore > CONTEXTENGINE_LIMIT_PROMPT).length > 0
            ) {
                contextEnginePrompt = <Tag onClick={this.showInput} color="gold" style={{ cursor: 'pointer' }}>
                    <InfoCircleOutlined /> {t('app.entry.attributes.contextEngineFindsPrompt')}
                </Tag>;
            }

            if (this.state.inputVisible) {
                relationsEditLayout = (
                    <Tooltip placement="top"
                        title={(this.state.toolTipVisibleIsEDITError ? t('app.entry.attributes.toolTipNeedEditRightsMessage') : t('app.entry.attributes.toolTipExistingRelationTitle'))}
                        visible={this.state.toolTipVisible}
                        arrowPointAtCenter
                    >
                        <Select
                            showSearch={!isDisplayTypePreload}
                            value={null}
                            placeholder={t('app.entry.attributes.boxRelationSearchHint')}
                            style={{ width: '100%' }}
                            defaultActiveFirstOption={false}
                            //showArrow={false}
                            filterOption={false}
                            onSearch={this.handleSearch}
                            onChange={this.handleSearchChange}
                            notFoundContent={<span>{t('app.entry.attributes.boxRelationToFound')}</span>}
                            ref={this.selectRef}
                            //autoFocus={true}
                            onBlur={this.hideInput}
                            suffixIcon={selectSuffix}
                            defaultOpen
                            loading={this.state.isSearchBoxLoading}
                        >
                            <OptGroup key="search"
                                label={
                                    <div style={{ fontWeight: 'bold', backgroundColor: '#d9ffd2b8' }}><SearchOutlined />
                                        <span>{(isDisplayTypePreload ? t('app.entry.attributes.relationsSectionPreloadedData') : t('app.entry.attributes.relationsSectionUserSearch'))}</span>
                                    </div>
                                }
                            >
                                {options}
                            </OptGroup>
                            {
                                (!this.props.isForIncomingRelations && this.state.useContextEngine) && (
                                    <OptGroup key="context" label={<div style={{ fontWeight: 'bold', backgroundColor: '#f8f9c6b8' }}><DeploymentUnitOutlined /> <span>{t('app.entry.attributes.relationsSectionContextEngine')}</span></div>}>
                                        {contextEngineItems}
                                    </OptGroup>
                                )
                            }
                        </Select>
                    </Tooltip>);
            } else {
                relationsEditLayout = [
                    <Tag key="addRelBtn" onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed', cursor: 'pointer', marginLeft: '10px' }}>
                        <PlusOutlined /> {t('app.entry.attributes.btnNewRelation')}
                    </Tag>
                ];
                if (!this.props.isForIncomingRelations) {
                    relationsEditLayout.push([
                        <Tag key="createRelBtn" onClick={this.openCreateNewModal} style={{ background: '#fff', borderStyle: 'dashed', cursor: 'pointer' }}>
                            <PlusSquareOutlined /> {t('app.entry.attributes.btnCreateNewEntryInRelation')}
                        </Tag>,
                        <EntryNewModal
                            key="createNewRelModal"
                            isVisible={this.state.createNewModalVisible}
                            parentDefault={this.props.entry.getData().parent}
                            allowedParents={this.props.entryParent}
                            allowedEntryTypes={this.props.entryType}
                            onCancel={this.handleCreateNewModalClose}
                            infoBoxText={t('app.entry.attributes.createNewEntryInRelationInfoBox', { relation_name: this.props.title, entry_name: this.props.entry.getData().name })}
                            onNewEntryCreated={this.handleNewEntryCreated}
                        ></EntryNewModal>
                    ]);
                }
            }

        } else {
            /*
            * VIEW
            */
            let entryLimit = (!isEmptyValue(this.props.generalSettingsObject) ?
                //if settings are loaded, get value of the limit
                this.props.generalSettingsObject.limits.displayRelationTagLimit :
                //null if waiting to settings
                null);

            if (!isUndefined(entryLimit)) {
                let displayTypeForRelation = this.state.attributeDisplayType;
                if (rHolder.relatedEntries.length > entryLimit) {
                    //If data exceded limit, force show table
                    displayTypeForRelation = ATTRIBUTE_DISPLAYTYPE_OPTIONS_VIEW.table.name;
                }
                switch (displayTypeForRelation) {
                    //TABLE display layout
                    case ATTRIBUTE_DISPLAYTYPE_OPTIONS_VIEW.table.name:
                        relationDisplayHolder = <RelationAttributeTableView
                            attributeRelationsData={rHolder.relatedEntries}
                            entryTypeNameList={entryTypeNameList}
                            attributeProperties={(isEmptyValue(this.props.attributesProperties) ? null : this.props.attributesProperties[this.props.attributeTechName])}
                            possibleEntryTypes={this.props.entryType}
                            entryTypesRequestResult={this.props.entryTypesRequestResult}
                        >
                        </RelationAttributeTableView>;
                        break;
                    //TAG display layout is default
                    case ATTRIBUTE_DISPLAYTYPE_OPTIONS_VIEW.tag.name:
                    default:
                        let tags = rHolder.relatedEntries.map(entry => {
                            let tagText = entry.name;
                            let tagElem = null;
                            let isDontTouch = false;

                            if (entry.name !== "@@@RESTRICTED@@@") {
                                if (!this.props.isEditMode) {
                                    tagText = (<Link to={`/entry/${entry.id}`}>{entry.name}</Link>);
                                }
                                isDontTouch = (!isEmptyObject(this.props.dontTouchIds) && this.props.dontTouchIds.indexOf(entry.id) > -1);
                                tagElem = (
                                    <Tag
                                        //closable={this.props.isEditMode && !isDontTouch}
                                        //onClose={this.handleClose.bind(this, entry)}
                                        style={isDontTouch ? { background: '#fff', borderStyle: 'dashed' } : { backgroundColor: '#e6f7ff' }}
                                    >
                                        {tagText}
                                    </Tag>
                                );
                            } else {
                                tagElem = (
                                    <Tag
                                        color="red"
                                        style={{ borderStyle: 'dashed' }}
                                        title={t('app.entry.attributes.relationIsHiddenMessage')}
                                    >
                                        <LockOutlined /> {t('app.entry.attributes.relationIsHiddenTagTitle')}
                                    </Tag>
                                );
                            }

                            return (
                                <span key={`${rHolder.name} - ${entry.id}`} style={{ display: 'inline-block' }}>
                                    {tagElem}
                                </span>
                            );
                        });
                        relationDisplayHolder =
                            (<div style={{ marginBottom: 16, padding: '5px', borderRadius: '10px', border: '1.3px dashed lightgray', backgroundColor: '#fafafa' }}>
                                <TweenOneGroup
                                    enter={{
                                        scale: 0.8,
                                        opacity: 0,
                                        type: 'from',
                                        duration: 100,
                                    }}
                                    leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                                    appear={true}
                                >
                                    {tags}
                                </TweenOneGroup>
                            </div>);
                        break;
                }
            }
        }

        return <div className="ant-form-item">
            <div className="ant-form-item-label">
                <b>{this.props.title}</b>
            </div>
            {relationDisplayHolder}
            {relationsEditLayout}
            {contextEnginePrompt}

        </div>;
    }
}

export default withTranslation()(RelationAttributeTagCloud);

RelationAttributeTagCloud.propTypes = {
    isEditMode: PropTypes.bool.isRequired,
    isForIncomingRelations: PropTypes.bool,
    relationHolder: relationHolderShape.isRequired,
    onCloseTag: PropTypes.func.isRequired,
    onAddTag: PropTypes.func.isRequired,
    entryType: PropTypes.arrayOf(string).isRequired,
    entryParent: PropTypes.arrayOf(string),
    attributeTechName: PropTypes.string,
    dontTouchIds: PropTypes.arrayOf(string)
};