export const WS_CONNECT = 'WS_CONNECT';
export const WS_CONNECTING = 'WS_CONNECTING';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_DISCONNECT = 'WS_DISCONNECT';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';
export const WS_STOMP_ERROR = 'WS_STOMP_ERROR';
export const WS_ERROR = 'WS_ERROR';
export const WS_CLOSED = 'WS_CLOSED';
export const WS_TOPIC_SUBSCRIBE = 'WS_TOPIC_SUBSCRIBE';
export const WS_TOPIC_SUBSCRIBED = 'WS_TOPIC_SUBSCRIBED';
export const WS_MESSAGE_RECEIVED = 'WS_MESSAGE_RECEIVED';
export const WS_REFRESH_TOKEN = 'WS_REFRESH_TOKEN';
export const WS_REFRESHED_TOKEN = 'WS_REFRESHED_TOKEN';
export const WS_CLIENT_INFO_RECEIVED = 'WS_CLIENT_INFO_RECEIVED';
export const WS_MESSAGE_PUBLISH = 'WS_MESSAGE_PUBLISH';
export const WS_MESSAGE_PUBLISHED = 'WS_MESSAGE_PUBLISHED';


export const wsConnect = (conf, token, subscribedTopics) => ({ type: WS_CONNECT, conf: conf, token: token, subscribedTopics: subscribedTopics });
export const wsConnecting = (conf, token, subscribedTopics) => ({ type: WS_CONNECTING, conf: conf, token: token, subscribedTopics: subscribedTopics });
export const wsConnected = (conf, token, subscribedTopics, frame) => ({ type: WS_CONNECTED, conf: conf, token: token, subscribedTopics: subscribedTopics, frame: frame });
export const wsDisconnect = () => ({ type: WS_DISCONNECT });
export const wsDisconnected = (conf, frame) => ({ type: WS_DISCONNECTED, conf: conf, frame: frame });
export const wsStompError = (conf, frame) => ({ type: WS_STOMP_ERROR, conf: conf, frame: frame });
export const wsError = (conf, event) => ({ type: WS_ERROR, conf: conf, event: event });
export const wsClosed = (conf, event) => ({ type: WS_CLOSED, conf: conf, event: event });
export const wsTopicSubscribe = topic => ({ type: WS_TOPIC_SUBSCRIBE, topic: topic });
export const wsTopicSubscribed = (topic, subscription) => ({ type: WS_TOPIC_SUBSCRIBED, topic: topic, subscription: subscription });
/**
 *
 * @param {String} topic topic
 * @param {StompMessage} message
 * @return {MessageAction}
 */
export const wsMessageReceived = (topic, message) => ({ type: WS_MESSAGE_RECEIVED, topic: topic, message: message });
export const wsRefreshToken = (token) => ({ type: WS_REFRESH_TOKEN, token: token });
export const wsRefreshedToken = (token) => ({ type: WS_REFRESHED_TOKEN, token: token });
/**
 *
 * @param {ClientInfo} clientInfo
 * @return {{clientInfo: *, type: string}}
 */
export const wsClientInfoReceived = (clientInfo) => ({ type: WS_CLIENT_INFO_RECEIVED, clientInfo: clientInfo });
/**
 * @param {PublishParams} params
 * @return {{type: string, params: PublishParams}}
 */
export const wsMessagePublish = (params) => ({ type: WS_MESSAGE_PUBLISH, params: params });
/**
 * @param {PublishParams} params
 * @return {{type: string, params: PublishParams}}
 */
export const wsMessagePublished = (params) => ({ type: WS_MESSAGE_PUBLISHED, params: params });