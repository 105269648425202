import { fetchJson } from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const CANDIDATE_REQUESTED = "CANDIDATE_REQUESTED";
/**
 * Action for request of entry.
 *
 * @return {CandidateAction}
 */
export const requestCandidate = () => {
  return {
    type: CANDIDATE_REQUESTED,
  };
};


export const CANDIDATE_RECEIVED = "CANDIDATE_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} candidate
 * @return {ReceiveCandidateAction}
 */
export const receiveCandidate = (candidate) => {
  return {
    type: CANDIDATE_RECEIVED,
    candidate: candidate,
    receivedAt: Date.now(),
  };
};

export const CANDIDATE_ERROR = "CANDIDATE_ERROR";
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveCandidateErrorAction}
 */
export const receiveError = (
  error,
  type = CANDIDATE_ERROR,
  errorResponseStatusCode = null,
  errorResponseMessage = null
) => {
  return {
    type: type,
    error: error,
    errorResponseStatusCode: errorResponseStatusCode,
    errorResponseMessage: errorResponseMessage,
  };
};


/**
 * 
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchCandidate = (taskId,update = false) => {
  return (dispatch, getState) => {
      if (update) {
        dispatch(requestCandidate());
      } else {
        dispatch(requestCandidate());
      }

      /** @type RequestOptions options **/
      let options = {
          method: "GET"
      };
    
     return  fetchJson(`${config.url.API_URL}/bpmn/task/${taskId}/identityLink`, options, dispatch)
     .then(json => {
        let res = [];
        if (json._embedded && json._embedded.identityLinkDtoes && json._embedded.identityLinkDtoes.length > 0 ) {
            res = json._embedded.identityLinkDtoes;
        }
       dispatch(receiveCandidate(res));
    }
   )  
         .catch(error => {
              if(error === "Ignoring request") {
              } else if(error instanceof ResponseStatusException) {
                  const description = error.response.message ? error.response.message : null;
                  dispatch(receiveError(error, CANDIDATE_ERROR, error.response.status, description));
                  showError(dispatch, error.message, description);
              } else {
                  dispatch(receiveError(error, CANDIDATE_ERROR));
                  showError(dispatch, error.message);
              }
          });
  }
};


