import BpmnAdminPagePresenter from "../../components/bpmn/BpmnAdminPanel";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchUserList } from "../../actions/userList";
import { fetchBpmnDeleteAssignee } from "../../apicalls/bpmn/fetchBpmnDeleteAssignee";
import { fetchGroupList } from "../../actions/groupList";
import { fetchCandidate } from "../../actions/bpmnCandidate";
import { fetchIdentityLink } from "../../apicalls/bpmn/fetchIdentityLink"
import { fetchDueDate } from "../../apicalls/bpmn/fetchDueDate";
import { fetchAllTaskList } from "../../actions/bpmnAllTaskList";
import { fetchSetAssignee } from "../../apicalls/bpmn/fetchSetAssignee";
import { fetchDeleteCandidate } from "../../apicalls/bpmn/fetchDeleteCandidate";
import { fetchTodoList } from "../../actions/bpmnTodoList";


const mapStateToProps = (state, ownProps) => {
	return {
		userListRequestResult: state.userList,
		groupListRequestResult: state.groupList,
		user: state.loggedUser.user,
		due: ownProps.dueDate,
		candidate: state.candidate,
		taskId: ownProps.taskId,
		taskDetail: ownProps.taskDetail
	}
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: () => {
			if(ownProps.taskId){
				dispatch(fetchCandidate(ownProps.taskId));
			}
			dispatch(fetchGroupList());
			dispatch(fetchUserList());
		},
		deleteAssignee: (assigneeId) => {
			let reloadTask = () => {
				dispatch(fetchCandidate(ownProps.taskId));
				dispatch(fetchTodoList(true));
			}
			fetchBpmnDeleteAssignee(ownProps.taskId, assigneeId, reloadTask)
		},
		setAssignee: (assignee) => {
			let reload = () => {
				dispatch(fetchCandidate(ownProps.taskId));
				dispatch(fetchTodoList(true));
			}
			fetchSetAssignee(ownProps.taskId, assignee, reload)
		},
		setDueDate: (date) => {
			let reloadTaskList = () => {
				dispatch(fetchAllTaskList());
			}
			fetchDueDate(ownProps.taskId, date, reloadTaskList)
		},
		addCandidate: (candidate) => {
			let reload = () => {
				dispatch(fetchCandidate(ownProps.taskId));
				dispatch(fetchAllTaskList());
			}
			fetchIdentityLink(ownProps.taskId, candidate, reload)
		},
		deleteCandidate: (candidate) => {
			let reload = () => {
				dispatch(fetchCandidate(ownProps.taskId));
			}
			fetchDeleteCandidate(ownProps.taskId, candidate, reload)
		},


	};
};

const AdminPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(BpmnAdminPagePresenter);

export default withRouter(AdminPage);
