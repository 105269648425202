import React from "react";
import {Alert, Modal} from "antd";
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { withTranslation} from 'react-i18next'
import { baseEntryWithParentShape } from "../../../shapes/BmcEntryShape";
import { isUndefined } from "../../../utils/JsObjectHelper";
import PropTypes from "prop-types";
import { entriesRequestResult, incomingRelationsRequestResult, outgoingRelationsRequestResult } from "../../../shapes/RequestResult";

class EntryDeleteDialog extends React.Component {
    
    constructor(props) {
        super(props);

        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleOk = e => {
        this.props.onOk();
    };

    handleCancel = e => {
        this.props.onCancel();
    };

    findFolderReferences = (entry, entryTypes) => {
        let refArray = [];
        if (entry.systemType === "folder") {
            entryTypes.getData().forEach(eType => {
                if (!isUndefined(eType.properties.attributes.find(a => { 
                        return a.type="relation" && !isUndefined(a.relationEntryParent) && a.relationEntryParent.indexOf(entry.id) > -1 ;
                    }))) 
                {
                    refArray.push({ id: eType.id, name: eType.name, useType: "entryTypes" });
                }
            });
        }
        return refArray;
    };

    render () {
        const {t} = this.props;

        let childCount = (this.props.childs.data != null ? this.props.childs.data.length : 0);
        let inCount = (this.props.inRelations.data != null ? this.props.inRelations.data.length : 0);
        let outCount = (this.props.outRelations.data != null ? this.props.outRelations.data.length : 0);

        let title = (<div><span><QuestionCircleTwoTone twoToneColor="red" title={t('app.entry.delete.title')} style={{marginRight: '15px'}} /></span><span>{t('app.entry.delete.title')}</span></div>);
        
        let cannotDeleteMessage = null;

        if (this.props.visible 
            && !isUndefined(this.props.entryData) 
            && this.props.entryData.systemType === "folder"
            && this.props.entryTypesRequestResult.getState().isDone()
            ) {
            let errList = this.findFolderReferences(this.props.entryData, this.props.entryTypesRequestResult)
            if (errList.length > 0) {
                let errMessageContent = null;
                //usage in EntryTypes
                let errET = errList.filter(e=>e.useType="entryTypes");
                errMessageContent = <div><p>{t('app.entry.delete.usageEntryType')}</p><ul>{errET.map(e => <li key={e.id}>{e.name}</li>)}</ul></div>;

                //some future checks...

                //final alert
                cannotDeleteMessage = <Alert
                                        message={t('app.entry.delete.cannotDeleteTitle')}
                                        description={errMessageContent}
                                        type="error"
                                        showIcon
                                        />;
            }
        }

        return (
            <Modal
                title={title}
                visible={this.props.visible}
                okText={t('app.entry.delete.btnDelete')}
                okType="danger"
                cancelText={t('app.entry.delete.btnCancel')}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okButtonProps={{ disabled: !isUndefined(cannotDeleteMessage) }}
            >
                <div>
                    {cannotDeleteMessage}
                    <p><b>{t('app.entry.delete.promptText')}</b></p>
                    <p>{t('app.entry.delete.summary')}</p>
                    <ul>
                        <li><b>{childCount}</b> {t('app.entry.delete.childCount')}</li>
                        <li><b>{inCount}</b> {t('app.entry.delete.inCount')}</li>
                        <li><b>{outCount}</b> {t('app.entry.delete.outCount')}</li>
                    </ul>
                </div>
            </Modal>
        );
    }
}

export default withTranslation() (EntryDeleteDialog);

EntryDeleteDialog.propTypes = {
    entryData: baseEntryWithParentShape,
    childs: entriesRequestResult,
    inRelations: incomingRelationsRequestResult,
    outRelations: outgoingRelationsRequestResult,
    visible: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}