import {EMPTY_OBJECT} from "../utils/JsObjectHelper";
import {WS_CLOSED, WS_MESSAGE_RECEIVED} from "../modules/websocket/actions";

const deepEqual = require('deep-equal');

/**
 *
 * @param {Map<String, CacheEditedEntry>} state
 * @param {MessageAction} action
 * @return {Map<String, CacheEditedEntry>}
 */
export const editedEntries = (state = EMPTY_OBJECT, action)  => {

    switch (action.type) {
        case WS_MESSAGE_RECEIVED:
            if("/topic/editedEntries" === action.topic) {
                const newState = JSON.parse(action.message.body);
                if(!deepEqual(newState, state)) {
                    return newState;
                }
            }
            return state;
        case WS_CLOSED:
            console.log("editEntries set empty object");
            console.log(EMPTY_OBJECT);
            return EMPTY_OBJECT;
        default:
            return state
    }
};