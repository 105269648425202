import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";
import {HOMEPAGE_ERROR, HOMEPAGE_RECEIVED, HOMEPAGE_REQUESTED} from "../actions/homepage";

/**
 *
 * @param {RequestResult} state
 * @param {ItemIdAction|ReceiveErrorAction} action
 * @return {RequestResult}
 */
export const homepage = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

    switch (action.type) {
        case HOMEPAGE_REQUESTED:
            return new RequestResult(RequestState.getInstanceLoading(), state.data);
        case HOMEPAGE_RECEIVED:
            return new RequestResult(RequestState.getInstanceDone(), action.itemId, action.receivedAt);
        case HOMEPAGE_ERROR:
            return new RequestResult(
                RequestState.getInstanceError(
                    action.errorResponseStatusCode,
                    action.errorResponseMessage ? action.errorResponseMessage : null
                ),
                state.getData()
            );
        default:
            return state
    }
};