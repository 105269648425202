import React from "react";
import { withRouter } from "react-router-dom";
import { PageHeader, Button, Modal, Table, Tag, Input, Checkbox, Popover } from 'antd';
import { EditOutlined, PlusOutlined, InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next'
import { isEmptyObject, isUndefined } from "../../../utils/JsObjectHelper";
import { SETTINGS_WORKFLOW_USETYPES } from "../../../utils/EmptySettings";

const { Column } = Table;
const { Search } = Input;

class WorkflowList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchNameText: [],
            advSearchPageSize: (isEmptyObject(localStorage.getItem("setupWorkflowPageSize")) ? 30 : parseInt(localStorage.getItem("setupWorkflowPageSize"))),
            openPopover: null
        };

        this.onMount = this.onMount.bind(this);
        this.filterEntryTypes = this.filterEntryTypes.bind(this);
    }

    showTotal = (total) => {
        return `(${total})`;
    };

    handlePageSizeChanged = (current, page) => {
        localStorage.setItem("setupWorkflowPageSize", page);
        this.setState({ advSearchPageSize: page });
    };

    componentDidMount() {
        this.onMount();
    }

    onMount(entryId) {
        this.props.onMount();
    }

    filterEntryTypes = (searchValue) => {
        this.setState({ searchNameText: searchValue.split(' ') });
    }

    createNewWorkflow = () => {
        this.props.history.push(`/setup/workflowedit/`);
    }

    editWorkflow = (e, record) => {
        this.props.history.push(`/setup/workflowedit/` + record.name);
    }
    cancelPopover =()=>{
        this.setState({openPopover:false});
    }
    showPopover =(newOpen)=>{
        this.setState({openPopover:newOpen});
    }

    render() {
        const { t } = this.props;

        let info = () => {
            Modal.info({
                title: t('setup.workflowSetup.infoTitle'),
                content: (
                    <div>
                        {t('setup.workflowSetup.infoText')}
                    </div>
                ),
                onOk() {
                },
            });
        };

        let useTypes = Object.keys(SETTINGS_WORKFLOW_USETYPES).map(k => {
            return { text: t(SETTINGS_WORKFLOW_USETYPES[k]), value: k, }
        });

        let paginationSetup = {
            showSizeChanger: true,
            size: "small",
            showTotal: this.showTotal,
            defaultPageSize: this.state.advSearchPageSize,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            onShowSizeChange: this.handlePageSizeChanged
        };

        return (
            <div>
                <PageHeader
                    title={t('setup.workflowSetup.listTitle')}
                    subTitle={t('setup.workflowSetup.listTitleHint')}
                    extra={[
                        <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                        </Button>,
                    ]}
                >
                </PageHeader>
                <Table
                    dataSource={this.props.workflowSettingsList}
                    rowKey={record => record.name}
                    size="small"
                    scroll={{ x: true }}
                    loading={isUndefined(this.props.workflowSettingsList)}
                    pagination={paginationSetup}
                    title={() => <div className="searchForSetup" >
                        <Button type="primary" style={{ marginRight: '15px' , marginBottom: '10px' }} onClick={this.createNewWorkflow}><PlusOutlined />{t('setup.workflowSetup.btnAddWorkflowSetup')}</Button>
                      
                        <Search
                            placeholder={t('setup.workflowSetup.searchBoxHint')}
                            onSearch={this.filterEntryTypes}
                            style={{ width: '100%' }}
                            allowClear
                        />
                       
                    </div>}
                >
                    <Column
                        title={t('setup.workflowSetup.tblAction')}
                        key="action"
                        render={(text, record) => (
                            <span>
                                <Button onClick={(e) => { this.editWorkflow(e, record) }} icon={<EditOutlined />}> <span className="setupActionBtn">{t('setup.workflowSetup.tblBtnEditWorkflowSetup')}</span></Button>
                                <Popover
                                    content={<>{t('setup.workflowSetup.areYouSure')} <Button type="danger" onClick={() => { this.props.deleteWorkflow(record.id, record.name) }} size='small' shape="round"> {t('setup.workflowSetup.btnPopover')}</Button> </>}
                                    trigger="click"
                                    open={this.state.openPopover}
                                    onOpenChange={()=>this.showPopover()}
                                >
                                    <Button  style={{ marginLeft: "5px" }} icon={<DeleteOutlined />}><span className="setupActionBtn">{t('setup.workflowSetup.tblBtnDeleteWorkflowSetup')}</span></Button>
                                </Popover>

                            </span>
                        )}
                    />
                    <Column
                        title={t('setup.workflowSetup.tblName')}
                        dataIndex={["value", "workflowName"]}
                        key="value.workflowName"
                        render={(text, record) => (
                            <span><b>{record.value.workflowName}</b></span>
                        )}
                        filteredValue={this.state.searchNameText}
                        onFilter={(value, record) => record.value.workflowName.toLowerCase().includes(value.toLowerCase())}
                        sorter={(a, b) => {
                            return (isUndefined(a.value.workflowName) ? "" : a.value.workflowName).localeCompare((isUndefined(b.value.workflowName) ? "" : b.value.workflowName));
                        }}
                    />
                    <Column
                        title={t('setup.workflowSetup.detailFormUseType')}
                        dataIndex={["value", "workflowUseType"]}
                        key="value.workflowUseType"
                        render={(text, record) => (
                            <span>{t(SETTINGS_WORKFLOW_USETYPES[record.value.workflowUseType])}</span>
                        )}
                        filters={useTypes}
                        onFilter={(value, record) => record.value.workflowUseType === value}
                    />
                    <Column
                        title={t('setup.workflowSetup.tblEntryTypes')}
                        dataIndex={["value", "entryTypesNameList"]}
                        key="value.entryTypesNameList"
                        render={tags => (
                            <span>
                                {tags.map(tag => (
                                    <Tag color="blue" key={tag}>
                                        {tag}
                                    </Tag>
                                ))}
                            </span>
                        )}
                    />
                    <Column
                        title={t('setup.workflowSetup.detailFormIsEnabled')}
                        dataIndex={["value", "isEnabled"]}
                        key="value.isEnabled"
                        width={100}
                        render={isDisp => (
                            <Checkbox disabled checked={isDisp}></Checkbox>
                        )}
                    />
                    {/* <Column
                        key="id"
                        width={100}
                        render={(text, record) => (
                            <span>
                                <Button type="link" onClick={(e) => {this.props.deleteWorkflow(record.id,record.name)}}><DeleteOutlined style={{fontSize: '18px'}}/> </Button>
                            </span>
                            )}
                    /> */}
                </Table>
            </div>
        );
    }
}

const WorkflowListWithRouter = withRouter(WorkflowList);

export default withTranslation()(WorkflowListWithRouter);