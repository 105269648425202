import {
    SETTINGS_LIST_REQUESTED,
    SETTINGS_LIST_RECEIVED,
    SETTINGS_LIST_ERROR,
} from '../actions/settings.js';
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";

/**
 *
 * @param {RequestResult} state
 * @param {BaseAction|ReceiveSettingsAction|ReceiveSettingsErrorAction} action
 * @return {RequestResult}
 */
export const settingsList = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

    switch (action.type) {
        case SETTINGS_LIST_REQUESTED:
            return new RequestResult(RequestState.getInstanceLoading(), state.data);
        case SETTINGS_LIST_RECEIVED:
            return new RequestResult(RequestState.getInstanceDone(), action.settingsList, action.receivedAt);
        case SETTINGS_LIST_ERROR:
            return new RequestResult(
                RequestState.getInstanceError(
                    action.errorResponseStatusCode,
                    action.errorResponseMessage ? action.errorResponseMessage : null
                ),
                state.getData()
            );
        default:
            return state
    }
};
