import BpmnRunProcessPresenter from "../../components/detail/view/bpmn/BpmnRunProcess";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchTaskCount } from "../../actions/bpmnTaskCount";



const mapStateToProps = (state, ownProps) => {
	return {

	}
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		refreshTaskCount: ()=>{
            dispatch(fetchTaskCount(true));
        }
	};
};

const RunProcess = connect(
	mapStateToProps,
	mapDispatchToProps
)(BpmnRunProcessPresenter);

export default withRouter(RunProcess);
