import {createBaseOptions, fetchJson, METHOD_POST} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError, showSuccess} from "../utils/NotificationsHelper";

export const ENTRYACCESS_REQUESTED = 'ENTRYACCESS_REQUESTED';
/**
 * Action for request of entry access rights.
 *
 * @return {EntryAccessAction}
 */
export const requestEntryAccess = (entryId) => {
    return {
        type: ENTRYACCESS_REQUESTED,
        entryId: entryId
    };
};

export const ENTRYACCESS_RECEIVED = "ENTRYACCESS_RECEIVED";
/**
 *
 * @param {string} entryId
 * @param {EntryAccessList} entryAccessRights
 * @return {ReceiveEntryAccessAction}
 */
export const receiveEntryAccess = (entryId, entryAccessRights) => {
    return {
        type: ENTRYACCESS_RECEIVED,
        entryId: entryId,
        entryAccessRights: entryAccessRights,
        receivedAt: Date.now()
    }
};

export const ENTRYACCESS_ERROR = 'ENTRYACCESS_ERROR';
/**
 *
 * @param {String} entryId
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveGroupUsersErrorAction}
 */
export const receiveError = (
    entryId,
    error,
    type = ENTRYACCESS_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        entryId: entryId,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};

export const ENTRYACCESS_CHANGE_REQUESTED = 'ENTRYACCESS_CHANGE_REQUESTED';
/**
 * Action for changing entry access for group.
 *
 * @param {String} entryId
 * @param {String} groupId
 * @param {String} accessLevel
 * @param {Boolean} applyRecursively
 * @return {GroupUserPutAction}
 */
export const requestEntryAccessChange = (entryId, groupId, accessLevel, applyRecursively) => {
    return {
        type: ENTRYACCESS_CHANGE_REQUESTED,
        entryId: entryId,
        groupId: groupId,
        accessLevel: accessLevel,
        applyRecursively: applyRecursively
    };
};

export const ENTRYACCESS_CHANGE_SUCCESFULL = 'ENTRYACCESS_CHANGE_SUCCESFULL';
/**
 * Action for changing entry access for group.
 *
 * @param {String} entryId
 * @param {String} groupId
 * @param {String} accessLevel
 * @param {Boolean} applyRecursively
 * @param {String} response
 * @return {GroupUserPutAction}
 */
export const requestEntryAccessChangedSuccesfully = (entryId, groupId, accessLevel, applyRecursively, response) => {
    return {
        type: ENTRYACCESS_CHANGE_SUCCESFULL,
        entryId: entryId,
        groupId: groupId,
        accessLevel: accessLevel,
        applyRecursively: applyRecursively,
        response: response
    };
};

/**
 * Fetch entry access rights list
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchEntryAccess = (entryId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            //dispatch(requestGroupDetailUpdate(groupId));
        } else {
            dispatch(requestEntryAccess(entryId));
        }

        //return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/children/projection/baseEntry`, null, dispatch)
        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/access/rights`, null, dispatch)
            .then(json => {
                    let res = [];

                    if (json._embedded && json._embedded.groupEntryRights && json._embedded.groupEntryRights.length > 0) {
                        res = json._embedded.groupEntryRights;
                    }

                    dispatch(receiveEntryAccess(entryId, res));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(entryId, error, ENTRYACCESS_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(entryId, error, ENTRYACCESS_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};

export const updateEntryAccess = (entryId, groupId, accessLevel, applyRecursively) => {
    return (dispatch, getState) => {
        dispatch(requestEntryAccessChange(entryId, groupId, accessLevel, applyRecursively));
        
        let values = {
            groupId: groupId,
            entryId: entryId,
            entryAccess: accessLevel,
            applyRecursively: applyRecursively
        };

        /** @type RequestOptions */
        let options = createBaseOptions();
        options.method = METHOD_POST;
        options.headers["Content-Type"] = "application/json";
        options["body"] = values;

        return fetchJson(`${config.url.API_URL}/access/rights`, options, dispatch)
            .then(json => {
                    dispatch(requestEntryAccessChangedSuccesfully(entryId, json));
                    dispatch(fetchEntryAccess(entryId));
                    showSuccess(dispatch, "Success", "Entry access rights has been successfully updated!");
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(entryId, error, ENTRYACCESS_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(entryId, error, ENTRYACCESS_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};