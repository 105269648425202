import React from "react";
import { Alert, Table, Tag} from "antd";
import { LockOutlined } from '@ant-design/icons';
import { withTranslation} from 'react-i18next';
import { entryTypesRequestResult} from "../../../shapes/RequestResult";
import EntryTypeTag from '../../controls/EntryTypeTag';
import { isArray, isEmptyObject, isEmptyValue, isUndefined } from "../../../utils/JsObjectHelper";
import { Link } from "react-router-dom";
import { highlightSearchedText } from "../../../utils/TextHighlighter";
import { getColumnCustomAttributeProps, getColumnNameValueTag, getColumnSearchProps } from "../../../utils/TableHelper";
import { idNameWithTypeShape } from "../../../shapes/IdNameShapes";
import PropTypes, { object, string } from 'prop-types';
import { fetchEntries } from "../../../apicalls/fetchEntries";

const cloneDeep = require('lodash.clonedeep');

class RelationAttributeTableView extends React.Component {

    state = {
        searchText: '',
        searchedColumn: '',
        relationTablePageSize: (isEmptyObject(localStorage.getItem("relationTablePageSize")) ? 10 : parseInt(localStorage.getItem("relationTablePageSize")) ),
        relatedEntriesData: cloneDeep(this.props.attributeRelationsData)
    };

    /**
     * 
     */
    componentDidMount() {
        this.props.onMount();
        if (
            //are data ready?
            !isUndefined(this.props.attributeRelationsData) && this.props.attributeRelationsData.length > 0 && 
            //are properties ready?
            (!isEmptyValue(this.props.attributeProperties) && !isEmptyValue(this.props.attributeProperties.relationAttributeArray)) &&
            //is setup ready?
            !isEmptyValue(this.props.generalSettingsObject)
            ) {
            this.reloadPropertiesData();
        }

        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    /**
     * Check attributeRelationsData change. If data changed, load new properties detail.
     * 
     * @param {*} prevProps 
     * @param {*} prevState 
     */
     componentDidUpdate(prevProps, prevState) {
        if (this.props.attributeRelationsData !== prevProps.attributeRelationsData &&
            (isUndefined(this.props.attributeRelationsData)? 0 : this.props.attributeRelationsData.length) !== (isUndefined(prevProps.attributeRelationsData)? 0 : prevProps.attributeRelationsData.length) && 
            (!isEmptyValue(this.props.attributeProperties) && !isEmptyValue(this.props.attributeProperties.relationAttributeArray))) {
            this.setState({
                relatedEntriesData: cloneDeep(this.props.attributeRelationsData)
            }, () => {this.reloadPropertiesData()});
        }

        //Try reload data after setting load
        if (this.props.generalSettingsObject !== prevProps.generalSettingsObject && !isEmptyValue(this.props.generalSettingsObject)) {
            this.reloadPropertiesData();
        }
    }

    /**
     * Gets all relation entries IDs and calls fetch detail for those entries.
     */
    reloadPropertiesData = () => {
        let entryLimit = (!isEmptyValue(this.props.generalSettingsObject) ? 
                            this.props.generalSettingsObject.limits.loadRelationAttDetailLimit : 
                            0);
        if (!isEmptyValue(this.state.relatedEntriesData) && isArray(this.state.relatedEntriesData) 
            && this.state.relatedEntriesData.length <= entryLimit //!!!!!!!
            ) {
            let searchQuery = "";
            this.state.relatedEntriesData.forEach((entry) => {
                searchQuery += "id:" + entry.id + " ";
            });

            let that = this;
            let callbackFn = function(entriesDetails) {
                if (that._isMounted) {
                    that.handlePropertiesDataLoaded(entriesDetails);
                }
            };

            fetchEntries(searchQuery,"relationAtributeTableView", callbackFn);
        }
    };

    /**
     * Entry detail data has been loaded. Lets merge them with data from relations.
     * @param {*} entriesData 
     */
    handlePropertiesDataLoaded = (entriesData) => {
        let iteratePropertiesSection = (section, entry) => {
            if (!isUndefined(section) && !isEmptyObject(section)) {
                Object.keys(section).forEach(fieldName => {
                    if(isUndefined(entry.properties)) {
                        entry.properties = {};
                    }
                    entry.properties[fieldName] = section[fieldName];
                });
            }
        };

        if (!isEmptyObject(entriesData)) {
            let copyOfData = cloneDeep(this.state.relatedEntriesData);

            entriesData.forEach(ent => {
                let entry2merge = copyOfData.find(e => e.id === ent.id);

                iteratePropertiesSection(ent.dynamicBoolFieldValues, entry2merge);
                iteratePropertiesSection(ent.dynamicDateFieldValues, entry2merge);
                iteratePropertiesSection(ent.dynamicDoubleFieldValues, entry2merge);
                iteratePropertiesSection(ent.dynamicIntFieldValues, entry2merge);
                iteratePropertiesSection(ent.dynamicStringFieldValues, entry2merge);
                iteratePropertiesSection(ent.dynamicTextFieldValues, entry2merge);
            });

            //write changes back to state
            this.setState({ relatedEntriesData: copyOfData });
        }
    };

    handleRenderTextColumn = (text, record, dataIndex) => {
        const {t} = this.props;

        if (text === "@@@RESTRICTED@@@") {
            return <Tag
                        color="red"
                        style={{ borderStyle: 'dashed' }}
                        title={t('app.entry.attributes.relationIsHiddenMessage')}
                    >   
                        <LockOutlined /> {t('app.entry.attributes.relationIsHiddenTagTitle')}
                    </Tag>;
        }

        return this.state.searchedColumn === dataIndex ? (
            <Link to={`/entry/${record.id}`}>{highlightSearchedText(text, this.state.searchText)}</Link>
        ) : (
            <Link to={`/entry/${record.id}`}>{text}</Link>
        );
    };

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handlePageSizeChanged = (current, page) => {
        localStorage.setItem("relationTablePageSize", page);
        this.setState({ relationTablePageSize: page });
    };

    /**
     * Total record number display helper
     * @param {number} total 
     * @returns 
     */
    showTotal = (total) => {
        return `(${total})`;
    };

    /**
     * Populate columnList array with user defined columns. Those are configured in Setup section in EntryType layout.
     * 
     * @param {Array<object>} columnList 
     * @param {Object} t i18n translator instance
     * @returns number of newly added columns
     */
    addUserAttributes = (columnList, t) => {
        let newCollCount = 0;
        if (!isEmptyValue(this.props.attributeProperties) && !isEmptyValue(this.props.attributeProperties.relationAttributeArray)) {
            let attDefs = [];
            if (this.props.entryTypesRequestResult.getState().isDone() && 
                !isEmptyObject(this.props.entryTypesRequestResult.getData()) &&
                !isEmptyValue(this.props.possibleEntryTypes)) {
                let eTypes = this.props.entryTypesRequestResult.getData().filter(eT => this.props.possibleEntryTypes.includes(eT.type));
                eTypes.forEach(eT => {
                    if (!isEmptyValue(eT.properties) && !isEmptyValue(eT.properties.attributes)) {
                        eT.properties.attributes.forEach(att => {
                            if (this.props.attributeProperties.relationAttributeArray.includes(att.techName) &&
                                isEmptyObject(attDefs.find(a => a.techName === att.techName))) {
                                attDefs.push(att);
                            }
                        });
                    }
                });
            }
            this.props.attributeProperties.relationAttributeArray.forEach(att => {
                let attName = att;
                let attType = "attribute";
                let attDef = attDefs.find(a => a.techName === att);
                if (!isEmptyObject(attDef)) {
                    attName = attDef.name;
                    attType = attDef.type;
                    if (attDef.type === "lov") {
                        columnList.push({ ...getColumnNameValueTag(["properties", att], attName, attDef, t) });
                    } else {
                        columnList.push({ ...getColumnCustomAttributeProps(["properties", att], attName, attType, t) });
                    }
                    newCollCount++;
                }
            });
        }

        return newCollCount;
    };

    render() {
        const {t} = this.props;

        let tooManyRecordsInfo = null;

        let entryLimit = (!isEmptyValue(this.props.generalSettingsObject) ? 
                        this.props.generalSettingsObject.limits.loadRelationAttDetailLimit : 
                        100);
                        
                        let columns = [
            {
                title: t('app.entry.attributes.tblRelationsEntry'),
                dataIndex: 'name',
                key: 'name',
                ...getColumnSearchProps('name', this.handleSearch, this.handleReset, this.handleRenderTextColumn, t, this.searchInput)
            },
            {
                title: t('app.entry.attributes.tblRelationsType'),
                dataIndex: 'type',
                key: 'type',
                render: (text, record) => <EntryTypeTag entryTypeName={text} entryTypeNameList={this.props.entryTypeNameList}></EntryTypeTag>,
                filters: Array.from(new Set(this.props.attributeRelationsData.map(itm => itm.type))).map(
                    itmName => {
                        return { text: this.props.entryTypeNameList.find(t=>t.type===itmName).name, value: itmName };
                    }
                ),
                onFilter: (value, record) => record.type.indexOf(value) === 0
            }
        ];
        
        let custCollCount = this.addUserAttributes(columns, t);
        
        if (this.state.relatedEntriesData.length > entryLimit && custCollCount > 0) {
            tooManyRecordsInfo = <Alert message={t('app.entry.attributes.tblRelationTooManyDataInfo')} type="info" showIcon />;
        }

        columns.push({ ...getColumnCustomAttributeProps("inserted", t('app.entry.attributes.tblRelationInserted'), "date", t) });

        let paginationSetup = false;
        if (this.props.attributeRelationsData.length > 10) {
            paginationSetup = {
                showSizeChanger: true, 
                size:"small", 
                showTotal:this.showTotal,
                defaultPageSize: this.state.relationTablePageSize,
                pageSizeOptions: ['10', '20', '30', '50', '100'],
                onShowSizeChange: this.handlePageSizeChanged
            };
        }

        return (
            <>
                {tooManyRecordsInfo}
                <Table
                    columns={columns}
                    dataSource={this.state.relatedEntriesData}
                    size="small"
                    rowKey={record => record.id}
                    pagination={paginationSetup}
                    scroll={{ x: true }}
                />
            </>
        );

    }
}

export default withTranslation() (RelationAttributeTableView);

RelationAttributeTableView.propTypes = {
    //entryTypeNameList: PropTypes.arrayOf(),
    attributeRelationsData: PropTypes.arrayOf(idNameWithTypeShape).isRequired,
    attributeProperties: object,
    possibleEntryTypes: PropTypes.arrayOf(string),
    entryTypesRequestResult: entryTypesRequestResult
};