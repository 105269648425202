import React from "react";
import { Input, Select, Switch, Space } from "antd";
import { withTranslation } from "react-i18next";
import { isUndefined } from "../../../utils/JsObjectHelper";

const { Option } = Select;

class BpmnReservedVariable extends React.Component {
  constructor(props) {
    super(props);
    const value = this.props.value;
    this.state = {
      value,
     // showSelect: null,
    };
  }
  handleChangeValue = (value) => {
    let variable = "";
    if (this.props.value === value) {
      variable = this.props.value;
    } else {
      variable = value;
    }
    const { onChange } = this.props;
    if (onChange) {
      onChange(variable);
    }
  };

  // handleChange = (value) => {
  //   this.setState({
  //     showSelect: value,
  //   });

	// const { onChange } = this.props;
  //   if (onChange) {
  //     onChange(null);
  //   }
  // };

  render() {
    const { t } = this.props;
  //   let switchChange = null;
	// if (isUndefined(this.state.showSelect)) {
	// 	if (this.props.value) {
	// 		if (this.props.value.includes("@@")) {
	// 		  switchChange = true;
	// 		} else {
	// 		  switchChange = false;
	// 		}
	// 	} else {
	// 		switchChange = false;
	// 	}
	// } else {
	// 	switchChange = this.state.showSelect;
	// }

  //   let item = null;
  //   switch (switchChange) {
  //     case false:
  //       item = (
  //         <Input
	// 	    placeholder={t("setup.workflowSetup.bpmnCustomInputPlaceholder")}
  //           defaultValue={this.props.value}
  //           onChange={this.handleChangeValue}
  //         />
  //       );
  //       break;
  //     case true:
  //       item = (
  //         <Select
	// 	    placeholder={t("setup.workflowSetup.bpmnCustomSelectPlaceholder")}
  //           defaultValue={this.props.value}
  //           onChange={this.handleChangeValue}
  //         >
  //           <Option value={"@@entryName@@"}>
  //             {t("setup.workflowSetup.optionBpmnReservedNameEntry")}
  //           </Option>
  //           <Option value={"@@description@@"}>
  //             {t("setup.workflowSetup.optionBpmnReservedDescription")}
  //           </Option>
  //           <Option value={"@@userName@@"}>
  //             {t("setup.workflowSetup.optionBpmnReservedUserName")}
  //           </Option>
  //           <Option value={"@@userId@@"}>
  //             {t("setup.workflowSetup.optionBpmnReservedUserId")}
  //           </Option>
  //           <Option value={"@@entryCreated@@"}>
  //             {t("setup.workflowSetup.optionBpmnReservedEntryCreated")}
  //           </Option>
  //           <Option value={"@@entryCreatedById@@"}>
  //             {t("setup.workflowSetup.optionBpmnReservedEntryCreatedById")}
  //           </Option>
  //           <Option value={"@@entryCreatedByName@@"}>
  //             {t("setup.workflowSetup.optionBpmnReservedEntryCreatedByName")}
  //           </Option>
  //           <Option value={"@@entryModified@@"}>
  //             {t("setup.workflowSetup.optionBpmnReservedEntryModified")}
  //           </Option>
  //           <Option value={"@@entryModifiedByName@@"}>
  //             {t("setup.workflowSetup.optionBpmnReservedEntryModifiedByName")}
  //           </Option>
  //           <Option value={"@@entryModifiedById@@"}>
  //             {t("setup.workflowSetup.optionBpmnReservedEntryModifiedById")}
  //           </Option>
  //           <Option value={"@@entryType@@ "}>
  //             {t("setup.workflowSetup.optionBpmnReservedEntryType")}
  //           </Option>
  //           <Option value={"@@entryId@@ "}>
  //             {t("setup.workflowSetup.optionBpmnReservedEntryId")}
  //           </Option>
  //         </Select>
  //       );
  //       break;
  //     default:
  //       break;
  //   }
    return (
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {/* <Switch
          defaultChecked={switchChange}
          checkedChildren={t("setup.workflowSetup.customSwitchSelect")}
          unCheckedChildren={t("setup.workflowSetup.customSwitchInput")}
          onChange={this.handleChange}
        /> */}
           <Input
		    placeholder={t("setup.workflowSetup.bpmnCustomInputPlaceholder")}
            defaultValue={this.props.value}
            onChange={this.handleChangeValue}
          />
      </Space>
    );
  }
}
export default withTranslation()(BpmnReservedVariable);
