import React from "react";
import {Modal, Alert, Upload, Table } from "antd";
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { withTranslation} from 'react-i18next'
import {config} from "../../../config/Config";
import { isArray, isEmptyObject, isEmptyValue } from "../../../utils/JsObjectHelper";
import { getErrorParamsObject } from "../../../utils/EntryUploadHelper";

const { Dragger } = Upload;

//const TEMP_ERROR = {"type":"ENTRY","errorType":"HEADER_MISSING_REQUIRED","errorParams":{"headerName":"soubory_tst"},"sheetName":"object","message":"Sheet 'object': There are no header with name 'soubory_tst'.","localizedMessage":"Sheet 'object': There are no header with name 'soubory_tst'."};

class EntryUpload extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            isUploadingData: false,
            btnType: "default",
            messageType: "info",
            messageTitle: "",
            messageDescription: "",
            fileList: [],
            uploadErrors: []
        };

        this.handleBack = this.handleBack.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    handleBack = e => {
        this.setState({
            isUploadingData: false,
            btnType: "default",
            messageType: "info",
            messageDescription: "",
            fileList: [],
            uploadErrors: []
        });
        this.props.onBack();
    };

    onChange = (info) => {
        const {t} = this.props;

        this.setState({ fileList: [...info.fileList] });

        const { status } = info.file;
        if (status !== 'uploading') {
            this.setState({ isUploadingData : false });
        } else {
            this.setState({ isUploadingData : true });
        }
        if (status === 'done') {
            this.setState({ 
                btnType : "primary", 
                messageType: "success",
                messageTitle: t("app.entry.tools.actions.modalUploadInfoSuccess")
            });
            console.log(info.file);
        } else if (status === 'error') {
            let newErrorList = [];
            if (!isArray(info.file.response.error)) {
                newErrorList.push(info.file.response.error);
            } else {
                newErrorList = info.file.response.error;
            }
            this.setState({ 
                messageType: "error", 
                messageTitle: t("app.entry.tools.actions.modalUploadInfoError"),
                messageDescription: info.file.response.message,
                uploadErrors: newErrorList
            });
            console.log(info.file);
        }
    }

    /**
     * Builds localized error message with errorParams in text.
     * 
     * @param {object} record 
     * @returns localized error message
     */
    constructErrorMessage = (record) => {
        const {t} = this.props;
        let errorMessage = record.message;
        let errParams = null;

        //use helper method to determine error Params object
        errParams = getErrorParamsObject(record);

        if (errParams === null) {
            return record.message;
        }

        if (!isEmptyObject(errParams)) {
            errorMessage = t('app.entry.tools.actions.upload.errorMessages.' + record.errorType, errParams);
        } else {
            errorMessage = t('app.entry.tools.actions.upload.errorMessages.' + record.errorType);
        }

        return errorMessage;
    };

    /**
     * Renders extended column according to error detail.
     * 
     * @param {object} record 
     */
    errorDetailColumnRenderer = (record) => {
        return <p style={{ margin: 0 }}>{this.constructErrorMessage(record)}</p>;
    };

    render () {
        const {t} = this.props;

        //const uploadUri = `${config.url.API_URL}/bmcEntries/custom/item/${this.props.entry.id}`;
        //const uploadUri = `${config.url.API_URL}/files/base/upload`;
        //const uploadUri = `${config.url.FILES_URL}/base/upload`;
        const uploadUri = `${config.url.API_URL}/bmcEntries/custom/item/${this.props.entry.id}/bulkLoad`;

        let title = (<div>
                <span><UploadOutlined title={t('app.entry.tools.actions.modalUploadTitle')} style={{marginRight: '15px'}} /></span>
                <span>{t('app.entry.tools.actions.modalUploadTitle')}</span>
            </div>);

        let headers = {};
        let token = sessionStorage.getItem("kcToken");
        if(token) {
            headers = {
                Authorization: 'Bearer ' + token
            };
        }

        let alertMessage = <Alert 
                                message={( isEmptyValue(this.state.messageTitle) ? t('app.entry.tools.actions.modalUploadInfotext') : this.state.messageTitle)} 
                                type={this.state.messageType} 
                                description={this.state.messageDescription}
                                showIcon 
                                style={{ marginBottom: '15px'}} />;
        
        let errorTable = null;

console.log(this.state.uploadErrors);
        if (!isEmptyValue(this.state.uploadErrors)) {
            let columns = [
                { title: t('app.entry.tools.actions.upload.tblErrorTypeTitle'), dataIndex: 'errorType', key: 'errorType', render: text => <span>{t('app.entry.tools.actions.upload.errorCodes.' + text)}</span> },
                { title: t('app.entry.tools.actions.upload.tblErrorSheetNameTitle'), dataIndex: 'sheetName', key: 'sheetName' },
                { title: t('app.entry.tools.actions.upload.tblErrorRowTitle'), dataIndex: 'row', key: 'row' },
            ];
            errorTable = <Table 
                            style={{ marginTop: '15px'}}
                            columns={columns}
                            dataSource={this.state.uploadErrors}
                            expandedRowRender={this.errorDetailColumnRenderer}
                            size="small"
                            pagination={false}
                        ></Table>;
        }

        return (
            <Modal
                title={title}
                visible={this.props.visible}
                cancelText={t('app.entry.tools.actions.modalUploadBtnBack')}
                cancelType={this.state.btnType}
                //okType="danger"
                //cancelText={t('app.entry.delete.btnCancel')}
                onOk={this.handleBack}
                onCancel={this.handleBack}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ loading: this.state.isUploadingData }}
            >
                <div>
                    {alertMessage}
                    <Dragger 
                        name='file'
                        multiple={false}
                        action={uploadUri}
                        accept=".csv,.xlsx"
                        //withCredentials={true}
                        //headers={{'Access-Control-Allow-Origin': 'https://*.aphinit.com'}}
                        headers={headers}
                        onChange={this.onChange}
                        showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: false }}
                        fileList={this.state.fileList}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{t('app.entry.tools.actions.modalUploadBoxTitle')}</p>
                        <p className="ant-upload-hint">
                            {t('app.entry.tools.actions.modalUploadBoxContent')}
                        </p>
                    </Dragger>
                </div>
                <div>
                    {errorTable}
                </div>
            </Modal>
        );
    }
}

export default withTranslation() (EntryUpload);