import { UN_CLAIM_TASK_REQUESTED,
    UN_CLAIM_TASK_RECEIVED,
    UN_CLAIM_TASK_ERROR,

    } from "../actions/bpmnUnClaim";
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";

/**
*
* @param {RequestResult} state
* @param {BaseAction|ReceiveEntryTypesAction|ReceiveErrorAction} action
* @return {RequestResult}
*/
export const unClaimTask = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

switch (action.type) {
   case UN_CLAIM_TASK_REQUESTED:
       return new RequestResult(RequestState.getInstanceLoading(), state.data);
   case UN_CLAIM_TASK_RECEIVED:
       return new RequestResult(RequestState.getInstanceDone(), action.UnClaimTask, action.receivedAt);
   case UN_CLAIM_TASK_ERROR:
       return new RequestResult(
           RequestState.getInstanceError(
               action.errorResponseStatusCode,
               action.errorResponseMessage ? action.errorResponseMessage : null
           ),
           state.getData()
       );
 
   default:
       return state
}
};
