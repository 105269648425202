import { connect } from 'react-redux';
import NotificationPresenter from '../components/Notification';

const Notification = connect(
    state=>({
        notifications: state.notifications
    }),
    dispatch=>({

    })
)(NotificationPresenter);

export default Notification;