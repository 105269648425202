import React from "react";
import { Row, Col, Divider } from 'antd';
import PropTypes from "prop-types";
import {baseEntryWithParentShape} from "../../../shapes/BmcEntryShape";
import {relationsHolderShape} from "../../../shapes/RelationShapes";
import { withTranslation} from 'react-i18next'

import AttributesSectionDetail from './AttributesSectionDetail';
import {isUndefined, isEmptyObject, isEmptyValue} from "../../../utils/JsObjectHelper";

const cloneDeep = require('lodash.clonedeep');

class AttributesSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      relationData : this.props.relationData.relations
    };

    this.handleRelationChanged = this.handleRelationChanged.bind(this);
  }

  componentDidMount() {
    this.props.onMount();
  }

  createRow(content, key) {
    return <Row key={key} align="top" gutter={[{ xs: 0, sm: 8, lg: 16, xl: 32 }, 0]} wrap={true} /*type="flex"*/>{content}</Row>;
  }

  createColumn(content, colWidth, key) {
    return <Col key={key} xs={24} lg={colWidth} className="attSectionRowGutter">{content}</Col>;
  }

  /*handleAttributeChange = (index, fields) => {
    this.props.onPropertyChange(fields);
  }*/

  handleRelationChanged(values) {
    this.setState({relationData : values});
    let relValues = {
        type : "outgoing",
        relations : values
    };
    this.props.onRelationChange("outgoing", relValues);
  }
  
  /**
  * UNSAFE and deprecated lifecycle method!
  */
  /*UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
      if (nextProps.relationData !== this.props.relationData) {
        this.setState({relationData: cloneDeep(nextProps.relationData.relations)});
      }
  }*/
  /**
   * Changed from deprecated componentWillReceiveProps
   * @param {*} prevProps 
   * @param {*} prevState 
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.relationData !== prevProps.relationData) {
      this.setState({relationData: cloneDeep(this.props.relationData.relations)});
    }
  }
  
  render () {
    const {t} = this.props;

    let layoutContent = [];

    if (!isUndefined(this.props.entryTypesRequestResult) && this.props.entryTypesRequestResult.getState().isDone()) {

      let objDefData = this.props.entryTypesRequestResult.getData().find(oL => { return oL.type === this.props.data.type });

      let layoutType = (this.props.isEditMode ? "editLayout" : "viewLayout");

      //sort columns
      //let sorted = objDefData.properties.editLayout.layout.sort((a, b) => (a.y < b.y) ? ( (a.y === b.y) ? (a.x > b.x) : -1) : 1)
      let sorted = objDefData.properties[layoutType].layout.sort((a, b) => (a.y === b.y) ? (a.x - b.x) : (a.y - b.y) )
  
      let uniqueRows = [...new Set(sorted.map(item => item.y))];
 
      uniqueRows.forEach(rowY => {
        let rowContent = [];
        sorted.forEach(col => {
          if (col.y === rowY) {
            let showEditContentPrompt = (this.props.data.userEntryAccess === "EDIT");
            let currentAttributeDef = objDefData.properties[layoutType].attributesDivDefinition.find(a => a.index === parseInt(col.i));

            if (!isEmptyObject(currentAttributeDef)) {
              let colContent = <AttributesSectionDetail 
                  isEditMode={this.props.isEditMode}
                  onRelationChange={this.handleRelationChanged}
                  attributeValues={this.props.data.properties}
                  attributeIndexes={currentAttributeDef.attributeIndexes}
                  attributeDefinitions={objDefData.properties.attributes}
                  attributesProperties={objDefData.properties[layoutType].attributesProperties}
                  sectionName={currentAttributeDef.name}
                  relationData={this.state.relationData}
                  entryID={this.props.data.id}
                  showEditPrompt={showEditContentPrompt}
                  hasDraft={!isEmptyValue(this.props.data.draftChildId)}
                  formRef={this.props.formRef}
                  isLoadingRelations={this.props.isLoadingRelations}
                  //wrappedComponentRef={(ref) => (this.props.isEditMode ? this.props.saveAttFormRef(currentAttributeDef.index, ref) : () => {return;} )}
                  >
                </AttributesSectionDetail>
    
              rowContent.push(this.createColumn(colContent, col.w, rowY + '-' + col.x));
            }
          }
        });
  
        layoutContent.push(this.createRow(rowContent, rowY));
      });
    }

    return (
      <div>
       {isEmptyValue(this.props.data.description) ? null : <Divider className="entryAttDividerPrint">{t('app.entry.attributes.lblAttributeSection')}</Divider>}
          {layoutContent}
      </div>
      );
  }
};
export default withTranslation() (AttributesSection);

AttributesSection.propTypes = {
  data: baseEntryWithParentShape.isRequired,
  relationData: relationsHolderShape.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isLoadingRelations: PropTypes.bool,
  formRef: PropTypes.any,
};
