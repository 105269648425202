import React from "react";
import { Button, Table } from "antd";
import { isEmptyValue } from "../../../utils/JsObjectHelper";
import { withTranslation } from "react-i18next";
import { isUndefined } from "lodash";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import BpmnVariablesModal from "./BpmnVariablesModal";

const cloneDeep = require("lodash.clonedeep");

class BpmnVariables extends React.Component {
  constructor(props) {
    super(props);
    const value = this.props.value;
    this.state = {
      value,
      isModalEdit: false,
      isModalOpen: false,
      modalItemData: null,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleChange = (value) => {
    this.setState({ value: value });
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  itemAdded = (itemName, itemValue) => {
    let newItemObj = { name: itemName, value: itemValue };

    let thisItemArray = cloneDeep(this.state.value);
    if (isUndefined(thisItemArray)) {
      thisItemArray = [];
      thisItemArray.push(newItemObj);
    } else {
      thisItemArray.push(newItemObj);
    }
    this.setState({
      isModalEdit: false,
      isModalOpen: false,
      modalItemData: null,
    });
    this.handleChange(thisItemArray);
  };

  itemUpdated = (itemName, itemValue, prevItem) => {
    let thisItemArray = cloneDeep(this.state.value);
    let newItemObj = { name: itemName, value: itemValue };
    thisItemArray = thisItemArray.filter(
      (f) => !(f.name === prevItem.name && f.value === prevItem.value)
    );
    thisItemArray.push(newItemObj);
      
    this.setState({
      isModalEdit: false,
      isModalOpen: false,
      modalItemData: null,
    });
    this.handleChange(thisItemArray);
  };

  onItemEdit = (record) => {
    this.setState({
      isModalEdit: true,
      isModalOpen: true,
      modalItemData: record,
    });
  };

  onItemDeleted = (record) => {
    let thisItemArray = cloneDeep(this.state.value);
    if (!isUndefined(thisItemArray)) {
      thisItemArray = thisItemArray.filter(
        (f) => !(f.name === record.name && f.value === record.value)
      );
      this.handleChange(thisItemArray);
    }
  };

  onModalHide = () => {
    this.setState({
      isModalEdit: false,
      isModalOpen: false,
      modalItemData: null,
    });
  };

  render() {
    const { t } = this.props;

    let columns = [
      {
        title: t("setup.workflowSetup.bpmnAllVariable"),
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: "ascend",
      },
      {
        title: t("app.genericControls.formNameValueItem.tabValue"),
        dataIndex: "value",
        key: "value",
        render: (text, record) => {
          return (
            <span title={record.value}>
              {isEmptyValue(record.value)
                ? ""
                : record.value.length > 50
                ? `${record.value.substring(0, 50)}...`
                : record.value}
            </span>
          );
        },
      },
      {
        title: "",
        dataIndex: "action",
        width: "76px",
        render: (text, record) => {
          return [
            <Button
              key="btnEdit"
              onClick={() => this.onItemEdit(record)}
              icon={<EditOutlined />}
              size="small"
              type="primary"
              ghost
            ></Button>,
            <Button
              key="btnDelete"
              onClick={() => this.onItemDeleted(record)}
              icon={<DeleteOutlined />}
              size="small"
              type="danger"
              ghost
            ></Button>,
          ];
        },
      },
    ];

    return (
      <Table
        key="nameValueTable"
        columns={columns}
        dataSource={this.state.value}
        size="small"
        pagination={false}
        scroll={{
          x: true,
          }}
        title={() => (
          <BpmnVariablesModal
            onItemAdd={this.itemAdded}
            onItemUpdated={this.itemUpdated}
            onModalHide={this.onModalHide}
            isEdit={this.state.isModalEdit}
            isOpen={this.state.isModalOpen}
            itemValues={this.state.modalItemData}
            entry={this.props.entryType}
            formRef={this.props.formRef}
            userType={this.props.useType}
          ></BpmnVariablesModal>
        )}
        rowKey={(record) => {
          return `${record.name}|${record.value}`;
        }}
      />
    );
  }
}

export default withTranslation()(BpmnVariables);
