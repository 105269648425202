import React from "react";
import PropTypes from "prop-types";
import { withTranslation} from 'react-i18next'
import { Button, Input, Alert, Form } from 'antd';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { isUndefined } from "../../utils/JsObjectHelper";

class GroupEdit extends React.Component {
    constructor(props) {
        super(props);

        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleSave = (finishValues) => {
        //e.preventDefault();
        this.formRef.current.validateFields()
        .then(values => {
            this.props.onSave(this.props.groupData.key, values);
        })
        .catch(errorInfo => {
            console.log(errorInfo);
            return;
        });
    };

    handleCancel = e => {
        this.props.onCancel();
    };

    render () {
        const {t} = this.props;

        let formFields = [];
        Object.keys(this.props.groupData).forEach((field) => {
            formFields.push({ name: [field], value: this.props.groupData[field]});
        });

        return (
            <div>
                <Alert message={t('setup.userManagement.groupEditInfoBox')} type="info" showIcon></Alert>
                <Form onFinish={this.handleSave}
                    name="group_form"
                    fields={formFields}
                    ref={this.formRef}
                >
                    <Form.Item label={t('setup.userManagement.groupBoxNameLbl')}
                        name="name"
                        rules={[{ required: true, message: 'Please input group name!' }]}
                    >
                        <Input placeholder={t('setup.userManagement.groupBoxNameHint')} style={{ width: '100%' }} allowClear={true} />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (
                            <Button type="primary" htmlType="submit" style={{marginRight: '10px'}} icon={<SaveOutlined />} 
                                disabled={
                                    isUndefined(this.formRef.current) ||
                                    (!isUndefined(this.formRef.current) && 
                                        (
                                            !this.formRef.current.isFieldsTouched(true) ||
                                            !!this.formRef.current.getFieldsError().filter(({ errors }) => errors.length).length
                                        )
                                    )
                                }
                                >{t('setup.userManagement.usrEditBtnSave')}</Button>
                        )}
                        <Button icon={<CloseOutlined />} onClick={this.handleCancel}>{t('setup.userManagement.usrEditBtnCancel')}</Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default withTranslation() (GroupEdit);

GroupEdit.propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isCreateNew: PropTypes.bool.isRequired,
};
