import { fetchJson, METHOD_POST,createBaseOptions } from "../../utils/SuperAgentFetch";
import ResponseStatusException from "../../exceptions/ResponseStatusException";
import { config } from "../../config/Config";
import { notificationError } from "../../utils/NotificationsHelper";
/**
 *
 * @param {String} taskId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */

 export const fetchComplete = (taskId, update = false, variables, callback) => {

      let variabl = {
        variables,
        withVariablesInReturn: true,
      };
  
      let options = createBaseOptions();
      options.method = METHOD_POST;
      options.headers["Content-Type"] = "application/json";
      options["body"] = variabl;
  
      return fetchJson( `${config.url.API_URL}/bpmn/task/${taskId}/complete`,  options,)
        .then((json) => {
          let res = [];
          res = json;
          if (callback) {
            callback();
          }
        })
        .catch((error) => {
          if (error === "Ignoring request") {
          } else if (error instanceof ResponseStatusException) {
            const description = error.response.message
              ? error.response.message
              : null;
              notificationError( error.message, description);
          } else {
            notificationError(error.message);
          }
        });
    
  };
  