import React from 'react';
import { isEmptyObject, isEmptyValue, isUndefined } from "../../../utils/JsObjectHelper";
import { Alert, Col, Form, Modal, Row, Select, Spin, } from 'antd';
import { withTranslation } from 'react-i18next'
import AttributesSectionDetail from '../edit/AttributesSectionDetail';
import { notificationSuccess } from '../../../utils/NotificationsHelper';
import { fetchBulkUpdateAttributsEntries } from '../../../apicalls/fetchBulkUpdateAttributsEntries';

const { Option } = Select;

class MultipleAttributeChangesModal extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            inProgress: false
        };
    }
    onCancel = () => {
        this.props.cancel();
        this.setState({ inProgress: false });
    }
    onOk = () => {
        this.setState({ inProgress: true });
        const formValues = this.formRef.current.getFieldsValue().properties;
        let finalValues = Object.entries(formValues).reduce((acc, [key, value]) => {
            if (value !== undefined && typeof value !== 'object') {
                acc[key] = value;
            }
            if(typeof value === 'object' && (value.label !== undefined || value.url !== undefined)){
                acc[key] = value;
            }
            return acc;
        }, {});
        let object = {
            entryIds: this.props.entriesId,
            editData: {
                partialPatchedProperties: finalValues
            }
        }
        fetchBulkUpdateAttributsEntries(object, (data) => {
            notificationSuccess("Success");
            this.props.cancel();
            this.setState({ inProgress: false });
        });

    }
    handleSubmit = (value) => {
        console.log(value)
    }
    createColumn(content, colWidth, key) {
        return <Col key={key} xs={24} lg={colWidth} className="attSectionRowGutter">{content}</Col>;
    }
    createRow(content, key) {
        return <Row key={key} align="top" gutter={[{ xs: 0, sm: 8, lg: 16, xl: 32 }, 0]} wrap={true} /*type="flex"*/>{content}</Row>;
    }
    handleRelationChanged = () => {

    }

    render() {
        let layoutContent = [];
        const { t } = this.props;
        if (!isUndefined(this.props.entryTypesRequestResult) && this.props.entryTypesRequestResult.getState().isDone()) {

            let objDefData = this.props.entryTypesRequestResult.getData().find(oL => { return oL.type === this.props.type[0] });
            console.log(objDefData);
            let sorted = objDefData.properties["editLayout"].layout.sort((a, b) => (a.y === b.y) ? (a.x - b.x) : (a.y - b.y));
            let uniqueRows = [...new Set(sorted.map(item => item.y))];
            console.log(objDefData.properties.attributes);
            let attributesWithoutRelation = objDefData.properties.attributes.map(item => 
                (item.type === 'relation' || item.type === "fileUrlArray") ? null : item
            );
           // let attributesWithoutRelation = attributesWithPlaceholders.filter(item => item !== null);
            uniqueRows.forEach(rowY => {
                let rowContent = [];
                sorted.forEach(col => {
                    if (col.y === rowY) {
                        let currentAttributeDef = objDefData.properties["editLayout"].attributesDivDefinition.find(a => a.index === parseInt(col.i));
                        if (!isEmptyObject(currentAttributeDef)) {
                            let colContent = <AttributesSectionDetail
                                isEditMode={true}
                                onRelationChange={this.handleRelationChanged}
                                attributeValues={{}}
                                attributeIndexes={currentAttributeDef.attributeIndexes}
                                attributeDefinitions={attributesWithoutRelation}
                                attributesProperties={objDefData.properties["editLayout"].attributesProperties}
                                sectionName={currentAttributeDef.name}
                                relationData={[]}
                                entryID={this.props.data}
                                showEditPrompt={false}
                                hasDraft={false}
                                formRef={this.formRef}
                                isLoadingRelations={undefined}
                                multipleEdit={true}
                            >
                            </AttributesSectionDetail>

                            rowContent.push(this.createColumn(colContent, col.w, rowY + '-' + col.x));
                        }
                    }
                });

                layoutContent.push(this.createRow(rowContent, rowY));
            });
        }
        let forma = <Form
            onFinish={this.handleSubmit}
            ref={this.formRef}
            name="entry_edit_form"
            layout="vertical"
            style={
                { overflowY: "auto", height: "850px", overflowX: "hidden", }
            }
        ><Alert message={t('app.entry.view.multipleAttributeChangesAlert')} type="warning" />
            {layoutContent}
        </Form>
        return <>
            <Modal
                visible={this.props.visible}
                title={t('app.entry.view.modalMultiSelectionEditAtt')}
                onCancel={this.onCancel}
                onOk={this.onOk}
                width="60%"
            >
                <Spin spinning={this.state.inProgress} size="large">
                    
                    {forma}
                </Spin>
            </Modal>

        </>;

    }
}

export default withTranslation()(MultipleAttributeChangesModal);

