export const getTextColorFromBackgroundHex = (backgroundHex) => {
    let r = 0, g = 0, b = 0;
    let textColor = "#000000";
                
    if (backgroundHex) {
        // strip the leading # if it's there
        let hex = backgroundHex.replace(/^\s*#|\s*$/g, '');
    
        // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
        if(hex.length === 3){
            hex = hex.replace(/(.)/g, '$1$1');
        }
    
        r = parseInt(hex.substr(0, 2), 16);
        g = parseInt(hex.substr(2, 2), 16);
        b = parseInt(hex.substr(4, 2), 16);
        
        if ((r*0.299 + g*0.587 + b*0.114) < 186 ) {
            textColor = '#ffffff';
        }
    }

    return textColor;
};