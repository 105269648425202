import RelationAttributeTagCloudPresenter from '../../components/detail/edit/RelationAttributeTagCloud';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchObjectList} from "../../actions/objectList";
//import { fetchSettingsList } from '../../actions/settings';
import { getGeneralSettingsObject } from '../../utils/GeneralSettingsHelper';

const mapStateToProps = (state, ownProps) => {
    return {
        entryTypesRequestResult: state.objectList,
        entryId: ownProps.match.params.entryID,
        entry: state.entryDetail,
        generalSettingsObject: getGeneralSettingsObject(state.settingsList)
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList(false));
            //dispatch(fetchSettingsList(false)); //moved to EntryView
        },
    }
};

const RelationAttributeTagCloud = connect(
    mapStateToProps,
    mapDispatchToProps
)(RelationAttributeTagCloudPresenter);

export default withRouter(RelationAttributeTagCloud);
