import React from "react";
import {withRouter} from "react-router-dom";
import { PageHeader, Button, Modal, Table, Input } from 'antd';
import { PlusOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { withTranslation} from 'react-i18next'
import { isEmptyObject } from "../../../utils/JsObjectHelper";
//import { isEmptyValue } from "../../utils/JsObjectHelper";

const { Column } = Table;
const { Search } = Input;

class LoadSettingList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchNameText: [],
            advSearchPageSize: (isEmptyObject(localStorage.getItem("setupLoadSettingPageSize")) ? 30 : parseInt(localStorage.getItem("setupLoadSettingPageSize")) )
        };

        this.filterLoadSettings = this.filterLoadSettings.bind(this);
    }

    showTotal = (total) => {
        return `(${total})`;
    };

    handlePageSizeChanged = (current, page) => {
        localStorage.setItem("setupLoadSettingPageSize", page);
        this.setState({ advSearchPageSize: page });
    };

    componentDidMount() {
        this.props.onMount();
    }

    filterLoadSettings = (searchValue) => {
        this.setState({searchNameText: searchValue.split(' ')});
    }

    createNewLoadSetting = () => {
        this.props.history.push(`/setup/loaderedit/`);
    }

    editLoadSetting = (e, record) => {
        this.props.history.push(`/setup/loaderedit/` + record.id);
    }

    render () {
        const {t} = this.props;

        let info = () => {
            Modal.info({
                title: t('setup.loader.infoTitle'),
                content: (
                    <div>
                        {t('setup.loader.infoText')}
                    </div>
                ),
                onOk() {
                },
            });
        };

        let paginationSetup = {
            showSizeChanger: true, 
            size:"small", 
            showTotal: this.showTotal, 
            defaultPageSize: this.state.advSearchPageSize, 
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            onShowSizeChange: this.handlePageSizeChanged
        };

        let loadSettingList = (this.props.loadSettingsList.getState().isDone() ? this.props.loadSettingsList.getData() : []);

        return (
            <div>
                <PageHeader
                    title={t('setup.loader.listTitle')}
                    subTitle={t('setup.loader.listTitleHint')}
                    extra={[
                        <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                        </Button>,
                    ]}
                >
                </PageHeader>
                <Table 
                    dataSource={loadSettingList}
                    rowKey= {record => record.id}
                    size="small"
                    scroll={{ x: true }}
                    loading={this.props.loadSettingsList.getState().isLoading()}
                    pagination={paginationSetup}
                    title={() => <div className="searchForSetup">
                        <Button type="primary" style={{marginRight: '15px' , marginBottom:'10px'}} onClick={this.createNewLoadSetting}><PlusOutlined /> {t('setup.loader.btnAddLoaderSetup')}</Button>
                        <Search
                            placeholder={t('setup.loader.searchBoxHint')}
                            onSearch={this.filterLoadSettings}
                            style={{ width: '100%' }}
                            allowClear
                        />
                        </div>}
                >
                    <Column
                        title={t('setup.loader.tblAction')}
                        key="action"
                        render={(text, record) => (
                            <span>
                                <Button onClick={(e) => {this.editLoadSetting(e,record)}}><EditOutlined /> {t('setup.loader.tblBtnEditLoaderSetup')}</Button>
                            </span>
                            )}
                    />
                    <Column 
                        title={t('setup.loader.tblName')}
                        dataIndex={["code"]}
                        key="code" 
                        render={(text, record) => (
                            <span><b>{record.code}</b></span>
                        )}
                        filteredValue= {this.state.searchNameText}
                        onFilter= {(value, record) => record.code.toLowerCase().includes(value.toLowerCase())}
                        sorter={(a, b) => {
                            return a["code"].localeCompare(b["code"]);
                        }}
                    />
                    <Column 
                        title={t('setup.loader.tblPropsCount')}
                        dataIndex={["value", "customProps"]}
                        key="propCount" 
                        render={(text, record) => (
                            <span>{record.value.customProps.length}</span>
                        )}
                        sorter={(a, b) => {
                            return a.value.customProps - b.value.customProps;
                        }}
                    />
                    <Column 
                        title={t('setup.loader.tblWriteFolderCount')}
                        dataIndex={["value", "folders", "write"]}
                        key="writeFolderCount" 
                        render={(text, record) => (
                            <span>{record.value.folders.write.length}</span>
                        )}
                        sorter={(a, b) => {
                            return a.value.folders.write - b.value.folders.write;
                        }}
                    />
                    <Column 
                        title={t('setup.loader.tblReadFolderCount')}
                        dataIndex={["value", "folders", "read"]}
                        key="readFolderCount" 
                        render={(text, record) => (
                            <span>{record.value.folders.read.length}</span>
                        )}
                        sorter={(a, b) => {
                            return a.value.folders.read - b.value.folders.read;
                        }}
                    />
                    <Column 
                        title={t('setup.loader.tblID')}
                        dataIndex={["id"]}
                        key="id" 
                        render={(text, record) => (
                            <span>{record.id}</span>
                        )}
                    />
                </Table>
            </div>
        );
    }
}

const LoadSettingListWithRouter = withRouter(LoadSettingList);

export default withTranslation() (LoadSettingListWithRouter);