import RelationAttributeTableViewPresenter from '../../../components/detail/attributes/RelationAttributeTableView';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { getGeneralSettingsObject } from '../../../utils/GeneralSettingsHelper';
//import { fetchSettingsList } from '../../../actions/settings';

const mapStateToProps = (state, ownProps) => {
    return {
        //entryTypesRequestResult: state.objectList,
        generalSettingsObject: getGeneralSettingsObject(state.settingsList)
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            //dispatch(fetchObjectList());
            //dispatch(fetchSettingsList(false));
        },
    }
};

const RelationAttributeTableView = connect(
    mapStateToProps,
    mapDispatchToProps
)(RelationAttributeTableViewPresenter);

export default withRouter(RelationAttributeTableView);
