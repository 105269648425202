import { fetchJson } from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const TASK_LIST_REQUESTED = "TASK_LIST_REQUESTED";
/**
 * Action for request of entry.
 *
 * @return {TaskListAction}
 */
export const requestTaskList = () => {
  return {
    type: TASK_LIST_REQUESTED,
  };
};


export const TASK_LIST_RECEIVED = "TASK_LIST_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} taskList
 * @return {ReceiveTaskListAction}
 */
export const receiveTaskList = (taskList) => {
  return {
    type: TASK_LIST_RECEIVED,
    taskList: taskList,
    receivedAt: Date.now(),
  };
};

export const TASK_LIST_ERROR = "TASK_LIST_ERROR";
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveTaskListErrorAction}
 */
export const receiveError = (
  error,
  type = TASK_LIST_ERROR,
  errorResponseStatusCode = null,
  errorResponseMessage = null
) => {
  return {
    type: type,
    error: error,
    errorResponseStatusCode: errorResponseStatusCode,
    errorResponseMessage: errorResponseMessage,
  };
};


/**
 * 
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchTaskList = (update = false) => {
  return (dispatch, getState) => {
      if (update) {
        dispatch(requestTaskList());
      } else {
        dispatch(requestTaskList());
      }

      /** @type RequestOptions options **/
      let options = {
          method: "GET"
      };
    
    options.ignore = {
        key: "fetchTaskList",
        maxLifeTime: 60000
    };
    
     

     return  fetchJson(`${config.url.API_URL}/bpmn/task/assignable/currentUser`, options, dispatch)
     .then(json => {
        let res = [];
        if (json._embedded && json._embedded.assignableTaskDtoes && json._embedded.assignableTaskDtoes.length > 0 ) {
            res = json._embedded.assignableTaskDtoes;
        }
       dispatch(receiveTaskList(res));
    }
   )  
         .catch(error => {
              if(error === "Ignoring request") {
              } else if(error instanceof ResponseStatusException) {
                  const description = error.response.message ? error.response.message : null;
                  dispatch(receiveError(error, TASK_LIST_ERROR, error.response.status, description));
                //  showError(dispatch, error.message, description);
                console.log(error);
              } else {
                  dispatch(receiveError(error, TASK_LIST_ERROR));
                  console.log(error);
                //  showError(dispatch, error.message);
              }
          });
  }
};


