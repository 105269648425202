import {
    ENTRYACCESS_REQUESTED,
    ENTRYACCESS_RECEIVED,
    ENTRYACCESS_ERROR,
} from '../actions/entryAccess.js';
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";

/**
 *
 * @param {RequestResult} state
 * @param {BaseAction|ReceiveEntryTypesAction|ReceiveErrorAction} action
 * @return {RequestResult}
 */
export const entryAccess = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

    switch (action.type) {
        case ENTRYACCESS_REQUESTED:
            return new RequestResult(RequestState.getInstanceLoading(), state.data);
        case ENTRYACCESS_RECEIVED:
            return new RequestResult(RequestState.getInstanceDone(), action.entryAccessRights, action.receivedAt);
        case ENTRYACCESS_ERROR:
            return new RequestResult(
                RequestState.getInstanceError(
                    action.errorResponseStatusCode,
                    action.errorResponseMessage ? action.errorResponseMessage : null
                ),
                state.getData()
            );
        default:
            return state
    }
};
