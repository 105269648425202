import { fetchJson } from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const PROCESS_REQUESTED = "PROCESS_REQUESTED";
/**
 * Action for request of entry.
 *
 * @return {ProcessAction}
 */
export const requestProcess = () => {
  return {
    type: PROCESS_REQUESTED,
  };
};


export const PROCESS_RECEIVED = "PROCESS_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} process
 * @return {ReceiveProcessAction}
 */
export const receiveProcess = (process) => {
  return {
    type: PROCESS_RECEIVED,
    process: process,
    receivedAt: Date.now(),
  };
};

export const PROCESS_ERROR = "PROCESS_ERROR";
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveProcessErrorAction}
 */
export const receiveError = (
  error,
  type = PROCESS_ERROR,
  errorResponseStatusCode = null,
  errorResponseMessage = null
) => {
  return {
    type: type,
    error: error,
    errorResponseStatusCode: errorResponseStatusCode,
    errorResponseMessage: errorResponseMessage,
  };
};


/**
 * 
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchProcess = (update = false) => {
  return (dispatch, getState) => {
      if (update) {
        dispatch(requestProcess());
      } else {
        dispatch(requestProcess());
      }

      /** @type RequestOptions options **/
      let options = {
          method: "GET",
          
      };
    
     return  fetchJson(`${config.url.API_URL}/bpmn/processInstance/history?active=true`, options, dispatch)
     .then(json => {
        let res = [];
        if (json._embedded && json._embedded.historicProcessInstanceDtoes && json._embedded.historicProcessInstanceDtoes.length > 0 ) {
            res = json._embedded.historicProcessInstanceDtoes;
        }
       dispatch(receiveProcess(res));
    }
   )  
         .catch(error => {
              if(error === "Ignoring request") {
              } else if(error instanceof ResponseStatusException) {
                  const description = error.response.message ? error.response.message : null;
                  dispatch(receiveError(error, PROCESS_ERROR, error.response.status, description));
                  showError(dispatch, error.message, description);
              } else {
                  dispatch(receiveError(error, PROCESS_ERROR));
                  showError(dispatch, error.message);
              }
          });
  }
};


