import {fetchJson} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";

export const ENTRY_CHILDREN_REQUESTED = 'ENTRY_CHILDREN_REQUESTED';
/**
 * Action for request of entry.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const requestEntryDetailChildren = (entryId) => {
    return {
        type: ENTRY_CHILDREN_REQUESTED,
        entryId: entryId
    };
};

export const ENTRY_CHILDREN_UPDATE_REQUESTED = "ENTRY_CHILDREN_UPDATE_REQUESTED";
/**
 * Action for request of entry.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const requestEntryDetailChildrenUpdate = (entryId) => {
    return {
        type: ENTRY_CHILDREN_UPDATE_REQUESTED,
        entryId: entryId
    }
};

export const ENTRY_CHILDREN_RECEIVED = "ENTRY_CHILDREN_RECEIVED";
/**
 *
 * @param {String} entryId
 * @param {Array<BaseEntry>} entries
 * @return {ReceiveRelatedEntriesAction}
 */
export const receiveEntryDetailChildren = (entryId, entries) => {
    return {
        type: ENTRY_CHILDREN_RECEIVED,
        relatedEntryId: entryId,
        entries: entries,
        receivedAt: Date.now()
    }
};

export const ENTRY_CHILDREN_ERROR = 'ENTRY_CHILDREN_ERROR';
/**
 *
 * @param {String} entryId
 * @param {Object} error
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveEntryErrorAction}
 */
export const receiveError = (
    entryId,
    error,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: ENTRY_CHILDREN_ERROR,
        entryId: entryId,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};

/**
 * Fetch entry detail
 *
 * @param {String} entryId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchDetailChildren = (entryId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            dispatch(requestEntryDetailChildrenUpdate(entryId));
        } else {
            dispatch(requestEntryDetailChildren(entryId));
        }

        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/children/projection/baseEntry`, null, dispatch)
            .then(json => {
                    let res = [];
                    if (json._embedded && json._embedded.bmcEntries && json._embedded.bmcEntries.length > 0) {
                        res = json._embedded.bmcEntries;
                    }
                    dispatch(receiveEntryDetailChildren(entryId, res));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(entryId, error, error.response.status, description));
                } else {
                    dispatch(receiveError(entryId, error));
                }
            });
    }
};
export const fetchDetailChildrenWithoutDescription = (entryId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            dispatch(requestEntryDetailChildrenUpdate(entryId));
        } else {
            dispatch(requestEntryDetailChildren(entryId));
        }
       
        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/children/projection/baseEntryWithoutDescription`, null, dispatch)
            .then(json => {
                    let res = [];
                    if (json._embedded && json._embedded.bmcEntries && json._embedded.bmcEntries.length > 0) {
                        res = json._embedded.bmcEntries;
                    }
                    dispatch(receiveEntryDetailChildren(entryId, res));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(entryId, error, error.response.status, description));
                } else {
                    dispatch(receiveError(entryId, error));
                }
            });
    }
};