const psl = require('psl');

/**
 * get actual base domain name like aphinit.com, google.com (without subdomain)...
 * @return {string}
 */
export const getBaseDomainName = () => {
    const hostname = window.location.hostname;
    if(hostname === 'localhost') return hostname;
    const parsed = psl.parse(hostname);
    return parsed.domain;
};