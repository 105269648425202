import {
    USER_LIST_REQUESTED,
    USER_LIST_RECEIVED,
    USER_LIST_ERROR
} from '../actions/userList.js';
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";

/**
 *
 * @param {RequestResult} state
 * @param {BaseAction|ReceiveEntryTypesAction|ReceiveErrorAction} action
 * @return {RequestResult}
 */
export const userList = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action)  => {

    switch (action.type) {
        case USER_LIST_REQUESTED:
            return new RequestResult(RequestState.getInstanceLoading(), state.data);
        case USER_LIST_RECEIVED:
            return new RequestResult(RequestState.getInstanceDone(), action.userList, action.receivedAt);
        case USER_LIST_ERROR:
            return new RequestResult(
                RequestState.getInstanceError(
                    action.errorResponseStatusCode,
                    action.errorResponseMessage ? action.errorResponseMessage : null
                ),
                state.getData()
            );
        default:
            return state
    }
};
