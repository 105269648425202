import {fetchJson} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const GROUP_DETAIL_REQUESTED = 'GROUP_DETAIL_REQUESTED';
/**
 * Action for request of group detail.
 *
 * @return {GroupDetailAction}
 */
export const requestGroupDetail = (groupId) => {
    return {
        type: GROUP_DETAIL_REQUESTED,
        groupId: groupId
    };
};

export const GROUP_DETAIL_RECEIVED = "GROUP_DETAIL_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} groupUsers
 * @return {ReceiveGroupDetailAction}
 */
export const receiveGroupDetail = (groupId, groupDetail) => {
    return {
        type: GROUP_DETAIL_RECEIVED,
        groupId: groupId,
        groupDetail: groupDetail,
        receivedAt: Date.now()
    }
};

export const GROUP_DETAIL_ERROR = 'GROUP_DETAIL_ERROR';
/**
 *
 * @param {String} groupId
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveGroupUsersErrorAction}
 */
export const receiveError = (
    groupId,
    error,
    type = GROUP_DETAIL_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        groupId: groupId,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};


/**
 * Fetch detail of a group
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchGroupDetail = (groupId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            //dispatch(requestGroupDetailUpdate(groupId));
        } else {
            dispatch(requestGroupDetail(groupId));
        }

        //TODO endpoint na groupDetail
        return fetchJson(`${config.url.API_URL}/auth/custom/groups`, null, dispatch)
            .then(json => {
                    let res = [];

                    if (json._embedded && json._embedded.groups && json._embedded.groups.length > 0) {
                        res = json._embedded.groups;
                    }

                    dispatch(receiveGroupDetail(groupId, res.find(u=> u.id === groupId)));
                    //dispatch(receiveGroupDetail(groupId, res[parseInt(groupId) -1]));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(groupId, error, GROUP_DETAIL_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(groupId, error, GROUP_DETAIL_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};