import React from "react";
import { isEmptyObject, isEmptyValue, isUndefined } from "../../utils/JsObjectHelper";
import { PageHeader, Skeleton, Row, Button, Descriptions, Result, Modal, Select } from "antd";
import { ApartmentOutlined,DeleteTwoTone } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import AttributesSection from '../../containers/detail/AttributesSection';
import EntryTypeTag from '../controls/EntryTypeTag';
import { withTranslation } from 'react-i18next';
import EntryUserRelationTable from "./view/EntryUserRelationTable";
import { fetchEntries } from "../../apicalls/fetchEntries";
import { SearchOutlined } from '@ant-design/icons';
import { fetchIdentityToEntry } from "../../apicalls/fetchIdentityToEntry";
import debounce from 'lodash/debounce';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins.pkgd.min.css';
import '../../froala_custom.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';
import { notificationSuccess, notificationWarning } from "../../utils/NotificationsHelper";
const { Option } = Select;

class UserPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rel: true,
            visible: false,
            searchedData: [],
            isSearchBoxLoading: false,
            entry: null,

        };
        this.handleSearch = debounce(this.handleSearch, 500);
    }


    componentDidUpdate(prevProps, prevState) {
        if (!isUndefined(this.props.entryRequestResult.getData()) && isUndefined(this.props.incomingRelationsRequestResult.getData()) && this.state.rel) {
            let data = this.props.entryRequestResult.getData()
            this.props.relations(data.entry.id);
            this.setState({ rel: false })
        }
    }

    componentDidMount() {
        this.props.onMount();
    }
    showLineage = (id) => {
        window.open("/lineage/" + id, "_blank");
    };
    connectedSuccess = (err) => {
        const { t } = this.props;
        if (err.message === "Couldn't get successful response") {
            notificationWarning(t('setup.identity2entry.warningNotificationTitle'), t('setup.identity2entry.warningNotificationMessage'))
        }
        else {
            notificationSuccess(t('app.userPage.notificationTitle'))
            this.setState({ visible: false });
            window.location.reload()
        }
    }
    handleOk = () => {
        let entryId = this.state.entry.split('|#|')
        console.log(entryId[1]);
        fetchIdentityToEntry(this.props.identityId, entryId[1], this.connectedSuccess)


    };
    handleCancel = () => {
        this.setState({ visible: false });
    };
    showModal = () => {
        this.setState({ visible: true });
    };
    handleSearch = (value) => {
        this.setState({ isSearchBoxLoading: true });
        fetchEntries(value, "userPage", searchData => this.handleDataSearched(searchData), null, ["object"]);
    };
    handleDataSearched = (searchData) => {
        this.setState({ searchedData: searchData, isSearchBoxLoading: false })
    };
    handleSearchChange = (value) => {
        this.setState({ entry: value });
    };

    render() {
        const { t } = this.props;
        let entry = null;
        let main = <Skeleton active />;
        let entryTypeTag = null;
        let attSection = null;
        let relations = null;
        let entryTypeNameList = null;
        let userDetail = null;
        let data = this.props.entryRequestResult.getState().isLoading() ? undefined : this.props.entryRequestResult.getData();
        console.log(data);

        if (this.props.entryTypesRequestResult.getState().isDone()) {
            entryTypeNameList = this.props.entryTypesRequestResult.getData().map((item, key) => { return { type: item.type, name: item.name, color: item.properties.typeColor }; });
        }

        let displData = (this.props.userDetailRequestResult.getState().isLoading() ? undefined : this.props.userDetailRequestResult.getData());
        if (!isUndefined(displData)) {
            let button = null
            if (this.props.user.realmRoles.find((el) => el === "AP_SETUP" || el === "AP_ADMIN")) {
                button = <Button onClick={this.showModal} type="primary">{t('app.userPage.btnChooseEntry')}</Button>
            }

            userDetail = (<div className="tableInfoUserPage">
                <Descriptions bordered size="small" layout="vertical" column={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
                    <Descriptions.Item label={t('setup.userManagement.usrDetailId')}>{displData.id}</Descriptions.Item>
                    <Descriptions.Item label={t('setup.userManagement.usrDetailUserName')} span="2">{displData.name}</Descriptions.Item>
                    <Descriptions.Item label={t('setup.userManagement.usrDetailEmail')} span="2">{displData.email}</Descriptions.Item>
                    <Descriptions.Item label={t('setup.userManagement.usrDetailFirstName')}>{displData.firstName}</Descriptions.Item>
                    <Descriptions.Item label={t('setup.userManagement.usrDetailLastName')}>{displData.lastName}</Descriptions.Item>
                </Descriptions>
            </div>)
            if (isUndefined(data) || !isUndefined(data) && !isUndefined(data.entry) && data.entry.deleted ) {
                const options = this.state.searchedData.map(d =>
                    <Option key={"search|#|" + d.id}>
                        <div>
                            <span>{d.name}</span>
                            <span style={{ float: 'right' }}><EntryTypeTag entryTypeName={d.type} entryTypeNameList={entryTypeNameList}></EntryTypeTag></span>
                        </div>
                    </Option>
                );
                let result = (this.props.entryRequestResult.getState().isLoading() ? null : <Result status="404" title={t('app.userPage.noEntry')} extra={button} />);
                let modal = <Modal title={t('app.userPage.btnChooseEntry')} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel}>
                    <div>
                        <Select
                            showSearch={true}
                            value={this.state.entry}
                            placeholder={t('app.entry.attributes.boxRelationSearchHint')}
                            style={{ width: '100%' }}
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            onSearch={this.handleSearch}
                            onChange={this.handleSearchChange}
                            notFoundContent={<span>{t('app.entry.attributes.boxRelationToFound')}</span>}
                            loading={this.state.isSearchBoxLoading}
                            suffixIcon={<SearchOutlined />}
                        >
                            {options}
                        </Select>
                    </div>
                </Modal>
                main = (<div><PageHeader
                    title={displData.firstName + " " + displData.lastName}
                    className="entryPageHeader"
                >
                    {userDetail}
                    <Row className="pgContent">
                        <div className="fr-box fr-basic fr-top">
                            <div className="fr-element fr-view" style={{ padding: '0px' }}>
                                {modal}
                                {result}
                            </div>
                        </div>
                    </Row>
                </PageHeader>
                </div >)
            }
        }
        if (!isUndefined(data) && !isUndefined(data.entry) && data.entry.deleted === null) {

            entry = data.entry
            entryTypeTag = <EntryTypeTag entryTypeName={entry.type} entryTypeNameList={entryTypeNameList}></EntryTypeTag>
            
                attSection = (<AttributesSection
                    data={entry}
                    relationData={this.props.outgoingRelationsHolder}
                    isEditMode={false}
                    isLoadingRelations={this.props.outgoingRelationsRequestResult.getState().isLoading()} />)

            relations = (<EntryUserRelationTable
                entryRequestResult={entry}
                incomingRelationsRequestResult={this.props.incomingRelationsRequestResult}
                outgoingRelationsRequestResult={this.props.outgoingRelationsRequestResult}
                entryTypesRequestResult={this.props.entryTypesRequestResult}
            >
            </EntryUserRelationTable>)
            main = (<div><PageHeader
                title={entry.name}
                tags={entryTypeTag}
                className="entryPageHeader"
                extra={[
                    entry.systemType === "object" ? <Button onClick={() => this.showLineage(entry.id)} key="lineage" name="lineage" icon={<ApartmentOutlined />}>
                        {t('app.entry.tools.btnLineage')}
                    </Button> : null
                ]} >
                {userDetail}
                <Row className="pgContent">
                    <div className="fr-box fr-basic fr-top">
                        <div className="fr-element fr-view" style={{ padding: '0px' }}>
                            {ReactHtmlParser(entry.description)}

                        </div>
                        {attSection}
                        {relations}
                    </div>
                </Row>
            </PageHeader>


            </div >)
        }
        else if(!isUndefined(data)&& data.identity && data.identity.deleted){
            main = <Result
            icon={<DeleteTwoTone twoToneColor="#eb2f96" />}
            title={t('app.userPage.textUserHasBeenDeleted')}
          />
        }

        return <div>
            {main}
        </div>;
    }
}


export default withTranslation()(UserPage);



