import PropTypes from 'prop-types';
import {requestStateShape} from "./RequestState";
import {baseEntryShape, baseEntryWithParentShape} from "./BmcEntryShape";
import {idNameShapeWithBmcEntrySource, idNameShapeWithBmcEntryTarget, idNameWithTypeShape} from "./IdNameShapes";
//import {baseContentShape} from "./StringContentShape";
import {baseObjectDefinitionShape} from "./ObjectDefinitionShape";
import {baseSettingShape} from "./SettingShape";
import { baseLoadSettingShape } from './LoadSettingShape';
import { bpmnTaskShape } from './BpmnTaskShape';
import { bpmnFormShape } from './BpmnFormShape';

export const entryResultDetailShape = PropTypes.shape({
    data: baseEntryWithParentShape,
    state: requestStateShape.isRequired,
    getData: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});

export const entriesRequestResult = PropTypes.shape({
    data: PropTypes.arrayOf(baseEntryShape),
    state: requestStateShape.isRequired,
    getData: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});

export const idNameTypeWithFoldersRequestResult = PropTypes.shape({
    data: PropTypes.arrayOf(idNameWithTypeShape),
    state: requestStateShape.isRequired,
    getData: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});

export const incomingRelationsRequestResult = PropTypes.shape({
    data: PropTypes.arrayOf(idNameShapeWithBmcEntrySource),
    state: requestStateShape.isRequired,
    getData: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});

export const outgoingRelationsRequestResult = PropTypes.shape({
    data: PropTypes.arrayOf(idNameShapeWithBmcEntryTarget),
    state: requestStateShape.isRequired,
    getData: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});

export const entryTypesRequestResult = PropTypes.shape({
    data: PropTypes.arrayOf(baseObjectDefinitionShape),
    state: requestStateShape.isRequired,
    getData: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});


// object definition
export const objectDefinitionResultDetailShape = PropTypes.shape({
    data: baseObjectDefinitionShape,
    state: requestStateShape.isRequired,
    getData: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});

// Settings
export const settingResultDetailShape = PropTypes.shape({
    data: baseSettingShape,
    state: requestStateShape.isRequired,
    getData: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});

export const stringResultShape = PropTypes.shape({
    data: PropTypes.string.isRequired,
    state: requestStateShape.isRequired,
    getData: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});


//load setting
export const loadSettingResultShape = PropTypes.shape({
    data: PropTypes.arrayOf(baseLoadSettingShape),
    state: requestStateShape.isRequired,
    getData: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});

export const loadSettingResultDetailShape = PropTypes.shape({
    data: baseLoadSettingShape,
    state: requestStateShape.isRequired,
    getData: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});

//bpmn
export const bpmTaskShape = PropTypes.shape({
    data: PropTypes.arrayOf(bpmnTaskShape),
    state: requestStateShape.isRequired,
    getData: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});

export const bpmFormShape = PropTypes.shape({
    data: PropTypes.arrayOf(bpmnFormShape),
    state: requestStateShape.isRequired,
    getData: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired
});