import { config } from "../config/Config";
import Keycloak from "keycloak-js";
const keycloak = Keycloak(config.keycloak);

export const TASK_COUNT_REQUESTED = "TASK_COUNT_REQUESTED";
/**
 * Action for request of entry.
 *
 * @return {TaskCountAction}
 */
export const requestTaskCount = () => {
  return {
    type: TASK_COUNT_REQUESTED,
  };
};


export const TASK_COUNT_RECEIVED = "TASK_COUNT_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} taskCount
 * @return {ReceiveTaskCountAction}
 */
export const receiveTaskCount = (taskCount) => {
  return {
    type: TASK_COUNT_RECEIVED,
    taskCount: taskCount,
    receivedAt: Date.now(),
  };
};

export const TASK_COUNT_ERROR = "TASK_COUNT_ERROR";
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveTaskCountErrorAction}
 */
export const receiveError = (
  error,
  type = TASK_COUNT_ERROR,
  errorResponseStatusCode = null,
  errorResponseMessage = null
) => {
  return {
    type: type,
    error: error,
    errorResponseStatusCode: errorResponseStatusCode,
    errorResponseMessage: errorResponseMessage,
  };
};


/**
 * 
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
let token = sessionStorage.getItem("kcToken");
const refreshTokenAndUpdateTaskCount = (dispatch,) => {
  let newToken = sessionStorage.getItem("kcToken");
  if(newToken !== token){
    token = newToken
    fetchTaskCount(true,)(dispatch);
  }
};


export const fetchTaskCount = (update = false,) => {
  return (dispatch, getState) => {
    if (update) {
      dispatch(requestTaskCount());
    } else {
      dispatch(requestTaskCount());
    }

    return fetch(`${config.url.API_URL}/bpmn/task/assigned/currentUser`, {
      mode: "cors",
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + sessionStorage.getItem("kcToken")
      }
    }, dispatch)
      .then(response => response.json())
      .then(json => {
        console.log(json);
        let res = [];
        if (json._embedded && json._embedded.taskDtoes && json._embedded.taskDtoes.length > 0) {
          res = json._embedded.taskDtoes.length;
          dispatch(receiveTaskCount(res));
        }
        
        if (json.error && json.error === "invalid_token") {
          refreshTokenAndUpdateTaskCount(dispatch);
          dispatch(receiveTaskCount(null));
        }
        if(!json._embedded && !json.error){
          dispatch(receiveTaskCount(res.length));
        }
        else {
          console.log(json);
        }
      })
  }
};
    // return fetchJson(`${config.url.API_URL}/bpmn/task/assigned/currentUser`, options, dispatch)
    //   .then(json => {
    //     let res = [];
    //     if (json._embedded && json._embedded.taskDtoes && json._embedded.taskDtoes.length > 0) {
    //       res = json._embedded.taskDtoes.length;
    //     }
    //     dispatch(receiveTaskCount(res));
    //   }
    //   )
    //   .catch(error => {
    //     if (error === "Ignoring request") {
    //     } else if (error instanceof ResponseStatusException) {
    //       const description = error.response.message ? error.response.message : null;
    //       // dispatch(receiveError(error, TASK_COUNT_ERROR, error.response.status, description));
    //       // showError(dispatch, error.message, description);
    //     } else {
    //       // dispatch(receiveError(error, TASK_COUNT_ERROR));
    //       // showError(dispatch, error.message);
    //     }
    //   });



