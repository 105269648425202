import { Tag, Tooltip } from "antd";
import { Icon } from '@ant-design/compatible';
//import Icon from '@ant-design/icons';
import React from "react";
import { withTranslation} from 'react-i18next'
import { isEmptyValue } from "../../utils/JsObjectHelper";
import PropTypes from 'prop-types';

const OPERAND_AND = "AND";
const OPERAND_OR = "OR";

class AdvancedSearchOperand extends React.Component {


    handleOperandChange = () => {
        if (this.props.operand === OPERAND_AND)
            this.props.onOperandChange(OPERAND_OR);
        else
            this.props.onOperandChange(OPERAND_AND);
    };

    render() {
        const {t} = this.props;

        let operandObj = null;
        if (!isEmptyValue(this.props.operand)) {
            let color = "";
            let text = "";
            let icon = "";

            switch (this.props.operand) {
                case OPERAND_AND:
                    color = "#91d5ff";
                    text = t('app.advancedSearch.operandAND');
                    icon = "up";
                    break;
                case OPERAND_OR:
                    color = "#fffc91";
                    text = t('app.advancedSearch.operandOR');
                    icon = "down";
                    break;
                default:
                    break;
            }
            operandObj = <Tooltip placement="right" title={t('app.advancedSearch.switchOperandHint')}>
                            <Tag style={{backgroundColor: color}} onClick={this.handleOperandChange}><Icon type={icon}/> {text}</Tag>
                        </Tooltip>;
        }

        return <div className="advancedSearchOperand">{operandObj}</div>;
    }
}

AdvancedSearchOperand.propTypes = {
    operand: PropTypes.string,
    onOperandChange: PropTypes.func.isRequired,
};

export default withTranslation() (AdvancedSearchOperand);