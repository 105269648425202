export const EMPTY_OBJECT_DEFINITION = {
    name: null,
    description: null,
    type: null,
    systemType: "object",
    properties: {
        typeColor: "#91d5ff",
        displayName: {
            default: null
        },
        attributes: [],
        editLayout: {
            layout: [],
            attributesDivDefinition: [],
            attributesProperties: {}
        },
        viewLayout: {
            layout: [],
            attributesDivDefinition: [],
            attributesProperties: {}
        },
    }
};

export const ATTRIBUTE_DISPLAYTYPE_OPTIONS_EDIT = {
    find : {
        name: "find",
        title: "setup.entryTypes.detail.attDisplayTypeFIND"
    },
    preload : {
        name: "preload",
        title: "setup.entryTypes.detail.attDisplayTypePRELOAD"
    }
};

export const ATTRIBUTE_DISPLAYTYPE_OPTIONS_VIEW = {
    tag : {
        name: "tag",
        title: "setup.entryTypes.detail.attDisplayTypeTAG"
    },
    table : {
        name: "table",
        title: "setup.entryTypes.detail.attDisplayTypeTABLE"
    }
};

export const ATTRIBUTE_PROPERTYTYPE_DISPLAYTYPE = "displayType";
export const ATTRIBUTE_PROPERTYTYPE_RELATIONTABLEATTRIBUTES = "relationAttributeArray";
export const ATTRIBUTE_PROPERTYTYPE_USE_CONTEXTENGINE = "useContextEngine";
export const ATTRIBUTE_PROPERTYTYPE_CONTEXTENGINE_DEPTH = "contextEngineDepth";