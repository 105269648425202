import React from "react";
import { withRouter } from "react-router-dom";
import { PageHeader, Button, Modal,  Collapse } from 'antd';
import {  InfoCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next'
import WithoutAssignedEntriesTable from "./WithoutAssignedEntriesTable";
import WithAssignedEntriesTable from "./WithAssignedEntriesTable";
const { Panel } = Collapse;

class Id2EntryMapping extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
       
    }
    componentDidMount() {
        this.props.onMount();
    }

    render() {
        const { t } = this.props;
        let info = () => {
            Modal.info({
                title: t('setup.identity2entry.infoTitle'),
                content: (
                    <div>
                        {t('setup.identity2entry.infoText')}
                    </div>
                ),
                onOk() {
                },
            });
        };


        return (
            <div>
                <PageHeader
                    title={t('setup.identity2entry.headerDetail')}
                    subTitle={t('setup.identity2entry.headerDetailLabel')}
                    extra={[
                        <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                        </Button>,
                    ]}
                >
                </PageHeader>
                <Collapse defaultActiveKey={['1']} accordion >
                    <Panel header={t('setup.identity2entry.hederwithAssignedEntries')} key="1">
                    <WithoutAssignedEntriesTable idMapping={this.props.idMapping} entryTypesRequestResult={this.props.entryTypesRequestResult}/>
                    </Panel>
                    <Panel header={t('setup.identity2entry.hederwithoutAssignedEntries')} key="2">
                    <WithAssignedEntriesTable idMapping={this.props.idMapping} entryTypesRequestResult={this.props.entryTypesRequestResult}/>
                    </Panel>
                </Collapse>

            </div>
        );
    }
}

const Id2EntryMappingWithRouter = withRouter(Id2EntryMapping);

export default withTranslation()(Id2EntryMappingWithRouter);