import MyTasksPresenter from "../../../components/pluginPage/widgets/MyTasks";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchTodoList } from "../../../actions/bpmnTodoList";



const mapStateToProps = (state, ownProps) => {
	return {
		myTasks:state.todoList,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: () => {
			dispatch(fetchTodoList());
		},
	
	};
};

const MyTasks = connect(
	mapStateToProps,
	mapDispatchToProps
)(MyTasksPresenter);

export default withRouter(MyTasks);
