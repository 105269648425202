import {createBaseOptions, fetchJson, METHOD_DELETE} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";

export const fetchDeleteSettings = (id,name,callback) => {
    
        
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_DELETE;
    options.headers["Content-Type"] = "application/json";

    let r = fetchJson(`${config.url.API_URL}/settings/custom/item/${id}/${name}`, options);
    r.then(json => {
        callback(json); 
    }).catch(error => {
        callback(error);
    });
};