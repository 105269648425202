import AttributesSectionPresenter from '../../components/detail/edit/AttributesSection';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchObjectList} from "../../actions/objectList";


const mapStateToProps = (state, ownProps) => {
    return {
        entryTypesRequestResult: state.objectList
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList());
        },
    }
};

const AttributesSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(AttributesSectionPresenter);

export default withRouter(AttributesSection);
