import WorkflowListPresenter from '../../components/setup/workflow/WorkflowList';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchSettingsList} from "../../actions/settings";
import {fetchObjectList} from "../../actions/objectList";
import {isUndefined} from "../../utils/JsObjectHelper";
import { fetchDeleteSettings } from '../../apicalls/fetchDeleteSettings';


const getWorkflowSettingsList = (settingsList, objectList) => {
    if (!isUndefined(settingsList) && settingsList.getState().isDone() && !isUndefined(objectList) && objectList.getState().isDone()) {
        let setList = settingsList.getData().filter(eT => eT.id === "workflow");
        let objList = objectList.getData();
        setList.forEach(element => {
            let objNameList = element.value.entryTypes.map(eT => { 
                return (isUndefined(objList.find(obj=>obj.type === eT)) ? eT : objList.find(obj=>obj.type === eT).name);
            });
    
            element.value.entryTypesNameList = objNameList;
        });

        return setList;
    } else {
        return null;
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        workflowSettingsList: getWorkflowSettingsList(state.settingsList, state.objectList)
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList());
            dispatch(fetchSettingsList(true));
        },
        deleteWorkflow: (id,name) => {
            let reload = ()=>{ dispatch(fetchSettingsList(true))}
            fetchDeleteSettings(id,name,reload)
        }
    }
};

const WorkflowList = connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkflowListPresenter);

export default withRouter(WorkflowList);