import React from "react";
import PropTypes from "prop-types";
import { bmcEntryShape } from "../../../shapes/BmcEntryShape";
import { Table, Card, Radio } from "antd";
import { FolderOutlined, TableOutlined, OrderedListOutlined } from '@ant-design/icons';
//import Icon from '@ant-design/icons';
import { Icon } from '@ant-design/compatible';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next'
import { highlightSearchedText } from "../../../utils/TextHighlighter";
import { isEmptyObject, isUndefined } from "../../../utils/JsObjectHelper";
import { getColumnSearchProps } from "../../../utils/TableHelper";
import { entryTypeNameShape } from "../../../shapes/EntryTypeNameShape";
import EntryTypeTag from "../../controls/EntryTypeTag";
import SubFoldersEditChildPosition from "../../../containers/detail/SubFoldersEditChildPosition";
import {EMPTY_FOLDER_PROPERTIES_DEFINITION} from "../../../utils/EmptyFolderPropertiesDefinition";
import handIcon from "../../../images/hand.png"

const SubFolderCard = ({ title, id, iconName, iconColor }) => (
    <Link to={`/entry/${id}`}>
        <Card.Grid className="entrySubFolderCard">
            <div className="folderInfo" title={title}>
                <Icon theme="outlined" type={iconName} style={{ width: "100%", color: iconColor }} /><br /><div className="folder-card-title">{title}</div>
            </div>
        </Card.Grid>
    </Link>
);

const CardTitle = ({ translator, value, onChangeEvent, onEditChildPosition,userEntryAccess }) => (
    <div>
        <span>{translator('app.entry.view.cardSubFolders')}</span>
        {userEntryAccess && userEntryAccess ==='EDIT'?<a title={translator('app.entry.view.folderPositionChanges.titleBtn')}><img src={handIcon}  onClick={onEditChildPosition} width="28px" className="iconHand" /></a>:null} 
        <Radio.Group onChange={onChangeEvent} value={value} style={{ float: "right" }}>
            <Radio value="icons"><FolderOutlined title={translator('app.entry.view.tabSubFoldersFolderIcon')} /></Radio>
            <Radio value="table"><TableOutlined title={translator('app.entry.view.tabSubFoldersTableIcon')} /></Radio>
        </Radio.Group>
       
    </div>
);

const TableTitle = ({ translator, value, onChangeEvent }) => (
    <div className="childTableTitle">
        <span>{translator('app.entry.view.cardSubFolders')}</span>
        <Radio.Group onChange={onChangeEvent} value={value} style={{ float: "right" }}>
            <Radio value="icons"><FolderOutlined title={translator('app.entry.view.tabSubFoldersFolderIcon')} /></Radio>
            <Radio value="table"><TableOutlined title={translator('app.entry.view.tabSubFoldersTableIcon')} /></Radio>
        </Radio.Group>
    </div>
);

class EntrySubFolders extends React.Component {

    state = {
        typeValue: this.props.defaultDisplayType,
        searchText: '',
        searchedColumn: '',
        subfolderTablePageSize: (isEmptyObject(localStorage.getItem("subfolderTablePageSize")) ? 30 : parseInt(localStorage.getItem("subfolderTablePageSize"))),
        editChildPosition: false
    }

    componentDidUpdate(prevProps, prevState) {
    if(this.props.defaultDisplayType!== this.state.typeValue && prevProps.defaultDisplayType!==this.props.defaultDisplayType){
        this.setState({ typeValue: this.props.defaultDisplayType});
    }
    }
    handleTypeChange = (e) => {
        this.setState({ typeValue: e.target.value });
    };

    //Renderer for Entry name column. Renders it as link with ability to highlight quick search results
    handleRenderTextColumn = (text, record, dataIndex) => {
        return this.state.searchedColumn === dataIndex ? (
            <Link to={`/entry/${record.id}`}>{highlightSearchedText(text, this.state.searchText)}</Link>
        ) : (
            <Link to={`/entry/${record.id}`}>{text}</Link>
        );
    };

    //set filters
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    //reset filters
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    showTotal = (total) => {
        return `(${total})`;
    };

    handlePageSizeChanged = (current, page) => {
        localStorage.setItem("subfolderTablePageSize", page);
        this.setState({ subfolderTablePageSize: page });
    };
    onEditChildPosition = () => {
        this.setState({ editChildPosition: !this.state.editChildPosition });
    }
    sortArrayByAnotherArray = (childsDataList, ids) => {
        let data = childsDataList.filter(fol => { return fol.type === "folder" }).sort((a, b) => a.name.localeCompare(b.name));

        if (ids && ids.length > 0) {
            let idSet = new Set(ids);
            let rest = [];
            data = data.filter(item => {
                if (idSet.has(item.id)) {
                    return true;
                } else {
                    rest.push(item);
                    return false;
                }
            });

            rest.sort((a, b) => a.name.localeCompare(b.name));

            data.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));

            return data.concat(rest);
        }
        else {
            return data
        }
    }
    render() {
        const { t } = this.props;
        let subFolders = null;
        let subFolderContent = [];
        switch (this.state.typeValue) {
            case "table":
                let subfoldersTableData = this.props.childsDataList.filter(fol => { return fol.type === "folder" })
                    .map(
                        (item, key) => {
                            let folderIcon = EMPTY_FOLDER_PROPERTIES_DEFINITION.folderIcon;
                            let folderIconColor = EMPTY_FOLDER_PROPERTIES_DEFINITION.folderIconColor;
                            let childObjectEntryTypes = [];
                            if (!isUndefined(item.properties) && !isUndefined(item.properties.folderIcon)) {
                                folderIcon = item.properties.folderIcon;
                            }
                            if (!isUndefined(item.properties) && !isUndefined(item.properties.folderIconColor)) {
                                folderIconColor = item.properties.folderIconColor;
                            }
                            if (!isUndefined(item.properties) && !isUndefined(item.properties.childObjectEntryTypes)) {
                                childObjectEntryTypes = item.properties.childObjectEntryTypes;
                            }
                            return {
                                key: item.id,
                                id: item.id,
                                name: item.name,
                                folderIcon: folderIcon,
                                folderIconColor: folderIconColor,
                                childObjectEntryTypes: childObjectEntryTypes
                            };
                        }
                    );
                let columnsChilds = [
                    {
                        title: "",
                        key: 'folder',
                        dataIndex: 'folderIcon',
                        render: (icon, record) => (
                            <span style={{ color: record.folderIconColor }}>
                                <Icon type={icon} color={record.folderIconColor}></Icon>
                            </span>
                        ),
                        width: "40px"
                    },
                    {
                        title: t('app.entry.view.tblEntriesFolderName'),
                        dataIndex: 'name',
                        key: 'name',
                        ...getColumnSearchProps('name', this.handleSearch, this.handleReset, this.handleRenderTextColumn, t, this.searchInput),
                    },
                    {
                        title: t('app.entry.view.tblEntriesFolderEntryTypes'),
                        key: 'childObjectEntryTypes',
                        dataIndex: 'childObjectEntryTypes',
                        render: tags => (
                            <span>
                                {tags.map(tag => {
                                    return (
                                        <EntryTypeTag key={tag} entryTypeName={tag} entryTypeNameList={this.props.entryTypeNameList}></EntryTypeTag>
                                    );
                                })}
                            </span>
                        ),
                    }
                ];

                let paginationSetup = {
                    showSizeChanger: true,
                    size: "small",
                    showTotal: this.showTotal,
                    defaultPageSize: this.state.subfolderTablePageSize,
                    pageSizeOptions: ['10', '20', '30', '50', '100'],
                    onShowSizeChange: this.handlePageSizeChanged
                };

                subFolders = <Table title={() => <TableTitle translator={t} value={this.state.typeValue} onChangeEvent={this.handleTypeChange}></TableTitle>}
                    columns={columnsChilds}
                    dataSource={subfoldersTableData}
                    size="small"
                    pagination={paginationSetup}
                    key="subfolderTable" />;


                break;
            case "icons":
            default:
                let ids = this.props.entry.properties.childrenPosition ? this.props.entry.properties.childrenPosition : [];
                subFolderContent = this.sortArrayByAnotherArray(this.props.childsDataList, ids)
                    .map(
                        (item, key) => {
                            let folderIcon = "folder-open";
                            let folderIconColor = "";
                            if (!isUndefined(item.properties) && !isUndefined(item.properties.folderIcon)) {
                                folderIcon = item.properties.folderIcon;
                            }
                            if (!isUndefined(item.properties) && !isUndefined(item.properties.folderIconColor)) {
                                folderIconColor = item.properties.folderIconColor;
                            }
                            return <SubFolderCard key={item.id} title={item.name} id={item.id} iconName={folderIcon} iconColor={folderIconColor} />
                        }
                    );
                if (subFolderContent.length > 0) {
                    subFolders = <Card title={<CardTitle translator={t} value={this.state.typeValue} onChangeEvent={this.handleTypeChange} userEntryAccess={this.props.entry.userEntryAccess} onEditChildPosition={this.onEditChildPosition}></CardTitle>}>
                        {this.state.editChildPosition ? <SubFoldersEditChildPosition entry={this.props.entry} ids={ids} childsDataList={this.props.childsDataList} onEditChildPosition={this.onEditChildPosition} /> : subFolderContent}
                    </Card>;
                }
                break;
        };



        return subFolders;
    }

}

export default withTranslation()(EntrySubFolders);

EntrySubFolders.propTypes = {
    childsDataList: PropTypes.arrayOf(bmcEntryShape).isRequired,
    defaultDisplayType: PropTypes.string,
    entryTypeNameList: PropTypes.arrayOf(entryTypeNameShape)
};