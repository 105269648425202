import {createBaseOptions, fetchJson, METHOD_GET} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";

export const fetchUserCustomizationById = (id,callback) => {
    
        
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_GET;
    options.headers["Content-Type"] = "application/json";

    let r = fetchJson(`${config.url.API_URL}/usercustomization/custom/item/${id}`, options);
    r.then(json => {
        callback(json); 
    }).catch(error => {
        callback(error);
    });
};

