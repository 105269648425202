import { createBaseOptions, fetchJson, METHOD_POST } from "../../utils/SuperAgentFetch";
import { config } from "../../config/Config";

export const fetchCreateBranding = (obj,callback) => {


    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_POST;
    options.headers["Content-Type"] = "application/json";
    options["body"] = obj;

    let r = fetchJson(`${config.url.API_URL}/branding/custom/item`, options);
    r.then(json => {
        if (json) {
            callback(json);
        }

    }).catch(error => {
        console.log(error);
        // callback(error);
    });
};


