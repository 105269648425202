import {fetchJson} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";

export const ENTRY_FILES_REQUESTED = 'ENTRY_FILES_REQUESTED';
/**
 * Action for request of entry files.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const requestEntryDetailFiles = (entryId) => {
    return {
        type: ENTRY_FILES_REQUESTED,
        entryId: entryId
    };
};

export const ENTRY_FILES_UPDATE_REQUESTED = "ENTRY_FILES_UPDATE_REQUESTED";
/**
 * Action for request of entry files.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const requestEntryDetailFilesUpdate = (entryId) => {
    return {
        type: ENTRY_FILES_UPDATE_REQUESTED,
        entryId: entryId
    }
};

export const ENTRY_FILES_RECEIVED = "ENTRY_FILES_RECEIVED";
/**
 *
 * @param {String} entryId
 * @param {Array<Object>} files
 * @return {ReceiveRelatedEntriesAction}
 */
export const receiveEntryDetailFiles = (entryId, files) => {
    return {
        type: ENTRY_FILES_RECEIVED,
        relatedEntryId: entryId,
        files: files,
        receivedAt: Date.now()
    }
};

export const ENTRY_FILES_UNMOUNT = "ENTRY_FILES_UNMOUNT";
/**
 * action unmount files
 * @return {BaseAction}
 */
export const unmountDetailFiles = () => {
    return {
        type: ENTRY_FILES_UNMOUNT
    }
};

export const ENTRY_FILES_ERROR = 'ENTRY_FILES_ERROR';
/**
 *
 * @param {String} entryId
 * @param {Object} error
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveEntryErrorAction}
 */
export const receiveError = (
    entryId,
    error,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: ENTRY_FILES_ERROR,
        entryId: entryId,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};

/**
 * Fetch entry files list
 *
 * @param {String} entryId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchDetailFiles = (entryId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            dispatch(requestEntryDetailFilesUpdate(entryId));
        } else {
            dispatch(requestEntryDetailFiles(entryId));
        }

        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/files`, null, dispatch)
            .then(json => {
                    let res = [];
                    if (json._embedded && json._embedded.tupleBackedMaps && json._embedded.tupleBackedMaps.length > 0) {
                        res = json._embedded.tupleBackedMaps;
                    }
                    dispatch(receiveEntryDetailFiles(entryId, res));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(entryId, error, error.response.status, description));
                } else {
                    dispatch(receiveError(entryId, error));
                }
            });
    }
};
