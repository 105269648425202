import React from "react";
import {bmcEntryShape} from "../../../shapes/BmcEntryShape";
import { Timeline, Spin } from 'antd';
import { ClockCircleOutlined,UserOutlined } from '@ant-design/icons';
import { withTranslation} from 'react-i18next'
import { isUndefined, isEmptyValue } from "../../../utils/JsObjectHelper";

class EntryVersionList extends React.Component {


    componentDidMount() {
        this.props.onMount(this.props.entry.id);
    }

    render() {
        const {t} = this.props;
        
        let timeLineItems = [];

        //let currentEntryDate = (!isUndefined(this.props.entry.updated) ? this.props.entry.updated : this.props.entry.inserted);
        let versionCompareUrlCurrent = "/entryarchive/" + this.props.entry.id;
        //Actual version is first up top
        timeLineItems.push(
            <Timeline.Item key="current version" dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
                <b>{t('app.entry.tools.versions.currentVersion')}</b>
                <span> ({isEmptyValue(this.props.entry.modifiedByUserName) ? <a  href={"/id2entry/" + this.props.entry.createdByUserId } target="_blank" ><UserOutlined /> {this.props.entry.createdByUserName}</a>  : <a  href={"/id2entry/" + this.props.entry.modifiedByUserId } target="_blank" ><UserOutlined /> {this.props.entry.modifiedByUserName}</a>}) - </span>
                <a href={versionCompareUrlCurrent} target="_blank" rel="noopener noreferrer">{this.props.entry.name}</a>
            </Timeline.Item>
        );

        if (this.props.entryVersionListRequestResult.getState().isDone()) {
            let versionData = this.props.entryVersionListRequestResult.getData();

            for (var i = versionData.length - 1; i >= 0; i--) {
                let colorVariant = (i===0?"green":"blue");

                let versionDate = (!isUndefined(versionData[i].updated) ? versionData[i].updated : versionData[i].created);

                let versionCompareUrl = "/entryarchive/" + this.props.entry.id + "/" + versionData[i].version;

                timeLineItems.push(
                    <Timeline.Item key={versionDate} color={colorVariant}>
                        <b>{t('datetime', {date : new Date(versionDate)})}</b>
                        <span> ({isEmptyValue(versionData[i].versionLastModifiedByUserName) ? " - " : <a  href={"/id2entry/" + versionData[i].versionLastModifiedByUserId } target="_blank" ><UserOutlined /> {versionData[i].versionLastModifiedByUserName}</a> }) - </span>
                        <a href={versionCompareUrl} target="_blank" rel="noopener noreferrer">
                            {versionData[i].name} (V{versionData[i].version})
                        </a>
                    </Timeline.Item>
                );
            }
        }



        return (
                <Spin spinning={!this.props.entryVersionListRequestResult.getState().isDone()} size="large">
                    <div className="entryPanelDiv">
                        <Timeline mode="left">
                            {timeLineItems}
                        </Timeline>
                    </div>
                </Spin>
           );
    }
};

export default withTranslation() (EntryVersionList);

EntryVersionList.propTypes = {
    entry: bmcEntryShape.isRequired,
};