import { Tooltip } from 'antd';
import React from 'react';
import { withTranslation} from 'react-i18next';
import { config } from '../config/Config';


class FooterContent extends React.Component {
    render() {
        const {t} = this.props;

        //Update before new version
        let versionNumber = config.version.number;
        let versionDate = config.version.date;

        return <Tooltip title={t("date", {date: versionDate})}>
            Aphinit ({versionNumber}) ©{new Date().getFullYear()}
        </Tooltip>;
    }
}

export default withTranslation() (FooterContent);