import { fetchJson } from "../../utils/SuperAgentFetch";
import { config } from "../../config/Config";

export const fetchBpmnGetRunningProcessCount = async (businessKey) => {
    /** @type RequestOptions options **/
    let options = {
        method: "GET",
    };

    try {
        const json = await fetchJson(`${config.url.API_URL}/bpmn/processInstance/history?active=true&processInstanceBusinessKey=${businessKey}`, options);
        let res = [];
        if (json._embedded && json._embedded.historicProcessInstanceDtoes && json._embedded.historicProcessInstanceDtoes.length > 0) {
            res = json._embedded.historicProcessInstanceDtoes;
            return res;
        }
    } catch (error) {
        console.log(error);
    }
}

