import {fetchJson} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";

export const fetchRootHierarchy = (entryId, arrEntryType,callback) => {
    let r = fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/children/hierarchyroot/${arrEntryType}`)
    r.then(json => {
        let res = [];
        if (json._embedded && json._embedded.bmcEntries && json._embedded.bmcEntries.length > 0) {
            res = json._embedded.bmcEntries;
        }
        callback(res);
    }).catch(error => {
        console.log(error);
        callback([]);
    });
};