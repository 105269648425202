import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { moveEntry, copyEntry } from '../../actions/entryManipulation'
import EntryChildMultiSelectionPresenter from '../../components/detail/view/EntryChildMultiSelection';
import { fetchDetailChildrenWithoutDescription } from '../../actions/entryDetailChildren';


const mapStateToProps = (state, ownProps) => {
    return {
        entryRequestResult: state.entryDetail,
        entryTypesRequestResult: state.objectList,

    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMove(entryId, manipulationDefinition,afterMoveCallback) {
            dispatch(moveEntry(entryId, manipulationDefinition,afterMoveCallback));
        },
        onCopy(entryId, manipulationDefinition, afterCopyCallback) {
            dispatch(copyEntry(entryId, manipulationDefinition, afterCopyCallback));
        },
        updateChildren(entryId){
            dispatch(fetchDetailChildrenWithoutDescription(entryId, true));
        }
    }
};

const EntryChildMultiSelection = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryChildMultiSelectionPresenter);

export default withRouter(EntryChildMultiSelection);