import EntryVersionListPresenter from '../../components/detail/view/EntryVersionList';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchEntryVersionList} from "../../actions/entryVersionList";
//import {isUndefined, isEmptyValue, isArray} from "../../utils/JsObjectHelper";

const mapStateToProps = (state, ownProps) => {
    return {
        entryVersionListRequestResult: state.entryVersionList,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: (entryId) => {
            dispatch(fetchEntryVersionList(entryId));
        },
    }
};

const EntryVersionList = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryVersionListPresenter);

export default withRouter(EntryVersionList);