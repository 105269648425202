import {createBaseOptions, fetchJson, METHOD_DELETE, METHOD_PATCH, METHOD_POST} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError, showSuccess} from "../utils/NotificationsHelper";
import { push } from 'connected-react-router'
import {fetchDetailIncomingRelations} from "./entryDetailIncomingRelations";
import {fetchDetailOutgoingRelations} from "./entryDetailOutgoingRelations";

export const ENTRY_DETAIL_REQUESTED = 'ENTRY_DETAIL_REQUESTED';
/**
 * Action for request of entry.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const requestEntryDetail = (entryId) => {
    return {
        type: ENTRY_DETAIL_REQUESTED,
        entryId: entryId
    };
};

export const ENTRY_DETAIL_UPDATE_REQUESTED = "ENTRY_DETAIL_UPDATE_REQUESTED";
/**
 * Action for request of entry.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const requestEntryDetailUpdate = (entryId) => {
    return {
        type: ENTRY_DETAIL_UPDATE_REQUESTED,
        entryId: entryId
    }
};


export const ENTRY_SUCCESSFULLY_SAVED = "ENTRY_SUCCESSFULLY_SAVED";
/**
 * Action for request of entry.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const receiveEntrySuccessfullySaved = (entryId) => {
    return {
        type: ENTRY_SUCCESSFULLY_SAVED,
        entryId: entryId
    }
};

export const ENTRY_DETAIL_RECEIVED = "ENTRY_DETAIL_RECEIVED";
/**
 *
 * @param {String} entryId
 * @param {BaseEntryWithParent} entry
 * @return {ReceiveEntryAction}
 */
export const receiveEntryDetail = (entryId, entry) => {
    return {
        type: ENTRY_DETAIL_RECEIVED,
        entry: entry,
        entryId: entryId,
        receivedAt: Date.now()
    }
};

export const ENTRY_DETAIL_UNMOUNT = "ENTRY_DETAIL_UNMOUNT";
/**
 * action unmount detail
 * @return {BaseAction}
 */
export const unmountDetail = () => {
    return {
        type: ENTRY_DETAIL_UNMOUNT
    }
};

export const ENTRY_DETAIL_ERROR = 'ENTRY_DETAIL_ERROR';
export const ENTRY_SAVE_ERROR = 'ENTRY_SAVE_ERROR';
export const ENTRY_DELETE_ERROR = 'ENTRY_DELETE_ERROR';
export const ENTRY_EDIT_EVENT_ERROR = 'ENTRY_EDIT_EVENT_ERROR';
/**
 *
 * @param {String} entryId
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveEntryErrorAction}
 */
export const receiveError = (
    entryId,
    error,
    type = ENTRY_DETAIL_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        entryId: entryId,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};

export const ENTRY_DETAIL_EDIT_REQUESTED = "ENTRY_DETAIL_EDIT_REQUESTED";
/**
 * Action for request of entry.
 *
 * @param {String} entryId
 * @param {Object} values
 * @return {EntryEditAction}
 */
export const requestEntryDetailEdit = (entryId, values) => {
    return {
        type: ENTRY_DETAIL_EDIT_REQUESTED,
        entryId: entryId,
        values: values,
    }
};

export const ENTRY_DETAIL_NEW_REQUESTED = "ENTRY_DETAIL_NEW_REQUESTED";
/**
 * Action for request of entry.
 *
 * @param {String} parentEntryId
 * @param {Object} values
 * @return {EntryEditAction}
 */
export const requestEntryDetailNew = (parentEntryId, values) => {
    return {
        type: ENTRY_DETAIL_NEW_REQUESTED,
        parentEntryId: parentEntryId,
        values: values,
    }
};

/**
 * Fetch entry detail
 *
 * @param {String} entryId
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchDetail = (entryId, update = false) => {
    return (dispatch, getState) => {
        if (update) {
            dispatch(requestEntryDetailUpdate(entryId));
        } else {
            dispatch(requestEntryDetail(entryId));
        }

        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/projection/entryWithParent`, null, dispatch)
        // return fetchJson(`${config.url.API_URL}/bmcEntries/${entryId}?projection=entryWithParent`, null, dispatch)
            .then(json => {
                    //json['productID'] = productID;
                    dispatch(receiveEntryDetail(entryId, json));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(entryId, error, ENTRY_DETAIL_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(entryId, error, ENTRY_DETAIL_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};


/**
 * Fetch entry detail
 *
 * @param {String} entryId
 * @param {Object} values key = name of attribute, value = value of the attribute
 * @return {function(*=, *): Promise<Object | never>}
 */
export const saveEditedValues = (entryId, values) => {
    return (dispatch, getState) => {
        dispatch(requestEntryDetailEdit(entryId, values));
        /** @type RequestOptions */
        let options = createBaseOptions();
        options.method = METHOD_PATCH;
        options.headers["Content-Type"] = "application/json";
        options["body"] = values;

        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}`, options, dispatch)
            .then(json => {
                    //json['productID'] = productID;
                    //dispatch(receiveEntryDetail(entryId, json));
                    dispatch(fetchDetail(entryId, true));
                    dispatch(receiveEntrySuccessfullySaved(entryId));
                    if(values.hasOwnProperty("incomingRelations")) {
                        // refresh incoming relations
                        dispatch(fetchDetailIncomingRelations(entryId, true));
                    }
                    if(values.hasOwnProperty("outgoingRelations")) {
                        // refresh outgoing relations
                        dispatch(fetchDetailOutgoingRelations(entryId, true));
                    }
                    dispatch(push(`/entry/${entryId}`));
                    showSuccess(dispatch, "Success", "Entry has been successfully updated!");
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(entryId, error, ENTRY_SAVE_ERROR, error.response.status, description));
                } else {
                    dispatch(receiveError(entryId, error, ENTRY_SAVE_ERROR));
                }
                showError(dispatch, "Error", "Entry couldn't be updated!<br />Contact aphinit administrator.");
            });
    }
};

/**
 * Save new entry
 *
 * @param {String} parentEntryId
 * @param {Object} values key = name of attribute, value = value of the attribute
 * @param {function} callback function with returning parameter of new entry
 * @return {function(*=, *): Promise<Object | never>}
 */
export const saveNewValues = (parentEntryId, values, doRedirect = true, callback) => {
    return (dispatch, getState) => {
        dispatch(requestEntryDetailNew(parentEntryId, values));
        /** @type RequestOptions */
        let options = createBaseOptions();
        options.method = METHOD_POST;
        options.headers["Content-Type"] = "application/json";
        options["body"] = values;

        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${parentEntryId}/children`, options, dispatch)
            .then(json => {
                    //json['productID'] = productID;
                    dispatch(receiveEntrySuccessfullySaved(json.id));
                    if(callback) {
                        callback(json);
                    }
                    if (doRedirect) {
                        dispatch(receiveEntryDetail(json.id, json));
                        dispatch(push(`/entry/${json.id}/edit`));
                    }
                    showSuccess(dispatch, "Success", "Entry has been successfully inserted!");
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(parentEntryId, error, ENTRY_SAVE_ERROR, error.response.status, description));
                } else {
                    dispatch(receiveError(parentEntryId, error, ENTRY_SAVE_ERROR));
                }
                showError(dispatch, "Error", "Entry couldn't be inserted!<br />Contact aphinit administrator.");
            });
    }
};

export const ENTRY_DELETE_REQUESTED = 'ENTRY_DELETE_REQUESTED';
/**
 * Action for delete of entry.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const requestEntryDelete = (entryId) => {
    return {
        type: ENTRY_DELETE_REQUESTED,
        entryId: entryId
    };
};

export const ENTRY_SUCCESSFULLY_DELETED = "ENTRY_SUCCESSFULLY_DELETED";
/**
 * Action successfully deleted entry.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const receiveEntrySuccessfullyDeleted = (entryId) => {
    return {
        type: ENTRY_SUCCESSFULLY_DELETED,
        entryId: entryId
    }
};

/**
 * Delete entry
 *
 * @param {String} entryId
 * @return {function(*=, *): Promise<Object | never>}
 */
export const deleteEntry = (entryId) => {
    return (dispatch, getState) => {

        dispatch(requestEntryDelete(entryId));

        let options = createBaseOptions();
        options.method = METHOD_DELETE;

        return fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}`, options, dispatch)
            .then(json => {
                    //json['productID'] = productID;
                    dispatch(receiveEntrySuccessfullyDeleted(entryId));
                    dispatch(receiveEntryDetail(json.id, json));
                    dispatch(push(`/entry/${json.parent.id}`));
                    showSuccess(dispatch, "Success", "Entry has been successfully deleted!");
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(entryId, error, ENTRY_DELETE_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(entryId, error, ENTRY_DELETE_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};






export const SEND_ENTRY_EDIT_EVENT = 'SEND_ENTRY_EDIT_EVENT';
/**
 * Action for editing event for entry.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const sendEntryEditEventAction = (entryId) => {
    return {
        type: SEND_ENTRY_EDIT_EVENT,
        entryId: entryId
    };
};

export const ENTRY_EDIT_EVENT_SUCCESSFULLY_ACCEPTED = "ENTRY_EDIT_EVENT_SUCCESSFULLY_ACCEPTED";
/**
 * Action successfully entry edit event succesfully accepted.
 *
 * @param {String} entryId
 * @return {EntryIdAction}
 */
export const receiveEntryEditEventSuccessfullyAccepted = (entryId) => {
    return {
        type: ENTRY_EDIT_EVENT_SUCCESSFULLY_ACCEPTED,
        entryId: entryId
    }
};

/**
 * Edit event entry
 *
 * @param {String} entryId
 * @return {function(*=, *): Promise<Object | never>}
 */
export const sendEntryEditEventRequest = (entryId) => {
    return (dispatch, getState) => {

        dispatch(sendEntryEditEventAction(entryId));

        let options = createBaseOptions();

        return fetchJson(`${config.url.API_URL}/concurrent/editing/entry/${entryId}`, options, dispatch)
            .then(json => {
                    dispatch(receiveEntryEditEventSuccessfullyAccepted(entryId));
                }
            ).catch(error => {
                if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(entryId, error, ENTRY_EDIT_EVENT_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(entryId, error, ENTRY_EDIT_EVENT_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};