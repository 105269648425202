import React from "react";
import { Alert, Skeleton, Spin, Table } from 'antd';
import { EyeTwoTone, EditTwoTone, ContainerTwoTone,CloseCircleTwoTone} from '@ant-design/icons';
import { withTranslation } from 'react-i18next'
import { isArray } from "../../utils/JsObjectHelper";



class GroupRights extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }



    render() {
        const { t } = this.props;
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => {
                    console.log(record);
                    return (<a href={`/entry/${record.id}`} target="_blank">{text}</a>)
                }
            },
            {
                title: 'Rights',
                dataIndex: 'rights',
                key: 'rights',
                render: (text, record) => {
                    console.log(text );
                    let colorB = "white"
                    let icon = null
                    switch (text) {
                        case "VIEW":
                            colorB = '#fff7e6'
                            icon = <EyeTwoTone twoToneColor="#ffd400" />
                            break;
                        case "NONE":
                            colorB = '#fff1f0'
                            icon = <CloseCircleTwoTone twoToneColor="red"/>
                            break;
                        case "EDIT":
                            colorB = '#f6ffed'
                            icon = <EditTwoTone twoToneColor="green" />
                            break;
                        case "VIEW_PARENT_EDIT_CHILD":
                            colorB = '#e6f7ff'
                            icon = <ContainerTwoTone twoToneColor="blue" />
                            break;
                        case "INHERITED":
                            colorB = '#e6f7ff'
                            break;

                        default:
                            break;
                    }

                    return <span style={{ backgroundColor: colorB, padding: '5px' }}>{icon}&nbsp;{text}</span>
                }
            },
        ];
        let main = null;

        if (this.props.foldersId && this.props.foldersId.length === 0) {
            main = <Alert
                message={t('setup.userManagement.groupDoesNotHaveAccessRights')}
                type="info"
            />
        }
        else if (this.props.foldersId && this.props.foldersId.length > 0) {
            main = <Spin tip={t('setup.userManagement.groupHasAccessRights', { count: this.props.foldersId.length })} size="large" spinning={this.props.loading} >
                <Table
                    columns={columns}
                    dataSource={this.props.data}
                />
            </Spin>
        }
        return <div>
            {main}
        </div>;
    }
}

export default withTranslation()(GroupRights);
