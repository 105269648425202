import React from 'react';
import PropTypes from "prop-types";
import {isUndefined, isEmptyObject, isEmptyValue, isArray} from "../../../utils/JsObjectHelper";
import { EMPTY_SETTINGS_LINEAGE_HIERARCHY } from '../../../utils/EmptySettings';
import {entryTypesRequestResult} from "../../../shapes/RequestResult";
import { Modal, Descriptions, Select, Checkbox } from 'antd';
import update from 'immutability-helper';
import { withTranslation } from 'react-i18next'

const cloneDeep = require('lodash.clonedeep');
const { Option } = Select;

class LineageNewHierarchy extends React.Component {
    constructor(props) {
        super(props);
        this.onOk = this.onOk.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onParentSelected = this.onParentSelected.bind(this);
        this.onRelationSelected = this.onRelationSelected.bind(this);
        this.onChildSelected = this.onChildSelected.bind(this);
        
        this.state = {
            hierarchyRecord: EMPTY_SETTINGS_LINEAGE_HIERARCHY,
            canAdd: false,
        };
    }

    /**
     * 
     * @param {*} prevProps 
     * @param {*} prevState 
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.props.isVisible && !prevProps.isVisible) {
            if (isEmptyObject(this.props.editingEntryData))
                this.setState({ hierarchyRecord: EMPTY_SETTINGS_LINEAGE_HIERARCHY });
            else 
                this.setState({ hierarchyRecord: cloneDeep(this.props.editingEntryData), canAdd : true });
        }
    }

    onOk () {
        this.props.onAddHierarchy(this.state.hierarchyRecord);
    };

    onCancel () {
        this.props.onCancel();
    };

    onParentSelected = (parent) => {
        this.setState( (prevState, props) => {
            return update(prevState, {
                hierarchyRecord : {
                    parent : {$set: parent},
                    relation : {$set: null},
                    child : {$set: null},
                },
                canAdd : {$set: false}
            });
        });
    };

    onRelationSelected = (relation) => {
        this.setState( (prevState, props) => {
            return update(prevState, {
                hierarchyRecord : {
                    relation : {$set: relation},
                    child : {$set: null},
                },
                canAdd : {$set: false}
            });
        });
    };

    onChildSelected = (child) => {
        this.setState( (prevState, props) => {
            return update(prevState, {
                hierarchyRecord : {
                    child : {$set: child},
                },
                canAdd : {$set: true}
            });
        });
    };

    onDoExpandParentChange = (value) => {
        this.setState( (prevState, props) => {
            return update(prevState, {
                hierarchyRecord : {
                    doExpandParent : {$set: value.target.checked},
                }
            });
        });
    };

    render() {
        const {t} = this.props;

        let parentTypes = null;
        if (this.props.objectListRequestResult.getState().isDone()) {
            parentTypes = this.props.objectListRequestResult.getData()
                .filter(et => { return (et.systemType === "object"); })
                .sort((a,b) => a.name.localeCompare(b.name))
                .map(oT => <Option key={oT.type} value={oT.type}>{`${oT.name} (${oT.type})`}</Option>);
        }
            
        let relationList = null;
        if (!isEmptyValue(this.state.hierarchyRecord.parent)) {
            let selectedParent = this.props.objectListRequestResult.getData()
                .find(oT => oT.type === this.state.hierarchyRecord.parent).properties.attributes
                .filter(oT => oT.type === "relation");
            relationList = selectedParent.map(rel => <Option key={rel.techName} value={rel.techName}>{`${rel.name} (${rel.techName})`}</Option>)
        }
        
        let childTypes = null;
        if (!isEmptyValue(this.state.hierarchyRecord.parent) && !isEmptyValue(this.state.hierarchyRecord.relation)) {
            let selectedRelation = this.props.objectListRequestResult.getData()
                .find(oT => oT.type === this.state.hierarchyRecord.parent).properties.attributes
                .find(att => att.techName === this.state.hierarchyRecord.relation).relationEntryType
            if (!isArray(selectedRelation)) {
                selectedRelation = [selectedRelation];
            }
            let childObjTypes = this.props.objectListRequestResult.getData()
                .filter(oT => selectedRelation.indexOf(oT.type) > -1)

            childTypes = childObjTypes.map(oT => <Option key={oT.type} value={oT.type}>{`${oT.name} (${oT.type})`}</Option>)
        }

        return <Modal
                    visible={this.props.isVisible}
                    title={t('setup.lineageSetup.detailAddHierarchyTitle')}
                    okText={t('setup.lineageSetup.detailAddHierarchyBtnOk')}
                    cancelText={t('setup.lineageSetup.detailAddHierarchyBtnCancel')}
                    onCancel={this.onCancel}
                    onOk={this.onOk}
                    okButtonProps={ { disabled: !this.state.canAdd } }
                    //destroyOnClose={true}
                >
                    <Descriptions bordered column={1}>
                        <Descriptions.Item label={t('setup.lineageSetup.detailAddHierarchyParent')}>
                            <Select 
                                style={{ width: 250 }} 
                                onChange={this.onParentSelected}
                                value={this.state.hierarchyRecord.parent}
                                >
                                {parentTypes}
                            </Select> 
                        </Descriptions.Item>
                        <Descriptions.Item label={t('setup.lineageSetup.detailAddHierarchyRelation')}>
                            <Select 
                                style={{ width: 250 }} 
                                onChange={this.onRelationSelected}
                                value={this.state.hierarchyRecord.relation}
                                loading={isEmptyObject(this.state.hierarchyRecord.parent)}
                                >
                                {relationList}
                            </Select>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('setup.lineageSetup.detailAddHierarchyChild')}>
                            <Select 
                                style={{ width: 250 }} 
                                onChange={this.onChildSelected}
                                value={this.state.hierarchyRecord.child}
                                loading={isEmptyObject(this.state.hierarchyRecord.relation)}
                                >
                                {childTypes}
                            </Select>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('setup.lineageSetup.detailDoExpandParent')}>
                            <Checkbox 
                                checked={this.state.hierarchyRecord.doExpandParent}
                                onChange={this.onDoExpandParentChange}
                            ></Checkbox>
                        </Descriptions.Item>
                    </Descriptions>
                </Modal>;
    }
}

export default withTranslation() (LineageNewHierarchy);

LineageNewHierarchy.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    objectListRequestResult: entryTypesRequestResult.isRequired,
    onAddHierarchy: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    editingEntryData: PropTypes.object
};