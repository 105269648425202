import ExtendableError from 'es6-error';
import {isUndefined} from "../utils/JsObjectHelper";

export const BAD_STATUS_EXCEPTION_BASE_MESSAGE = 'Couldn\'t get successful response';

export default class ResponseStatusException extends ExtendableError {
    /**
     * Special exception for response error.
     *
     * @param {Response} response error response returned from api call
     */
    constructor(response) {
        // this exception will be handled by message
        super(!isUndefined(response.statusCode) ? `${BAD_STATUS_EXCEPTION_BASE_MESSAGE}: ${response.statusCode} - ${response.statusText}` : BAD_STATUS_EXCEPTION_BASE_MESSAGE);
        // Custom debugging information
        this.date = new Date();
        this.response = response;
    }
}