export const EMPTY_LOAD_SETTINGS = {
    id: "",
    code: "",
    value: {
        customProps: [],
        folders: {
            write: [],
            read: []
        }
    }
};