import {fetchJson} from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {config} from "../config/Config";
import {showError} from "../utils/NotificationsHelper";

export const OBJECT_LIST_REQUESTED = 'OBJECT_LIST_REQUESTED';
/**
 * Action for request of entry.
 *
 * @return {ObjectListAction}
 */
export const requestObjectList = () => {
    return {
        type: OBJECT_LIST_REQUESTED
    };
};

export const OBJECT_LIST_RECEIVED = "OBJECT_LIST_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} objectList
 * @return {ReceiveObjectListAction}
 */
export const receiveObjectList = (objectList) => {
    return {
        type: OBJECT_LIST_RECEIVED,
        objectList: objectList,
        receivedAt: Date.now()
    }
};

export const OBJECT_LIST_ERROR = 'OBJECT_LIST_ERROR';
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveObjectListErrorAction}
 */
export const receiveError = (
    error,
    type = OBJECT_LIST_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};


/**
 * Fetch object list
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchObjectList = (update = false) => {
    return (dispatch, getState) => {
        if (update) {
            //dispatch(requestEntryDetailUpdate());
            dispatch(requestObjectList());
        } else {
            dispatch(requestObjectList());
        }

        /** @type RequestOptions options **/
        let options = {
            method: "GET"
        };
        options.ignore = {
            key: "fetchObjectList",
            maxLifeTime: 60000
        };
        options.cache = {
            key: "fetchObjectList",
            maxLifeTime: 60000,
            forceRefresh: update
        };

        //TODO: doresit otazku kolem strankovani
        return fetchJson(`${config.url.API_URL}/objectDefinitions?size=1000`, options, dispatch)
            .then(json => {
                    let res = [];
                    if (json._embedded && json._embedded.objectDefinitions && json._embedded.objectDefinitions.length > 0) {
                        res = json._embedded.objectDefinitions;
                    }
                    dispatch(receiveObjectList(res));
                }
            ).catch(error => {
                if(error === "Ignoring request") {
                    // TODO create some better solution with ignoring same requests
                    // do nothing, is expected state
                } else if(error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, OBJECT_LIST_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(error, OBJECT_LIST_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};