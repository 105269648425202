import React, { createRef } from "react";
import PropTypes from "prop-types";
import { Input, Button, Modal, Form, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import { isUndefined, isEmptyValue } from "../../../utils/JsObjectHelper";
import BpmnVariablesValue from "./BpmnVariablesValue";
import BpmnReservedVariable from "./BpmnReservedVariable";

const { Option } = Select;

class BpmnVariablesModal extends React.Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      addModalVisible: false,
      initVals: { name: "", value: null },
    };
  }

  /**
   * React lifecycle after update method
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState(
        {
          addModalVisible: true,
          initVals: this.props.itemValues,
        },
        () => {
          if (!isUndefined(this.formRef.current)) {
            this.formRef.current.resetFields();
          }
        }
      );
    }
  }

  /**
   * @param {*} rule
   * @param {*} value
   * @param {*} callback
   */
  valueSpace = (rule, value, callback) => {
    if (/\s/g.test(value)) {
      callback(rule.message);
    }
    callback();
  };
  nameValidator = (rule, value, callback) => {
    if (!isUndefined(this.props.formRef.current)) {
      if (
        (isUndefined(this.props.itemValues) || this.props.itemValues.name !== value) 
         && this.props.formRef.current.getFieldValue(["dynamicMapping"]).find((att) => {return att.name === value;}) != null 
         || this.props.formRef.current.getFieldValue(["staticMapping"]).find((att) => {return att.name === value;}) != null
      ) {
        callback(rule.message);
      }
    }
    callback();
  };

  valueValidator = (rule, value, callback) => {
    if (value.includes("|#|")) {
      callback(rule.message);
    }
    callback();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.formRef.current
      .validateFields()
      .then((values) => {
        if (this.props.isEdit) {
          this.props.onItemUpdated(
            values.name,
            values.value,
            this.props.itemValues
          );
        } else {
          this.props.onItemAdd(values.name, values.value);
        }
        this.hideModal();
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
        return;
      });
  };

  hideModal = () => {
    this.setState(
      {
        addModalVisible: false,
        initVals: { name: "", value: null },
      },
      () => {
        this.formRef.current.resetFields();
        this.props.onModalHide();
      }
    );
  };

  openModalNew = () => {
    this.setState(
      {
        addModalVisible: true,
        initVals: { name: "", value: null },
      },
      () => {
        this.formRef.current.resetFields();
      }
    );
  };

  render() {
    const { t } = this.props;
    return [
      <Button
        key="addButton"
        size="small"
        icon={<PlusOutlined />}
        onClick={this.openModalNew}
      >
        {t("setup.workflowSetup.bpmnAddVariables")}
      </Button>,
      <Modal
        title={t("setup.workflowSetup.bpmnAddVariable")}
        key="addVariableModal"
        onCancel={this.hideModal}
        okButtonProps={{ hidden: true }}
        visible={this.state.addModalVisible}
      >
        <Form
          layout="vertical"
          name="horizontal_addUrl"
          ref={this.formRef}
          onFinish={this.handleSubmit}
          initialValues={this.state.initVals}
        >
          <Form.Item
            label={t("setup.workflowSetup.bpmnNameVariable")}
            name="name"
            rules={[
              {
                required: true,
                message: t(
                  "app.genericControls.formNameValueManipulationModal.msgAddName"
                ),
              },
              {
                validator: this.nameValidator,
                message: t("setup.workflowSetup.validateVariableText"),
              },
              {
                validator: this.valueSpace,
                message: t("setup.workflowSetup.validateMesageInputSpaces"),
              },
            ]}
          >
            <Input placeholder={t("setup.workflowSetup.bpmnInputVariable")} />
          </Form.Item>
          <Form.Item shouldUpdate={true}>
            {() => {
              let modalItem = null;
              switch (this.props.userType) {
                case "attribut":
                  modalItem = (
                    <Form.Item
                      label={t("setup.workflowSetup.bpmnEntryTypes")}
                      key="value"
                      name="value"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "setup.workflowSetup.validateMesagefromSelect"
                          ),
                        },
                        {
                          validator: this.valueValidator,
                          message: t(
                            "setup.workflowSetup.validateMesagefromSelect"
                          ),
                        },
                      ]}
                    >
                      <BpmnVariablesValue
                        entryType={this.props.entry}
                        list={this.props.formRef.current.getFieldValue(
                          "entryTypes"
                        )}
                        isEdit={this.props.isEdit}
                        isOpen={this.state.addModalVisible}
                      />
                    </Form.Item>
                  );
                  break;
                case "reserved":
                  modalItem = (
                    <Form.Item
                      label={t("setup.workflowSetup.modalCustomDescription")}
                      key="value"
                      name="value"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "setup.workflowSetup.validateMesageFromVariableReserved"
                          ),
                        },
                      ]}
                    >
                      <BpmnReservedVariable />
                    </Form.Item>
                  );
                  break;
                default:
                  break;
              }
              return modalItem;
            }}
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={this.handleSubmit}
                disabled={
                  isUndefined(this.formRef.current) ||
                  (!isUndefined(this.formRef.current) &&
                    ((!this.formRef.current.isFieldsTouched(true) &&
                      !this.props.isEdit) ||
                      this.formRef.current
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length > 0))
                }
              >
                {this.props.isEdit
                  ? t("setup.workflowSetup.bpmnButtonUpdateVariable")
                  : t("setup.workflowSetup.bpmnButtonAddVariable")}
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>,
    ];
  }
}

export default withTranslation()(BpmnVariablesModal);

BpmnVariablesModal.propTypes = {
  onItemAdd: PropTypes.func.isRequired,
  onItemUpdated: PropTypes.func.isRequired,
  onModalHide: PropTypes.func.isRequired,
  itemValues: PropTypes.object,
  isEdit: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
};
