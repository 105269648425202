import PropTypes from 'prop-types';
import {idNameWithTypeShape} from "./IdNameShapes";

export const relationHolderShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    relatedEntries: PropTypes.arrayOf(idNameWithTypeShape).isRequired,
});

export const relationsHolderShape = PropTypes.shape({
    type: PropTypes.string.isRequired,    // incoming, outgoing
    relations: PropTypes.arrayOf(relationHolderShape).isRequired,
});