import {createBaseOptions, fetchJson, METHOD_POST} from "../../utils/SuperAgentFetch";
import {config} from "../../config/Config";


export const fetchBpmnStartProcess = (workflowId,entryid,variablesform,callback) => {
    let variabl = {
        entryId : entryid,
        staticVariables : {},
        formVariables: variablesform? variablesform :{},
        businessKey: entryid,
      };
    
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_POST;
    options.headers["Content-Type"] = "application/json";
    options["body"] = variabl;
    
    let e =''
   

    let r = fetchJson(`${config.url.API_URL}/bpmn/workflow/${workflowId}/start`, options) // /async
    r.then(json => {
        e = "success"
        callback(e)
        
    })
    .catch(error => {
        e = "error"
        callback(e)
        
    });
    

};