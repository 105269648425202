import ObjectDefinitionWizardPresenter from '../../components/setup/ObjectDefinitionWizard';
import { connect } from 'react-redux';
import {
    fetchDetail,
    fetchEmptyNewObjectDefinition,
    saveEditedObjectDefinition,
    saveNewObjectDefinition,
    unmountDetail
} from "../../actions/objectDefinitionDetail";
import {withRouter} from "react-router-dom";
import {isUndefined} from "../../utils/JsObjectHelper";

const mapStateToProps = (state, ownProps) => {
    return {
        objectDefinitionRequestResult: state.objectDefinitionDetail,
        isEdit: (!isUndefined(ownProps.match.params.setupDetailId)),
        isCreateNew: (isUndefined(ownProps.match.params.setupDetailId)),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: (objectDefinitionId = null, shouldUpdateOnly = false) => {
            if(isUndefined(objectDefinitionId)) {
                dispatch(fetchEmptyNewObjectDefinition());
            } else {
                dispatch(fetchDetail(objectDefinitionId, shouldUpdateOnly));
            }
        },
        onUnmount: () => {
            dispatch(unmountDetail());
        },
        /**
         * @param {BaseObjectDefinition} item
         */
        onSaveObjectDefinition: (item) => {
            if(isUndefined(ownProps.match.params.setupDetailId)) {
                // create new
                dispatch(saveNewObjectDefinition(item));
            } else {
                // update existing
                dispatch(saveEditedObjectDefinition(ownProps.match.params.setupDetailId, item));
            }
        },
    }
};

const ObjectDefinitionWizard = connect(
    mapStateToProps,
    mapDispatchToProps
)(ObjectDefinitionWizardPresenter);

export default withRouter(ObjectDefinitionWizard);