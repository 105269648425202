import {createBaseOptions, fetchJson, METHOD_PUT,} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";



export const fetchIdentityToEntry = (identityId,entryId,callback) => {
   
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_PUT;
    options.headers["Content-Type"] = "application/json";
    
  
    let r = fetchJson(`${config.url.API_URL}/identityToEntries/custom/item/${identityId}/${entryId}`, options) // /async
    r.then(json => {
        console.log('ok');
        callback()   
    })
    .catch(error => {
       callback(error)
        console.log(error);
    });
    

};