import React from "react";
import { withRouter } from "react-router-dom";
import { PageHeader, Button, Modal, Table, Tag, Input, Checkbox, Skeleton ,Popover} from 'antd';
import { EditOutlined, PlusOutlined, InfoCircleOutlined,DeleteOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next'
import PropTypes from "prop-types";
import { isEmptyObject, isUndefined } from "../../../utils/JsObjectHelper";
import { fetchEntries } from "../../../apicalls/fetchEntries";
import EntryTypeTag from "../../controls/EntryTypeTag";

const { Column } = Table;
const { Search } = Input;

class UserMappingList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchNameText: [],
            foldersDetail: [],
            advSearchPageSize: (isEmptyObject(localStorage.getItem("setupWorkflowPageSize")) ? 30 : parseInt(localStorage.getItem("setupWorkflowPageSize"))),
            openPopover: null
        };

        this.onMount = this.onMount.bind(this);
        this.filterEntryTypes = this.filterEntryTypes.bind(this);
    }

    showTotal = (total) => {
        return `(${total})`;
    };

    handlePageSizeChanged = (current, page) => {
        localStorage.setItem("setupWorkflowPageSize", page);
        this.setState({ advSearchPageSize: page });
    };

    componentDidMount() {
        if (this.state.foldersDetail.length === 0) {
            this.getFolderDetail(this.props.List)
        }
        this.onMount();
    }
    componentDidUpdate(prevProps,prevState){
        if(prevProps.List !== this.props.List && this.state.foldersDetail.length === 0){
            this.getFolderDetail(this.props.List)
        }
    }

    onMount(entryId) {
        this.props.onMount();
    }

    filterEntryTypes = (searchValue) => {
        this.setState({ searchNameText: searchValue.split(' ') });
    }

    createNewUserMapping = () => {
        this.props.history.push(`/setup/idmappingedit/`);
    }

    editUserMapping = (e, record) => {
        this.props.history.push(`/setup/idmappingedit/` + record.name);
    }

    getFolderDetail = (settingsList) => {
        if (!isUndefined(settingsList) && settingsList.getState().isDone()) {
            let setList = settingsList.getData().filter(eT => eT.id === "id_mapping");
            let searchQuery = "";
            setList.forEach((entry) => {
                searchQuery += "id:" + entry.value.folderId + " ";
            });
            fetchEntries(searchQuery, "getFolderDetail", search => this.setState({ foldersDetail: search }))

        }
    }
    showPopover =(newOpen)=>{
        this.setState({openPopover:newOpen});
    }


    render() {
        let entryTypeNameList = null;
        if (this.props.entryTypesRequestResult.getState().isDone()) {
            entryTypeNameList = this.props.entryTypesRequestResult.getData().map((item, key) => { return { type: item.type, name: item.name, color: item.properties.typeColor }; });
        }

        const { t } = this.props;
        let info = () => {
            Modal.info({
                title: t('setup.usersMapping.infoTitle'),
                content: (
                    <div>
                        {t('setup.usersMapping.infoText')}
                    </div>
                ),
                onOk() {
                },
            });
        };
        let paginationSetup = {
            showSizeChanger: true,
            size: "small",
            showTotal: this.showTotal,
            defaultPageSize: this.state.advSearchPageSize,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            onShowSizeChange: this.handlePageSizeChanged
        };

        let main = <Skeleton></Skeleton>
        if (this.props.List) {


            let colums = [
                {
                    title: t('setup.usersMapping.tblAction'),
                    key: "action",
                    render: (text, record) => {
                        return (<span>
                            <Button onClick={(e) => { this.editUserMapping(e, record) }}><EditOutlined /> <span className="setupActionBtn">{t('setup.usersMapping.tblBtnEditWorkflowSetup')}</span></Button>
                            <Popover
                                    content={<>{t('setup.workflowSetup.areYouSure')} <Button type="danger" onClick={() => { this.props.deleteMapping(record.id, record.name) }} size='small' shape="round"> {t('setup.workflowSetup.btnPopover')}</Button> </>}
                                    trigger="click"
                                    open={this.state.openPopover}
                                    onOpenChange={()=>this.showPopover()}
                                >
                                    <Button  style={{ marginLeft: "5px" }} icon={<DeleteOutlined />}><span className="setupActionBtn">{t('setup.workflowSetup.tblBtnDeleteWorkflowSetup')}</span></Button>
                                </Popover>
                        </span>)
                    }


                },
                {
                    title: t('setup.usersMapping.tblName'),
                    dataIndex: ["value", "idMapName"],
                    key: "value.idMapName",
                    render: (text, record) => (
                        <span><b>{record.value.idMapName}</b></span>
                    ),
                    filteredValue: this.state.searchNameText,
                    onFilter: (value, record) => record.value.idMapName.toLowerCase().includes(value.toLowerCase()),
                    sorter: (a, b) => {
                        return (isUndefined(a.value.idMapName) ? "" : a.value.idMapName).localeCompare((isUndefined(b.value.idMapName) ? "" : b.value.idMapName));
                    }
                },
                {
                    title: t('setup.usersMapping.tblEntryTypes'),
                    dataIndex: ["value", "entryType"],
                    key: "value.entryType",
                    render: (tag) => (
                        <EntryTypeTag entryTypeName={tag} entryTypeNameList={entryTypeNameList}></EntryTypeTag>

                    )
                },
                {
                    title: t('setup.usersMapping.detailFolderName'),
                    dataIndex: ["value", "folderId"],
                    key: "value.folderId",
                    render: (folderId) => {
                        let folderName = this.state.foldersDetail.find((el) => el.id === folderId)
                        if (!isUndefined(folderName)) {
                            return <span>{folderName.name}</span>
                        }
                    }
                },
              
            ];
            main =
                <Table
                    dataSource={this.props.idMappingSettingsList}
                    columns={colums}
                    rowKey={record => record.name}
                    size="small"
                    scroll={{ x: true }}
                    loading={isUndefined(this.props.idMappingSettingsList)}
                    pagination={paginationSetup}
                    title={() => <div className="searchForSetup" >
                        <Button type="primary" style={{ marginRight: '15px',marginBottom: "10px" }} onClick={this.createNewUserMapping}><PlusOutlined /> {t('setup.usersMapping.btnAddWorkflowSetup')}</Button>
                        <Search
                            placeholder={t('setup.usersMapping.searchBoxHint')}
                            onSearch={this.filterEntryTypes}
                            style={{ width: '100%' }}
                            allowClear
                        />
                    </div>}
                >
                </Table>

        }

        return (
            <div>
                <PageHeader
                    title={t('setup.usersMapping.headerDetail')}
                    subTitle={t('setup.usersMapping.headerDetailLabel')}
                    extra={[
                        <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                        </Button>,
                    ]}
                >
                </PageHeader>

                {main}
            </div>
        );
    }
}

const UserMappingListWithRouter = withRouter(UserMappingList);

export default withTranslation()(UserMappingListWithRouter);
UserMappingList.propTypes = {
    idMappingSettingsList: PropTypes.arrayOf(Object),
    folderDetail: PropTypes.arrayOf(Object),


};