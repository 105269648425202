import { fetchJson,METHOD_POST,createBaseOptions, } from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import { config } from "../config/Config";
import { showError } from "../utils/NotificationsHelper";



export const USER_CUSTOMIZATIONS_REQUESTED = 'USER_CUSTOMIZATIONS_REQUESTED';
/**
 * Action for request of workflow list.
 *
 * @return {UserCustomizationsAction}
 */
export const requestUserCustomizations = () => {
    return {
        type: USER_CUSTOMIZATIONS_REQUESTED
    };
};

export const USER_CUSTOMIZATIONS_RECEIVED = "USER_CUSTOMIZATIONS_RECEIVED";
/**
 *
 * @param {BaseEntryWithParent} userCustomizations
 * @return {ReceiveUserCustomizationsAction}
 */
export const receiveUserCustomizations = (userCustomizations) => {
    return {
        type: USER_CUSTOMIZATIONS_RECEIVED,
        userCustomizations: userCustomizations,
        receivedAt: Date.now()
    }
};

export const USER_CUSTOMIZATIONS_ERROR = 'USER_CUSTOMIZATIONS_ERROR';
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveUserCustomizationsErrorAction}
 */
export const receiveError = (
    error,
    type = USER_CUSTOMIZATIONS_ERROR,
    errorResponseStatusCode = null,
    errorResponseMessage = null
) => {
    return {
        type: type,
        error: error,
        errorResponseStatusCode: errorResponseStatusCode,
        errorResponseMessage: errorResponseMessage,
    }
};


/**
 * Fetch workflow list
 *
 * @param {boolean} update if is a update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchUserCustomizations = (update = false) => {
    return (dispatch, getState) => {
        if (update) {
            dispatch(requestUserCustomizations());
        } else {
            dispatch(requestUserCustomizations());
        }

        /** @type RequestOptions options **/
        let options = {
            method: "GET"
        };
     

        return fetchJson(`${config.url.API_URL}/usercustomization/custom/items/own`, options, dispatch)
            .then(json => {
                let res = [];
                if (json._embedded && json._embedded.userCustomizations && json._embedded.userCustomizations.length > 0) {
                    res = json._embedded.userCustomizations;
                }
                dispatch(receiveUserCustomizations(res));
            }
            ).catch(error => {
                if (error === "Ignoring request") {
                } else if (error instanceof ResponseStatusException) {
                    const description = error.response.message ? error.response.message : null;
                    dispatch(receiveError(error, USER_CUSTOMIZATIONS_ERROR, error.response.status, description));
                    showError(dispatch, error.message, description);
                } else {
                    dispatch(receiveError(error, USER_CUSTOMIZATIONS_ERROR));
                    showError(dispatch, error.message);
                }
            });
    }
};

