import PropTypes from 'prop-types';

export const idNameShape = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
});

export const idNameWithTypeShape = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string
});

export const idNameShapeWithBmcEntrySource = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    source: idNameWithTypeShape.isRequired
});

export const idNameShapeWithBmcEntryTarget = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    target: idNameWithTypeShape.isRequired
});

export const idNameTypeWithFoldersShape = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    folders: PropTypes.arrayOf(idNameWithTypeShape),
});