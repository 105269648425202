import React from "react";
import {isEmptyObject} from "../../utils/JsObjectHelper";
import PropTypes from "prop-types";
import {entryResultDetailShape, entryTypesRequestResult} from "../../shapes/RequestResult";
import EntryNewForm from "./EntryNewForm";
import { withTranslation} from 'react-i18next';

class EntryNew extends React.Component {

    constructor(props) {
        super(props);
        this.onMount = this.onMount.bind(this);
    }

    componentDidMount() {
        this.onMount();
    }

    /**
     * UNSAFE and deprecated lifecycle method!
     */
    /*UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.match.params.entryID !== this.props.match.params.entryID) {
            this.onMount();
        }
    }*/
    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.entryID !== prevProps.match.params.entryID) {
            this.onMount();
        }
    }


    onMount() {
        this.props.onMount();
    }

    render() {
        const {t} = this.props;

        /** @type {RequestResult} */
        const rr = this.props.parentEntryRequestResult;
        if(!(isEmptyObject(rr) || rr.getState().isLoading() || isEmptyObject(rr.getData()))) {
            /** @type {BaseEntryWithParent} */
            const entry = rr.getData();

            let headerText = <h1>{t('app.entry.new.lblCreateNew')} {(this.props.folderCreation ? t('app.entry.new.lblCreateFolder') : t('app.entry.new.lblCreateEntry'))} {t('app.entry.new.lblCreateIn')} {entry.name}</h1>;

            return <div>
                {headerText}
                <EntryNewForm 
                    parentData={entry} 
                    onSaveNew={this.props.onSaveNew} 
                    folderCreation={this.props.folderCreation} 
                    entryTypesRequestResult={this.props.entryTypesRequestResult} 
                    generalSettingsObject={this.props.generalSettingsObject}
                    />
            </div>;
        } else {
            return <p>&nbsp;</p>;
        }
    }
}
export default withTranslation() (EntryNew);

EntryNew.propTypes = {
    parentEntryRequestResult: entryResultDetailShape.isRequired,
    onSaveNew: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    folderCreation: PropTypes.bool.isRequired,
    entryTypesRequestResult: entryTypesRequestResult.isRequired
};

