import {fetchJson} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";
//import { isEmptyValue, isArray } from "../utils/JsObjectHelper";


export const fetchEntryLineage = (entryId, callback) => {
    let r = fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/projection/entryWithParent`);
    r.then(json => {
        callback(json);
    }).catch(error => {
        callback(null, error);
    });
};

export const fetchEntryRelOutgoingLineage = (entryId, callback) => {
    let r = fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/relations/outgoing/projection/relationWithTarget`);
    r.then(json => {
        let res = [];
        if (json._embedded && json._embedded.bmcEntryRelations && json._embedded.bmcEntryRelations.length > 0) {
            res = json._embedded.bmcEntryRelations;
        }
        callback(res);
    }).catch(error => {
        callback(null, error);
    });
};

export const fetchEntryRelIncomingLineage = (entryId, callback) => {
    let r = fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/relations/incoming/projection/relationWithSource`);
    r.then(json => {
        let res = [];
        if (json._embedded && json._embedded.bmcEntryRelations && json._embedded.bmcEntryRelations.length > 0) {
            res = json._embedded.bmcEntryRelations;
        }
        callback(res);
    }).catch(error => {
        callback(null, error);
    });
};