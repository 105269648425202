import {fetchJson} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";

export const fetchEntryDetail = (entryId, callback) => {
    let r = fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/projection/entryWithParent`);
    r.then(json => {
        callback(json);
    }).catch(error => {
        callback(null);
    });
};

export const fetchEntryDetailMinimal = (entryId, callback) => {
    let r = fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/projection/minimal`);
    r.then(json => {
        callback(json);
    }).catch(error => {
        callback(null);
    });
};