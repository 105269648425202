import React from "react";
import {withRouter, Link} from "react-router-dom";
import { Menu } from 'antd';
import { 
    TeamOutlined, 
    LayoutOutlined, 
    ApartmentOutlined, 
    PullRequestOutlined, 
    OrderedListOutlined, 
    SettingOutlined, 
    ImportOutlined, 
    AuditOutlined ,
    BuildOutlined,
    CalendarOutlined
} from '@ant-design/icons';
import { withTranslation} from 'react-i18next'

const { SubMenu } = Menu;

class SetupMenu extends React.Component {

    render () {
        const {t} = this.props;

        let itemKey = (this.props.setupType.split('_').length > 1 ? this.props.setupType.split('_')[1] : this.props.setupType);
        let groupKey = (this.props.setupType.split('_').length > 1 ? this.props.setupType.split('_')[0] : this.props.setupType);
        return (
            <Menu
                mode="inline"
                defaultSelectedKeys={[itemKey]}
                defaultOpenKeys={[groupKey + '_sub']}
                selectedKeys={[itemKey]}
                style={{ height: '100%' }}
                //inlineCollapsed={this.state.collapsed}
                //theme="dark"
                //onOpenChange={opnChanged}
                >
                <Menu.Item key="general"><Link to={`/setup/general`}><SettingOutlined />&nbsp;{t('setup.menu.generalSetup')}</Link></Menu.Item>
                <SubMenu
                    key="users_sub"
                    title={
                    <span>
                        <TeamOutlined />&nbsp;
                        {t('setup.menu.userManagement')}
                    </span>
                    }
                >
                    <Menu.Item key="users"><Link to={`/setup/users`}>{t('setup.menu.users')}</Link></Menu.Item>
                    <Menu.Item key="groups"><Link to={`/setup/groups`}>{t('setup.menu.groups')}</Link></Menu.Item>
                    <Menu.Item key="id_mapping"><Link to={`/setup/idmapping`}>{t('setup.menu.usersMapping')}</Link></Menu.Item>
                    <Menu.Item key="id2entrymapping"><Link to={`/setup/id2entrymapping`}>{t('setup.menu.id2EntryMapping')}</Link></Menu.Item>
                    {/* <Menu.Item key="domain"><Link to={`/setup/domain`}>{t('setup.menu.domainIntegration')}</Link></Menu.Item> */}
                </SubMenu>
                {/* <Menu.Item key="localization"><Link to={`/setup/localization`}><Icon type="notification" />{t('setup.menu.localization')}</Link></Menu.Item> */}
                {/* <Menu.Item key="integration"><Link to={`/setup/integration`}><Icon type="api" />{t('setup.menu.integrations')}</Link></Menu.Item> */}
                <Menu.Item key="content"><Link to={`/setup/content`}><LayoutOutlined />&nbsp;{t('setup.menu.contentStructure')}</Link></Menu.Item>
                <Menu.Item key="lineage"><Link to={`/setup/lineage`}><ApartmentOutlined />&nbsp;{t('setup.menu.lineage')}</Link></Menu.Item>
                <SubMenu
                    key="bpmn_sub"
                    title={
                    <span>
                        <PullRequestOutlined />&nbsp;
                        {t('setup.menu.bpmn')}
                    </span>
                    }
                >
                         <Menu.Item key="infobpmn"><Link to={`/setup/infobpmn`}>{t('setup.menu.infoForBpmn')}</Link></Menu.Item>
                         <Menu.Item key="workflow"><Link to={`/setup/workflow`}>{t('setup.menu.workflow')}</Link></Menu.Item>
                         <Menu.Item key="tasklist"><Link to={`/setup/tasklist`}>{t('setup.menu.tasklist')}</Link></Menu.Item>
                         <Menu.Item key="bpmnprocess"><Link to={`/setup/bpmnprocess`}>{t('setup.menu.bpmnProcess')}</Link></Menu.Item>
                </SubMenu>
                <SubMenu
                    key="loader_sub"
                    title={
                    <span>
                        <ImportOutlined />&nbsp;
                        {t('setup.menu.loader')}
                    </span>
                    }
                >
                    <Menu.Item key="loader"><Link to={`/setup/loader`}>{t('setup.menu.loaderSetup')}</Link></Menu.Item>
                    <Menu.Item key="loaderAudit"><Link to={`/setup/loaderAudit`}>{t('setup.menu.loaderAudit')}</Link></Menu.Item>{/* <AuditOutlined />&nbsp; */}
                </SubMenu>
                <Menu.Item key="reindex"><Link to={`/setup/reindex`}><OrderedListOutlined />&nbsp;{t('setup.menu.reindex')}</Link></Menu.Item>
                {/* <Menu.Item key="lookandfeel"><Link to={`/setup/lookandfeel`}><Icon type="skin" />{t('setup.menu.lookAndFeel')}</Link></Menu.Item> */}
            </Menu>
        );
    }
}


const SetupMenuWithRouter = withRouter(SetupMenu);

export default withTranslation() (SetupMenuWithRouter);
