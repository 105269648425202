import React from 'react';
import PropTypes from "prop-types";
import { Input, Button, Modal, Form, InputNumber, Checkbox, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { isUndefined } from '../../../utils/JsObjectHelper';

class LoadFormNameFolderModal extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            addModalVisible: false,
            initVals: { name: "", folderId: null },
            valueType: "string"
        };
    }

    /**
     * React lifecycle after update method
     * 
     * @param {*} prevProps 
     * @param {*} prevState 
     */
    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setState({ addModalVisible: true, initVals: this.props.itemValues }, () => {
                if (!isUndefined(this.formRef.current)) {
                    this.formRef.current.resetFields();
                }
            });
        }
    }

    handleSubmit = e => {
        e.preventDefault();

        this.formRef.current.validateFields()
            .then(values => {
                if (this.props.isEdit) {
                    this.props.onItemUpdated(values.name, values.folderId, this.props.itemValues);
                } else {
                    this.props.onItemAdd(values.name, values.folderId);
                }
                this.hideModal();
            })
            .catch(errorInfo => {
                console.log(errorInfo);
                return;
            });
    };

    hideModal = () => {
        this.setState({ addModalVisible: false, initVals: { name: "", value: null } }, () => {
            this.formRef.current.resetFields();
            this.props.onModalHide();
        });
    };

    openModalNew = () => {
        this.setState({ addModalVisible: true, initVals: { name: "", value: null } }, () => {
            this.formRef.current.resetFields();
        })
    };

    render() {
        const { t } = this.props;

        return [
            <Button key="addButton" size="small" icon={<PlusOutlined />} onClick={this.openModalNew}>
                {t('setup.loader.nameFolderItem.btnAddItem')}
            </Button>,
            <Modal
                title={t('setup.loader.nameFolderItem.titleAddItem')}
                key="addItemModal"
                onCancel={this.hideModal}
                okButtonProps={{ hidden: true }}
                visible={this.state.addModalVisible}>

                <Form layout="vertical"
                    name="horizontal_addUrl"
                    ref={this.formRef}
                    onFinish={this.handleSubmit}
                    initialValues={this.state.initVals}
                >
                    <Form.Item label={t('setup.loader.nameFolderItem.lblAddName')}
                        name="name"
                        rules={[{ required: true, message: t('setup.loader.nameFolderItem.msgAddName') }]}>
                        <Input
                            placeholder={t('setup.loader.nameFolderItem.lblAddName')}
                        />
                    </Form.Item>
                    <Form.Item label={t('setup.loader.nameFolderItem.lblFolderId')}
                        name="folderId"
                        rules={[{ required: true, message: t('setup.loader.nameFolderItem.msgFolderId') }]}>
                        <Input
                            placeholder={t('setup.loader.nameFolderItem.lblFolderId')}
                        />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (
                            <Button type="primary" icon={<PlusOutlined />} onClick={this.handleSubmit}
                                disabled={
                                    isUndefined(this.formRef.current) ||
                                    (!isUndefined(this.formRef.current) &&
                                        ((!this.formRef.current.isFieldsTouched(true) && !this.props.isEdit) ||
                                            this.formRef.current.getFieldsError().filter(({ errors }) => errors.length).length > 0)
                                    )
                                }
                            >
                                {(this.props.isEdit ? t('setup.loader.nameFolderItem.btnUpdateItem') : t('setup.loader.nameFolderItem.btnAddItem'))}
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        ];
    }
}

export default withTranslation()(LoadFormNameFolderModal);

LoadFormNameFolderModal.propTypes = {
    onItemAdd: PropTypes.func.isRequired,
    onItemUpdated: PropTypes.func.isRequired,
    onModalHide: PropTypes.func.isRequired,
    itemValues: PropTypes.object,
    isEdit: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool
};