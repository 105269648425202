import {createBaseOptions, fetchJson, METHOD_DELETE,} from "../../utils/SuperAgentFetch";
import {config} from "../../config/Config";


export const fetchBpmnDeleteAssignee = (taskId,assigneeId,callback) => {
    let delAssignee = {
        userId: assigneeId,
        type: "assignee",
      };
    
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_DELETE;
    options.headers["Content-Type"] = "application/json";
    options["body"] = delAssignee;
    
   
    let r = fetchJson(`${config.url.API_URL}/bpmn/task/${taskId}/identityLink`, options) // /async
    r.then(json => {
        callback()   
    })
    .catch(error => {
        callback()
    });
    

};