import React from "react";
import { CaretRightOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { Collapse, Table, Tag, } from 'antd';
import { withTranslation } from 'react-i18next'
import { isArray } from "../../../utils/JsObjectHelper";
const { Panel } = Collapse;

class ViewValidEntry extends React.Component {
    render() {
        const { t } = this.props
        let columns = [
            {
                title: t('setup.entryTypes.importExport.importTableTwoTitleAtt'),
                key: "name",
                dataIndex: 'name',
                width: "10%",
            },
            {
                title: t('setup.entryTypes.importExport.importFinalStepTableType'),
                key: "name",
                dataIndex: 'type',
                width: "10%",
            },
            {
                title: t('setup.entryTypes.importExport.importFinalStepTable'),
                width: "10%",
                render: (text, record) => {
                    let relationEntryType = [];
                    if (isArray(record.relationEntryType) && record.relationEntryType.length > 0) {
                        record.relationEntryType.forEach((relation) => {
                            let entryType = this.props.listDataRequestResult.find((et) => relation === et.type);
                            if (entryType) {
                                relationEntryType.push(<Tag color="blue" >{entryType.name}</Tag>)
                            }
                        });
                    }
                    if (!isArray(record.relationEntryType) && record.relationEntryType) {
                        let entryType = this.props.listDataRequestResult.find((et) => record.relationEntryType === et.type);
                        if (entryType) {
                            relationEntryType.push(<Tag color="blue" >{entryType.name}</Tag>)
                        }
                    }
                    return relationEntryType
                }
            },
        ]
        let panel = [];
        if (this.props.selectedEntries.length > 0) {
            this.props.selectedEntries.forEach((et, index) => {
                let icon = <span>{et.name}<CheckCircleTwoTone style={{ marginLeft: '8px' }} twoToneColor="#52c41a" /></span>
                panel.push(<Panel header={icon} key={index}>
                    <Table
                        key={index + 'table'}
                        rowKey={record => record.name}
                        dataSource={et.properties.attributes}
                        size="small"
                        pagination={false}
                        scroll={{ x: true }}
                        columns={columns} />
                </Panel>)
            })
        }
        return <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0}style={{ backgroundColor: 'white' }} />} ghost >{panel}</Collapse>
    }
}

export default withTranslation()(ViewValidEntry)