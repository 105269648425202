import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { fetchDetailFiles, unmountDetailFiles } from "../../actions/entryFiles";
import EntryFilesPresenter from '../../components/detail/view/EntryFiles';

const mapStateToProps = (state, ownProps) => {
    return {
        entryFilesRequestResult: state.entryDetailFiles,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: (entryId, shouldUpdateOnly = false) => {
            dispatch(fetchDetailFiles(entryId, shouldUpdateOnly));
        },
        onUnmount: () => {
            dispatch(unmountDetailFiles());
        },
        refreshList: (entryId,shouldUpdateOnly = true) =>{
            dispatch(fetchDetailFiles(entryId, shouldUpdateOnly));
        },
    }
};

const EntryFiles = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryFilesPresenter);

export default withRouter(EntryFiles);