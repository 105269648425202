import PropTypes, { bool, number, string } from "prop-types";

export const baseLoadSettingCustomShape = PropTypes.shape({
    name: string,
    value: PropTypes.oneOfType([string, bool, number])
});

export const baseLoadSettingFolderDetailShape = PropTypes.shape({
    name: string,
    folderId: string
});

export const baseLoadSettingFoldersShape = PropTypes.shape({
    write: PropTypes.arrayOf(baseLoadSettingFolderDetailShape),
    read: PropTypes.arrayOf(baseLoadSettingFolderDetailShape)
});

export const baseLoadSettingValueShape = PropTypes.shape({
    customProps: PropTypes.arrayOf(baseLoadSettingCustomShape),
    folders: baseLoadSettingFoldersShape
});

export const baseLoadSettingShape = PropTypes.shape({
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    value: baseLoadSettingValueShape
});


