import {createBaseOptions, fetchJson, METHOD_POST} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";
import { isEmptyValue, isArray } from "../utils/JsObjectHelper";

let currentValue = {};

/**
 * 
 * @param {String} searchTerm 
 * @param {Function<Array[Object]>} callback 
 * @param {Array[String]} entryTypeArr 
 * @param {Array[String]} entrySystemTypeArr 
 * @param {Array[String]} entryParentIdArr 
 * @param {object} dynamicConditions 
 */
export const fetchEntries = (searchTerm,callName = 'default', callback, entryTypeArr = null, entrySystemTypeArr = null, entryParentIdArr = null, dynamicConditions = null,size=10000) => {
    currentValue[callName] = searchTerm;
    let searchedTerm = {
        terms: searchTerm
    };
    if(searchTerm && searchTerm.length > 0) {
        if (searchTerm.indexOf('*') < 0 && searchTerm.indexOf('"') < 0) {
            //Prozatim se vyhledavani obohati o hvezdicku na zacatku. Pak bude potreba doresit lepsi tokenizaci a hledani blizkych vyskytu.
            if (searchTerm.indexOf(':') < 0) {
                //start hvezdicku pridame jen v pripade ze se nejedna o specialni hledani podle atributu (hlavne u hledani id:XY)
                searchedTerm.terms = "*";
            }
            else {
                searchedTerm.terms = "";
            }
            searchedTerm.terms += searchTerm.trim().split(" ").join("* ") + "*";
        }
        else
            searchedTerm.terms = searchTerm.trim();
    } else {
        callback([]);
        return;
    }

    if (!isEmptyValue(entryTypeArr)) {
        if (!isArray(entryTypeArr)) {
            entryTypeArr = [entryTypeArr];
        }
        searchedTerm["types"] = entryTypeArr;
    }

    if (!isEmptyValue(entrySystemTypeArr)) {
        if (!isArray(entrySystemTypeArr)) {
            entrySystemTypeArr = [entrySystemTypeArr];
        }
        searchedTerm["systemTypes"] = entrySystemTypeArr;
    }

    if (!isEmptyValue(entryParentIdArr)) {
        if (!isArray(entryParentIdArr)) {
            entryParentIdArr = [entryParentIdArr];
        }
        searchedTerm["parents"] = entryParentIdArr;
    }

    if (!isEmptyValue(dynamicConditions)) {
        searchedTerm["dynamicConditions"] = dynamicConditions;
    }

    let options = createBaseOptions();
    options.method = METHOD_POST;
    options.headers["Content-Type"] = "application/json";
    options["body"] = searchedTerm;

    let r = fetchJson(config.url.API_URL + "/bmcEntryDocs/custom/search/searchForAdvancedSearch?size="+size, options);
    r.then(json => {
        if (currentValue[callName] === searchTerm) {
            if(json._embedded && json._embedded.bmcEntryDocs && json._embedded.bmcEntryDocs.length > 0) {
                callback(json._embedded.bmcEntryDocs);
            } else {
                callback([]);
            }
        }
    })   .catch(error => {
        console.log(error);
          });
};


export const fetchCurrentEntryVersionId = (entryId, callback) => {
    let r = fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/version/id`);
    r.then(json => {
        if(json.content) {
            callback(json.content);
        } else {
            callback(null);
        }
    });
};