import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { Button, Dropdown, Menu,Popover } from 'antd';
import { FolderAddOutlined, FileAddOutlined, DownOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
    entryResultDetailShape,
} from "../../shapes/RequestResult";
import PropTypes from "prop-types";
import { isUndefined, isEmptyObject } from "../../utils/JsObjectHelper";
import { withTranslation } from 'react-i18next'
import { isTablet, isMobile } from '../../utils/AdaptiveLayout';

class CreateNewButton extends React.Component {
    render() {
        const { t } = this.props;

        /** @type {RequestResult} */
        const rr = this.props.entryRequestResult;
        if (
            this.props.visible
            && !(isEmptyObject(rr) || rr.getState().isLoading() || isEmptyObject(rr.getData()))
            && (rr.getData().userEntryAccess === "EDIT" || rr.getData().userEntryAccess === "VIEW_PARENT_EDIT_CHILD")
        ) {
            /** @type {BaseEntryWithParent} */
            const entry = rr.getData();

            let createFolderBtn = null;
            let createChildBtn = null;
            let createChildBtnMobile = null;
            let createFolderBtnMobile = null;

            if (isUndefined(entry.properties) || isEmptyObject(entry.properties) ||
                (!isUndefined(entry.properties.childFolderEntryTypes) && !isEmptyObject(entry.properties.childFolderEntryTypes) && entry.properties.childFolderEntryTypes.length > 0)
            ) {
                createFolderBtn = <Menu.Item key="1"><Link to={`/entry/${entry.id}/createNewFolder`}><FolderAddOutlined /> {t('app.header.createNewBtnFolder')}</Link></Menu.Item>;
                createFolderBtnMobile = <Link to={`/entry/${entry.id}/createNewFolder`}><FolderAddOutlined style={{ fontSize: '34px' }} /> </Link>
            }

            if (isUndefined(entry.properties) || isEmptyObject(entry.properties) ||
                (!isUndefined(entry.properties.childObjectEntryTypes) && !isEmptyObject(entry.properties.childObjectEntryTypes) && entry.properties.childObjectEntryTypes.length > 0)
            ) {
                createChildBtn = <Menu.Item key="2"><Link to={`/entry/${entry.id}/createNew`}><FileAddOutlined /> {t('app.header.createNewBtnEntry')}</Link></Menu.Item>;
                createChildBtnMobile = <Link to={`/entry/${entry.id}/createNew`}><FileAddOutlined style={{ fontSize: '28px', marginLeft: '5px' }} /> </Link>
            }

            const menu = (
                <Menu
                    style={{ width: 100 }}
                    mode="inline"
                >
                    {createFolderBtn}
                    {createChildBtn}
                </Menu>
            );
            let button = <Dropdown overlay={menu} disabled={!createFolderBtn && !createChildBtn} >
                <Button size="large" type="primary" ghost >{t('app.header.createNewBtnHint')}<DownOutlined /></Button>
            </Dropdown>;
            if (isMobile()) {
                button =  <Popover
                placement="bottomLeft"
              
                content={menu}
                trigger="click"
            >
                <PlusCircleOutlined style={{fontSize:'32px'}}/>
                
            </Popover> 
            }
            if (isTablet()) {
                button = <>{createFolderBtnMobile}{createChildBtnMobile} </>
            }

            return <>{button}</>
        } else {
            return <span>&nbsp;</span>;
        }
    }
}

const CreateNewButtonWithRouter = withRouter(CreateNewButton);

export default withTranslation()(CreateNewButtonWithRouter);

CreateNewButton.propTypes = {
    entryRequestResult: entryResultDetailShape,
    visible: PropTypes.bool.isRequired
};