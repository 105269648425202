import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import Notifications from 'react-notification-system-redux';

export default class Notification extends PureComponent {

    render() {
        const {notifications} = this.props;

        //Optional styling
        const style = {
            NotificationItem: { // Override the notification item
                DefaultStyle: { // Applied to every notification, regardless of the notification level
                    margin: '10px 5px 2px 1px',
                    background: '#fff',
                    borderRadius: 'unset',
                    boxShadow: '0 0 10px #999'
                },

                success: { // Applied only to the success notification item
                    // color: 'green'
                }
            }
        };

        return (
            <Notifications
                notifications={notifications}
                style={style}
                allowHTML={true}
            />
        );
    }
}

Notification.propTypes = {
    notifications: PropTypes.array
};