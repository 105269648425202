import SetupMainPresenter from '../components/SetupMain';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";

const mapStateToProps = (state, ownProps) => {
    return {
        setupType: (ownProps.match.params.setuptype !== undefined ? ownProps.match.params.setuptype : "general"),
        userRealmRoles :state.loggedUser.user.realmRoles
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // onMount: () => {
        // },
        // onUnmount: () => {
        // },
    }
};

const Setup = connect(
    mapStateToProps,
    mapDispatchToProps
)(SetupMainPresenter);

export default withRouter(Setup);